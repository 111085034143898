import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {NavLink} from 'react-router-dom';
import {HTML_SPACE, ID} from "../../Constants";
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import PropTypes from "prop-types";
import {GetEditableClassNames, getLocalName, getResourceId, toArray} from "../../components/util";
import {WithAdminEdit} from "./WithAdminEdit";
import {
    SETTINGS_EDIT_CARD_DESCRIPTION,
    SETTINGS_EDIT_CARD_IMAGE,
    SETTINGS_EDIT_CARD_OTHER_PROPERTIES,
    SETTINGS_EDIT_CARD_TITLE
} from "./Workspace";

export const CARD_WIDTH = 262;

const styles = theme => ({
    card : {
        maxWidth: CARD_WIDTH,
        width: CARD_WIDTH,
        color : theme.palette.primary.main
    },
    cardLink: {
        textDecoration: 'none'
    },
    media: {
        height: 160,
    },
    action : {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#B83D1C',
            color: '#FFFFFF'
        }
    }
    ,
    description: {
        height: '100px',
        borderTop: `2px solid ${theme.palette.secondary.main}`,
        color : theme.palette.primary.main
    },
    cardTitle: {
        fontFamily:'Rajdhani, sans-serif',
        letterSpacing:'0',
        fontSize:'24px',
        textAlign: 'left'
    },
    avatar: {
        backgroundColor: 'light',
    },
});

class ResultCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
    }

    getCard = () => {
        const {theme, customizations, variant, onItemFocus, resource, classes, url, title, description, thumbnail, letter, otherProperties, actionAreaFooter } = this.props;
        let {focus} = this.state
        let style = focus  ? {
            cursor: 'pointer',
            color : theme.palette.primary.main
        } : {}

        const cardActionArea = <CardActionArea
            style={style}
        >
            <>
                {
                    thumbnail ?
                        <CardMedia
                            datatest={'cardMedia'}
                            style={{backgroundColor: theme.palette.grey.main}}
                            className={classes.media+" " +GetEditableClassNames(SETTINGS_EDIT_CARD_IMAGE, getResourceId(resource), undefined, customizations)}
                            component="div"
                            alt={title}
                            image={thumbnail}
                            title={title}
                        >
                            <WithAdminEdit
                                style={{marginTop :'8px'}}
                                data={{
                                    action: SETTINGS_EDIT_CARD_IMAGE,
                                    className : 'editableComponentHighlightOffsetIn3',
                                    id : getResourceId(resource),
                                    resource, customizations
                                }}
                            /></CardMedia>
                        : <div datatest={'cardTitleMain'} className={GetEditableClassNames(SETTINGS_EDIT_CARD_IMAGE, getResourceId(resource), undefined, customizations)}>
                            <WithAdminEdit
                                style={{marginTop :'0px'}}
                                data={{
                                    action: SETTINGS_EDIT_CARD_IMAGE,
                                    className : 'editableComponentHighlightOffsetIn3',
                                    id : getResourceId(resource),
                                    resource, customizations
                                }}
                            />
                            <div>
                                <Typography
                                    style={{padding : '16px', borderBottom: this.isButtonCard() ? `2px solid ${theme.palette.secondary.main}` : undefined}}
                                    noWrap={true}
                                    gutterBottom
                                    variant="h2"
                                    component="h2"
                                    className={classes.cardTitle+" " +GetEditableClassNames(SETTINGS_EDIT_CARD_TITLE, getResourceId(resource), 'editableComponentOutlineIn16', customizations)}
                                >
                                    <WithAdminEdit
                                        style={{marginTop :'0px', marginLeft : '24px'}}
                                        data={{
                                            action: SETTINGS_EDIT_CARD_TITLE,
                                            id : getResourceId(resource),
                                            className : 'editableComponentHighlightOffsetIn16',
                                            resource, customizations
                                        }}
                                    />
                                    {title || getLocalName(resource?.[ID]) || HTML_SPACE}
                                </Typography>
                            </div>
                        </div>
                }
            </>
            {
                this.isButtonCard() ? <></> :
                    <CardContent className={classes.description} style={this.isButtonCard() ? {height: '0px'} : {}}>
                        {
                            this.isButtonCard() ? <></>
                                :
                                <>
                                    {thumbnail && <Typography
                                        datatest={'cardContentTitle'}
                                        noWrap={true}
                                        gutterBottom
                                        variant="h2"
                                        component="h2"
                                        className={classes.cardTitle+" " +GetEditableClassNames(SETTINGS_EDIT_CARD_TITLE, getResourceId(resource), undefined, customizations)}
                                    >
                                        <WithAdminEdit
                                            style={{marginTop :'-8px', marginLeft : '0px'}}
                                            data={{
                                                action: SETTINGS_EDIT_CARD_TITLE,
                                                id : getResourceId(resource),
                                                resource, customizations
                                            }}
                                        />
                                        {title}
                                    </Typography>}
                                    {
                                            <Typography
                                            datatest={'description'}
                                            noWrap={true}
                                            variant="body2"
                                            component="div"
                                            className={GetEditableClassNames(SETTINGS_EDIT_CARD_DESCRIPTION, getResourceId(resource), undefined, customizations)}
                                        >
                                            <WithAdminEdit
                                                style={{marginTop :'-8px', marginLeft : '0px'}}
                                                data={{
                                                    action: SETTINGS_EDIT_CARD_DESCRIPTION,
                                                    id : getResourceId(resource),
                                                    resource, customizations
                                                }}
                                            />
                                             {description ? description : HTML_SPACE}
                                        </Typography>
                                    }
                                    {
                                        <div datatest={'otherProperties'} style={{minHeight: '40px'}}
                                             className={GetEditableClassNames(SETTINGS_EDIT_CARD_OTHER_PROPERTIES, getResourceId(resource), undefined, customizations)}>
                                            <WithAdminEdit
                                                style={{marginTop: '-8px', marginLeft: '40px'}}
                                                data={{
                                                    action: SETTINGS_EDIT_CARD_OTHER_PROPERTIES,
                                                    id: getResourceId(resource),
                                                    resource, customizations
                                                }}
                                            />
                                            {
                                                toArray(otherProperties).map((p, index) => {
                                                    return <div datatest={'otherProperty'} key={index} style={{
                                                        marginTop: '8px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flexWrap: 'wrap'
                                                    }}>

                                                        {p.icon || <LayersOutlinedIcon color={'secondary'}
                                                                                       fontSize={'small'}/>}
                                                        <Typography style={{marginLeft: '16px', maxWidth: '190px'}}
                                                                    noWrap={true}
                                                                    variant="body2" color={"inherit"}
                                                                    component="span">{p.value || HTML_SPACE}</Typography>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    }
                                </>
                        }
                    </CardContent>
            }
            {focus && onItemFocus ? <></> : this.props.actionAreaFooter && this.props.actionAreaFooter()}
        </CardActionArea>;

        return <Card id={resource?.[ID]} datatest={'applicationCard'} className={classes.card}
                                           elevation={focus ? 12 : 1}
                                           onFocus={(e) => {
                                               this.setState({focus: true})
                                           }}
                                           onBlur={(e) => {
                                               this.setState({focus: false})
                                           }}
                                           onMouseEnter={(e) => this.setState({focus: true})}
                                           onMouseLeave={(e) => this.setState({focus: false})}

        >
            {
                url ? <NavLink style={{color: theme.palette.primary.main}} to={url}>
                    {cardActionArea}
                </NavLink>
                    : cardActionArea
            }
            {
                focus && onItemFocus &&
                <div>
                    <div style={{width: '100%', backgroundColor: theme.palette.grey.main, height: '1px'}}></div>
                    <div datatest={'summary'} style={{padding: '7px 8px'}}>

                        {onItemFocus(resource)}
                    </div>
                </div>
            }
        </Card>;
    }

    isButtonCard = () => {
        const {variant} = this.props;
        return variant === 'button';
    }

    render() {
        const {theme, target, classes, url, title, description, thumbnail, letter, backgroundColor } = this.props;

        let card = this.getCard(theme, classes, backgroundColor, letter, title, thumbnail, description);
        return <div className={classes.card}>
            {card}
        </div>;
    }


}

ResultCard.propTypes = {
    location: PropTypes.object,
    resource: PropTypes.object,
    actionAreaFooter: PropTypes.func,
    otherProperties: PropTypes.array,
    onSaveSettings: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
    thumbnail: PropTypes.string,
    url: PropTypes.string,
    variant: PropTypes.string,
    onItemFocus: PropTypes.func,
    customizations: PropTypes.any
};

export default withStyles(styles, {withTheme:true})(ResultCard);
