import React, {useState} from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {
    Badge,
    Card,
    IconButton,
    MenuItem,
    Paper,
    Popper,
    TextField as OtherTextField,
    TextField,
    Typography
} from "@material-ui/core";
import H4Title from "../../components/H4Title";
import PropTypes from "prop-types";
import {
    ALIAS_SH_DATATYPE,
    ALIAS_SYS_FACET_LABEL,
    ALIAS_SYS_FACET_RESULTS,
    ALIAS_SYS_FACET_VALUE,
    ALIAS_SYS_FACET_VALUE_COUNT,
    ALIAS_SYS_FACET_VALUE_LABEL,
    ALIAS_SYS_FACETS,
    ALIAS_SYS_RESULTS,
    AT_DEFAULT,
    BETWEEN,
    EQUAL_TO,
    EXISTS,
    ID,
    NOT_EQUAL_TO,
    OBJECT,
    PAGE,
    PAGE_SIZE,
    QUERY,
    SORT_BY,
    TEXT,
    TYPE,
    VALUE,
    VALUE_END_KEY,
    XSD_DATE,
    XSD_DATETIME,
    XSD_TIME
} from "../../Constants";
import {
    centerVertically,
    excludeHidden,
    getBrowseLanguageCode,
    GetEditableClassNames,
    getLocalName,
    getMultilingualValue,
    getPropertyName,
    getUiLabelTranslationFromContext,
    handleBackendError,
    isEmptyArray,
    scrollToView,
    sort,
    sortByFunction,
    toArray,
    typesOptionsForLanguage
} from "../../components/util";
import {getChipWithDelete, getLanguageLabelForSite, getTypesOptions} from "../apiplayground/SearchFilter";
import {DateTimePickerComponent} from "../../components/ShapeToForm/DateTimePicker";
import {DatePickerComponent} from "../../components/ShapeToForm/DatePicker";
import {TimePickerComponent} from "../../components/ShapeToForm/TimePicker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import {
    getUiLabelTranslation,
    UI_LABELS_AI_VIEW,
    UI_LABELS_ANY,
    UI_LABELS_FILTERS,
    UI_LABELS_FROM,
    UI_LABELS_TO
} from "./UILabel";
import List from '@material-ui/core/List';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import H2Title from "../../components/H2Title";
import {
    ArrowUpwardOutlined,
    AssistantOutlined,
    CloseOutlined,
    FilterListOutlined,
    KeyboardVoiceOutlined
} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import GlobalsContext from "../../components/GlobalsContext";
import {WithAdminEdit} from "./WithAdminEdit";
import {
    getSiteHomePath,
    SETTINGS_EDIT_ACTION_AI_SETTINGS,
    SETTINGS_EDIT_ACTION_BANNER_SEARCH,
    SETTINGS_EDIT_ACTION_BANNER_SEARCH_PAPER_TITLE,
    SETTINGS_EDIT_ACTION_SEARCH_LEFT_CARD_ALL,
    SETTINGS_EDIT_ACTION_SEARCH_LEFT_CARD_FILTER,
    SETTINGS_EDIT_ACTION_TRANSLATION
} from "./Workspace";
import CircularProgress from "@material-ui/core/CircularProgress";
import history from "../../history";
import uuid4 from "uuid/v4";
import {isAIEnabledInSettings, isAISearchForUserEnabled} from "./BasicSetup";
import {isSuperadmin} from "../common/Profile";
import {getBaseEndpointWithInstance, getData} from "../../service/graph-api";
import {BACKEND_PATH_MANAGEMENT_GRAPH_SEARCH} from "../../service/backend-paths";


const styles = theme => ({
    card : {
        width: 262,
        color : theme.palette.primary.main
    },
    cardLink: {
        textDecoration: 'none'
    },
    media: {
        height: 160,
    },
    action : {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#B83D1C',
            color: '#FFFFFF'
        }
    }
    ,
    description: {
        height: '100px',
        borderTop: `2px solid ${theme.palette.secondary.main}`,
        color : theme.palette.primary.main
    },
    cardTitle: {
        fontFamily:'Rajdhani, sans-serif',
        letterSpacing:'0',
        fontSize:'24px',
        textAlign: 'left'
    },
    avatar: {
        backgroundColor: 'light',
    },
});



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    }
}));

const ListItemTextForFacet = withStyles({

    primary : {
        fontFamily : 'unset',
        letterSpacing: '0',
        fontSize:'unset',
        fontWeight: 'unset',
        overflowWrap: 'break-word'
    }

})(ListItemText);

function CheckboxList({listValues, checkedValues = [], onChange }) {
    const classes = useStyles();
    const [showCount, setShowCount] = useState(10);

    const normalisedCheckedValues = toArray(checkedValues).map(cvo => {
        let label = cvo.label;
        let value = cvo.value;
        if (!label || !value) {
            let found = listValues.find(lv => lv.value === (cvo.value || cvo));
            label = found && !label ? found.label : label;
            value = found && !value ? found.value : value;
        }
        return {value, label};
    }).filter(ob => ob.value);

    const isChecked = (vo) => {
        let value = vo.value;
        let checkedIndex = normalisedCheckedValues.map(vo => vo.value).indexOf(value);
        return checkedIndex !== -1;
    }

    const handleToggle = (valueObject, isChecked) => () => {
        let newChecked = [...checkedValues];
        if (isChecked) {
            let checkedIndex = checkedValues.map(vo => vo.value).indexOf(valueObject.value);
            newChecked.splice(checkedIndex, 1);
        } else {
            newChecked.push(valueObject);
        }
        newChecked = newChecked.filter(vo => vo.value);
        onChange(newChecked);
    };
    let subList = (listValues.slice(0, showCount));
    return (
        <div>
            <div style={{padding : '8px', overflow : 'auto'}}>
            {
                normalisedCheckedValues.map((cvo, index) => {
                    let label = cvo.label;
                    let value = cvo.value;
                    return <Chip  key={value + index} datatest={'chip-'+label} style={{marginTop : '8px', marginRight : '8px'}} size={'small'} label={label}  onDelete={handleToggle(cvo, true)}/>
                })
            }
            </div>
        <div style={{marginTop : '8px', maxHeight : '200px', overflow : 'auto'}}>
        <List datatest={'list'} className={classes.root}>
            {subList.map((valueObject, index) => {
                let {label, value, count, tooltip} = valueObject;
                const labelId = `checkbox-list-label-${label}`;
                let isOptionChecked = isChecked(valueObject);
                return (
                    <ListItem datatest={label} key={tooltip+label} role={undefined} dense button onClick={handleToggle(valueObject, isOptionChecked)}>
                        <ListItemIcon>
                            <Checkbox
                                datatest={'checkbox'}
                                edge="start"
                                checked={isOptionChecked}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                        </ListItemIcon>
                        <ListItemTextForFacet id={labelId} primary={`${label}`} />
                        <ListItemSecondaryAction style={{paddingRight: '8px' }}>
                            <Badge datatest={'count'} color="secondary" max={999} badgeContent={count} />
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            })}
        </List>
            {listValues[showCount] && <Button onClick={() => setShowCount(showCount + 10)} size={'small'} variant={'text'} color={'secondary'}>Show More</Button>}
        </div>
        </div>
    );
}


function getFacetValues(facetObject) {
    let array = toArray(facetObject?.[ALIAS_SYS_FACET_RESULTS]);
    return array;
}

export function showAISearchForUser(settings) {
    return isSuperadmin() ? isAIEnabledInSettings(settings) : isAIEnabledInSettings(settings) && isAISearchForUserEnabled(settings);
}

export function GetTextSearchField({textSearch, browseLanguage, onChange, settings, languageStyle = {}, location}) {
    const [listening, setListening] = useState(false);
    const [searchChange, setSearchChange] = useState();
    const [popupOpen, setPopupOpen] = useState();
    const [autocompleteOptions, setAutocompleteOptions] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState();
    const [anchorPopup, setAnchorPopup] = useState();
    const [inputRef, setInputRef] = useState();
    const [backupText, setBackupText] = useState();
    let searchValue = textSearch.q && textSearch.q[0];
    //no lang code or lang code is not in the options set default
    if(textSearch.lang === undefined || toArray(settings.browseLanguages).find(bl => bl.value === textSearch.lang) === undefined) {
        textSearch.lang = getBrowseLanguageCode(browseLanguage);
    }

    const handleAutocomplete = async (text) => {
        if(!text) {
            setAutocompleteOptions([]);
            return
        }
        let query = `{type(eq:["UserMessage"]) assistantMessage{userFeedback(eq:["1"])} content(q:["${text}"])}`;
        const searchParams = {
            [QUERY]: query,
            [PAGE]: 1,
            [PAGE_SIZE]: 100,
            [SORT_BY]: '{ createdOn (order:"descending") }'
        }
        const searchResponse = await getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_GRAPH_SEARCH, searchParams).catch(handleBackendError(this));
        if(textSearch.q[0] === text) {
            const jsonData = await searchResponse.json();
            const content = jsonData[ALIAS_SYS_RESULTS].map(i => i.content).filter(txt => txt && !txt.trim().startsWith("User question"));
            const options = new Set(content);
            if(textSearch.q[0] === text) {
                setAutocompleteOptions([...options].sort().slice(0, 10));
            }
        }
    }

    const updateText = (value) => {
        if (!textSearch.q) {
            textSearch.q = []
        }
        textSearch.q[0] = value;
        setSearchChange(uuid4());
    }

    const moveUp = () => {
        if (selectedIndex > 0) {
            const newIndex = selectedIndex-1;
            setSelectedIndex(newIndex);
            updateText(autocompleteOptions[newIndex]);
            const node = document.getElementById("item-"+newIndex);
            scrollToView(node, 'instant')
        } else {
            setSelectedIndex(undefined);
            updateText(backupText);
        }
    }

    const moveDown = () => {
        if(selectedIndex === undefined) {
            setSelectedIndex(0);
            setBackupText(textSearch.q[0]);
            updateText(autocompleteOptions[0])
        } else if (selectedIndex < autocompleteOptions.length - 1) {
            const newIndex = selectedIndex +1;
            setSelectedIndex(newIndex);
            const node = document.getElementById("item-"+newIndex);
            scrollToView(node, 'instant')
            updateText(autocompleteOptions[newIndex]);
        }
    }

    const onKeyPressed = (e) => {
        if (e.keyCode == '38') {
            // up arrow
            moveUp();
        } else if (e.keyCode == '40') {
            // down arrow
            moveDown();
        }
    }

    return <div className={GetEditableClassNames(SETTINGS_EDIT_ACTION_BANNER_SEARCH)} style={{display : 'flex'}}>
        <WithAdminEdit
            style={{marginTop: '32px' }}
            data={{action: SETTINGS_EDIT_ACTION_BANNER_SEARCH}}
        />
        {
            textSearch?.ui?.enableLanguageSelect === true &&
            <TextField
                datatest={'mainSearchLanguageSelect'}
                style={{minWidth: '80px', maxWidth: '80px', marginRight: '16px', ...languageStyle}}
                id="standard-select-language"
                size={'medium'}
                variant={'outlined'}
                margin={'normal'}
                select
                label="Language"
                value={textSearch.lang}
                onChange={(event) => {
                    textSearch.lang = event.target.value;
                    onChange();
                }}
            >
                {settings && toArray(settings.browseLanguages).map(l => <MenuItem
                    datatest={'mainSearchLanguageSelect-'+l.value}
                    key={l.value}
                    value={l.value}>{l.label}{" (" + l.value + ")"}</MenuItem>)}
            </TextField>
        }

        {
            centerVertically(
                <div ref={(ref) => setAnchorPopup(ref)}>
    <TextField
        datatest={'mainSearchText'}
        autoComplete='off'
        margin={'normal'}
        size={'medium'}
        InputProps={{
            startAdornment : <SearchIcon style={{marginLeft : '8px', marginRight : '8px'}} color={'secondary'}/>,
            endAdornment: <div style={{ display : 'flex', gap : '8px', marginRight : '8px'}}>

                {searchValue  && <>
                    <IconButton
                        size={'small'}
                        onClick={onChange}
                    ><ArrowUpwardOutlined/></IconButton>
                    <IconButton
                        datatest={'mainSearchTextClearButton'}
                        onClick={() => {
                            textSearch.q[0] = "";
                            onChange();
                        } }
                        size={'small'}>
                            <ClearIcon></ClearIcon>
                    </IconButton>
                </>
                }
                {
                    window.webkitSpeechRecognition && listening === false &&
                    <IconButton
                        color={'primary'}
                        datatest={'speakButton'}
                        onClick={() => {
                            let recognization = new window.webkitSpeechRecognition();
                            recognization.onstart = () => {
                                setListening(true);
                            }
                            recognization.onresult = (e) => {
                                var transcript = e.results[0][0].transcript;
                                textSearch.q[0] = transcript;
                                setListening(false);
                                onChange()
                            }
                            recognization.start();
                        }}
                        size={'small'}>
                        <KeyboardVoiceOutlined></KeyboardVoiceOutlined>
                    </IconButton>
                }
                {listening && centerVertically(<CircularProgress size={24}></CircularProgress>)}
                {listening && centerVertically("Listening...")}
                {
                    listening && <IconButton
                        color={'primary'}
                        datatest={'speakButton'}
                        onClick={() => {
                            setListening(false);
                        }}
                        size={'small'}>
                        <CloseOutlined></CloseOutlined>
                    </IconButton>
                }
            </div>,
            margin : '4'
        }}
        fullWidth={true}
        variant={"outlined"}
        onChange={(value) => {
            if (!textSearch.q) {
                textSearch.q = []
            }
            if(!popupOpen) {
                setPopupOpen(true)
            }

            textSearch.q[0] = value.target.value;
            if(isAIEnabledInSettings(settings)) {
                if(isSuperadmin() || isAISearchForUserEnabled(settings)) {
                    handleAutocomplete(textSearch.q[0]);
                }
            }
            setSearchChange(uuid4());
        }}
        onKeyDown={(e) => {
            if(e.key === 'Enter'){
                setPopupOpen(false);
                setAutocompleteOptions([]);
                onChange();
            } else {
                onKeyPressed(e);
            }
        }}
        value={searchValue || ""}

    />
                    {
                        autocompleteOptions.length > 0 &&
                        <Popper
                            id={'logout'}
                            open={popupOpen}
                            anchorEl={anchorPopup}
                            placement="bottom-start"
                            disablePortal={true}
                            modifiers={{
                                flip: {
                                    enabled: false,
                                }
                            }}
                            style={{ width: anchorPopup?.clientWidth, zIndex :  1300, marginTop : '-8px'}}
                        >
                            <Paper datatest={'autocompleteOptions'} style={{width: '100%', maxHeight : '300px', overflow : "auto"}}>
                                <List dense={true} onKeyDown={onKeyPressed} component="nav" aria-label="main mailbox folders">
                                {
                                    autocompleteOptions.map((ao, index) => {
                                        return <ListItem id={"item-"+index} key={index} selected={selectedIndex === index } button onClick={() => {
                                            textSearch.q[0] = ao;
                                            setPopupOpen(false);
                                            setAutocompleteOptions([]);
                                            onChange();
                                        }}><ListItemText primary={ao} /></ListItem>
                                    })
                                }
                                </List>
                            </Paper>
                        </Popper>
                    }
                </div>, {flexGrow : '1'})
        }
        {
            showAISearchForUser(settings) ?
            centerVertically(
                <div className={GetEditableClassNames(SETTINGS_EDIT_ACTION_AI_SETTINGS)} style={{display : 'flex'}}>
                    <WithAdminEdit
                        data={{action: SETTINGS_EDIT_ACTION_AI_SETTINGS}}
                    />
                <Tooltip title={getUiLabelTranslation(settings, UI_LABELS_AI_VIEW, browseLanguage, UI_LABELS_AI_VIEW)}>
                <IconButton
                    color={'primary'}
                    datatest={'aiViewButton'}
                    onClick={() => {
                        let path = getSiteHomePath(location) + '/view/pageForAI';
                        history.push(`${path}`);
                    }}
                >
                    <AssistantOutlined/>
                </IconButton></Tooltip></div>,
                {marginLeft : "16px",paddingTop : '10px'}
            ) : <div className={GetEditableClassNames(SETTINGS_EDIT_ACTION_AI_SETTINGS)} style={{display : 'flex'}}>
                    <WithAdminEdit
                        data={{action: SETTINGS_EDIT_ACTION_AI_SETTINGS}}
                    />
            </div>
        }
        </div>;
}

const StyledFormControlForSelect = withStyles((theme) => ({
    root: {
        minWidth: "80px",
        maxWidth: "80px",
        marginRight: "16px",
        "& .MuiInput-underline": {
            borderBottom: "none",
            "&:focus,&:hover,&$active": {
                "&:not(.Mui-disabled):before": {
                    borderBottom: "none"
                },
                ":before": {
                    borderBottom: "none"
                }
            }
        },
        "& .MuiInput-underline::before": {
            borderBottom: "none"
        },
        "& .MuiInput-underline::after": {
            borderBottom: "none"
        }
    }
}))(FormControl);

class SearchCard extends React.Component {
    static contextType = GlobalsContext;

    constructor(props) {
        super(props);
        this.state = {}
    }

    typeSelectValueChange = (filter) => (value) => {
        filter.value = value
        this.props.onChange && this.props.onChange(this.props.search)
    }

    getTypeValue = (types, type, onChange) => {
        let {classes, theme, typeValueRequired} = this.props;
        let inputLabelProps = classes
            ? {
                classes : {
                    shrink: classes.smallWidthLabelShrink,
                    root : classes.largeWidthPropertyLabelRoot
                }
            }
            : {};


        let disabled = typeValueRequired && [...type, ...types].length <= 1;
        return <Autocomplete
            disableClearable={typeValueRequired}
            disabled={disabled}
            datatest={'autocompleteType'}
            id="typeValueSelect"
            value={type ? type  : []}
            options={types}
            getOptionLabel={option => option.label ?  option.label : ''}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <div datatest={option.label} style={{paddingLeft : '8px'}}>
                        {option.count}{ ' '} {option.label}
                        {
                            <Typography variant={'caption'}>
                                <div>{option.tooltip}</div>
                            </Typography>
                        }
                    </div>
                </React.Fragment>
            )}
            multiple={true}
            onChange={(event, val) => onChange(val)}
            renderInput={params => (
                <OtherTextField
                    {...params}
                    margin={"dense"}
                    variant="outlined"
                    fullWidth
                    InputLabelProps={inputLabelProps}
                />
            )}
            renderTags={(value, getTagProps) => {
                let isLast = typeValueRequired && value.length === 1;
                return value.map((option, index) => {
                    let isSame = option.value === option.tooltip
                    let title = <div>
                        <div style={isSame ? {} : {marginBottom : '8px'}}>{option.value}</div>
                        { isSame ? <></> : <div>{option.tooltip}</div>}
                    </div>;
                    return getChipWithDelete(theme, index, option.label, title, getTagProps({index}), isLast);
                })
            }}
            size={"small"}
        />;
    }


    singleValueChange = (filter, valueKey = 'value') => (value) => {
        filter[valueKey] = value.target.value
        this.props.onChange && this.props.onChange(this.props.search)
    }

    getSingleValued = (value, onChange, label) => {
        let {autoFocus} = this.state;
        return <OtherTextField
            datatest={'textField-Value'}
            label={label || 'Value'}
            onChange={onChange}
            value={value}
            autoFocus={autoFocus}
            fullWidth={true}
        />;
    }

    getSingleValuedDatetime = (value, onChange, label) => {
        return <DateTimePickerComponent onChange={onChange} value={value} label={label}/>;
    }

    getSingleValuedDate = (value, onChange) => {
        return <DatePickerComponent onChange={onChange} value={value} />;
    }

    getSingleValuedTime = (value, onChange) => {
        return <TimePickerComponent onChange={onChange} value={value}/>;
    }

    singleDateTimeValueChange = (filter, valueKey = 'value') => (value) => {
        filter[valueKey] = value;
        this.props.onChange && this.props.onChange(this.props.search);
    }


    getValue = (filter, level, label) => {
        let {settings, searchResult, aliasesToIRIMap, ontology, browseLanguage, aliasesMap, configurations, onChange, search, typeFilterOptions} = this.props
        let {property, operator, value, lang} = filter
        let operatorValue = operator && operator.value ? operator.value : operator;

        if(filter.enableFacet && property && property.value !== TYPE) {
            let propertyValue = property.label;
            let facetObject = searchResult?.[ALIAS_SYS_FACETS]?.find(f => f[ALIAS_SYS_FACET_LABEL] === propertyValue);
            let typesOptions = getFacetValues(facetObject).map(fr => {
                let value = this.facetValue(fr);
                let title = getMultilingualValue(fr[ALIAS_SYS_FACET_VALUE_LABEL], browseLanguage) || getLocalName(value);

                return {
                        value: aliasesMap[value] || value,
                        label: title,
                        tooltip: value,
                        count: fr[ALIAS_SYS_FACET_VALUE_COUNT]
                    }
                })
            //Do not render filter if there is no value to filter on
            if(isEmptyArray(toArray(value).filter(v => v)) && isEmptyArray(typesOptions)) {
                return;
            }
            typesOptions = this.sortFacetValue(filter, typesOptions);

            return <CheckboxList checkedValues={value || []} listValues={typesOptions} onChange={this.typeSelectValueChange(filter)}/>;

        }


        if (operatorValue && operatorValue !== EXISTS) {
            if (operatorValue === EQUAL_TO || operatorValue === NOT_EQUAL_TO) {
                if (property.value === TYPE) {
                    let valuesSet = new Set(toArray(value).map(v => v.value));
                    //If type options are overridden use those
                    let typeFilterOptionsArray = toArray(typeFilterOptions);
                    let typesOptions = typeFilterOptionsArray.length > 0 ? typeFilterOptionsArray : getTypesOptions(aliasesMap, configurations, false);
                    typesOptions = typesOptions.filter(o => !valuesSet.has(o.value));
                    typesOptions = typesOptionsForLanguage(typesOptions, aliasesToIRIMap, ontology, browseLanguage);
                    let facetObject = searchResult?.[ALIAS_SYS_FACETS]?.find(f => f[ALIAS_SYS_FACET_LABEL] === TYPE);
                    if(facetObject) {
                        typesOptions = facetObject[ALIAS_SYS_FACET_RESULTS].map(fr => {
                            let value = this.facetValue(fr);
                            return {
                                value: aliasesMap[value] || value,
                                label: getPropertyName(aliasesToIRIMap, value, ontology, browseLanguage),
                                tooltip: value,
                                count: fr[ALIAS_SYS_FACET_VALUE_COUNT]
                            }
                        })
                        typesOptions = this.sortFacetValue(filter, typesOptions);
                        return <CheckboxList checkedValues={value} listValues={typesOptions} onChange={this.typeSelectValueChange(filter)}/>;
                    }
                    typesOptions = sort(typesOptions, 'label');
                    return <div>{this.getTypeValue(typesOptions, value, this.typeSelectValueChange(filter))}</div>;
                }  else {
                    return this.getSingleValued(value, this.singleValueChange(filter));
                }
            } else if (operatorValue === TEXT) {
                return <div>
                    <TextField
                        InputProps={{
                            startAdornment: settings?.isMultilingual && <StyledFormControlForSelect  size={'small'}   variant="standard" >
                                <Select
                                    value={filter?.lang || AT_DEFAULT}
                                    onChange={(event) => {
                                        filter.lang = event.target.value;
                                        onChange();
                                    }}
                                >
                                    <MenuItem value={AT_DEFAULT}>{getUiLabelTranslation(settings, UI_LABELS_ANY, browseLanguage, 'Any')}</MenuItem>
                                    {settings && toArray(settings.browseLanguages).map(l => <MenuItem key={l.value} value={l.value}>{l.label}{" ("+l.value+")"}</MenuItem>)}
                                </Select>
                            </StyledFormControlForSelect>
                        }}
                        fullWidth={true}
                        name={'searchText'}
                        variant={"outlined"}
                        onChange={(value) => {
                            if(!filter.value) {
                                filter.value = []
                            }
                            filter.value[0] = value.target.value;
                            onChange()
                        }}
                        value={(filter.value && filter.value[0]) || ""}

                    />

                </div>
            } else if (operatorValue === OBJECT) {
                return toArray(value).map( (v, i) => <React.Fragment key={i}>{this.getValue(v, level)}</React.Fragment>);
            } else {
                const isBetween = operatorValue === BETWEEN;
                if(property.value[ALIAS_SH_DATATYPE] === XSD_DATETIME) {
                    return <>
                        {this.getSingleValuedDatetime(value, this.singleDateTimeValueChange(filter), isBetween &&  getUiLabelTranslationFromContext(this, UI_LABELS_FROM))}
                        { isBetween && this.getSingleValuedDatetime(filter[VALUE_END_KEY], this.singleDateTimeValueChange(filter, VALUE_END_KEY), getUiLabelTranslationFromContext(this, UI_LABELS_TO))}
                    </>;
                } else if(property.value[ALIAS_SH_DATATYPE] === XSD_DATE) {
                    return <>
                        {this.getSingleValuedDate(value, this.singleDateTimeValueChange(filter), isBetween &&  getUiLabelTranslationFromContext(this, UI_LABELS_FROM))}
                        {isBetween && this.getSingleValuedDate(filter[VALUE_END_KEY], this.singleDateTimeValueChange(filter, VALUE_END_KEY), getUiLabelTranslationFromContext(this, UI_LABELS_TO))}
                    </>
                } else if(property.value[ALIAS_SH_DATATYPE] === XSD_TIME) {
                    return <>
                        {this.getSingleValuedTime(value, this.singleDateTimeValueChange(filter), isBetween &&  getUiLabelTranslationFromContext(this, UI_LABELS_FROM))}
                        {isBetween && this.getSingleValuedTime(filter[VALUE_END_KEY], this.singleDateTimeValueChange(filter, VALUE_END_KEY), getUiLabelTranslationFromContext(this, UI_LABELS_TO))}
                    </>
                } else {
                    return <>
                        {
                            <div>
                                {this.getSingleValued(value, this.singleValueChange(filter), isBetween &&  getUiLabelTranslationFromContext(this, UI_LABELS_FROM))}
                                {isBetween && this.getSingleValued(filter[VALUE_END_KEY], this.singleValueChange(filter, VALUE_END_KEY), getUiLabelTranslationFromContext(this, UI_LABELS_TO))}
                            </div>}
                    </>;
                }
            }
        } else if (operatorValue === EXISTS) {
            if(filter.value !== true) {
                filter.value = false;
            }
            return <div style={{padding : '16px'}}>
                <FormControlLabel
                    control={
                        <Switch
                            datatest={'exists'}
                            size={"medium"}
                            checked={filter.value || false}
                            value={true}
                            onChange={() => {
                                filter.value = !filter.value;
                                onChange(search);
                                this.setState({})
                            }}
                            name="existsFilter"
                        ></Switch>
                    }
                    label={label}
                />

            </div>
        }
    }


    facetValue = (fr) => {
        return fr[ALIAS_SYS_FACET_VALUE][ID] || fr[ALIAS_SYS_FACET_VALUE][VALUE];
    }

    sortFacetValue = (filter, typesOptions) => {
        return filter.facetSortByCount === true
            ? sortByFunction(typesOptions, (v) => Number(v.count), 'desc')
            : sortByFunction(typesOptions, (v) => v.label, 'asc');
    }

    render() {
        const {theme, onChange, browseLanguage, settings} = this.props;
        let {filters, textSearch} = this.props.searchRequestObject.search;

        return <Card datatest={'searchCard'} className={GetEditableClassNames(SETTINGS_EDIT_ACTION_SEARCH_LEFT_CARD_ALL)} style={{ padding : '16px', color : theme.palette.primary.main}}>
            <WithAdminEdit data={{action: SETTINGS_EDIT_ACTION_SEARCH_LEFT_CARD_ALL, textSearch}} style={{marginTop: "-32px", marginLeft : "-16px"}}/>
            {
                <div style={{paddingBottom: '8px', display : 'flex'}}>
                    {centerVertically(<FilterListOutlined color={'secondary'}></FilterListOutlined>, {marginRight : '8px'})}
                    {
                        centerVertically(<H2Title className={GetEditableClassNames(SETTINGS_EDIT_ACTION_TRANSLATION, UI_LABELS_FILTERS)} color={'secondary'} >
                            <WithAdminEdit data={{action: SETTINGS_EDIT_ACTION_TRANSLATION, id : UI_LABELS_FILTERS}}/>
                            {getUiLabelTranslationFromContext(this, UI_LABELS_FILTERS)}
                        </H2Title>)
                    }
               </div>
            }
            { textSearch.showInFilter === true && textSearch.disable !== true &&
                <div datatest={'textSearchContainer'} style={{paddingBottom: '16px'}}>
                    <H4Title
                        className={GetEditableClassNames(SETTINGS_EDIT_ACTION_BANNER_SEARCH_PAPER_TITLE)}
                    >
                        <WithAdminEdit data={{action : SETTINGS_EDIT_ACTION_BANNER_SEARCH_PAPER_TITLE}} />
                        {(textSearch.ui && textSearch.ui.label && getMultilingualValue(textSearch.ui.label, browseLanguage)) || 'Search'}
                    </H4Title>
                    <div style={{display: 'flex'}}>

                        <div className={GetEditableClassNames(SETTINGS_EDIT_ACTION_BANNER_SEARCH)} style={{flexGrow: '1'}}>
                            <WithAdminEdit
                                style={{marginTop: '-1px'}}
                                data={{action: SETTINGS_EDIT_ACTION_BANNER_SEARCH}}
                            />
                            <TextField
                                datatest={'textSearchInput'}
                                autoComplete='off'

                                InputProps={{
                                    startAdornment: textSearch.ui && textSearch.ui.enableLanguageSelect === true &&
                                        <StyledFormControlForSelect size={'small'} variant="standard">
                                            <Select
                                                datatest={'textSearchInputLanguageSelect'}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={textSearch?.lang || browseLanguage.value}
                                                onChange={(event) => {
                                                    textSearch.lang = event.target.value;
                                                    onChange();
                                                }}
                                            >
                                                {settings && toArray(settings.browseLanguages).map(l => <MenuItem
                                                    key={l.value}
                                                    value={l.value}>{getLanguageLabelForSite(l)}</MenuItem>)}
                                            </Select>
                                        </StyledFormControlForSelect>
                                }}
                                fullWidth={true}
                                name={'searchText'}
                                variant={"outlined"}
                                onChange={(value) => {
                                    if (!textSearch.q) {
                                        textSearch.q = []
                                    }
                                    textSearch.q[0] = value.target.value
                                    onChange()
                                }}
                                value={(textSearch.q && textSearch.q[0]) || ""}

                            />
                        </div>
                    </div>
                </div>
            }
            {
                filters && excludeHidden(filters).map((f, i) => {
                    let label = f.ui && f.ui.label ? getMultilingualValue(f.ui.label, browseLanguage)  : f.property?.label;
                    let blockStyle =  { marginTop : '16px', borderTop : '1px solid', borderTopColor : theme.palette.grey.main};
                    if(textSearch.disable && i === 0) {
                        blockStyle =  {};
                    }
                    let {operator} = f;
                    let operatorValue = operator && operator.value ? operator.value : operator;
                    let valueComponent = this.getValue(f, 0, label);

                    return valueComponent && <div datatest={label} key={i+label} style={blockStyle} className={GetEditableClassNames(SETTINGS_EDIT_ACTION_SEARCH_LEFT_CARD_FILTER, f[ID])}>
                        <WithAdminEdit data={{action: SETTINGS_EDIT_ACTION_SEARCH_LEFT_CARD_FILTER, id: f[ID], filter: f}}/>
                        {   operatorValue !== EXISTS &&
                            <H4Title style={{marginTop: '16px'}} title={label}></H4Title>
                        }
                        {valueComponent}
                    </div>
                })
            }

        </Card>;
    }
}


SearchCard.propTypes = {
    settings: PropTypes.object,
    location: PropTypes.object,
    searchRequestObject :  PropTypes.object,
    configurations: PropTypes.object.isRequired,
    aliasesMap: PropTypes.object.isRequired,
    aliasesToIRIMap: PropTypes.object,
    ontology: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    browseLanguage: PropTypes.any,
    searchResult: PropTypes.any,
    typeFilterOptions : PropTypes.any,
    typeValueRequired : PropTypes.any,


};

export default withStyles(styles, {withTheme:true})(SearchCard);
