import React, {Component, createContext, useContext, useEffect, useLayoutEffect, useRef} from "react";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import {isResourceRefreshEvent, isResourceUpdateEvent, loadResourceAndGet, withEvent} from "./Event";
import DataGrid, {Row} from 'react-data-grid';

import {
    Badge,
    Checkbox,
    IconButton as MUIIconButton,
    IconButton,
    makeStyles,
    Tooltip,
    Typography
} from "@material-ui/core";
import PropTypes from "prop-types";
import {
    centerVertically,
    computeShapePropertiesWithSuperClasses,
    excludeHidden,
    flatten,
    getBrowseLanguageCode,
    getContainerData,
    getIdGeneratingClassIRIsSetWithSubclasses,
    getPropertyName,
    getQuery,
    getSearchResult,
    getTextQuery,
    handleBackendError,
    hasValue,
    isClientError,
    isEmptyArray,
    isFilterItemEditInProgress,
    isObjectProperty,
    isObjectShapeProperty,
    isRequestSuccessful,
    toArray,
    toGraphQL
} from "../../components/util";
import AddDataPropertyValueDialog, {renderErrors} from "./AddDataPropertyValueDialog";
import {loadResource, primeFromSearchResult} from "../../service/data-loader";
import {
    ALIAS_MANAGEMENT_FOR_APPLICATION,
    ALIAS_MANAGEMENT_NAME,
    ALIAS_MANAGEMENT_SETTINGS,
    ALIAS_MANAGEMENT_TITLE,
    ALIAS_MANAGEMENT_TYPE_CONFIGURATION,
    ALIAS_RDFS_LABEL,
    ALIAS_SH_DATATYPE,
    ALIAS_SH_MAX_COUNT,
    ALIAS_SH_MIN_COUNT,
    ALIAS_SH_NODE_KIND,
    ALIAS_SH_PATH,
    ALIAS_SH_TARGET_CLASS,
    ALIAS_SYS_ETAG,
    ALIAS_SYS_HAS_NEXT_PAGE,
    ALIAS_SYS_RESULTS,
    AT_CONTEXT,
    AT_GRAPH,
    DATA,
    EASYGRAPH_DATA_APPLICATION_EXPLORER_SPREADSHEET, EQUAL_TO,
    EVENT_SHACL_VALIDATE,
    GRAPH,
    HTTP_HEADER_PREFER,
    ID,
    LABEL_PROPERTY_LANG,
    LANG,
    MIXIN,
    ORDER,
    ORDER_LANG,
    ORDER_NO_VALUE,
    ORDER_NO_VALUE_LAST,
    PAGE,
    PAGE_SIZE,
    PATHS,
    QUERY,
    SH_LITERAL,
    SORT_ASCENDING,
    SORT_BY,
    TYPE,
    VALUE
} from "../../Constants";
import cloneDeep from "lodash/cloneDeep";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import {getUsername, isSuperadmin} from "../common/Profile";
import Chip from "@material-ui/core/Chip";
import H3Title from "../../components/H3Title";
import {isString} from "lodash";
import TabPanel from "../../components/TabPanel";
import LinkResourceDialog from "./LinkResourceDialog";
import {
    isAnyValueIdObject,
    isDatatypePropertyOrValue,
    isLangPropertyOrLangValue,
    isLiteralOrLanguageAndDatatypeMixed,
    langValueArray,
    sortValues
} from "./Tree";
import ObjectSummaryTooltip, {getStyledTooltip} from "./ObjectSummaryTooltip";
import {
    AcUnitOutlined,
    AddOutlined,
    ArrowDownwardOutlined,
    ArrowUpwardOutlined,
    DeleteOutlined,
    FilterListOutlined,
    GetAppOutlined,
    MoreVertOutlined,
    RefreshOutlined,
    SaveOutlined,
    SettingsBackupRestoreOutlined,
    SortOutlined,
    UnfoldMoreOutlined,
    ViewListOutlined,
    VisibilityOffOutlined
} from "@material-ui/icons";
import EditableTextField from "../../components/EditableTextField";
import {patchPropertyValue} from "./NodeContainer";
import AlertSnackbarContent from "../../components/AlertSnackbarContent";
import {deleteResource} from "./WithObjectSummary";
import {getImageUrl, isImageProperty} from "./SearchResultItem";
import {addFilter, nonEmptyToUndefinedQuery} from "../apiplayground/SearchRequest";
import {
    deleteManagementGraph,
    getBaseEndpointWithInstance,
    getData,
    getManagementContextURL,
    graphDownloadByMaps,
    graphSearchByMaps, patchManagementGraph,
    postManagementGraph
} from "../../service/graph-api";
import AddNewResourceDialog from "./AddNewResourceDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import {PermissionService, withPermissions} from "../../service/permission-service";
import AddNewTabDialog from "./AddNewTabDialog";
import InstructionForAction from "../../components/InstructionForAction";
import {
    getUiLabelTranslation,
    UI_LABELS_ADD_NEW_RESOURCES,
    UI_LABELS_DELETE_RESOURCE,
    UI_LABELS_DOWNLOAD,
    UI_LABELS_DOWNLOAD_FORMAT_CSV,
    UI_LABELS_DOWNLOAD_FORMAT_TURTLE,
    UI_LABELS_FREEZE,
    UI_LABELS_HIDE,
    UI_LABELS_HIDE_COLUMNS,
    UI_LABELS_LOAD_MORE,
    UI_LABELS_NO_RESULTS,
    UI_LABELS_REFRESH,
    UI_LABELS_RESET_FILTERS,
    UI_LABELS_SHEET_VIEW_LANGUAGE,
    UI_LABELS_SHEET_VIEW_LANGUAGE_HELP,
    UI_LABELS_SHEETS,
    UI_LABELS_SORT,
    UI_LABELS_UNFREEZE
} from "./UILabel";
import AddNewFilter from "./AddNewFilter";
import ViewLinkedObjectDialog from "./ViewLinkedObjectDialog";
import ViewDataValueDialog from "./ViewDataValueDialog";
import './DataGridView.css';
import MenuListComposition from "../../components/MoreMenuIcon";
import uuid4 from "uuid/v4";
import {getLanguageLabelForSite, isLangProperty} from "../apiplayground/SearchFilter";
import AddSort from "./AddSort";
import LanguageSearchSelect from "../apiplayground/LanguageSearchSelect";
import fileDownload from "js-file-download";
import DownloadDialog, {downloadCSV} from "./DownloadDialog";
import {validateFromSHACL} from "../../components/ShapeToForm/validation-util";
import {renderLogo} from "./Workspace";
import DoneIcon from "@material-ui/icons/Done";
import SavedSpreadsheet from "./SavedSpreadsheet";
import SaveDialogWithTitle, {ACTION_UPDATE} from "./SaveDialogWithTitle";
import {getNewIdForWorkspace} from "./PagesSetup";
import {setLinkedToId} from "./Workspaces";
import {BACKEND_PATH_MANAGEMENT_APP_CONFIGURATION} from "../../service/backend-paths";
import {isInHiddenProperties} from "./DataViewSetup";

const useStyles = makeStyles(theme => ({
    root: {
        blockSize :'100%',
        borderBottom : 'none'
    }
}));

export function StyledDataGrid({columns, rows, rowHeight, ...rest}) {
    const classes = useStyles();

    try {
        return (<DataGrid className={classes.root}  columns={columns} rows={rows} rowHeight={rowHeight} {...rest}/>);
    } catch (e) {
        return <div>Failed to render Grid</div>
    }
}

class DataPropertyValueTooltip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open : props.isCellSelected
        }
    }

    componentDidMount() {
    }

    renderDatatypePropertyValue = () => {
        let {theme, sort, settings, browseLanguage, isCellSelected, columnValue, sheetType, columnKey, aliasesToIRIMap, ontology, location} = this.props;
        let {open} = this.state;
        const StyledTooltip = getStyledTooltip(theme);

        let valueArray = toArray(columnValue);
        if(isEmptyArray(valueArray)) {
            return <div datatest={'dataType'} style={{width : isCellSelected ? `calc(100% - 32px)` : '100%', display : 'flex'}}/>;
        }
        let sortedValueArray = valueArray;
        let cellBrowseLanguage = sort?.[ORDER_LANG] ? sort[ORDER_LANG] : browseLanguage;
        let valueObjectForLang = sortedValueArray.find(v => {
            return v.lang === getBrowseLanguageCode(cellBrowseLanguage);
        });
        if(columnKey === TYPE) {
            valueObjectForLang = sheetType;
        }
        //if all the values are non language then show the first one
        //otherwise show nothing because we do not want to show values which are not matching to view language
        if(!valueObjectForLang) {
            let hasAnyLangValue = sortedValueArray.find(v => v.lang);
            if(!hasAnyLangValue) {
                valueObjectForLang = sortedValueArray[0];
            }
        }

        let chipValue = valueObjectForLang?.value || valueObjectForLang;


        //If there is only one value and the value is shown in cell do not show count
        //in case view language is different but there are other value show the count even though cell is not showing any value
        let valueCount = valueArray.length > 1 || (!chipValue && valueArray.length > 0)
            ? (valueArray.length > 99 ? '+99' : valueArray.length)
            : '';

        let summary = <div datatest={'dataType'} style={{width : isCellSelected ? `calc(100% - 32px)` : '100%', display : 'flex'}}>
            {
                centerVertically(
                    <Typography style={isString(chipValue) && chipValue?.includes('\n')? {whiteSpace : 'pre-line', overflow : 'auto', margin : '2px 0px'} : {}} component={'div'} noWrap={true}>{chipValue}</Typography>,
                    {maxWidth : `calc(100% - ${valueCount ? '40':'2'}px)`, flexGrow : '1'}
                )
            }
            {
                valueCount ?
                    centerVertically(<div>
                        <Chip datatest={'countChip'} variant={'outlined'} color={'secondary'} size="small" label={valueCount}/>
                    </div>, {marginLeft : '2px'})
                : <></>
            }
        </div>;
        if(isCellSelected) {
            return summary;
        } else {
            return summary;
        }
    }

    render() {
        return this.renderDatatypePropertyValue();
    }
}

DataPropertyValueTooltip.propTypes = {
    isCellSelected  : PropTypes.any,
    enableCellTooltip  : PropTypes.any,
    columnValue: PropTypes.any,
    columnKey: PropTypes.any,
    sheetType: PropTypes.any,
    ontology: PropTypes.any,
    location: PropTypes.any,
    aliasesToIRIMap: PropTypes.any,
    theme: PropTypes.any,
    settings: PropTypes.any,
    browseLanguage: PropTypes.any,
    sort: PropTypes.any
}


export const ROW_INDEX_KEY = "_rowIndex";

export function RowRenderer(props) {
    //console.log('RowRenderer', props);
    return <Row {...props} />;
}

export function EmptyRowsRenderer({settings, browseLanguage}) {
    return (
        <div style={{ textAlign: 'center', gridColumn: '1/-1' }}>
            <InstructionForAction text={
                getUiLabelTranslation(settings, UI_LABELS_NO_RESULTS, browseLanguage, 'No results found.')
            }/>
        </div>
    );
}

const FilterContext = createContext(undefined);

function useFocusRef(isSelected) {
    const ref = useRef(null);

    useLayoutEffect(() => {
        if (!isSelected) return;
        ref.current?.focus({ preventScroll: true });
    }, [isSelected]);

    return {
        ref,
        tabIndex: isSelected ? 0 : -1
    };
}

function FilterRenderer({isCellSelected, children, title}) {
    const filters = useContext(FilterContext);
    const { ref, tabIndex } = useFocusRef(isCellSelected);

    return (
        <>
            {title}
            { <div style={{padding : '4px', display : "flex", overflowX : 'auto', overflowY :'hidden'}}>{children({ ref, tabIndex, filters })}</div>}
        </>
    );
}

function inputStopPropagation(event) {
    if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
        event.stopPropagation();
    }
}

export const EXTRA_ROWS = 0;
export const DEFAULT_ROW_HEIGHT = 36;

const DEFAULT_PAGE_SIZE = 100;

function getSortForColumn(aliasesMap, propertyIRI, searchRequestForSheet) {
    let key = aliasesMap[propertyIRI] || propertyIRI;

    let sort = searchRequestForSheet?.sort?.filters?.find(f => f.value === key);
    return sort;
}

export const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -2,
        top: 13,
        border: `1px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        minWidth : '16px',
        height : '16px',

    },
}))(Badge);

function ColumnHeader({settings, gridCellProps, searchRequestForSheet, columnShape, aliasesMap, aliasesToIRIMap, ontology, browseLanguage, onFilterAdd, onSort, onAdvancedSort, isFrozen, onFreezeToggle, onHide}) {
    const [focus, setFocus] = React.useState(false);

    let propertyIRI = columnShape[ALIAS_SH_PATH];
    let filters = searchRequestForSheet?.search?.filters;
    let isIdOrType = [ID, TYPE].includes(propertyIRI);
    let filtersForColumn = excludeHidden(toArray(filters)).filter(f => {
        if(isFilterItemEditInProgress(f) || hasValue(f) === false) {
            return false;
        }
        if(isIdOrType) {
            const path = f.property?.value;
            return path && path === columnShape[ALIAS_SH_PATH];
        } else {
            const path = f.property?.value?.[ALIAS_SH_PATH];
            return path && path === columnShape[ALIAS_SH_PATH];
        }
    });
    let sort = getSortForColumn(aliasesMap, propertyIRI, searchRequestForSheet);


    let required = columnShape[ALIAS_SH_MIN_COUNT] ? '*' : '';
    let propertyKey = aliasesMap[propertyIRI] || propertyIRI;
    let name = getPropertyName(aliasesToIRIMap, propertyKey, ontology, browseLanguage);
    let tooltipTitle = <div>
        <div>{propertyKey}</div>
        {
            propertyKey !== propertyIRI && <div>{propertyIRI}</div>
        }
    </div>;

    const handleFocusToggle = (focus) => {
        setFocus(focus)
    }

    return <div datatest={'header-'+name} onMouseEnter={() => handleFocusToggle(true)} onMouseLeave={() => handleFocusToggle(false)}  style={{display: 'flex', height: '100%'}}>
        {
            sort && centerVertically(sort[ORDER].value === SORT_ASCENDING ? <ArrowUpwardOutlined datatest={'arrowUp'} style={{fontSize : '20px'}}/> : <ArrowDownwardOutlined datatest={'arrowDown'} style={{fontSize : '20px'}}/>)
        }
        {
            centerVertically(
                <H3Title noWrap={true}>
                    <Tooltip title={tooltipTitle}><span>{required}{name}</span></Tooltip>
                </H3Title>, {maxWidth: 'calc(100% - 32px)', marginLeft: '0px'}
            )
        }
        <div style={{flexGrow: '1'}}></div>
        {
            (gridCellProps.isCellSelected || focus || isEmptyArray(filtersForColumn) === false) &&
            <>
                {
                    (isEmptyArray(filtersForColumn)
                        ? centerVertically(<IconButton datatest={'filterButton'} style={{padding: '1px'}} size={'small'} aria-controls="simple-menu" aria-haspopup="true"
                                                       onClick={onFilterAdd}>
                            <FilterListOutlined fontSize={'small'} style={{padding : '0px'}} datatest={'filterIcon'} color={'action'}/>
                        </IconButton>, {marginRight: '12px'})
                        : centerVertically(
                            <IconButton datatest={'filterButton'} style={{padding: '1px'}} size={'small'} aria-controls="simple-menu" aria-haspopup="true"
                                        onClick={onFilterAdd}>
                                <StyledBadge datatest={'badge'} color={'secondary'} badgeContent={filtersForColumn.length}>
                                    <FilterListOutlined fontSize={'small'} style={{padding : '0px'}} datatest={'filterIcon'} color={'action'}/>
                                </StyledBadge>
                            </IconButton>
                            , {marginRight: '12px'}))
                }
                {
                    centerVertically(
                        <MenuListComposition
                            menuDatatest={'menuOptions'}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            buttonRenderer={(onClick) => <IconButton
                                    style={{padding: '1px'}}
                                    size={'small'}
                                    onClick={onClick}
                                    datatest={'moreButton'}
                            >
                                    <MoreVertOutlined fontSize={'small'} style={{padding : '0px'}} datatest={'moreIcon'} color={'action'}/>
                                </IconButton>
                            }
                            withClose={true}
                            itemIconStyle={{maxWidth : '32px', minWidth : '32px'}}
                            items={[
                                {
                                    icon : <SortOutlined/>,
                                    label : getUiLabelTranslation(settings, UI_LABELS_SORT, browseLanguage, UI_LABELS_SORT),
                                    onMouseDown : () => {
                                        onAdvancedSort()
                                    }
                                },
                                {
                                    icon : <AcUnitOutlined/>,
                                    label : getUiLabelTranslation(settings, (isFrozen ? UI_LABELS_UNFREEZE : UI_LABELS_FREEZE), browseLanguage, (isFrozen ? UI_LABELS_UNFREEZE : UI_LABELS_FREEZE)),
                                    onMouseDown : () => {
                                        onFreezeToggle(!isFrozen)
                                    }
                                },
                                {
                                    icon : <VisibilityOffOutlined/>,
                                    label : getUiLabelTranslation(settings, UI_LABELS_HIDE, browseLanguage, UI_LABELS_HIDE),
                                    onMouseDown : () => {
                                        onHide()
                                    }
                                }
                            ]}
                        />
                    )
                }
            </>
        }
    </div>;
}

function isJSONObjectTypeProperty(shapeProperty) {
    return isObjectShapeProperty(shapeProperty)  || shapeProperty[ALIAS_SH_NODE_KIND] === SH_LITERAL;
}

function isStringCell(propertyShape) {
    if(!propertyShape) {
        return false;
    }
    let propertyIRI = propertyShape[ALIAS_SH_PATH];
    return propertyShape[ALIAS_SH_MAX_COUNT] === "1" && !(propertyIRI !== ID && isJSONObjectTypeProperty(propertyShape));
}

function isLiteralTypeRender(shapeProperty, ontology) {
    let propertyIri = shapeProperty[ALIAS_SH_PATH];
    let op = ontology.find(op => op[ID] === propertyIri);
    if((op && isObjectProperty(op)) || (shapeProperty && isObjectShapeProperty(shapeProperty))) {
        return false;
    }
    if(shapeProperty && shapeProperty[ALIAS_SH_NODE_KIND] === SH_LITERAL) {
        return true;
    }
    if(shapeProperty && shapeProperty[ALIAS_SH_DATATYPE] === undefined) {
        return true;
    }
    return false;
}

export function isObjectTypeValueOrProperty(columnValue, ontology, column) {
    let ontologyProperty = column && column.property && ontology.find(o => o[ID] === column.property[ALIAS_SH_PATH]);
    return isAnyValueIdObject(columnValue) || (ontologyProperty && isObjectProperty(ontologyProperty)) || isObjectShapeProperty(column.property);
}

function isStringAndNotMultiline(value) {
    return isString(value) && value.includes('\n') === false;
}

function Cell({cellEvent, editCell,  sheetType, isSheetReadOnly, ontology, configurations, aliasesToIRIMap, aliasesMap, settings, browseLanguage, theme, location, publishEvent, onUpdateFail, permissionService, sort}) {

    const [openEditorFor, setOpenEditorFor] = React.useState();
    const [imageURL, setImageURL] = React.useState();

    let { row, column, isCellSelected } = cellEvent;
    let valueObject = row;
    let property = column.property;
    let columnValue =  valueObject[column.name];
    let isValueEditableInCell = isSheetReadOnly === false && isStringCell(property) && (columnValue === undefined || isStringAndNotMultiline(columnValue) || (toArray(columnValue).length <=1 && toArray(columnValue).find(v => !isStringAndNotMultiline(v)) === undefined ));
    let isImageType = isImageProperty(valueObject, settings, aliasesToIRIMap, column.name);


    useEffect(() => {
        if(isImageType && imageURL === undefined) {
            getImageUrl(valueObject, settings, aliasesToIRIMap, browseLanguage, ontology).then(vo => {
                setImageURL(vo);
            })
        }
    }, [])

    const closeViewEditDialog = () => {
        cellEvent?.onClose?.();
        setOpenEditorFor(undefined);
    }

    const renderAdvancedEditor = () => {
        let openEditorForToUse = isValueEditableInCell === false && editCell === true && openEditorFor === undefined
            ? cellEvent : openEditorFor;
        if(openEditorForToUse) {
            let {column, row} = openEditorForToUse;
            let {property} = column;
            let propertyLabel = aliasesMap[property[ALIAS_SH_PATH]] || property[ALIAS_SH_PATH];
            let columnValue =  row[column.name];
            let isIdColumn = column.name === ID;
            if (isObjectTypeValueOrProperty(columnValue, ontology, column) || isIdColumn) {
                if(isSheetReadOnly || isIdColumn) {
                    return <ViewLinkedObjectDialog
                        onClose={closeViewEditDialog}
                        browseLanguage={browseLanguage}
                        ontology={ontology}
                        aliasesToIRIMap={aliasesToIRIMap}
                        settings={settings}
                        location={location}
                        objectIris={toArray(row[propertyLabel]).map(id => id[ID] || id)}
                        linkProperty={propertyLabel}
                        sourceResource={row}
                        configurations={configurations}
                        aliasesMap={aliasesMap}
                        secondaryActionButton={isIdColumn && permissionService.canDeleteResource(valueObject) &&
                            <Tooltip title={getUiLabelTranslation(settings, UI_LABELS_DELETE_RESOURCE, browseLanguage, UI_LABELS_DELETE_RESOURCE)}>
                            <IconButton
                            datatest={'deleteButton'}
                            color="secondary"
                            onClick={async () => {
                                return await deleteResource.call(this, columnValue, publishEvent, valueObject, settings, browseLanguage);
                            }}
                        ><DeleteOutlined></DeleteOutlined></IconButton></Tooltip>}
                    />;
                } else {

                    return <LinkResourceDialog
                        onClose={closeViewEditDialog}
                        onSaveSuccess={closeViewEditDialog}
                        onSaveFailure={(updateFailed) => {
                            closeViewEditDialog();
                            onUpdateFail({updateFailed});
                        }}
                        sourceResource={row}
                        linkProperty={propertyLabel}
                        settings={settings}
                        ontology={ontology}
                        configurations={configurations}
                        aliasesMap={aliasesMap}
                        aliasesToIRIMap={aliasesToIRIMap}
                        browseLanguage={browseLanguage}
                        location={location}
                        shapeProperty={property}
                    />;
                }
            } else if (column.name === TYPE) {
                return <ViewLinkedObjectDialog
                    onClose={closeViewEditDialog}
                    browseLanguage={browseLanguage}
                    ontology={ontology}
                    aliasesToIRIMap={aliasesToIRIMap}
                    settings={settings}
                    location={location}
                    objectIris={toArray(row[propertyLabel]).map(t => aliasesToIRIMap[t] || t)}
                    linkProperty={propertyLabel}
                    sourceResource={row}
                    configurations={configurations}
                    aliasesMap={aliasesMap}
                />;
            } else {
                if(isSheetReadOnly  || column.name === TYPE) {
                    return <ViewDataValueDialog
                        onClose={closeViewEditDialog}
                        browseLanguage={browseLanguage}
                        ontology={ontology}
                        aliasesToIRIMap={aliasesToIRIMap}
                        settings={settings}
                        location={location}
                        dataProperty={propertyLabel}
                        sourceResource={row}
                        configurations={configurations}
                        aliasesMap={aliasesMap}
                        propertyShape={property}
                    />;

                } else {

                    return <AddDataPropertyValueDialog
                        configurations={configurations}
                        aliasesMap={aliasesMap}
                        aliasesToIRIMap={aliasesToIRIMap}
                        ontology={ontology}
                        settings={settings}
                        location={location}
                        sourceResource={row}
                        browseLanguage={browseLanguage}
                        onClose={closeViewEditDialog}
                        onSaveSuccess={closeViewEditDialog}
                        onSaveFailure={(updateFailed) => {
                            closeViewEditDialog();
                            onUpdateFail({updateFailed})
                        }}
                        propertyLabel={propertyLabel}
                        propertyShape={property}
                    />;
                }
            }
        }
    }

    let editInCell = isValueEditableInCell && editCell && openEditorFor === undefined;

    function getOnConfirm(label, value) {
        return async (ev) => {
            ev?.stopPropagation();
            ev?.preventDefault();
            let shapePropertyClone = cloneDeep(property);
            shapePropertyClone.value = value;
            let valueArray = toArray(columnValue);
            let renderedDataValueObject = {
                [VALUE]: valueArray.length > 0 ? valueArray[0] : (columnValue || "")
            }
            let oldParentObject = {
                [ID]: valueObject[ID],
                [ALIAS_SYS_ETAG]: valueObject[ALIAS_SYS_ETAG],
                [column.name]: valueObject[column.name] || ""
            }
            valueObject[column.name] = value;
            const result = await patchPropertyValue(shapePropertyClone, oldParentObject, renderedDataValueObject, aliasesMap, publishEvent, settings, browseLanguage, () => {
            });
            if (result.cancel) {
                valueObject[column.name] = oldParentObject[column.name];
            }
            if (result.updateFailed || result.errors) {
                valueObject[column.name] = oldParentObject[column.name];
                onUpdateFail(result);
            }
        };
    }

    if (editInCell) {
        let { onRowChange, onClose, row, ...rest} = cellEvent;
        if(column.language) {
            row[column.key] = columnValue?.[column.language];
        }
        return centerVertically(
            <EditableTextField
                inputProps={{
                    style : {padding: '0px'}
                }}
                disableUnderline={true}
                variant={'standard'}
                autoFocus={true}
                style={{margin : '2px'}}
                fullWidth={true}
                value={row[column.key]}
                confirmButton={(label, value) => {
                    return <Tooltip title={'Confirm change'}>
                        <MUIIconButton style={{padding : '0px'}}  datatest={'confirmChangeButton'} onMouseDown={(ev) => ev.preventDefault()} color={'secondary'} size={'small'} onClick={getOnConfirm(label, value)}>
                            <DoneIcon/>
                        </MUIIconButton>
                    </Tooltip>;
                }}
                onConfirm={(label, value) => getOnConfirm(label, value)()}
                validator={(value, label, maxLength) => {
                    let shapePropertyClone = cloneDeep(property);
                    shapePropertyClone.value = value;
                    let fromSHACL = validateFromSHACL(EVENT_SHACL_VALIDATE, shapePropertyClone, undefined, {settings, browseLanguage}, undefined);
                    return fromSHACL?.message;
                }}
                otherEndAdornments={[
                    <IconButton
                        datatest={'moreButton'}
                        style={{padding : '0px', marginRight : '8px'}}
                        onKeyDown={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                            setOpenEditorFor(cellEvent);
                            //this.setState({openEditorFor: props});
                        }}
                        onClick={async (ev) => {
                            setOpenEditorFor(cellEvent);
                        }}
                        size={'small'}
                    ><MoreVertOutlined/></IconButton>,
                ]}
                checkConfirmOnKeyDown={true}
            />
        ,
            {maxWidth : 'calc(100% - 4px)', padding : '3px 6px 0px 6px'},
            'cellEditBlock-'+column.name
        );

    }

    let cellContent;
    let sortDirection = sort && (sort[ORDER].value === SORT_ASCENDING ? 'asc' : 'desc');
    let showMore = isCellSelected;
    function getMoreButton() {
        return centerVertically(<IconButton datatest={'moreButton'} style={{padding: '0px'}} onClick={() => setOpenEditorFor(cellEvent)} tabIndex={0}
                                size={'small'}><MoreVertOutlined/></IconButton>);
    }

    if(column.key === ROW_INDEX_KEY) {
        cellContent = <>{columnValue !== undefined && columnValue + 1}</>;
    } else if (column.key === ID) {
        cellContent = <>
            <ObjectSummaryTooltip
                location={location}
                theme={theme}
                isCellSelected={openEditorFor === undefined && isCellSelected}
                objectIris={toArray(columnValue)}
                aliasesToIRIMap={aliasesToIRIMap}
                settings={settings}
                browseLanguage={browseLanguage}
                ontology={ontology}
            />
        </>
    } else if(isObjectTypeValueOrProperty(columnValue, ontology, column)) {
        let objectIris = toArray(columnValue).map(o => o[ID] ? o[ID] : o);

        cellContent = <>
            <ObjectSummaryTooltip
                linkProperty={column.name}
                location={location}
                theme={theme}
                isCellSelected={openEditorFor === undefined && isCellSelected}
                objectIris={objectIris}
                aliasesToIRIMap={aliasesToIRIMap}
                settings={settings}
                browseLanguage={browseLanguage}
                ontology={ontology}
                imageURL={imageURL}
            />
            {showMore && getMoreButton()}
        </>;

    } else if(isValueEditableInCell) {

        let valueArray = toArray(columnValue);
        cellContent = <>
            <div datatest={'editableCell'} style={{width : showMore ? `calc(100% - 32px)` : '100%', display : 'flex'}}>
            {valueArray.length > 0 ? valueArray[0] : columnValue}
            </div>
            {showMore &&  getMoreButton()}
        </>
    } else if(isLangPropertyOrLangValue(columnValue, property) && isLiteralTypeRender(property, ontology) === false) {
        let langValues = sortValues(langValueArray(columnValue), LANG, sortDirection);
        cellContent = <>
            <DataPropertyValueTooltip
                theme={theme}
                isCellSelected={openEditorFor === undefined && isCellSelected}
                sort={sort}
                browseLanguage={browseLanguage}
                settings={settings}
                columnValue={langValues}
                columnKey={column.key}
                ontology={ontology}
                aliasesToIRIMap={aliasesToIRIMap}
                location={location}
            />
            {showMore &&  getMoreButton()}
        </>;
    }  else if(column.key === TYPE || isDatatypePropertyOrValue(columnValue, property, property[ALIAS_SH_PATH], ontology, aliasesToIRIMap) || isLiteralOrLanguageAndDatatypeMixed(columnValue, undefined)) {
        let columnValueArray = sortValues(toArray(columnValue), LANG, sortDirection);
        columnValueArray = sortValues(toArray(columnValueArray), TYPE, sortDirection);
        cellContent = <>
            <DataPropertyValueTooltip
                theme={theme}
                isCellSelected={openEditorFor === undefined && isCellSelected}
                sort={sort}
                browseLanguage={browseLanguage}
                settings={settings}
                columnValue={columnValueArray}
                columnKey={column.key}
                sheetType={sheetType}
                ontology={ontology}
                aliasesToIRIMap={aliasesToIRIMap}
                location={location}
            />
            {showMore && getMoreButton()}
        </>;
    } else if (isString(columnValue)) {
        cellContent = <div style={{whiteSpace : 'pre-line'}}>{columnValue}</div>;
    } else {
        cellContent = <div>{JSON.stringify(columnValue)}</div>;
    }

    return <>
        {renderAdvancedEditor()}
        <div
            datatest={'cellContent-'+column.name+'-'+((row?.[ROW_INDEX_KEY] || 0))}
            style={{display :'flex', height :'100%'}}
        >
            {cellContent}
        </div>
    </>;


}

const BOTTOM_BAR_HEIGHT = '36px';
const ID_AND_TYPE_PROPERTIES = [
    {
        [ID]: ID,
        [ALIAS_SH_PATH]: ID
    },
    {
        [TYPE]: TYPE,
        [ALIAS_SH_PATH]: TYPE
    }
];

export function getLanguageSuggestionsForSite(settings) {
    return toArray(settings.browseLanguages).map(lv => {
        return {
            value: lv.value,
            label: getLanguageLabelForSite(lv)
        }
    });
}

export async function getSavedSpreadsheets(workspace) {
    const searchParams = {
        [ALIAS_MANAGEMENT_NAME] : NAME_SPREADSHEET,
        [ALIAS_MANAGEMENT_FOR_APPLICATION] : EASYGRAPH_DATA_APPLICATION_EXPLORER_SPREADSHEET,
    }
    setLinkedToId(searchParams, workspace[ID]);
    let headerMap = {
        [HTTP_HEADER_PREFER] : `return=representation;${MIXIN}="{ ${ID} ${ALIAS_SYS_ETAG} ${TYPE} ${ALIAS_MANAGEMENT_TITLE} ${ALIAS_MANAGEMENT_NAME}}";${DATA}=${PATHS}`
    }

    return new Promise((resolve, reject) => {
        getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_APP_CONFIGURATION, searchParams, headerMap, false)
            .then(pageObjectsResults => {
                if(isRequestSuccessful(pageObjectsResults)) {
                    pageObjectsResults.json().then(json => {
                        let pageObjects = getSearchResult(json);
                        resolve(pageObjects);
                    })
                } else {
                    resolve([]);
                }
            })
            .catch(reject);
    })
}

export async function getSavedSpreadsheetsOptions(workspace) {
    let saved = (await getSavedSpreadsheets(workspace)).map(r => ({backingObject : r, value : r[ID], label : r[ALIAS_MANAGEMENT_TITLE]}));
    return saved;
}

const NAME_SPREADSHEET = 'Spreadsheet';

class DataGridView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open || false,
            sheetTabValue : undefined,
            sheets : {},
            viewLanguage : getBrowseLanguageCode(props.browseLanguage)
        }
        this.rowHeights = {};
        this.connectedUsers = [];
        this.connectedUsers.push({sessionId: getUsername()});
        this.gridRef = React.createRef();
        this.sheetsSearchRequestObjects = {}

    }


    componentDidMount() {
        let {registerForEvents, embedded, spreadsheet} = this.props;
        registerForEvents(this.onUpdateEvent);
        this.syncSavedSpreadsheets().then(() => {});
        if(embedded) {
            if(spreadsheet) {
                this.setState({showSavedSpreadsheet : spreadsheet}, () => this.openSpreadsheet(spreadsheet.value));
            }
        }

        //this.loadInitialData();
    }


    onUpdateEvent = async (event) => {
        let {sheets} = this.state;
        let {payload} = event;
        let isResourceUpdate = isResourceUpdateEvent(event) || isResourceRefreshEvent(event)
        if(isResourceUpdate === true && payload?.[ID]) {
            let found = Object.keys(sheets).map(sh => sheets[sh].sheetData).find(sheetData => sheetData.find(dto => dto[ID] === payload?.[ID]));
            if(found) {
                this.reloadData();
            }
        }
    }

    reloadData = () => {
        let {sheets} = this.state;
        Object.keys(sheets).forEach(type => {
            let sheet = sheets[type];
            let data = sheet.sheetData;
            let ids = data.map(dto => dto[ID]);
            loadResource(ids).then((results) => {
                sheet.sheetData = results.filter(r => r);
                this.setState({sheets})
            });
        })
    }

    prepareViewData = () => {
        let {shapes, settings, ontology, aliasesToIRIMap, configurations, aliasesMap, browseLanguage, permissionService} = this.props;
        let {sheets} = this.state;
        let instantiableClasses = getIdGeneratingClassIRIsSetWithSubclasses(getContainerData(configurations), ontology);
        Object.keys(sheets).forEach(type => {
            let canUpdate = permissionService.canUpdate({resourceTypeIri : (aliasesToIRIMap[type] || type)});
            let sheet = sheets[type];
            let propertyName = getPropertyName(aliasesToIRIMap, type, ontology, browseLanguage);
            let typeIRI = aliasesToIRIMap[type] || type;
            let isInstantiable = instantiableClasses.has(typeIRI);
            sheet.readOnly = isInstantiable === true && canUpdate ? false : true;
            sheet.sheetLabel = propertyName;
            let existingSheetColumns = toArray(sheet.sheetColumns);
            let newColumns = [];
            let shapeProperties = [];
            //If columns exist then no need to recompute as shape is not going to change
            if(isEmptyArray(existingSheetColumns)) {
                let shape = shapes.find(s => s[ALIAS_SH_TARGET_CLASS] === aliasesToIRIMap[type] || s[ALIAS_SH_TARGET_CLASS] === type);
                shapeProperties = shape ? computeShapePropertiesWithSuperClasses(shape, shapes, ontology) : [];
            }

            if(isInstantiable) {
                flatten([
                    ...ID_AND_TYPE_PROPERTIES,
                    ...shapeProperties
                ]).forEach(p => {
                    this.addIfNotFound(existingSheetColumns, p, newColumns);
                })

            } else {
                let dataProperties = toArray(sheet.sheetData).map(d => Object.keys(d));
                dataProperties = flatten(dataProperties)
                    .filter(p =>  [ID, TYPE, ROW_INDEX_KEY].includes(p) === false)
                    //if the shape exists return that

                    .filter(p => {
                        let found = shapeProperties.find(sp => {
                            let shapePropertyIRI = sp[ALIAS_SH_PATH];
                            let propertyNamesArray = toArray([aliasesToIRIMap[p],p]);
                            return propertyNamesArray.includes(shapePropertyIRI)
                        });
                        return !found;

                    }).map(p => {
                    return {
                        [ALIAS_SH_PATH] : aliasesToIRIMap[p] || p
                    }
                });

                //Add both shape and data properties
                //means if shape is updated in configs user can apply filters on that
                flatten([
                    ...ID_AND_TYPE_PROPERTIES,
                    ...dataProperties,
                    ...shapeProperties
                ]).forEach(p => {
                    this.addIfNotFound(existingSheetColumns, p, newColumns);
                });
            }
            newColumns = newColumns.filter(cl => !isInHiddenProperties(settings, aliasesMap, cl[ALIAS_SH_PATH])).map(cl => {
                let propertyIRI = cl[ALIAS_SH_PATH];
                let propertyKey = aliasesMap[propertyIRI] || propertyIRI;
                let name = getPropertyName(aliasesToIRIMap, propertyKey, ontology, browseLanguage);
                cl.columnLabel = name;
                return cl;
            })
            let sortColumns1 = this.sortColumns(newColumns);
            sheet.sheetColumns = [...existingSheetColumns, ...sortColumns1];
        });



        this.setState({sheets})
    }

    addIfNotFound(existingSheetColumns, p, newColumns) {
        let foundInExisting = existingSheetColumns.find(sc => sc[ALIAS_SH_PATH] === p[ALIAS_SH_PATH]);
        let foundInNew = newColumns.find(sc => sc[ALIAS_SH_PATH] === p[ALIAS_SH_PATH]);
        let isFound = foundInExisting || foundInNew;
        if (isFound) {
            // do nothing as property already exists
        } else {
            newColumns.push(p);
        }
    }

    sortColumns = (columns) => {
        columns.sort((aItem, bItem) => {
            //console.log(aItem.columnLabel, bItem.columnLabel)
            if(bItem.columnLabel == ID) {
                return 1;
            }
            if(bItem.columnLabel == TYPE && aItem.columnLabel != ID) {
                return 1;
            }
            if(aItem.columnLabel > bItem.columnLabel) {
                return 1;
            }
            if(aItem.columnLabel < bItem.columnLabel) {
                return -1;
            }
            return 0;
        });
        //console.log(columns);
        return columns;

    }


    resizeRow = (e, data, row) => {
        let {rowHeights} = this;
        let newHeight = data.size.height;
       // console.log("resize", e, data, newHeight);
        let id = row[ID];
        let rowIndex = row[ROW_INDEX_KEY];
        if(!rowHeights[id]) {
            rowHeights[id] = {};
        }
        rowHeights[id][rowHeights] = newHeight;
        this.setState({heightKey : id+"-"+rowIndex+"-"+newHeight});
    }

    rowHeight = (props) => {

       // console.log('rowHeight', props);
        let {row} = props;
        let id = row[ID];
        let rowIndex = row[ROW_INDEX_KEY];

        let newRowHeight = this.rowHeights[id]?.[rowIndex];

       // console.log('rowHeight', props, newRowHeight);
        return newRowHeight || DEFAULT_ROW_HEIGHT;

    }

    commitCellChange = (props, closeValue) => {
        //console.log("row change commit", closeValue);

        let column = props.column;
        let row = props.row;
        let valueObject = row;
        let property = column.property;

        let propertyAlias = column.key;
        let columnValue = valueObject[propertyAlias];
        let oldValue = this.connectedData[valueObject[ID]][propertyAlias];
       // console.log("my row change", oldValue, columnValue, closeValue);
    }

    rowChange = (row, commitChanges, onRowChange, props) => {
       // console.log("rowChange", row, commitChanges, onRowChange, props);
        let column = props.column;
        let propertyAlias = column.name;
        this.editPropertyAlias = propertyAlias;
        this.editRow = row;
        this.editPropertyColumn = column;
        if(column.language) {
            if(!row[column.name]) {
                row[column.name] = {};
            }
            row[column.name][column.language] = row[column.key]
        }
        onRowChange(row, commitChanges);

    }

    onRowsChange = async (rows, changes) => {
        let {aliasesToIRIMap, data, viewType} = this.state;
       // console.log("onRowsChange", rows, changes, this.editPropertyAlias);
        let index = changes.indexes[0];
        let row = rows[index];
        let newValue = this.editPropertyColumn.language
            ? row[this.editPropertyAlias][this.editPropertyColumn.language]
            : row[this.editPropertyAlias];
        let id  = row[ID];

        let connectedDataObject = await loadResourceAndGet(id);
        let oldValue = this.editPropertyColumn.language && connectedDataObject[this.editPropertyAlias]
            ? connectedDataObject[this.editPropertyAlias][this.editPropertyColumn.language]
            : connectedDataObject[this.editPropertyAlias];
        connectedDataObject[this.editPropertyAlias] = newValue;
        this.setState({});
        let eventObject = {
            eventType: 'STATEMENT_PATCH_EVENT',
            payload: {}
        }
        if(newValue === null) {
            eventObject.payload.egDeleteStatement = {
                egSubject : id,
                egPredicate : this.editPropertyColumn.propertyIRI,
                egObject : {
                    type : this.editPropertyColumn.language ? undefined : this.editPropertyColumn.property[ALIAS_SH_DATATYPE],
                    lang : this.editPropertyColumn.language || undefined,
                    value : oldValue
                }
            }
        } else if (oldValue === undefined && newValue) {
            eventObject.payload.egInsertStatement = {
                egSubject : id,
                egPredicate : this.editPropertyColumn.propertyIRI,
                egObject : {
                    type : this.editPropertyColumn.language ? undefined : this.editPropertyColumn.property[ALIAS_SH_DATATYPE],
                    lang : this.editPropertyColumn.language|| undefined,
                    value : newValue
                }
            }
        } else {
            eventObject.payload.egUpdateStatement = {
                egSubject : id,
                egPredicate : this.editPropertyColumn.propertyIRI,
                egObject : {
                    type : this.editPropertyColumn.language ? undefined : this.editPropertyColumn.property[ALIAS_SH_DATATYPE],
                    lang : this.editPropertyColumn.language|| undefined,
                    value : oldValue
                },
                egNewObject : {
                    type : this.editPropertyColumn.language ? undefined : this.editPropertyColumn.property[ALIAS_SH_DATATYPE],
                    lang : this.editPropertyColumn.language|| undefined,
                    value : newValue
                }
            }

        }


        ///onRowChange(row, commitChanges);
    }


    renderTextCellFromValue = (props, isEditable) => {
        let {ontology, permissionService, configurations, aliasesToIRIMap, aliasesMap, settings, browseLanguage, theme, location, publishEvent} = this.props;
        let {sheets, viewLanguage} = this.state;
        let sheetTabValue = this.getSheetTabValue(sheets);
        let sheet = sheets[sheetTabValue];

        let searchRequestObject = this.sheetsSearchRequestObjects[sheetTabValue];
        let { column } = props;
        let key =  column.name;

        let sort = searchRequestObject?.sort?.filters?.find(f => f.value === key);

        return <Cell
            aliasesMap={aliasesMap}
            aliasesToIRIMap={aliasesToIRIMap}
            ontology={ontology}
            settings={settings}
            location={location}
            browseLanguage={viewLanguage}
            theme={theme}
            cellEvent={props}
            editCell={isEditable}
            sheetType={this.getSheetTabValue(sheets)}
            configurations={configurations}
            isSheetReadOnly={sheet.readOnly}
            publishEvent={publishEvent}
            onUpdateFail={(result) => {
                this.setState({updateFailed : result.updateFailed, errors : result.errors });
            }}
            permissionService={permissionService}
            sort={sort}
        />;


    }


    renderEditor = (props, isStringValue) => {
        return this.renderTextCellFromValue(props, true, props);
    }

    sortDataByColumn = (propertyShape, direction, orderLang, orderNoValue) => {
        let {browseLanguage, aliasesMap} = this.props;
        let {sheets, viewLanguage} = this.state;
        let currentSheetTabValue = this.getSheetTabValue(sheets);
        const searchRequestForSheet = this.sheetsSearchRequestObjects[currentSheetTabValue];
        this.resetPaging(searchRequestForSheet);

        let sortSpec = {
            ID : uuid4(),
            value : aliasesMap[propertyShape[ALIAS_SH_PATH]] || propertyShape[ALIAS_SH_PATH] ,
            [ORDER] : {
                value : direction
            }
        };
        if(orderNoValue) {
            sortSpec[ORDER_NO_VALUE] = orderNoValue;
        }

        if(orderLang === null) {
            // do not add anything it means user has cleared the selection in dialog
        } else if(orderLang) {
            sortSpec[ORDER_LANG] = getBrowseLanguageCode(orderLang);
        } else if(isLangProperty(propertyShape)) {
            sortSpec[ORDER_LANG] = viewLanguage || getBrowseLanguageCode(browseLanguage);
        }
        searchRequestForSheet.sort = {
            classIRIs : [],
            filters : [
                sortSpec
            ]
        }
        this.onLoadMore(true).then(() => this.setState({})).catch();

    }

    resetPaging = (searchRequestForSheet) => {
        searchRequestForSheet[ALIAS_SYS_HAS_NEXT_PAGE] = true;
        searchRequestForSheet[PAGE] = 0;
        searchRequestForSheet[PAGE_SIZE] = DEFAULT_PAGE_SIZE;
    }

    syncSavedSpreadsheets = async () => {
        let {workspace} = this.props;
        let saved = await getSavedSpreadsheetsOptions(workspace);
        this.setState({
            savedSpreadsheets : saved
        });
        return saved;
    }

    saveSpreadsheet = (title, action) => {
        const {workspace} = this.props;
        const {sheets} = this.state;
        const uuid = uuid4();
        let newIdForWorkspace = getNewIdForWorkspace(uuid);
        let obj = {
            [ID]: newIdForWorkspace,
            [ALIAS_MANAGEMENT_NAME]: NAME_SPREADSHEET,
            [ALIAS_MANAGEMENT_TITLE]: title
        }
        obj[AT_CONTEXT] = getManagementContextURL();
        obj[TYPE] = ALIAS_MANAGEMENT_TYPE_CONFIGURATION;
        obj[ALIAS_MANAGEMENT_FOR_APPLICATION] = EASYGRAPH_DATA_APPLICATION_EXPLORER_SPREADSHEET;
        setLinkedToId(obj, workspace[ID]);

        let data = {
            sheets : cloneDeep(sheets) ,
            sheetsSearchRequestObjects : cloneDeep(this.sheetsSearchRequestObjects)
        }
        //Now clean/remove/reset data which is not required
        Object.keys(data.sheets).forEach(k => {
            let sheet = data.sheets[k];
            sheet.sheetColumns = [];
            sheet.sheetData = [];
            let searchRequest = data.sheetsSearchRequestObjects[k];
            delete searchRequest.loading;
            delete searchRequest[ALIAS_SYS_HAS_NEXT_PAGE];
            delete searchRequest[PAGE];

        });
        console.log(data);
        obj[ALIAS_MANAGEMENT_SETTINGS] = JSON.stringify(data);
        console.log(obj);
        //return new Promise((resolve, reject) => {resolve({})});
        if(action === ACTION_UPDATE) {
            return new Promise((resolve, reject) => {
                let {showSavedSpreadsheet} = this.state;
                this.getFullResource(showSavedSpreadsheet.value).then(resource => {
                    resource[AT_CONTEXT] = obj[AT_CONTEXT];
                    resource[ALIAS_MANAGEMENT_SETTINGS] = obj[ALIAS_MANAGEMENT_SETTINGS];
                    patchManagementGraph(JSON.stringify(resource)).then(d => {
                        if (isRequestSuccessful(d)) {
                            d.json().then(j => {
                                resolve(j);
                            })
                        } else {
                            reject(d);
                        }
                    }).catch(e => {
                        reject(e);
                    })
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                postManagementGraph(JSON.stringify(obj)).then(d => {
                    if (isRequestSuccessful(d)) {
                        d.json().then(j => {
                            resolve(j);
                        })
                    } else {
                        reject(d);
                    }
                })
            });
        }
    }

    getFullResource = async (id) => {
        const workspaceSearchParams = {
            [ID] : id
        }
        let headerMap = {};
        const workspaceSearchResult = await getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_APP_CONFIGURATION, workspaceSearchParams, headerMap).catch(handleBackendError(this));
        if(!isRequestSuccessful(workspaceSearchResult)) {
            return Promise.reject(workspaceSearchResult);
        }
        let workspaceJSON = await workspaceSearchResult.json();
        let result = getSearchResult(workspaceJSON).find(obj => obj[ID] === id);
        return result;
    }

    openSpreadsheet = async (id) => {
        let {sheets} = this.state;
        let result = await this.getFullResource(id);
        if(!result) {
            this.setState({sheetOpenError : 'Not found'})
            return ;
        }
        let spreadsheetSettings = JSON.parse(result[ALIAS_MANAGEMENT_SETTINGS]);

        let existingTypes = Object.keys(sheets);
        let sheetsToSet = spreadsheetSettings.sheets;
        let sheetsSearchRequestObjectsToSet = spreadsheetSettings.sheetsSearchRequestObjects;
        let types = Object.keys(sheetsToSet);

        existingTypes.forEach(t => {
            delete sheets[t];
            delete this.sheetsSearchRequestObjects[t];
        });
        this.sheetsSearchRequestObjects = sheetsSearchRequestObjectsToSet;

        //this.initSheetObjectsFor(toLoad, sheets);

        let tabValue = types[0];

        this.setState({sheets: sheetsToSet, sheetTabValue : tabValue}, () => {
            this.loadDataForTypes(types).then(() => {
                let {sheets} = this.state;
                this.setState({addNewTab: undefined, sheetTabValue : 0});
            });
        })
    }

    deleteSpreadsheet = (toDelete) => {
        let backingObject = toDelete.backingObject;
        deleteManagementGraph({
            [AT_CONTEXT] : getManagementContextURL(),
            [AT_GRAPH] : [
                backingObject
            ]
        }).then(r => {
            if(isRequestSuccessful(r)) {
                this.syncSavedSpreadsheets().then(r => {
                    this.setState({loading: false, sheets : {}, showSavedSpreadsheet : undefined, addNewTab : true});
                });
            }
        });

    }

    renderDataGrid = () => {
        let {theme, embedded, workspace, classes, location, aliasesMap, aliasesToIRIMap, settings, browseLanguage, ontology, configurations} = this.props;
        let {sheets, viewLanguage, showSaveDialog, savedSpreadsheets, showSavedSpreadsheet} = this.state;
        let currentSheetTabValue = this.getSheetTabValue(sheets);
        if(currentSheetTabValue === undefined || isEmptyArray(Object.keys(sheets))) {
            return <></>;
        }

        let sheet = sheets[currentSheetTabValue];
        let columns = [];
        let sheetColumns = toArray(sheet?.sheetColumns);
        const searchRequestForSheet = this.sheetsSearchRequestObjects[currentSheetTabValue];

        sheetColumns.forEach(c => {
            let propertyIRI = c[ALIAS_SH_PATH];
            let isString = isStringCell(c);
            const isFrozen = sheet.sheetFrozenColumns.includes(propertyIRI)
            const isHidden = toArray(sheet.sheetHiddenColumns).includes(propertyIRI)
            let columnKey = aliasesMap[propertyIRI] || propertyIRI;
            let columnObject = {
                        resizable: true,
                        sortable : true,
                        width : 300,
                        key : columnKey ,
                        name : columnKey,
                        property : c,
                        frozen : isFrozen
                    }
                    columnObject.headerRenderer = (props) => {

                        return <ColumnHeader
                            settings={settings}
                            gridCellProps={props}
                            searchRequestForSheet = {searchRequestForSheet}
                            columnShape={c}
                            aliasesMap={aliasesMap}
                            aliasesToIRIMap={aliasesToIRIMap}
                            ontology={ontology}
                            browseLanguage={browseLanguage}
                            onFilterAdd={() => {
                                this.setState({openFilterBuilder: c});
                            }}
                            onAdvancedSort={(direction) => {
                                this.setState({openAdvancedSort: {
                                    direction : direction,
                                    column : c
                                }});
                            }}
                            onSort={(direction) => this.sortDataByColumn(c, direction, undefined, ORDER_NO_VALUE_LAST)}
                            isFrozen={isFrozen}
                            onFreezeToggle={(freeze) => {
                                if(sheet.sheetFrozenColumns === undefined) {
                                    sheet.sheetFrozenColumns = [];
                                }
                                if(freeze) {
                                    sheet.sheetFrozenColumns.push(propertyIRI);
                                } else {
                                    sheet.sheetFrozenColumns = sheet.sheetFrozenColumns.filter(i => i !== propertyIRI)
                                }
                                this.setState({});
                            }}
                            onHide={this.handleHideClick(isHidden, sheet, columnKey)}
                        />;


                    };
                    columnObject.formatter = (props) => {
                       // console.log('formatter', props);
                        return this.renderTextCellFromValue(props, false);
                    };
                    columnObject.editor = (props) => {
                      //  console.log('editor', props);
                        return this.renderTextCellFromValue(props, true);
                    };
                    columnObject.editorOptions = {
                        renderFormatter: true
                    };

                    columns.push(columnObject);
            });
            columns = [ {
                key : ROW_INDEX_KEY,
                name : ROW_INDEX_KEY,
                frozen: true,
                formatter : this.renderTextCellFromValue,
                headerRenderer : (props) => { return <></>},
                colSpan(args) {
                    if(args.type === 'SUMMARY') {
                        return sheetColumns.length + 1 ;
                    }
                    return undefined;
                },
                summaryFormatter(args){
                   // console.log('summaryFormatter', args);
                    return <>
                    </>;
                }

            }, ...columns]
            let sheetTypesAlias = aliasesMap[sheet?.sheetType] || sheet?.sheetType
            const rows = this.getRows(sheetTypesAlias);

            const summaryRows = [];

            //Add few more rows and a column so that tool tip for end row and corners can be seen
            if(rows.length > 0) {
                summaryRows.push({
                    totalCount: rows.length,
                });

                for (let i = 0; i < EXTRA_ROWS; i++) {
                    rows.push({});
                }
            }


        let panels = Object.keys(sheets).map((t, index) => {
            return <TabPanel datatest={'tabPanel-'+t} key={t} boxStyle={{blockSize : '100%'}} style={{height : `calc(100% - 52px)`}}  lazy={false} value={currentSheetTabValue} index={t}>
                 <StyledDataGrid
                     rowKeyGetter={(row) => row.id}
                     components={{ rowRenderer: RowRenderer, noRowsFallback: <EmptyRowsRenderer settings={settings} browseLanguage={browseLanguage} /> }}
                     ref={this.gridRef}
                     columns={columns.filter(cl => {
                         let propertyIRI = cl.key;
                         const isHidden = toArray(sheet.sheetHiddenColumns).includes(propertyIRI);
                         return !isHidden;
                     })}
                     rows={rows}
                     rowHeight={this.rowHeight}
                     onRowsChange={this.onRowsChange}
                     headerRowHeight={DEFAULT_ROW_HEIGHT}
                     onScroll={(event) => this.handleScroll(event)}
                 />
             </TabPanel>;
        });

        let disableCreateResourceButton = settings?.createResourceButton?.disabled ? true : false ;
        return <>
            <div datatest={'topBar'} style={{display :'flex', margin : embedded ? '6px 0px 18px 0px':'0px'}}>
                { embedded ? <></> : centerVertically(renderLogo(classes, location, settings, theme, {margin: '0px 16px' }, 'sheets'), { })}
                <>
                    {
                        embedded ? <></> : <>
                            {
                                <SavedSpreadsheet
                                    style={{minWidth: 180, marginRight: '8px'}}
                                    label={'Saved'}
                                    workspace={workspace}
                                    options={savedSpreadsheets || []}
                                    value={showSavedSpreadsheet}
                                    onChange={(event, newValue, reason) => {
                                        this.setState({showSavedSpreadsheet: newValue}, () => this.openSpreadsheet(newValue.value));
                                    }}

                                />
                            }
                            {
                                showSavedSpreadsheet && isSuperadmin() &&
                                centerVertically(<Tooltip title={'Delete'}>
                                    <IconButton datatest={'DeleteOutlined'} color={'primary'}
                                                style={{marginRight: '8px'}} size={'small'} onClick={() => {
                                        this.deleteSpreadsheet(showSavedSpreadsheet);
                                    }}>
                                        <DeleteOutlined color={'secondary'}></DeleteOutlined>
                                    </IconButton>
                                </Tooltip>)

                            }
                            {
                                isSuperadmin() &&
                                centerVertically(<Tooltip title={'Save'}>
                                    <IconButton datatest={'PhotoCameraOutlined'} color={'primary'}
                                                style={{marginRight: '8px'}} size={'small'} onClick={() => {
                                        this.setState({showSaveDialog: true})
                                    }}>
                                        <SaveOutlined></SaveOutlined>
                                    </IconButton>
                                </Tooltip>)

                            }
                            {showSaveDialog && <SaveDialogWithTitle
                                title={showSavedSpreadsheet?.label}
                                onCancel={() => {
                                    this.setState({showSaveDialog: false});
                                }}
                                onSave={(title, action) => {
                                    this.saveSpreadsheet(title, action).then(r => {
                                        this.syncSavedSpreadsheets().then(r => this.setState({showSaveDialog : false}));
                                    })
                                }}
                            />}

                        </>
                    }
                {
                    !embedded && this.props.permissionService.canCreateAnyResourceType() && disableCreateResourceButton === false &&
                    centerVertically(
                    <Tooltip title={getUiLabelTranslation(settings, UI_LABELS_ADD_NEW_RESOURCES, browseLanguage, UI_LABELS_ADD_NEW_RESOURCES)}>
                    <IconButton
                        datatest={'addResourceButton'}
                        style={{padding : '0px'}}
                        onClick={() => this.setState({addNewResource: true})}
                        size={'small'}
                            ><AddOutlined/></IconButton></Tooltip>
                    )
                }

                {
                    centerVertically(
                        <Tooltip title={getUiLabelTranslation(settings, UI_LABELS_RESET_FILTERS, browseLanguage, UI_LABELS_RESET_FILTERS)}>
                            <IconButton
                                datatest={'resetFiltersButton'}
                                style={{padding : '0px'}}
                                size={'small'} onClick={() => {
                                    let sheetTabValue = this.getSheetTabValue(sheets);
                                    this.sheetsSearchRequestObjects[sheetTabValue] = this.createSearchRequest(sheetTabValue);
                                    this.loadDataForTypes([sheetTabValue]).catch();
                                }}
                            ><SettingsBackupRestoreOutlined/></IconButton>
                        </Tooltip>, {marginLeft : '8px'}
                    )
                }
                {
                    centerVertically(
                        <Tooltip title={getUiLabelTranslation(settings, UI_LABELS_REFRESH, browseLanguage, UI_LABELS_REFRESH)}>
                            <IconButton
                                datatest={'refreshDataButton'}
                                style={{padding : '0px'}}
                                size={'small'}
                                onClick={this.refreshData}
                            ><RefreshOutlined/></IconButton>
                        </Tooltip>, {marginLeft : '8px'}
                    )
                }
                {
                    centerVertically(
                        <Tooltip title={getUiLabelTranslation(settings, UI_LABELS_DOWNLOAD, browseLanguage, UI_LABELS_DOWNLOAD)}>
                            <IconButton
                                datatest={'downloadButton'}
                                style={{padding : '0px'}}
                                size={'small'}
                                onClick={() => this.setState({openDownloadDialog : true})}
                            ><GetAppOutlined/></IconButton>
                        </Tooltip>, {marginLeft : '8px'}
                    )
                }
                {
                    centerVertically(
                        <MenuListComposition
                            menuDatatest={'showHideMenuOptions'}
                            buttonRenderer={(onClick) => <Tooltip title={getUiLabelTranslation(settings, UI_LABELS_HIDE_COLUMNS, browseLanguage, UI_LABELS_HIDE_COLUMNS)}>
                                <IconButton
                                    datatest={'showHideColumnButton'}
                                    style={{padding : '0px'}}
                                    size={'small'}
                                    onClick={onClick}
                                >
                                    <StyledBadge color={'secondary'} badgeContent={toArray(sheet.sheetHiddenColumns).length}>
                                        <VisibilityOffOutlined/>
                                    </StyledBadge>
                                </IconButton>
                            </Tooltip>
                            }
                            withClose={true}
                            disableCloseOnClick={true}
                            itemIconStyle={{maxWidth : '32px', minWidth : '32px'}}
                            items={columns.map(cl => {
                                let propertyIRI = cl.key;
                                let propertyKey = aliasesMap[propertyIRI] || propertyIRI;
                                let name = getPropertyName(aliasesToIRIMap, propertyKey, ontology, browseLanguage);
                                const isHidden = toArray(sheet.sheetHiddenColumns).includes(propertyIRI);

                                return {
                                    icon : <Checkbox
                                            datatest={'checkbox-'+name}
                                            edge="start"
                                            checked={isHidden}
                                            tabIndex={-1}
                                            disableRipple
                                            onClick={this.handleHideClick(isHidden, sheet, propertyIRI)}
                                        />,
                                    label : name,
                                    onClick : this.handleHideClick(isHidden, sheet, propertyIRI)
                                };
                            })}
                        />, {marginLeft : '8px'}
                    )
                }
                <div style={{flexGrow : '1'}}></div>

                {

                        centerVertically(
                            <LanguageSearchSelect
                                datatest={'viewLanguage'}
                                tooltip={getUiLabelTranslation(settings, UI_LABELS_SHEET_VIEW_LANGUAGE_HELP, browseLanguage, UI_LABELS_SHEET_VIEW_LANGUAGE_HELP)}
                                label={getUiLabelTranslation(settings, UI_LABELS_SHEET_VIEW_LANGUAGE, browseLanguage, UI_LABELS_SHEET_VIEW_LANGUAGE)}
                                addNameToLabel={true}
                                extraDense={true}
                                multiple={false}
                                value={viewLanguage || getBrowseLanguageCode(browseLanguage)}
                                onChange={(viewLanguage) => {
                                    if (viewLanguage) {
                                        this.setState({viewLanguage: viewLanguage});
                                    }
                                }}
                                suggestions={
                                    getLanguageSuggestionsForSite(settings)
                                }
                            />, {marginRight: '16px'})
                }
                </>
            </div>
            {panels}
        </>
    }

    handleHideClick(isHidden, sheet, columnKey) {
        return (ev) => {
            if(sheet.sheetHiddenColumns === undefined) {
                sheet.sheetHiddenColumns = [];
            }
            ev?.stopPropagation();
            if (isHidden) {
                sheet.sheetHiddenColumns = sheet.sheetHiddenColumns.filter(k => k !== columnKey);
            } else {
                sheet.sheetHiddenColumns.push(columnKey);
            }
            this.setState({});
        };
    }

    refreshData = () => {
        let {sheets} = this.state;
        let sheetTabValue = this.getSheetTabValue(sheets);
        this.resetPaging(this.sheetsSearchRequestObjects[sheetTabValue]);
        this.loadDataForTypes([sheetTabValue]).catch();
    }

    downloadData = async (options) => {
        let {aliasesMap, ontology, aliasesToIRIMap} = this.props;
        let {sheets, viewLanguage} = this.state;
        let sheetTabValue = this.getSheetTabValue(sheets);
        let sheetsSearchRequestObject = this.sheetsSearchRequestObjects[sheetTabValue];

        let cloned = cloneDeep(sheetsSearchRequestObject);
        this.resetPaging(cloned);

        if(options.format === UI_LABELS_DOWNLOAD_FORMAT_TURTLE) {
            cloned[PAGE_SIZE] = options.limit;
            let {result} = await this.performSearch(aliasesMap, sheetTabValue, cloned, viewLanguage, true);
            fileDownload(result.data, sheetTabValue + '_' + new Date().toISOString() + ".ttl")
            return;
        } else if (options.format === UI_LABELS_DOWNLOAD_FORMAT_CSV) {
            let rows = this.getRows(sheetTabValue);
            downloadCSV(sheets, sheetTabValue, options, rows, aliasesMap, ontology, aliasesToIRIMap);
        }
    }

    renderTabs = ( currentSheetTabValue) => {
        let {theme, aliasesToIRIMap} = this.props;
        let {sheets} = this.state;

        const MenuTab = withStyles({
            root: {
                textTransform: 'none',
                maxHeight : BOTTOM_BAR_HEIGHT,
                minHeight : BOTTOM_BAR_HEIGHT,
                minWidth : 'unset'
            },
            selected: {
                color: theme.palette.secondary.main,
                backgroundColor: theme.palette.white.main,
                borderRadius: '0px'
            }
        })(Tab);

        let tabsKey = Object.keys(sheets).join("");

        let tabs = <Tabs key={tabsKey} style={{maxHeight : BOTTOM_BAR_HEIGHT, minHeight : BOTTOM_BAR_HEIGHT}} variant="scrollable" scrollButtons="auto" value={currentSheetTabValue}
                         onChange={(event, newValue) => {
                             this.setState({sheetTabValue: newValue})
                         }} aria-label="sheet tabs">
            {
                Object.keys(sheets).map((key) => {
                    let sheet = sheets[key];
                    let title = <div>
                        <div style={{marginBottom: '8px'}}>{sheet.sheetType}</div>
                        <div>{aliasesToIRIMap[sheet.sheetType]}</div>
                    </div>;
                    let label = <Tooltip title={title}>
                        <span style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '160px'
                        }} noWrap={true}>{sheet.sheetLabel}</span>
                    </Tooltip>;
                    return <MenuTab key={key} datatest={'sheetTab'+sheet.sheetType} label={label} value={sheet.sheetType}/>;
                })
            }
        </Tabs>
        return tabs;
    }

    getSheetTabValue = (sheets) => {
        let {sheetTabValue} = this.state;
        if(sheetTabValue) {
            return sheetTabValue;
        }
        let keys = Object.keys(sheets);
        let firstSheetKey = keys.length > 0 ? keys[0] : undefined;
        return firstSheetKey;
    }

    handleScroll = async (event) => {
        let {currentTarget} = event;
        let {loading} = this.state;
        let isAtBottom = currentTarget.scrollTop +  currentTarget.clientHeight + (EXTRA_ROWS * DEFAULT_ROW_HEIGHT) >= (currentTarget.scrollHeight - 10);
        let {scrollTop, scrollHeight, clientHeight} = currentTarget;
        // console.log('scrol', scrollTop, scrollHeight, clientHeight, isAtBottom);
        let isScrollingUp = this.scrollState &&  this.scrollState.scrollTop < currentTarget.scrollTop ;
        //console.log('scrollState', this.scrollState, currentTarget.scrollTop, isScrollingUp, this.gridRef)
        this.scrollState = {
            scrollTop
        }
        if(loading || isAtBottom === false) {
            return;
        }

        await this.onLoadMore();

    }

    getRows = (sheetTypesAlias) => {
        let {aliasesMap} = this.props;
        let {sheets} = this.state;
        let rowsData = toArray(sheets[sheetTypesAlias].sheetData);

        return rowsData.filter(d => {
            let found = toArray(d[TYPE]).map(t => aliasesMap[t] || t).includes(sheetTypesAlias);
            return found ? true : false;
        }).map((d, index) => {
            d[ROW_INDEX_KEY] = index;
            return d;
        });
    }

    searchAndCache = async (paramMap, headerMap, latestRequestToken) => {
        let response = await graphSearchByMaps(paramMap, headerMap);
        try {
            let json = await response.json();
            if(isRequestSuccessful(response)) {
                primeFromSearchResult(json);
            }
            return {
                json,
                response,
                latestRequestToken
            };
        } catch (e) {
            return Promise.reject(response);
        }
    }

    download = async (paramMap, headerMap, latestRequestToken) => {
        let response = await graphDownloadByMaps(paramMap, headerMap);
        try {
            let data = await response.text();
            return {
                data,
                response,
                latestRequestToken
            };
        } catch (e) {
            return Promise.reject(response);
        }
    }

    onLoadMore = async (reset) => {
        let {sheets} = this.state;
        let sheetTabValue = this.getSheetTabValue(sheets);
        let currentSheet = sheets[sheetTabValue];
        let {sheetType} = currentSheet;
        let searchRequest = this.sheetsSearchRequestObjects[sheetType];
        if (searchRequest && (searchRequest.loading || searchRequest[ALIAS_SYS_HAS_NEXT_PAGE] === false)) {
            return [];
        }

        try {
            let searchData = await this.loadDataForSheetType(sheetType, reset);
            if (reset) {
                currentSheet.sheetData = searchData;
            } else {
                if (searchData.length > 0) {
                    let newSheetData = [...currentSheet.sheetData, ...searchData];
                    currentSheet.sheetData = newSheetData;
                    this.setState({});
                } else {
                    currentSheet.sheetData = [];
                    this.setState({sheets});
                }
            }
            this.prepareViewData();
        } catch (e) {


        }
    }

    loadDataForSheetType = async (sheetType, reset) => {
        let {browseLanguage, aliasesMap, aliasesToIRIMap} = this.props;

        if (!this.sheetsSearchRequestObjects) {
            this.sheetsSearchRequestObjects = {};
        }
        let searchRequest = this.sheetsSearchRequestObjects[sheetType];
        if (searchRequest && (searchRequest.loading || searchRequest[ALIAS_SYS_HAS_NEXT_PAGE] === false)) {
            return [];
        }

        if (!searchRequest) {
            searchRequest = this.createSearchRequest(sheetType);
        }

        searchRequest.loading = true;
        //render so that loading shows
        this.setState({});
        let {paramMap, result} = await this.performSearch(aliasesMap, sheetType, searchRequest, browseLanguage);
        let {json, response} = result;
        if(isRequestSuccessful(response)) {
            searchRequest[ALIAS_SYS_HAS_NEXT_PAGE] = json[ALIAS_SYS_HAS_NEXT_PAGE] === 'true';
            searchRequest[PAGE] = paramMap[PAGE];
            searchRequest[PAGE_SIZE] = paramMap[PAGE_SIZE];

            let searchData = toArray(json[ALIAS_SYS_RESULTS]);
            let clonedSearchData = cloneDeep(searchData);
            searchRequest.loading = false;
            return clonedSearchData;
        }  else if(isClientError(response)) {
            searchRequest.loading = false;
            this.setState({searchErrors : json})
        } else {
            searchRequest.loading = false;
            this.setState({searchErrors : json})
        }
    }


    performSearch = async (aliasesMap, sheetType, searchRequest, browseLanguage, download) => {

        let sheetTypesAliases = aliasesMap[sheetType] || sheetType;
        let search = searchRequest.search;
        let userFilters = search.filters;

        let userFiltersQuery = nonEmptyToUndefinedQuery(`{${getTextQuery(search.textSearch)} ${getQuery(userFilters, '')} }`);
        if (userFiltersQuery) {
            let allUserFilterPlusHiddenFilter = search.filters.filter(f => f.applyOnEmpty !== true);
            let newQuery = `{${getTextQuery(search.textSearch)} ${getQuery(allUserFilterPlusHiddenFilter, '')} }`;
            searchRequest.paramMap[QUERY] = newQuery;
        }
        if (searchRequest.sort) {
            let sort = searchRequest.sort;
            let sortBy = toGraphQL(sort);
            if (sortBy) {
                searchRequest.paramMap[SORT_BY] = sortBy;
            }
        }

        let {paramMap, headerMap} = searchRequest;
        let rows = this.getRows(sheetTypesAliases);
        let rowsCount = rows.length;
        let pageSize = DEFAULT_PAGE_SIZE;
        let page = searchRequest[PAGE] !== undefined
            ? Number(searchRequest[PAGE]) + 1
            : Math.floor(rowsCount / pageSize) + 1;
        if (page !== undefined) {
            paramMap[PAGE] = page;
        }
        paramMap[PAGE_SIZE] = searchRequest[PAGE_SIZE] !== undefined ? Number(searchRequest[PAGE_SIZE]) : pageSize;
        paramMap[LABEL_PROPERTY_LANG] = getBrowseLanguageCode(browseLanguage)
        let result = download
            ? await this.download(paramMap, headerMap)
            : await this.searchAndCache(paramMap, headerMap);
        return {paramMap, result};
    }

    createSearchRequest = (sheetType) => {
        let {configurations} = this.props;
        let searchRequest = cloneDeep(this.props.settings.searchRequestObject);
        //remove all filter except type
        let typeFilter = searchRequest.search.filters.find(f => f.property[ALIAS_SH_PATH] === TYPE);
        if(!typeFilter) {
            addFilter(configurations, searchRequest.search, TYPE, EQUAL_TO, [sheetType]);
            typeFilter = searchRequest.search.filters.find(f => f.property[ALIAS_SH_PATH] === TYPE);
        }
        searchRequest.search.filters = [];
        if(typeFilter) {
            typeFilter.hidden = true;
            typeFilter.value = [sheetType];
            searchRequest.search.filters.push(typeFilter);
        }

        this.sheetsSearchRequestObjects[sheetType] = searchRequest;
        //unset all the facet and other parameters
        searchRequest.mixin = {};
        searchRequest.paramMap = {};
        searchRequest.search.textSearch = {};
        return searchRequest;
    }

    loadInitialData = () => {
        let {data, aliasesMap, aliasesToIRIMap} = this.props;
        loadResource(data).then((results) => {
            let nonNullResults = results.filter(r => r);

            //If the main search has other types then add sheet for those types as well
            let {filters} = this.props.settings?.searchRequestObject?.search;
            let userFilters = excludeHidden(filters);
            let typesFromSearch = toArray(userFilters).map(f => {
                let {property, operator, value, lang, dataType} = f
                let propLabel = property ? property.label : ''
                if(propLabel === TYPE) {
                    return value;
                }
            }).filter(v => v);
            typesFromSearch = flatten(typesFromSearch).map(vo => vo.value).map(t => aliasesMap[t]||t);
            let typesFromData = flatten(nonNullResults.map(dto => dto[TYPE])).map(t => aliasesMap[t]||t);
            let allTypes = [...typesFromSearch, ...typesFromData];
            this.loadDataForTypes(allTypes).catch();
        });
    }

    loadDataForTypes = async (types, afterLoad) => {
        let {sheets} = this.state;
        this.initSheetObjectsFor(types, sheets);
        let all = types.map(async t =>  {
            let {sheets} = this.state;
            let dataForSheetType = await this.loadDataForSheetType(t);
            return sheets[t].sheetData = dataForSheetType;
        });
        return Promise.all(all).then(results => {
            this.prepareViewData();
            afterLoad && afterLoad()
        });
    }

    initSheetObjectsFor = (types, sheets) => {
        let {aliasesToIRIMap, ontology, browseLanguage} = this.props;

        types.forEach(async t =>  {
            if(!sheets[t]) {
                sheets[t] = {
                    sheetType : t,
                    sheetLabel : getPropertyName(aliasesToIRIMap, t, ontology, browseLanguage),
                    sheetColumns : [],
                    sheetData : [],
                    sheetFrozenColumns : []
                }
            }
        });
    }

    renderDialogActions = () => {
        let {sheets, viewLanguage, openFilterBuilder, addNewResource, addNewTab, openAdvancedSort, openDownloadDialog} = this.state;
        let {theme, embedded, settings, ontology, aliasesMap, browseLanguage, aliasesToIRIMap, configurations} = this.props;
        let currentSheetTabValue = this.getSheetTabValue(sheets);
        let sheet = sheets[currentSheetTabValue];
        if(sheet === undefined) {
            if(!addNewTab && !embedded) {
                this.setState({addNewTab: true});
            }
            return <></>;
        }
        let sheetType = sheet.sheetType;
        let sheetTypesAlias = aliasesMap[sheetType] || sheetType
        const rows = this.getRows(sheetTypesAlias);
        const searchRequestForSheet = this.sheetsSearchRequestObjects[currentSheetTabValue];

        return <div style={{display : 'flex', padding : '0px 16px', backgroundColor : theme.palette.grey.levelE0}}>
            {
                addNewResource &&
                <AddNewResourceDialog
                    ontology={ontology}
                    browseLanguage={browseLanguage}
                    aliasesToIRIMap={aliasesToIRIMap}
                    aliasesMap={aliasesMap}
                    settings={settings}
                    onClose={() => this.setState({addNewResource : undefined})}
                    onSaveSuccess={() => {
                        this.setState({addNewResource : undefined, addNewResourceSuccess : true}, this.refreshData);
                    }}
                />
            }
            {
                openFilterBuilder && <AddNewFilter
                    settings={settings}
                    search={searchRequestForSheet?.search}
                    aliasesMap={aliasesMap}
                    aliasesToIRIMap={aliasesToIRIMap}
                    browseLanguage={browseLanguage}
                    configurations={configurations}
                    ontology={ontology}
                    shapeProperty={openFilterBuilder}
                    onClose={() => this.setState({openFilterBuilder : undefined})}
                    onAdd={(newFilters) => {
                        this.resetPaging(searchRequestForSheet);
                        this.onLoadMore(true).then(() => this.setState({openFilterBuilder : undefined})).catch();

                    }}
                />
            }
            {
                openAdvancedSort && <AddSort
                    settings={settings}
                    sort={getSortForColumn(aliasesMap, openAdvancedSort.column[ALIAS_SH_PATH], searchRequestForSheet)}
                    aliasesMap={aliasesMap}
                    aliasesToIRIMap={aliasesToIRIMap}
                    browseLanguage={viewLanguage}
                    configurations={configurations}
                    ontology={ontology}
                    shapeProperty={openAdvancedSort.column}
                    onClose={() => this.setState({openAdvancedSort : undefined})}
                    onAdd={(direction, orderNoValue, orderLang) => {
                        this.sortDataByColumn(openAdvancedSort.column, direction, orderLang, orderNoValue);
                        this.setState({openAdvancedSort : undefined});
                    }}
                />
            }
            {
                openDownloadDialog &&
                    <DownloadDialog
                        settings={settings}
                        browseLanguage={viewLanguage}
                        ontology={ontology}
                        aliasesToIRIMap={aliasesToIRIMap}
                        aliasesMap={aliasesMap}
                        configurations={configurations}
                        onClose={() => this.setState({openDownloadDialog : undefined})}
                        onDownload={(options) => {
                            this.downloadData(options).finally(() => {
                                this.setState({openDownloadDialog : undefined})
                            });
                        }}
                    />
            }

            {
                embedded ? <></> :
                centerVertically(
                    <Tooltip title={getUiLabelTranslation(settings, UI_LABELS_SHEETS, browseLanguage, UI_LABELS_SHEETS)}>
                        <IconButton
                            datatest={'sheetsButton'}
                            size={'small'}
                            color={'default'}
                            variant={'outlined'}
                            onClick={() => this.setState({addNewTab: true})}
                        ><ViewListOutlined/></IconButton>
                    </Tooltip>, {marginRight : '8px'}
                )
            }
            {this.renderTabs(currentSheetTabValue)}

            <div style={{flexGrow : '1'}}></div>
            {
                rows.length > 0 && centerVertically(<Typography datatest={'rowCount'} color={'primary'}>Loaded {rows.length - EXTRA_ROWS} Rows</Typography>, {marginRight : '8px', minWidth: '104px'})
            }
            {
                centerVertically(
                <Tooltip title={getUiLabelTranslation(settings, UI_LABELS_LOAD_MORE, browseLanguage, UI_LABELS_LOAD_MORE)}>
                    <span>
                        {
                            this.sheetsSearchRequestObjects[currentSheetTabValue]?.loading
                                ? <CircularProgress style={{marginLeft: '6px'}} size={24} color={'secondary'}/>
                                : <IconButton
                                    disabled={this.sheetsSearchRequestObjects[currentSheetTabValue]?.[ALIAS_SYS_HAS_NEXT_PAGE] === false}
                                    size={'small'}
                                    color={'secondary'}
                                    onClick={() => this.onLoadMore()}
                                ><UnfoldMoreOutlined></UnfoldMoreOutlined></IconButton>
                        }
                    </span>
                </Tooltip>
                )
            }
        </div>;
    }

    renderSearchErrors = (searchErrors) => {
        let {ontology, browseLanguage, aliasesToIRIMap} = this.props;

        return toArray(searchErrors?.[GRAPH]).map((er) => {
            let propertyName = getPropertyName(aliasesToIRIMap, er[ALIAS_SH_PATH], ontology, browseLanguage);
            return <div key={er[ID]}>{propertyName} : {er[ALIAS_RDFS_LABEL].en}</div>;
        });
    }

    renderDialog = () => {
        let {open, sheetOpenError, sheets, searchErrors, openEditorFor, updateFailed, addNewTab, errors} = this.state;
        let {workspace, embedded, settings, theme, aliasesMap, browseLanguage, aliasesToIRIMap, configurations, location} = this.props;


        return <div
            aria-labelledby="Data Grid View"
            open={open}
            fullWidth={true}
            fullScreen={true}
            style={{display : 'flex', flexDirection : 'column', height : '100%'}}

        >
            <div style={{flexGrow : '1', maxHeight : 'calc(100% - 36px)'}}>

                {
                    addNewTab &&
                        <React.Fragment key={addNewTab}>
                            <AddNewTabDialog
                                workspace={workspace}
                                settings={settings}
                                selectedTypes={Object.keys(sheets)}
                                location={location}
                                aliasesMap={aliasesMap}
                                aliasesToIRIMap={aliasesToIRIMap}
                                browseLanguage={browseLanguage}
                                configurations={configurations}
                                onClose={() => {
                                    let existingTypes = Object.keys(sheets);
                                    if(isEmptyArray(toArray(existingTypes))) {
                                        this.props.onClose();
                                    } else {
                                        this.setState({addNewTab: undefined});
                                    }
                                }}
                                onAdd={(types) => {
                                    let {sheets} = this.state;

                                    let existingTypes = Object.keys(sheets);
                                    let toRemove = existingTypes.filter(t => !types.includes(t));
                                    let toLoad = types.filter(t => !existingTypes.includes(t));
                                    let sheetTabValue = this.getSheetTabValue(sheets);

                                    toRemove.forEach(t => {
                                        delete sheets[t];
                                        delete this.sheetsSearchRequestObjects[t];
                                    });
                                    this.initSheetObjectsFor(toLoad, sheets);

                                    //Handle current tab as sheet might be added removed
                                    //we just set the tab to last tab in new
                                    let sheetTypes = Object.keys(sheets);
                                    let tabValue = sheetTypes[sheetTypes.length - 1];

                                    this.setState({sheets, sheetTabValue : tabValue}, () => {
                                        this.loadDataForTypes(toLoad).then(() => {
                                            let {sheets} = this.state;
                                            //Set focus to last sheet
                                            let types = Object.keys(sheets);
                                            let tabValue = types[types.length - 1];
                                            this.setState({addNewTab: undefined, sheetTabValue : tabValue});
                                        });
                                    })
                                }}
                                onOpenSpreadsheet={(showSavedSpreadsheet) => {
                                    this.setState({loading : true, showSavedSpreadsheet : showSavedSpreadsheet});
                                    this.openSpreadsheet(showSavedSpreadsheet.value).then(() => {
                                        this.setState({addNewTab: undefined, loading : false});
                                    });
                                }}

                            />
                        </React.Fragment>
                }
                {updateFailed && <AlertSnackbarContent onClose={() => this.setState({updateFailed : undefined})} variant={'error'} autoHide={true} open={true} message={updateFailed} />}
                {errors && <AlertSnackbarContent onClose={() => this.setState({errors : undefined})} variant={'error'} autoHide={true} open={true} message={renderErrors(errors, theme.palette.white.main)} />}
                {searchErrors && <AlertSnackbarContent onClose={() => this.setState({searchErrors : undefined})} variant={'error'} autoHide={true} open={true} message={this.renderSearchErrors(searchErrors)} />}
                {embedded && sheetOpenError && <H3Title color={'secondary'}>{sheetOpenError}</H3Title> }
                {sheets && this.renderDataGrid()}
            </div>
            {this.renderDialogActions()}
        </div>;
    }


    render() {
        return <>
            {this.renderDialog()}
        </>;
    }
}

DataGridView.propTypes = {
    permissionService: PropTypes.instanceOf(PermissionService),
    publishEvent : PropTypes.func,
    registerForEvents : PropTypes.func,
    open : PropTypes.bool,
    fullScreen: PropTypes.bool,
    hideButton: PropTypes.bool,
    minimized: PropTypes.any,
    editMode: PropTypes.any,
    browseLanguage: PropTypes.any,
    location: PropTypes.any,
    configurations: PropTypes.any,
    ontology: PropTypes.array,
    shapes: PropTypes.array,
    spreadsheet: PropTypes.object,
    aliasesMap: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    workspace: PropTypes.object,
    settings: PropTypes.object,
    data: PropTypes.any,
    onExpand: PropTypes.func,
    onClose: PropTypes.func,
    eventRegister: PropTypes.func
};

export default withStyles(styles, {withTheme: true})(withPermissions(withEvent(DataGridView)));
