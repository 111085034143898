import { withStyles } from '@material-ui/core/styles';
import "@triply/yasgui/build/yasgui.min.css";
import YASQE from "@triply/yasqe";
import PropTypes from 'prop-types';
import React, { Component } from 'react'
import {styles as mainStyle} from "../../../components/styles";

import "./sparql-editor.css";
import {classesAutocomplete, prefixAutocomplete, propertiesAutocomplete} from './autoComplete';
import {getAuthorization, getSPARQLEndpoint} from "../../../service/graph-api";

YASQE.registerAutocompleter(prefixAutocomplete);
YASQE.registerAutocompleter(propertiesAutocomplete);
YASQE.registerAutocompleter(classesAutocomplete);
YASQE.defaults.autocompleters = ['prefixAutocomplete', 'propertiesAutocomplete', 'classesAutocomplete', 'variables'];


class YasqeWrapper extends Component {

  _setRef(componentNode) {
    this._rootNode = componentNode;
  }

  componentDidMount() {
    let sparqlEndpoint = getSPARQLEndpoint();

    const yasqe = new YASQE(this._rootNode, {
      requestConfig: {
        endpoint: sparqlEndpoint,
        method: 'POST',
        headers: () => ({
          ...getAuthorization(sparqlEndpoint)
        })
      },
      createShareableLink: null,
      showQueryButton: false,
      classList: this.props.classList,
      editorHeight: '300px',
      propertyList: this.props.propertyList,
      persistenceId: null
    });
    yasqe.setValue(this.props.initialQuery);
    this.props.onSetup?.(yasqe);
  }

  render() {
    let {containerStyle, key} = this.props;
    return (
      <div style={containerStyle}>
        <div ref={this._setRef.bind(this)} />
      </div>
    );
  }
}

YasqeWrapper.defaultProps = {
  initialQuery:
`SELECT * WHERE 
  {
    ?s ?p ?o .
  }
  LIMIT 10
`
};

YasqeWrapper.propTypes = {
  classes: PropTypes.object,
  containerStyle: PropTypes.object,
  classList: PropTypes.array,
  propertyList: PropTypes.array,
  initialQuery: PropTypes.string,
  onSetup: PropTypes.func.isRequired
};

export default withStyles(mainStyle)(YasqeWrapper);