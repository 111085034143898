import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {styles} from "../components/styles";
import ReactKeyByIndex from "../components/ReactKeyByIndex";
import {STYLE_GRID_ITEM_SPACING, STYLE_MAIN_SPACING} from "../Constants";
import BackendErrorDialog from "../components/BackendErrorDialog";
import SplitPane from "react-split-pane";
import "./Builder.css";
import dragBG from "../images/more_vert.png";
import {getUserPreference, setUserPreference} from "./common/Profile";



let hasLeftContent = (props) => {
    let {leftMainHeader, leftComponent, leftActions} = props
    return leftComponent || leftActions || leftMainHeader;
}

const LMV = 'LMV';
let AllPartsLayout = (props) => {
    let {theme, header, leftMainHeader, leftComponent, leftStyle, leftComponentStyle,
        middleActions, leftActions, middleComponent, middleComponentStyle, footer,
        rightComponent, rightComponentStyle, leftComponentScroll, middleStyle,
        leftComponentContainerStyle, apiError, apiErrorResponse, onApiErrorClose, resizeable, resizeStoreKey,
        fullScreenStyle, leftMinSize} = props
    let leftComponentStyleDefault = {
        paddingRight: theme.spacing(1),
        height: 'calc(100% - 40px)',
        paddingTop: theme.spacing(STYLE_GRID_ITEM_SPACING)
    }
    if(leftActions) {
        leftComponentStyleDefault.width = 'calc(100% - 89px)'
        leftComponentStyleDefault.float = 'right'
        leftComponentStyleDefault.paddingTop = '16px'
        leftComponentStyleDefault.paddingBottom = '16px'
        leftComponentStyleDefault.paddingRight = '16px'
    }
    if(leftComponentStyle) {
        Object.keys(leftComponentStyle).forEach(k => leftComponentStyleDefault[k] = leftComponentStyle[k])
    }
    let middleComponentStyleDefault = {}
    if(middleActions) {
        middleComponentStyleDefault.width = 'calc(100% - 88px)'
        middleComponentStyleDefault.float = 'right'
        middleComponentStyleDefault.paddingBottom = '16px'
        middleComponentStyleDefault.paddingRight = '16px'
    } else {
        // middleComponentStyleDefault.padding = '16px'
    }
    if(middleComponentStyle) {
        Object.keys(middleComponentStyle).forEach(k => middleComponentStyleDefault[k] = middleComponentStyle[k])
    }
    let leftStyleToApply = {
        paddingLeft: theme.spacing(STYLE_MAIN_SPACING),
        backgroundColor : theme.palette.white.main,
        borderTop: '1px solid '+theme.palette.border.main,
        paddingTop: theme.spacing(STYLE_MAIN_SPACING) - (theme.spacing(STYLE_GRID_ITEM_SPACING)),
        ...leftStyle,
    }

    let middleStyleToApply = hasLeftContent(props) ? {
        borderTop: '1px solid '+theme.palette.border.main,
        paddingLeft: theme.spacing(STYLE_MAIN_SPACING/2),
        paddingTop: theme.spacing(STYLE_MAIN_SPACING) - (theme.spacing(STYLE_GRID_ITEM_SPACING)),
        ...middleStyle
    } : {}


    let leftPart = hasLeftContent(props) &&
        <div style={leftStyleToApply} className={"left"}>
            {leftMainHeader}
            {leftActions &&
                <div style={{
                    float: 'left',
                    position: 'relative',
                    top: 0,
                    bottom: 0,
                    paddingTop: '16px',
                    paddingLeft: '4px',
                    paddingRight: '4px',
                }}>{
                    leftActions.map((a, i) => {
                        return <div key={"left-action-" + i} style={{marginBottom: "10px"}}>{a}</div>;
                    })
                }</div>}
            <div style={leftComponentStyleDefault}>
                <div style={{
                    overflowY: leftComponentScroll.y,
                    overflowX: leftComponentScroll.x,
                    paddingRight: theme.spacing(1),
                    height: '100%',
                    ...leftComponentContainerStyle
                }}>
                    {leftComponent}
                </div></div>
        </div>;
    let middlePart = (middleComponent || middleActions) &&
        <div className={"middle"} style={middleStyleToApply}>
            {middleActions &&
                <div style={{
                    float: 'left',
                    position: 'relative',
                    top: 0,
                    bottom: 0,
                    borderRight: 'solid 1px #eee',
                    paddingLeft: '4px',
                    paddingRight: '4px',
                }}>{
                    middleActions.map((a, i) => {
                        return <div key={"action-"+i} style={{marginBottom: "10px"}}>{a}</div>;
                    })
                }</div>}
            {
                Object.keys(middleComponentStyleDefault) && Object.keys(middleComponentStyleDefault).length > 0
                    ? <div style={middleComponentStyleDefault}>{middleComponent}</div>
                    : middleComponent
            }
        </div>;
    let rightPart = rightComponent && <div className={"right"} style={rightComponentStyle}>{rightComponent}</div>;
    const sizePrefKey = resizeStoreKey || LMV;
    let  minSize = Number(getUserPreference(sizePrefKey)) || 440;
    return (
        <React.Fragment>
            <div datatest={'allPartsLayout'} className={"full-screen"} style={fullScreenStyle}>
                {apiError && <BackendErrorDialog open={apiError} error={apiErrorResponse} handleClose={onApiErrorClose}/>}
                {header && <ReactKeyByIndex data={header}/>}
                <div className={"main"}>
                    {
                        resizeable ? <SplitPane resizerStyle={{
                            backgroundImage : `url(${dragBG})`,
                            backgroundRepeat : 'no-repeat',
                            backgroundSize: 'contain',
                            backgroundPositionY : 'center',
                            width: '16px'
                        }} size={minSize} onDragFinished={(ev) => setUserPreference( sizePrefKey, ev)} style={{height : `calc(100% - 64px)`}} split="vertical" minSize={leftMinSize || 440}>
                            {leftPart}
                            {middlePart}
                            {rightPart}
                        </SplitPane> :<>
                            {leftPart}
                            {middlePart}
                            {rightPart}
                        </>
                    }
                </div>
                {footer}
            </div>
        </React.Fragment>
    );
}

AllPartsLayout.propTypes = {
    apiError: PropTypes.bool,
    resizeable: PropTypes.bool,
    resizeStoreKey: PropTypes.string,
    leftMinSize: PropTypes.number,
    apiErrorResponse : PropTypes.any,
    onApiErrorClose: PropTypes.func,
    header: PropTypes.any,
    leftMainHeader: PropTypes.object,
    leftComponent: PropTypes.object,
    leftStyle: PropTypes.object,
    leftComponentStyle: PropTypes.object,
    leftComponentContainerStyle: PropTypes.object,
    middleActions: PropTypes.array,
    leftActions: PropTypes.array,
    middleComponent: PropTypes.object,
    middleComponentStyle: PropTypes.object,
    middleStyle: PropTypes.object,
    rightComponent: PropTypes.object,
    rightComponentStyle: PropTypes.object,
    fullScreenStyle: PropTypes.object,
    footer: PropTypes.object,
    leftComponentScroll: PropTypes.shape({
        x: PropTypes.oneOf(['scroll', 'hidden', 'auto']),
        y: PropTypes.oneOf(['scroll', 'hidden', 'auto']),
    }),
};

AllPartsLayout.defaultProps = {
    leftComponentScroll: {
        x: 'auto',
        y: 'scroll',
    },
    leftComponentContainerStyle : {}
}

export default withStyles(styles, {withTheme: true})(AllPartsLayout);
