import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {FormControlLabel, Switch, TextField as MuiTextField, Typography} from "@material-ui/core";
import {styles} from "../../components/styles";
import {traceRenderStart} from "../../components/Trace";
import {getMultilingualValue, toArray} from "../../components/util";
import {
    ALIAS_SYS_EXAMPLE_TYPE_BATCH,
    ALIAS_SYS_EXAMPLE_TYPE_CREATE,
    ALIAS_SYS_EXAMPLE_TYPE_DELETE,
    ALIAS_SYS_EXAMPLE_TYPE_RESET_DATA,
    ALIAS_SYS_EXAMPLE_TYPE_SEARCH,
    ALIAS_SYS_EXAMPLE_TYPE_UPDATE,
    AT_DEFAULT,
    CBD,
    DATA,
    LABEL_PROPERTY_LANG
} from "../../Constants";

export const UI_LABELS = 'uiLabels';
export const UI_LABELS_HELP = 'Help';
export const UI_LABELS_NO_RESULTS = 'No Results';
export const UI_LABELS_BROWSE_LANGUAGE = 'Browse Language Select Title';
export const UI_LABELS_ANY = 'Search Language Option Any';

//Search Request
export const UI_LABELS_BUILD = 'Build';
export const UI_LABELS_REQUEST = 'Request';
export const UI_LABELS_REQUEST_BODY = 'Request Body';
export const UI_LABELS_RESPONSE = 'Response';

export const UI_LABELS_EXPAND = 'Expand';
export const UI_LABELS_COLLAPSE = 'Collapse';
export const UI_LABELS_EXPAND_ALL = 'Expand All';
export const UI_LABELS_COLLAPSE_ALL = 'Collapse All';
export const UI_LABELS_FILTER = 'Filter';
export const UI_LABELS_FILTERS = 'Filters';
export const UI_LABELS_ADD_FEEDBACK = 'Add Feedback';
export const UI_LABELS_FROM = 'From';
export const UI_LABELS_TO = 'To';
export const UI_LABELS_DATA = 'Data';
export const UI_LABELS_FACET = 'Facet';
export const UI_LABELS_SORT = 'Sort';
export const UI_LABELS_FREEZE = 'Freeze';
export const UI_LABELS_UNFREEZE = 'Unfreeze';
export const UI_LABELS_SORT_A_TO_Z = 'Sort A to Z';
export const UI_LABELS_SORT_Z_TO_A = 'Sort Z to A';
export const UI_LABELS_SORT_ADVANCED = 'Sort Advanced';
export const UI_LABELS_SORT_DIRECTION = 'Direction';
export const UI_LABELS_SORT_EMPTY_OR_NO_VALUE = 'If no value sort';
export const UI_LABELS_SORT_EMPTY_OR_NO_VALUE_LAST = 'First';
export const UI_LABELS_SORT_EMPTY_OR_NO_VALUE_FIRST = 'Last';
export const UI_LABELS_SORT_EMPTY_OR_NO_VALUE_ANY = 'Any';
export const UI_LABELS_SORT_ASCENDING = 'Ascending';
export const UI_LABELS_SORT_DESCENDING = 'Descending';
export const UI_LABELS_PAGING_COUNTING = 'Paging Counting';
export const UI_LABELS_LANGUAGE = 'Language';
export const UI_LABELS_CONDITIONAL_GET = 'Conditional Get';

export const UI_LABELS_TEXT_SEARCH = 'Text Search';
export const UI_LABELS_PROPERTIES = 'Properties';
export const UI_LABELS_RESET_ALL = 'Reset All';
export const UI_LABELS_RESET_FILTERS = 'Reset Filters & Sort';
export const UI_LABELS_FULL_SCREEN = 'Full Screen';
export const UI_LABELS_FULL_SCREEN_EXIT = 'Exit Full Screen';
export const UI_LABELS_REFRESH = 'Refresh';
export const UI_LABELS_ADD = 'Add';
export const UI_LABELS_REMOVE = 'Remove';
export const UI_LABELS_EXISTING = 'Existing';
export const UI_LABELS_SELECTED = 'Selected';
export const UI_LABELS_NEW = 'New';
export const UI_LABELS_PROPERTY = 'Property';
export const UI_LABELS_OPERATOR = 'Operator';

export const UI_LABELS_EQUAL_TO = 'Is equal to';
export const UI_LABELS_NOT_EQUAL_TO = 'Is not equal to';
export const UI_LABELS_LESS_THAN = 'Less than';
export const UI_LABELS_LESS_THAN_EQUAL_TO = 'Less than equal to';
export const UI_LABELS_GREATER_THAN = 'Greater than';
export const UI_LABELS_GREATER_THAN_EQUAL_TO = 'Greater than equal to';
export const UI_LABELS_OBJECT = 'Resource Matches';
export const UI_LABELS_BETWEEN = 'Between';
export const UI_LABELS_TEXT = 'Text contains';
export const UI_LABELS_EXISTS = 'Any value exists';

export const UI_LABELS_VALUE = 'Value';
export const UI_LABELS_INVALID_DATATYPE_VALUE = 'Datatype value is invalid.';
export const UI_LABELS_VALIDATION_REQUIRED = 'Value is required.';
export const UI_LABELS_VALIDATION_MINIMUM = 'Minimum ';
export const UI_LABELS_VALIDATION_MAXIMUM = 'Maximum ';
export const UI_LABELS_VALIDATION_CHARACTERS_REQUIRED = 'characters required.';
export const UI_LABELS_VALIDATION_CHARACTERS_ALLOWED = 'characters allowed.';
export const UI_LABELS_INVALID_IRI_VALUE = 'Invalid IRI.';
export const UI_LABELS_VALIDATION_INVALID_VALUE = 'Invalid value.';
export const UI_LABELS_VALIDATION_INVALID_DATATYPE_VALUE = 'Invalid datatype value.';
export const UI_LABELS_SEARCH_AND_CONNECT = 'Search & Connect';
export const UI_LABELS_SEARCH_AND_CONNECT_TOOLTIP = 'Search existing resource(s) and connect.';
export const UI_LABELS_ADD_ID_OR_CREATE = 'Add Or Create';
export const UI_LABELS_ADD_ID_OR_CREATE_TOOLTIP = 'Add id(s) manually or embed resource object(s).';
export const UI_LABELS_UNDO = 'Undo';
export const UI_LABELS_ADD_ID = 'Add Id';
export const UI_LABELS_REMOVE_ALL = 'Remove All';
export const UI_LABELS_SELECT_PROPERTY_TO_ADD = 'Select property to add';
export const UI_LABELS_REMOVE_BLOCK = 'Remove Block';
export const UI_LABELS_ADD_FILTER = 'Add Filter';
export const UI_LABELS_ADD_AND_CONDITION = 'Add And Condition';
export const UI_LABELS_MORE = 'More';
export const UI_LABELS_ADD_MORE = 'Add More';
export const UI_LABELS_ADD_NEW_RESOURCES = 'Add New Resources';
export const UI_LABELS_AI_VIEW = 'AI View';
export const UI_LABELS_SHEETS = 'Sheets';
export const UI_LABELS_OPEN_GRAPH_VISUALISATION = 'Open Visualisation';
export const UI_LABELS_SELECT_VISUALISATION = 'Select Visualisation';
export const UI_LABELS_LOAD_MORE = 'Load More';
export const UI_LABELS_SPARQL_RUN = 'Run';
export const UI_LABELS_SPARQL_GENERATE = 'Generate Query';
export const UI_LABELS_DOWNLOAD = 'Download';
export const UI_LABELS_CLOSE_SHEETS_VIEW = 'Close Sheets View';
export const UI_LABELS_CLOSE = 'Close';
export const UI_LABELS_SHEET_VIEW_LANGUAGE = 'View Language';
export const UI_LABELS_SHEET_VIEW_LANGUAGE_HELP = 'Select view language for cells with values in multiple languages.';
export const UI_LABELS_HIDE_COLUMNS = 'Hide Columns';
export const UI_LABELS_HIDE = 'Hide';
export const UI_LABELS_DOWNLOAD_LIMIT = 'Limit';
export const UI_LABELS_DOWNLOAD_FORMAT = 'Format';
export const UI_LABELS_DOWNLOAD_FORMAT_TURTLE = 'Turtle';
export const UI_LABELS_DOWNLOAD_FORMAT_TURTLE_INFORMATION = 'Data will be fetched from server and order of resources in the downloaded data may change.';
export const UI_LABELS_DOWNLOAD_FORMAT_RDF_XML = 'RDF/XML';
export const UI_LABELS_DOWNLOAD_FORMAT_CSV = 'CSV';
export const UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR = 'Separator';
export const UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_COMMA = 'Comma';
export const UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_TAB = 'Tab';
export const UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_CUSTOM = 'Custom';
export const UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_CUSTOM_VALUE = 'Custom Separator';
export const UI_LABELS_DOWNLOAD_FORMAT_CSV_ADD_DATATYPE_AND_LANGUAGE = 'For mixed values columns serialise data with datatype, language or as id';
export const UI_LABELS_DOWNLOAD_FORMAT_TSV = 'TSV';
export const UI_LABELS_BACK = 'Back';
export const UI_LABELS_OPEN_SHEETS_VIEWS = 'Open Sheets View';
export const UI_LABELS_OPEN_DATA_VISUALISATION_VIEWS = 'Open Data Visualisation';
export const UI_LABELS_APPLY = 'Apply';
export const UI_LABELS_TYPE = 'Type';

export const UI_LABELS_DATA_FILTER = 'Data Filter';
export const UI_LABELS_NONE = 'None';
export const UI_LABELS_NONE_HELP = 'UI_LABELS_NONE_HELP';
export const UI_LABELS_SELECTED_PROPERTIES = 'Selected Properties';
export const UI_LABELS_SELECTED_PROPERTIES_HELP = 'Selected Properties';
export const UI_LABELS_NO_VALUE = 'No Value';
export const UI_LABELS_NO_VALUE_HELP = 'UI_LABELS_NO_VALUE_HELP';
export const UI_LABELS_DATA_FILTER_HELP = 'UI_LABELS_DATA_FILTER_HELP';
export const UI_LABELS_CONNECTED = 'Connected';
export const UI_LABELS_ADD_CONNECTION = 'Add Connection';
export const UI_LABELS_CONNECTION_PROPERTY = 'Connection Property';
export const UI_LABELS_PROPERTY_LANGUAGE = 'Label Property Language';
export const UI_LABELS_PROPERTY_LANGUAGE_HELP = 'UI_LABELS_PROPERTY_LANGUAGE_HELP';
export const UI_LABELS_ADD_PROPERTY = 'Add Property';
export const UI_LABELS_LABEL_PROPERTY = 'Label Property';
export const UI_LABELS_LABEL_PROPERTY_LANGUAGE = 'Label Property Language';
export const UI_LABELS_LABEL_PROPERTY_LANGUAGE_HELP_LINE1 = 'UI_LABELS_LABEL_PROPERTY_LANGUAGE_HELP_LINE1';
export const UI_LABELS_LABEL_PROPERTY_LANGUAGE_HELP_LINE2 = 'UI_LABELS_LABEL_PROPERTY_LANGUAGE_HELP_LINE2';
export const UI_LABELS_ORDER = 'Order';
export const UI_LABELS_PRECEDENCE = 'Precedence';
export const UI_LABELS_COUNT_UPTO = 'Count Upto';
export const UI_LABELS_LABEL_COUNT_UPTO_HELP = 'Maximum number of results to count.';
export const UI_LABELS_PAGE_SIZE = 'Page Size';
export const UI_LABELS_PAGE = 'Page';
export const UI_LABELS_SEARCH_LANGUAGE_HELP = 'UI_LABELS_SEARCH_LANGUAGE_HELP';
export const UI_LABELS_IF_NONE_MATCH = 'If None Match';
export const UI_LABELS_FETCH_ETAG_FOR_PAGE = 'Fetch Etag for Page';
export const UI_LABELS_REQUEST_QUERY_PARAMETERS = 'Request Query Parameters';
export const UI_LABELS_REQUEST_HEADERS = 'Request Headers';
export const UI_LABELS_REQUEST_NOT_SENT_HELP = 'UI_LABELS_REQUEST_NOT_SENT_HELP';
export const UI_LABELS_RESPONSE_HEADERS = 'Response Headers';
export const UI_LABELS_RESPONSE_BODY = 'Response Body';
export const UI_LABELS_SET_GLOBAL_VARIABLES_FROM_RESPONSE_BODY = 'Set Global Variables From Response Body';
export const UI_LABELS_VARIABLE_NAME = 'Variable Name';
export const UI_LABELS_JSON_PATH = 'JSON Path';
export const UI_LABELS_PREVIEW_WITH_GLOBAL_VARIABLES_SUBSTITUTION = "Preview with Global Variables Substitution";

export const UI_LABELS_LITERAL_TYPE = 'Literal Type';
export const UI_LABELS_STATUS = 'Status';
export const UI_LABELS_FAILED = 'Failed';
export const UI_LABELS_SUCCESS = 'Success';
export const UI_LABELS_MODE = 'Mode';
export const UI_LABELS_REQUEST_SETTINGS = 'Request Settings';
export const UI_LABELS_CANCEL = 'Cancel';
export const UI_LABELS_SAVE = 'Save';
export const UI_LABELS_DELETE_VALUE = 'Delete Value';
export const UI_LABELS_DELETE_HELP = 'Delete';
export const UI_LABELS_DELETE_RESOURCE = 'Delete Resource';
export const UI_LABELS_REMOVE_CONNECTION = 'Remove Connection';
export const UI_LABELS_ADD_RESOURCE_PROPERTY_VALUE = 'Add This Property Value';
export const UI_LABELS_EDIT_PROPERTY = 'Edit This Value';
export const UI_LABELS_SHAPE_NOT_CONFIGURED = 'Not editable. Shape for this property is not configured';
export const UI_LABELS_BULK_EDIT_PROPERTY = 'Bulk Edit This Property';

export const UI_LABELS_RESET = 'RESET';
export const UI_LABELS_SEARCH = 'SEARCH';
export const UI_LABELS_CREATE = 'CREATE';
export const UI_LABELS_UPDATE = 'UPDATE';
export const UI_LABELS_DELETE = 'DELETE';
export const UI_LABELS_BATCH = 'BATCH';


export const UI_LABELS_SEARCH_RESULTS = 'Search Results';
export const UI_LABELS_AUTO_REFRESH = 'Auto Refresh';
export const UI_LABELS_NO_LABEL_AVAILABLE = 'No label available';

export const UI_LABELS_LINK_BY = 'Connect By';
export const UI_LABELS_LINK_BY_SEARCH = 'Search Mode';
export const UI_LABELS_LINK_PREVIEW = 'List Mode';
export const UI_LABELS_MULTILINE_INPUT = 'Multiline';
export const UI_LABELS_ALREADY_CONNECTED = 'Already Connected';



export const UI_LABELS_NO_VALUE_HELP_VALUE = `Leave the ${DATA} value unspecified, which means the default value of ${CBD} will be used on the server. This will return full objects.`;

export const UI_LABELS_NONE_HELP_VALUE = 'For each selected object property the full object will be returned in the response.';
export const UI_LABELS_SELECTED_PROPERTIES_HELP_VALUE = 'This allows you to select specific properties for each object in the response.';
export const UI_LABELS_LABEL_PROPERTY_LANGUAGE_HELP_LINE1_VALUE = `By default the language used for facet value label properties is the system default language (as specified during the bootstrap).`;
export const UI_LABELS_LABEL_PROPERTY_LANGUAGE_HELP_LINE2_VALUE = `The parameter '${LABEL_PROPERTY_LANG}' allows you to override the system default. If specified, values for the label property in the given language are returned.`;

export const UI_LABELS_SEARCH_LANGUAGE_HELP_VALUE = "For search and facets language code is required for value comparisons of language type properties. By default system default language(specified during bootstrap) is used for these comparisons. To override system level default language for a request, parameter 'lang' can be specified.";
export const UI_LABELS_REQUEST_NOT_SENT_HELP_VALUE = 'Request not sent. Click the send icon to send.';

export const UI_LABELS_TO_SAVE_PRESS_ENTER_OR_ESC = 'Press Enter key to save or Esc key to cancel the edit.';
export const UI_LABELS_TO_SAVE_PRESS_CTRL_ENTER_OR_ESC = 'Ctrl + Enter to save or Esc to cancel.';
export const UI_LABELS_UPDATE_PRECONDITION_FAILED = 'Updated failed because resource has been changed on server side.';
export const UI_LABELS_UPDATE_FAILED = 'Update failed.';
export const UI_LABELS_ADD_SHEETS_DIALOG_HELP_VALUE = `Select type(s) to add sheet(s).`;
export const UI_LABELS_SHEETS_OPEN_SAVED = `Open saved`;
export const UI_LABELS_SHEETS_OPEN_FOR_TYPE = `Create for type`;

export const UI_LABELS_EXPAND_INCOMING_CONNECTIONS = `Expand incoming connections`;
export const UI_LABELS_EXPAND_OUTGOING_CONNECTIONS = `Expand outgoing connections`;
export const UI_LABELS_HIDE_THIS_EDGE = `Hide this edge`;
export const UI_LABELS_OPEN_STYLE_SETTINGS = `Open style settings`;
export const UI_LABELS_OPEN_NODE_SETTINGS = `Open node settings`;
export const UI_LABELS_STYLE_SCOPE = `Apply to`;
export const UI_LABELS_STYLE_FOR_EDGE = `Only selected edge`;
export const UI_LABELS_STYLE_FOR_NODE = `Only selected node`;
export const UI_LABELS_STYLE_FOR_NODE_TYPE = `All nodes of selected type`;
export const UI_LABELS_STYLE_FOR_EDGE_TYPE = `All edges of selected type`;

export const UI_LABELS_USE_SPACE = `To remove label use space`;

export const UI_LABELS_STYLE_NODE_BORDER_WIDTH = `Node Border Width`;
export const UI_LABELS_STYLE_NODE_BORDER_COLOR = `Node Border Color`;
export const UI_LABELS_STYLE_NODE_BORDER_STYLE = `Node Border Style`;
export const UI_LABELS_STYLE_NODE_BACKGROUND_IMAGE_HELP = 'Enter url above or upload a small PNG (.png)/ SVG (.svg) image without any background color.';

export const UI_LABELS_ELEMENT_STYLES = 'Element Style';
export const UI_LABELS_GLOBAL_STYLES = 'Global Styles';
export const UI_LABELS_TYPE_STYLES = 'Type Styles';
export const UI_LABELS_PROPERTY_STYLES = 'Property Styles';
export const UI_LABELS_GLOBAL_PROPERTY_STYLES = 'Global Property Styles';
export const UI_LABELS_SELECT_STYLE_PROPERTY = 'Select Style Property';
export const UI_LABELS_PROPERTY_VALUE = 'Property Value';
export const UI_LABELS_ADD_STYLE = 'Add Style';
export const UI_LABELS_NO_STYLE = 'No style added!';
export const UI_LABELS_IMAGE_URL = 'Image URL';
export const UI_LABELS_STYLE_SETTINGS = 'Style Settings';
export const UI_LABELS_SETTINGS = 'Settings';

export const UI_LABELS_LAYOUT_SETTINGS = 'Layout Settings';
export const UI_LABELS_MAXIMUM_ZOOM = 'Maximum Zoom';
export const UI_LABELS_MINIMUM_ZOOM = 'Minimum Zoom';
export const UI_LABELS_EDGE_TURN_DISTANCE = 'Edge Turn Distance';
export const UI_LABELS_HORIZONTAL_SPACING = 'Horizontal Spacing';
export const UI_LABELS_VERTICAL_SPACING = 'Vertical Spacing';
export const UI_LABELS_NODE_SPACING = 'Node Spacing';
export const UI_LABELS_EDGE_LENGTH = 'Edge Length';
export const UI_LABELS_PADDING = 'Padding';
export const UI_LABELS_AUTO_FIT = 'Auto Fit';
export const UI_LABELS_ZOOM_ON_SCROLL_PINCH = 'Zoom on Scroll/Pinch';


export const DEFAULT_UI_LABELS = {
    [UI_LABELS_NO_RESULTS] : {
        'en' : 'No results found.'
    },
    [UI_LABELS_BROWSE_LANGUAGE] : {
        'en' : 'Language'
    },
    [UI_LABELS_ANY] : {
        'en' : 'Any'
    },
    [UI_LABELS_FILTERS] : {
        'en' : UI_LABELS_FILTERS
    },
    [UI_LABELS_ADD_FEEDBACK] : {
        'en' : UI_LABELS_ADD_FEEDBACK
    },
    [UI_LABELS_FROM] : {
        'en' : UI_LABELS_FROM
    },
    [UI_LABELS_TO] : {
        'en' : UI_LABELS_TO
    },
    [UI_LABELS_DATA_FILTER_HELP] : {
        'en' : 'Leave the dataFilter value unspecified, which means the default value of none will be used on the server. This will return full objects.'
    },
    [UI_LABELS_PROPERTY_LANGUAGE_HELP] : {
        'en' : 'By default the language used for facet value label properties is the system default language (as specified during the bootstrap).' +
            'The parameter \'labelPropertyLang\' allows you to override the system default. If specified, values for the label property in the given language are returned.'
    },
    [UI_LABELS_SEARCH_LANGUAGE_HELP] : {
        'en' : UI_LABELS_SEARCH_LANGUAGE_HELP_VALUE
    },
    [UI_LABELS_REQUEST_NOT_SENT_HELP] : {
        'en' : UI_LABELS_REQUEST_NOT_SENT_HELP_VALUE
    },
    [UI_LABELS_SELECTED_PROPERTIES_HELP] : {
        'en' : UI_LABELS_SELECTED_PROPERTIES_HELP_VALUE
    },
    [UI_LABELS_NONE_HELP] : {
        'en' : UI_LABELS_NONE_HELP_VALUE
    },
    [UI_LABELS_NO_VALUE_HELP] : {
        'en' : UI_LABELS_NO_VALUE_HELP_VALUE
    },
    [UI_LABELS_LABEL_PROPERTY_LANGUAGE_HELP_LINE1] : {
        'en' : UI_LABELS_LABEL_PROPERTY_LANGUAGE_HELP_LINE1_VALUE
    },
    [UI_LABELS_LABEL_PROPERTY_LANGUAGE_HELP_LINE2] : {
        'en' : UI_LABELS_LABEL_PROPERTY_LANGUAGE_HELP_LINE2_VALUE
    },
    [UI_LABELS_TO_SAVE_PRESS_CTRL_ENTER_OR_ESC] : {
        'en' : UI_LABELS_TO_SAVE_PRESS_CTRL_ENTER_OR_ESC
    },
    [UI_LABELS_UPDATE_PRECONDITION_FAILED] : {
        'en' : UI_LABELS_UPDATE_PRECONDITION_FAILED
    },
    [UI_LABELS_UPDATE_FAILED] : {
        'en' : UI_LABELS_UPDATE_FAILED
    }
};

export const EXAMPLE_TYPE_TO_TITLE_KEY = {
    [ALIAS_SYS_EXAMPLE_TYPE_RESET_DATA] : UI_LABELS_RESET,
    [ALIAS_SYS_EXAMPLE_TYPE_CREATE] : UI_LABELS_CREATE,
    [ALIAS_SYS_EXAMPLE_TYPE_SEARCH] : UI_LABELS_SEARCH,
    [ALIAS_SYS_EXAMPLE_TYPE_UPDATE] : UI_LABELS_UPDATE,
    [ALIAS_SYS_EXAMPLE_TYPE_DELETE] : UI_LABELS_DELETE,
    [ALIAS_SYS_EXAMPLE_TYPE_BATCH] : UI_LABELS_BATCH,
};

(function() {
    [UI_LABELS_DATA, UI_LABELS_FACET, UI_LABELS_FACET, UI_LABELS_SORT, UI_LABELS_SORT_A_TO_Z, UI_LABELS_SORT_Z_TO_A, UI_LABELS_SORT_ADVANCED, UI_LABELS_SORT_DIRECTION,
        UI_LABELS_LAYOUT_SETTINGS,
        UI_LABELS_MAXIMUM_ZOOM,
        UI_LABELS_MINIMUM_ZOOM,
        UI_LABELS_EDGE_TURN_DISTANCE,
        UI_LABELS_REFRESH,
        UI_LABELS_SHEETS,

        UI_LABELS_BACK,
        UI_LABELS_LOAD_MORE,
        UI_LABELS_DOWNLOAD,
        UI_LABELS_DOWNLOAD_LIMIT,
        UI_LABELS_DOWNLOAD_FORMAT,
        UI_LABELS_DOWNLOAD_FORMAT_TURTLE,
        UI_LABELS_DOWNLOAD_FORMAT_TURTLE_INFORMATION,
        UI_LABELS_DOWNLOAD_FORMAT_RDF_XML,
        UI_LABELS_DOWNLOAD_FORMAT_CSV,
        UI_LABELS_DOWNLOAD_FORMAT_TSV,
        UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR,
        UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_COMMA,
        UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_TAB,
        UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_CUSTOM,
        UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_CUSTOM_VALUE,
        UI_LABELS_DOWNLOAD_FORMAT_CSV_ADD_DATATYPE_AND_LANGUAGE,

        UI_LABELS_ADD_RESOURCE_PROPERTY_VALUE,
        UI_LABELS_DELETE_VALUE,
        UI_LABELS_DELETE_RESOURCE,
        UI_LABELS_REMOVE_CONNECTION,
        UI_LABELS_BULK_EDIT_PROPERTY,
        UI_LABELS_EDIT_PROPERTY,
        UI_LABELS_SHAPE_NOT_CONFIGURED,
        UI_LABELS_EXISTING,
        UI_LABELS_NEW,
        UI_LABELS_SELECTED,

        UI_LABELS_SORT_EMPTY_OR_NO_VALUE,
        UI_LABELS_SORT_EMPTY_OR_NO_VALUE_FIRST,
        UI_LABELS_SORT_EMPTY_OR_NO_VALUE_LAST,
        UI_LABELS_SORT_EMPTY_OR_NO_VALUE_ANY,
        UI_LABELS_FREEZE,
        UI_LABELS_UNFREEZE,
        UI_LABELS_OPEN_SHEETS_VIEWS,
        UI_LABELS_OPEN_GRAPH_VISUALISATION,
        UI_LABELS_SELECT_VISUALISATION,
        UI_LABELS_SPARQL_RUN,
        UI_LABELS_SPARQL_GENERATE,
        UI_LABELS_SORT_ASCENDING, UI_LABELS_SORT_DESCENDING, UI_LABELS_PAGING_COUNTING, UI_LABELS_LANGUAGE,
        UI_LABELS_CONDITIONAL_GET, UI_LABELS_BUILD, UI_LABELS_REQUEST, UI_LABELS_REQUEST_BODY, UI_LABELS_RESPONSE,
        UI_LABELS_TEXT_SEARCH ,
    UI_LABELS_PROPERTIES,
    UI_LABELS_RESET_ALL,
    UI_LABELS_ADD,
    UI_LABELS_REMOVE,
    UI_LABELS_PROPERTY,
    UI_LABELS_OPERATOR,
        UI_LABELS_RESET_FILTERS,
        UI_LABELS_FULL_SCREEN,
        UI_LABELS_FULL_SCREEN_EXIT,
        UI_LABELS_TYPE,
        UI_LABELS_EXPAND,
        UI_LABELS_COLLAPSE,
        UI_LABELS_EXPAND_ALL,
        UI_LABELS_COLLAPSE_ALL,
        UI_LABELS_ADD_NEW_RESOURCES,
        UI_LABELS_AI_VIEW,
        UI_LABELS_VALIDATION_REQUIRED,
        UI_LABELS_FILTER,
        UI_LABELS_VALIDATION_MINIMUM,
        UI_LABELS_VALIDATION_MAXIMUM,
        UI_LABELS_VALIDATION_CHARACTERS_REQUIRED,
        UI_LABELS_VALIDATION_CHARACTERS_ALLOWED,
        UI_LABELS_VALIDATION_INVALID_VALUE,
        UI_LABELS_VALIDATION_INVALID_DATATYPE_VALUE,
        UI_LABELS_SEARCH_AND_CONNECT,
        UI_LABELS_SEARCH_AND_CONNECT_TOOLTIP,
        UI_LABELS_ADD_ID_OR_CREATE,
        UI_LABELS_ADD_ID_OR_CREATE_TOOLTIP,
        UI_LABELS_APPLY,
        UI_LABELS_MORE,
        UI_LABELS_ADD_MORE,
    UI_LABELS_EQUAL_TO,
    UI_LABELS_NOT_EQUAL_TO,
    UI_LABELS_LESS_THAN,
    UI_LABELS_LESS_THAN_EQUAL_TO,
    UI_LABELS_GREATER_THAN,
    UI_LABELS_GREATER_THAN_EQUAL_TO,
    UI_LABELS_OBJECT,
    UI_LABELS_BETWEEN,
    UI_LABELS_TEXT,
    UI_LABELS_EXISTS,
        UI_LABELS_INVALID_DATATYPE_VALUE,
        UI_LABELS_ADD_AND_CONDITION,
        UI_LABELS_SELECT_PROPERTY_TO_ADD,
    UI_LABELS_VALUE,
    UI_LABELS_REMOVE_BLOCK,
    UI_LABELS_ADD_FILTER,

    UI_LABELS_DATA_FILTER,
    UI_LABELS_NONE,
        UI_LABELS_NONE_HELP,
    UI_LABELS_SELECTED_PROPERTIES,
        UI_LABELS_NO_VALUE,
    UI_LABELS_DATA_FILTER_HELP,
    UI_LABELS_CONNECTED,
    UI_LABELS_ADD_CONNECTION,
    UI_LABELS_CONNECTION_PROPERTY,
    UI_LABELS_PROPERTY_LANGUAGE,
    UI_LABELS_PROPERTY_LANGUAGE_HELP,
    UI_LABELS_ADD_PROPERTY,
    UI_LABELS_LABEL_PROPERTY,
        UI_LABELS_INVALID_IRI_VALUE,
        UI_LABELS_UNDO,
        UI_LABELS_REMOVE_ALL,
        UI_LABELS_ADD_ID,
        UI_LABELS_LABEL_PROPERTY_LANGUAGE,
    UI_LABELS_ORDER,
    UI_LABELS_PRECEDENCE,
    UI_LABELS_COUNT_UPTO,
    UI_LABELS_LABEL_COUNT_UPTO_HELP,
    UI_LABELS_PAGE_SIZE,
    UI_LABELS_PAGE,
    UI_LABELS_SEARCH_LANGUAGE_HELP,
    UI_LABELS_IF_NONE_MATCH,
    UI_LABELS_FETCH_ETAG_FOR_PAGE,
    UI_LABELS_REQUEST_QUERY_PARAMETERS,
    UI_LABELS_REQUEST_HEADERS,
    UI_LABELS_REQUEST_NOT_SENT_HELP,
    UI_LABELS_RESPONSE_HEADERS,
    UI_LABELS_RESPONSE_BODY,
    UI_LABELS_SET_GLOBAL_VARIABLES_FROM_RESPONSE_BODY,
    UI_LABELS_VARIABLE_NAME,
    UI_LABELS_JSON_PATH,
        UI_LABELS_LITERAL_TYPE,
        UI_LABELS_SEARCH_RESULTS,
        UI_LABELS_STATUS,
        UI_LABELS_FAILED,
        UI_LABELS_SUCCESS,
        UI_LABELS_MODE,
        UI_LABELS_REQUEST_SETTINGS,
        UI_LABELS_CANCEL,
        UI_LABELS_SAVE,
        UI_LABELS_RESET,
        UI_LABELS_SEARCH,
        UI_LABELS_CREATE,
        UI_LABELS_UPDATE,
        UI_LABELS_DELETE,
        UI_LABELS_BATCH,
        UI_LABELS_NO_LABEL_AVAILABLE,
        UI_LABELS_LINK_BY,
        UI_LABELS_LINK_BY_SEARCH,
        UI_LABELS_LINK_PREVIEW,
        UI_LABELS_MULTILINE_INPUT,
        UI_LABELS_AUTO_REFRESH,
        UI_LABELS_ALREADY_CONNECTED,
        UI_LABELS_CLOSE_SHEETS_VIEW,
        UI_LABELS_CLOSE,
        UI_LABELS_HELP,
        UI_LABELS_SHEET_VIEW_LANGUAGE,
        UI_LABELS_SHEET_VIEW_LANGUAGE_HELP,
        UI_LABELS_HIDE_COLUMNS,
        UI_LABELS_HIDE,
        UI_LABELS_ADD_SHEETS_DIALOG_HELP_VALUE,
        UI_LABELS_DELETE_HELP,
        UI_LABELS_SHEETS_OPEN_SAVED,
        UI_LABELS_SHEETS_OPEN_FOR_TYPE,
        UI_LABELS_STYLE_FOR_NODE,
        UI_LABELS_STYLE_FOR_NODE_TYPE,
        UI_LABELS_STYLE_SCOPE,
        UI_LABELS_STYLE_NODE_BORDER_WIDTH,
        UI_LABELS_STYLE_NODE_BORDER_COLOR,
        UI_LABELS_STYLE_NODE_BORDER_STYLE,
        UI_LABELS_OPEN_STYLE_SETTINGS,
        UI_LABELS_OPEN_NODE_SETTINGS,
        UI_LABELS_HIDE_THIS_EDGE,
        UI_LABELS_USE_SPACE,

    UI_LABELS_ELEMENT_STYLES,
    UI_LABELS_GLOBAL_STYLES,
    UI_LABELS_TYPE_STYLES,
        UI_LABELS_PROPERTY_STYLES,
        UI_LABELS_ADD_STYLE,
    UI_LABELS_GLOBAL_PROPERTY_STYLES,
    UI_LABELS_SELECT_STYLE_PROPERTY,
    UI_LABELS_PROPERTY_VALUE,
    UI_LABELS_NO_STYLE,
    UI_LABELS_IMAGE_URL,
    UI_LABELS_STYLE_SETTINGS,
    UI_LABELS_SETTINGS,

    UI_LABELS_HORIZONTAL_SPACING,
    UI_LABELS_VERTICAL_SPACING,
    UI_LABELS_NODE_SPACING,
    UI_LABELS_EDGE_LENGTH,
    UI_LABELS_NODE_SPACING,
    UI_LABELS_PADDING,
    UI_LABELS_AUTO_FIT,
    UI_LABELS_ZOOM_ON_SCROLL_PINCH,
        UI_LABELS_OPEN_DATA_VISUALISATION_VIEWS
].forEach(k => {
        if(!DEFAULT_UI_LABELS[k]) {
            DEFAULT_UI_LABELS[k] = {
                'en': k
            }
        }
    })
})();

export function getUiLabelTranslationFrom(key, customization) {
    if(customization === undefined) {
        return key;
    }
    return getUiLabelTranslation(customization.settings, key, customization.browseLanguage, key);
}

export function getUiLabelTranslation(settings, key, browseLanguage, defaultValue) {
    let translation = settings?.[UI_LABELS]?.[key];
    if(translation) {
        let multilingualValue = getMultilingualValue(translation, browseLanguage);
        //Only return default if value is undefined which means user can set empty value for value on UI
        if(multilingualValue === undefined) {
            return defaultValue;
        } else {
            return multilingualValue;
        }
    }
    return defaultValue;
}

const COMPONENT = 'UILabel'

export function isSameForAllLanguages(valueObject) {
    return valueObject?.[AT_DEFAULT] !== undefined;
}

class UILabel extends Component {
    constructor(props) {
        super(props);
    }


    renderComponent = (label, defaultValue, name, valueKey, helpMessageText, valueObject, onChange) => {
        let {componentRenderer} = this.props;
        if(componentRenderer) {
            return componentRenderer(label, valueKey, valueObject, onChange)
        }
        return <MuiTextField
            datatest={label || 'noLabel'}
            fullWidth={true}
            label={label}
            name={name}
            defaultValue={defaultValue}
            innerHelperComponent={ () => <Typography component={'div'} variant={"caption"}>{helpMessageText}</Typography>}
            onChange={(event) => {
                const {target: {value}} = event;
                valueObject[valueKey] = value;
                onChange();
            }}
        />;

    }

    renderDefaultTextField = () => {
        let valueKey = AT_DEFAULT;
        let {valueObject , helpMessageText, defaultValue, onChange} = this.props;
        let defaultValueToUse = valueObject === undefined || valueObject[valueKey] === undefined ? defaultValue : (valueObject ? valueObject[valueKey] : '' );
        let labelToUse = '';
        return this.renderComponent(labelToUse, defaultValueToUse, valueKey, valueKey, helpMessageText, valueObject, onChange);
    }

    renderMUiTextField = (valueKey) => {
        let {valueObject , helpMessageText, defaultValue, onChange} = this.props;
        let lang = valueKey.value;
        let label = valueKey.label;
        let defaultValueToUse = valueObject === undefined || valueObject[lang] === undefined ? defaultValue : (valueObject ? valueObject[lang] : '' );
        let labelToUse = label+" ("+lang+")";
        return this.renderComponent(labelToUse, defaultValueToUse, lang, lang, helpMessageText, valueObject, onChange);
    }

    renderMultilingual = () => {
        let {settings, valueObject, onChange} = this.props;
        let browseLanguages = toArray(settings.browseLanguages);
        return <div>
            {
                browseLanguages.length > 1 &&
                <FormControlLabel
                    control={<Switch
                        datatest={'sameForAll'}
                        size={'small'}
                        name={'default'}
                        checked={isSameForAllLanguages(valueObject) ? true : false}
                        onChange={(event) => {
                            const {target: {}} = event;
                            if (valueObject?.[AT_DEFAULT] !== undefined) {
                                delete valueObject[AT_DEFAULT];
                            } else {
                                let defaultLanguageValue = valueObject?.[this.getDefaultLanguage()];
                                valueObject[AT_DEFAULT] = defaultLanguageValue || valueObject[AT_DEFAULT] || '';
                            }
                            onChange();
                            this.setState({});
                        }}
                        value="default"/>}
                    label={'Same for all languages'}
                />
            }
            {
                valueObject?.[AT_DEFAULT] !== undefined
                    ? this.renderDefaultTextField()
                    : browseLanguages.map(bl => {
                        return this.renderMUiTextField(bl);
                    })
            }
        </div>
    }

    getDefaultLanguage = () => {
        let {settings} = this.props;
        let browseLanguages = toArray(settings.browseLanguages);
        return browseLanguages[0].value;
    }

    render() {
        traceRenderStart('', COMPONENT)
        let {settings} = this.props;
        return settings.isMultilingual
            ? this.renderMultilingual()
            : this.renderDefaultTextField();
    }

}

UILabel.propTypes = {
    settings: PropTypes.object,
    valueObject: PropTypes.object,
    title: PropTypes.string,
    helpText: PropTypes.string,
    onChange: PropTypes.func,
    componentRenderer: PropTypes.func,
};

export default withStyles(styles, {withTheme: true})(UILabel);
