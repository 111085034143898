import AllPartsLayout from "../AllPartsLayout";
import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {getNavigateToConceptLink, getNavigateToOntologyLink, ResourceView} from "./LeftTreeComponents";
import H2Title from "../../components/H2Title";
import queryString from "query-string";
import {NavLink, withRouter} from "react-router-dom";
import {getResourceUrl, getTypeClassIRIs, getValuesObject} from "./SearchResultItem";
import {TYPE, TYPE_SKOS_CONCEPT, TYPE_SKOS_CONCEPT_SCHEME, TYPE_SKOS_XL_LABEL} from "../../Constants";
import Typography from "@material-ui/core/Typography";
import {loadResource} from "../../service/data-loader";
import {
    centerVertically,
    getBrowseLanguageCode, GetEditableClassNames,
    getResourceId,
    isAnnotationProperty,
    isDatatypeProperty,
    isObjectProperty,
    isOwlClass,
    isRdfsClass,
    scrollToView,
    sort,
    toArray
} from "../../components/util";
import {Avatar, Grid, makeStyles, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import throttle from 'lodash/throttle';
import history from "../../history";
import uuid4 from "uuid/v4";
import {styles} from "../../components/styles";
import {ANNOTATION_VIEW, CLASS_VIEW, DATATYPE_VIEW, OBJECT_VIEW, StyledButton} from "./OntologyView";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {getTaxonomyXLLabels, searchTaxonomyConcepts, searchTaxonomyXLLabels} from "../../service/sparql-queries";
import {isTaxonomyViewPath, SETTINGS_EDIT_CARD_IMAGE, SETTINGS_EDIT_CARD_TITLE} from "./Workspace";
import PropTypes from "prop-types";
import {findNodeInTreeData, LabelTree} from "./LabelTree";
import {WithAdminEdit} from "./WithAdminEdit";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

export const IRI_SKOS_IN_SCHEME = 'http://www.w3.org/2004/02/skos/core#inScheme';
export const IRI_SKOS_NARROWER = 'http://www.w3.org/2004/02/skos/core#narrower';
export const IRI_SKOS_BROADER = 'http://www.w3.org/2004/02/skos/core#broader';
export const IRI_SKOS_HAS_TOP_CONCEPT = 'http://www.w3.org/2004/02/skos/core#hasTopConcept';
export const IRI_SKOS_PREF_LABEL = 'http://www.w3.org/2004/02/skos/core#prefLabel';

export function getAlias(aliasesMap , iri) {
    return aliasesMap[iri] || iri;
}

const Search = ({conceptSchemeId,
                    aliasesToIRIMap,
                    location,
                    settings,
    ontology,
                    onSelect,
     aliasesMap, browseLanguage, treeTab}) => {
    const classes = useStyles();

    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                let browseLanguageCode = getBrowseLanguageCode(browseLanguage);
                let searchFunction;
                if (isHierarchyView(treeTab)) {
                    searchFunction = searchTaxonomyConcepts;
                } else {
                    searchFunction = searchTaxonomyXLLabels;
                }
                searchFunction(conceptSchemeId, request.input, browseLanguageCode).then(ids => {
                    loadResource(ids).then(resources => {
                        Promise.all(resources.map(async r => {
                            let valuesObject = await getValuesObject(r, settings, aliasesToIRIMap, browseLanguage, ontology);
                            if(valuesObject.title === undefined) {
                                valuesObject.title = getResourceId(r);
                            }
                            valuesObject.backingObject = r;
                            return valuesObject;
                        })).then(r => {
                            callback(r);
                        })
                    })
                })
            }, 200),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (inputValue === '') {
            if(value) {
                setOptions(value ? [value] : []);
            } else {
                fetch({ input: inputValue }, (results) => {
                    if (active) {
                        setOptions(results);
                    }
                });
            }
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                setOptions(results);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <Autocomplete
            datatest={'taxonomySearch'}
            id="free-solo-demo"
            freeSolo
            value={value}
            style={{ marginRight: 16 }}
            options={options}
            filterOptions={(x) => x}

            getOptionLabel={(option) => (typeof option === 'string' ? option : option.title)}
            onChange={(event, newValue, reason) => {
                if (reason === 'select-option') {
                    onSelect(newValue.backingObject);
                } else {
                    setOptions(newValue ? [newValue, ...options] : options);
                    setValue(newValue);
                }
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}

            renderInput={(params) => (
                <TextField {...params} label="Search" margin="normal" variant="outlined" />
            )}
            renderOption={(option) => {
                return (
                    <Grid container alignItems="center">
                        <Grid item xs>
                            <Typography variant="h3" color="textSecondary">
                                {option.title || getResourceId(option)}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
}

export const LABELS_VIEW = "LABELS_VIEW";
export const HIERARCHY_VIEW = "HIERARCHY_VIEW";

function isLabelsView(treeTab) {
    return treeTab === LABELS_VIEW;
}

function isHierarchyView(treeTab) {
    return treeTab === undefined || treeTab === HIERARCHY_VIEW;
}

const COMPONENT = 'TaxonomyViewInternal'

export function TreeHeader(thumbnail, title, theme, resource) {
    return <div datatest={'treeHeader'} style={{display: "flex"}}>
        {
            thumbnail &&
            centerVertically(
                (<div datatest={'treeHeaderAvatar'} className={GetEditableClassNames(SETTINGS_EDIT_CARD_IMAGE, getResourceId(resource))}>
                    <Avatar
                        alt={title}
                        src={thumbnail}
                        style={{width: theme.spacing(3), height: theme.spacing(3)}}
                    />
                    <WithAdminEdit
                        style={{marginTop :'-32px', marginLeft :'-24px'}}
                        data={{
                            action: SETTINGS_EDIT_CARD_IMAGE,
                            className : 'editableComponentHighlightOffsetIn3',
                            id : getResourceId(resource),
                            resource
                        }}
                    />
                </div>)
                , {marginRight: '8px'})
        }
        {
            centerVertically(<H2Title
                datatest={'treeHeaderTitle'}
                noWrap={true}
                color={'primary'}
                className={GetEditableClassNames(SETTINGS_EDIT_CARD_TITLE, getResourceId(resource) )}
            >
                <WithAdminEdit
                    style={{marginTop :'-8px', marginLeft : '4px'}}
                    data={{
                        action: SETTINGS_EDIT_CARD_TITLE,
                        id : getResourceId(resource),
                        resource
                    }}
                />
                {title}
            </H2Title>)
        }
    </div>;
}

export function getTypeIRIs(resource, aliasesToIRIMap) {
    let types = toArray(resource[TYPE]);
    let classIRI = types.map(t => aliasesToIRIMap[t] || t);
    return classIRI;
}

function isOfType(resource, aliasesToIRIMap, typeIRI) {
    const typeClassIRIs = getTypeClassIRIs(resource, aliasesToIRIMap);
    const includes = typeClassIRIs.includes(typeIRI);
    return includes;
}

function isXLLabel(resource, aliasesToIRIMap) {
    return isOfType(resource, aliasesToIRIMap, TYPE_SKOS_XL_LABEL);
}

function isConcept(resource, aliasesToIRIMap) {
    return isOfType(resource, aliasesToIRIMap, TYPE_SKOS_CONCEPT);
}

function isConceptScheme(resource, aliasesToIRIMap) {
    return isOfType(resource, aliasesToIRIMap, TYPE_SKOS_CONCEPT_SCHEME);
}

class TaxonomyViewInternal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            treeData : [],
            concept : undefined,
            selectedNodeId : undefined,
            expandedNodeIds : []
        }

        //Below handles browser back/forward button
        this.backListener = this.props.history.listen( (location, action) => {
            //Here we deal with taxonomy path only everything else is handled in other components
            if(isTaxonomyViewPath(location)) {
                const {conceptScheme, treeData, selectedNodeId} = this.state;
                let params = queryString.parse(location.search);
                let currentlyLoadedConceptSchemeId = conceptScheme ? getResourceId(conceptScheme) : this.getConceptSchemeId();
                let newConceptSchemeId = params['conceptSchemeId'];
                let newConceptId = params['conceptId'];
                //Each action in taxonomy browser push to history, so we handle all conditions below
                // Browser forward and back button generates POP but handling is same
                //A page refresh is handled by first call to componentDidMount
                //If no change in taxonomy do not reload just select the concept
                if (currentlyLoadedConceptSchemeId === newConceptSchemeId) {
                    let currentResource = this.state.concept;
                    let {treeTab} = this.state;
                    let {aliasesToIRIMap} = this.props;
                    loadResource(newConceptId).then((newResources) => {
                        let newResource = newResources.find(r => getResourceId(r) === newConceptId);
                        const typeIRIs = new Set(newResource ? getTypeIRIs(newResource, aliasesToIRIMap) : []);
                        const currentTypeIRIs = new Set(currentResource ? getTypeIRIs(currentResource, aliasesToIRIMap) : []);
                        const hasType = [...currentTypeIRIs].find(ti => typeIRIs.has(ti));
                        const hasConceptSchemeTypeInCurrent = [...currentTypeIRIs].find(ti => ti === TYPE_SKOS_CONCEPT_SCHEME);
                        const hasConceptInNew = [...typeIRIs].find(ti => ti === TYPE_SKOS_CONCEPT);
                        const newTreeTabValue = this.getTreeTabValue(newResource, true);
                        const newTreeTabIsSameAsCurrent = treeTab === newTreeTabValue;
                        if(newTreeTabIsSameAsCurrent || (hasConceptSchemeTypeInCurrent && hasConceptInNew) || hasType) {
                            this.loadAndSelectResource(conceptScheme, newConceptId).catch(() => {});
                        } else {
                            this.setState({treeTab : newTreeTabValue, treeData : [], treeLoading: true,}, () => {
                                this.loadAndSelectResource(conceptScheme, newConceptId).catch(() => {});
                            })
                        }
                    })
                } else {
                    this.loadConceptScheme(newConceptSchemeId, newConceptId);
                }
            }
        })
    }

    componentDidMount() {
        this.setState({treeLoading : true, treeData : [] });
        let conceptId = this.getConceptId();
        let conceptSchemeId = this.getConceptSchemeId();
        this.loadConceptScheme(conceptSchemeId, conceptId);
    }

    componentWillUnmount() {
        this.backListener?.()
    }

    loadConceptScheme = (conceptSchemeId, conceptId) => {
        let {settings, aliasesToIRIMap, browseLanguage, ontology} = this.props;
        loadResource(conceptSchemeId).then(async (resources) => {
            let conceptScheme = resources.find(r => r && getResourceId(r) === conceptSchemeId);
            if(conceptScheme) {
                let conceptSchemeVO = await getValuesObject(conceptScheme, settings, aliasesToIRIMap, browseLanguage, ontology);
                this.setState({
                    conceptScheme: conceptScheme,
                    conceptSchemeId: getResourceId(conceptSchemeVO),
                    conceptSchemeTitle: conceptSchemeVO.title,
                    conceptSchemeThumbnail: conceptSchemeVO.thumbnail,
                    conceptSchemeVO: conceptSchemeVO,
                    treeData : [],
                    treeLoading: true,
                    concept : undefined
                }, async () => {
                    await this.loadAndSelectResource(conceptScheme, conceptId);
                })
            }
        })
    }

    loadAndSelectResource = async (conceptScheme, resourceId) => {
        //By default, set the middle resource to conceptScheme
        let resource = conceptScheme;
        if (resourceId) {
            let concepts = await loadResource(resourceId);
            resource = concepts.find(r => r && getResourceId(r) === resourceId);
        }
        let {treeData} = this.state;
        let currentResource = this.state.concept;
        //Load relevant tree data only if needed
        const treeTabValue = this.getTreeTabValue(resource);
        const currentTreeTabResource = this.getTreeTabValue(currentResource);
        if(treeTabValue !== currentTreeTabResource || toArray(treeData).length < 1){
            treeData = await this.getTopLevelTreeData(conceptScheme, treeTabValue);
        }
        this.setState(
            {
                treeData: treeData,
                concept: resource,
                treeLoading: false
            },
            () => {
                if (resource) {
                    const nodeElement2 = document.getElementById('middleContentId');
                    nodeElement2 && scrollToView(nodeElement2, 'instant');
                    this.handleResourceSelect(treeData, resource);
                }
            }
        )
    }

    handleResourceSelect = async (treeData, newConceptToSelect) => {
        let {selectedNodeId, expandedNodeIds} = this.state;
        const foundNode = findNodeInTreeData(treeData, newConceptToSelect, selectedNodeId, expandedNodeIds);
        if(foundNode) {
            return;
        }
        let path = await this.findExpansionPath(newConceptToSelect);
        let treeDataToUse = treeData;
        let node = undefined;
        let leafNode;
        let newExpandedNodeIds = [];
        if (path.length > 0) {
            for (let i = 0; i < path.length; i++) {
                let p = path[i];
                node = treeDataToUse.find(td => getResourceId(td) === p);
                if (node.children === undefined) {
                    let narrowerIds = toArray(await this.getNarrowerIds(node.backingObject));
                    node.children = await this.getValueObjectsForTree(narrowerIds);
                }
                newExpandedNodeIds.push(node.nodeId);
                treeDataToUse = node.children;
            }
            leafNode = node.children.find(n => getResourceId(n) === getResourceId(newConceptToSelect));
        } else {
            leafNode = treeDataToUse.find(td => getResourceId(td) === getResourceId(newConceptToSelect));
        }
        if(!leafNode) {
            return;
        }
        let allExpandedNodeIdsSet = new Set([...expandedNodeIds, ...newExpandedNodeIds]);
        this.setState({concept: newConceptToSelect, expandedNodeIds: [...allExpandedNodeIdsSet], selectedNodeId : leafNode.nodeId}, () => {
            const nodeElement = document.getElementById(leafNode.nodeId);
            nodeElement && scrollToView(nodeElement, 'instant');
        })
    }

    getTreeTabValue = (resource, ignoreState=false) => {
        let {aliasesToIRIMap} = this.props;
        let treeTabFromState = this.state.treeTab

        if(treeTabFromState && ignoreState === false) {
            return treeTabFromState;
        }
        let treeTab = HIERARCHY_VIEW;
        if(resource && isXLLabel(resource, aliasesToIRIMap)) {
            treeTab = LABELS_VIEW;
        }
        return treeTab;
    }

    getTopLevelTreeData = async (conceptScheme, treeTab) => {
        let {aliasesMap} = this.props;
        let topResourceIds;
        if(isHierarchyView(treeTab)) {
            topResourceIds = toArray(conceptScheme[getAlias(aliasesMap, IRI_SKOS_HAS_TOP_CONCEPT)]);
        } else {
            topResourceIds = await getTaxonomyXLLabels(getResourceId(conceptScheme));
        }
        let vos = await this.getValueObjectsForTree(topResourceIds);
        return vos;
    }

    onTreeNodeClick = async (node) => {
        let {location} = this.props;
        let conceptSchemeId = this.getConceptSchemeId();
        this.setState({selectedNodeId : node.nodeId}, () => {
            history.push(getNavigateToConceptLink(conceptSchemeId, getResourceId(node), location, undefined));
        })
    }

    onTreeNodeToggle = async (node) => {
        let {expandedNodeIds} = this.state;
        if(expandedNodeIds.includes(node.nodeId)) {
            const filteredNodes = expandedNodeIds.filter(nid => nid !== node.nodeId);
            this.setState({expandedNodeIds : [...filteredNodes]})
        } else {
            let narrowerIds = toArray(await this.getNarrowerIds(node.backingObject));
            //Set children only if needed
            //if user had expanded and then collapsed, then on expand children are already there so no need to set again
            if(narrowerIds.length > 0 && !node.children) {
                node.children = await this.getValueObjectsForTree(narrowerIds);
            }
            this.setState({expandedNodeIds : [...expandedNodeIds, node.nodeId]})
        }
    }

    getNarrowerIds = async (concept) => {
        let narrowerIds = isHierarchyView(this.getTreeTabValue(concept))
            ? concept?.narrower
            : [];
        return narrowerIds;
    }

    getValueObjectsForTree = async (resourceIds) => {
        let {settings, aliasesToIRIMap, browseLanguage, ontology} = this.props;
        let resources = await loadResource(resourceIds);
        let vos = resources.filter(r => r).map(async tc => {
            let valueObject = await getValuesObject(tc, settings, aliasesToIRIMap, browseLanguage, ontology);
            let narrowerIds = toArray(await this.getNarrowerIds(tc));
            valueObject.backingObject = tc;
            valueObject.nodeId = uuid4();
            valueObject.tooltip = getResourceId(tc);
            valueObject.childCount = narrowerIds.length;
            return valueObject;
        });
        return sort(await Promise.all(vos));
    }

    getUrlParam = (key) => {
        let {location} = this.props;
        let params = queryString.parse(location.search);
        return params[key];
    }

    getConceptSchemeId = () => {
        return this.getUrlParam('conceptSchemeId');
    }

    getConceptId = () => {
        return this.getUrlParam('conceptId');
    }

    findExpansionPath = async (resource) => {
        let {aliasesMap} = this.props;
        let path = [];
        let broader = toArray(resource[getAlias(aliasesMap, IRI_SKOS_BROADER)]).map(r => getResourceId(r) || r).find(b => b);
        while (broader) {
            let broaderId = getResourceId(broader) || broader;
            path = [broaderId, ...path];
            let broaderResource = toArray(await loadResource(broaderId)).find(b => b);
            if (broaderResource) {
                broader = toArray(broaderResource[getAlias(aliasesMap, IRI_SKOS_BROADER)]).find(b => b);
            }
        }
        return path;
    }

    handleTreeViewChange = (event, treeTab) => {
        let {conceptScheme} = this.state;
        this.setState({treeLoading: true, treeData: [], treeTab : treeTab}, async () => {
            let vos = await this.getTopLevelTreeData(conceptScheme, treeTab);
            this.setState({treeLoading: false, treeData: vos, concept: undefined})
        });
    }

    getViewButtonGroup = () => {
        let {theme} = this.props;
        let {concept, treeTab} = this.state;
        let treeTabValue = this.getTreeTabValue(concept);
        return <div style={{paddingRight : '16px' , paddingTop: '8px', paddingBottom : '4px'}}>
            <ButtonGroup
                fullWidth={true}
                size={'small'}
                style={{
                    color: theme.palette.primary.main,
                    borderColor : theme.palette.grey.level2
                }}
            >
                <StyledButton
                    datatest={HIERARCHY_VIEW}
                    disableRipple={true}
                    disabled={isHierarchyView(treeTabValue)}
                    color={isHierarchyView(treeTabValue) ? 'secondary' : 'primary'}
                    style={{borderColor : isHierarchyView(treeTabValue) && theme.palette.secondary.main}}
                    onClick={(ev) => {
                        this.handleTreeViewChange(ev, HIERARCHY_VIEW);
                    }}
                >Hierarchy</StyledButton>
                <StyledButton
                    datatest={LABELS_VIEW}
                    disableRipple={false}
                    disabled={isLabelsView(treeTabValue)}
                    color={isLabelsView(treeTabValue) ? 'secondary' : 'primary'}
                    style={{borderColor : isLabelsView(treeTabValue) ? theme.palette.secondary.main : theme.palette.grey.level2,  ...(isHierarchyView(treeTabValue) ? {borderLeftColor : theme.palette.secondary.main} : {})}}

                    onClick={(ev) => {
                        this.handleTreeViewChange(ev, LABELS_VIEW);
                    }}
                >Labels</StyledButton>
            </ButtonGroup>
        </div>;
    }

    getLeftContent = () => {
        let {treeData, treeLoading, selectedNodeId, expandedNodeIds} = this.state;
        return <LabelTree
            treeData={treeData}
            treeLoading={treeLoading}
            onNodeSelect={this.onTreeNodeClick}
            onNodeToggle={this.onTreeNodeToggle}
            expandedNodeIds={expandedNodeIds}
            selectedNodeId={selectedNodeId}
        />;
    }

    middleContent = () => {
        let {theme, location, configurations, graphViewProvider, viewProperties, settings, aliasesMap, aliasesToIRIMap, browseLanguage, ontology} = this.props;
        let {concept, conceptScheme} = this.state;
        let resource = concept?.backingObject || concept || conceptScheme;
        const resourceId = getResourceId(resource);
        return <div datatest={'middleContent'} key={resourceId}>
            <div id={'middleContentId'}></div>
            <ResourceView
                resource={resource}
                theme={theme}
                middleLoading={false}
                viewProperties={viewProperties}
                configurations={configurations}
                ontology={ontology}
                location={location}
                aliasesToIRIMap={aliasesToIRIMap}
                settings={settings}
                aliasesMap={aliasesMap}
                browseLanguage={browseLanguage}
                graphViewProvider={graphViewProvider}
                onConceptClick={async (foundResource, details) => {
                    let resourceId = getResourceId(foundResource);
                    if(details.navigateInTaxonomy) {
                        if(details.navigateInTaxonomy === resourceId) {
                            return this.handleTreeViewChange({}, undefined);
                        }
                        if(isHierarchyView(this.getTreeTabValue(concept)) && isXLLabel(foundResource, aliasesToIRIMap)) {
                            return history.push(getNavigateToConceptLink(details.navigateInTaxonomy, resourceId, location, undefined));
                        } else {
                            return history.push(getNavigateToConceptLink(details.navigateInTaxonomy, resourceId, location, undefined));
                        }
                    } else if (details.navigateInOtherTaxonomy) {
                        return history.push(getNavigateToConceptLink(details.navigateInOtherTaxonomy, resourceId, location));
                    } else if (details.navigateInOntology || details.navigateInOtherOntology) {
                        let ontologyId = details.navigateInOntology || details.navigateInOtherOntology;
                        if(isOwlClass(foundResource) || isRdfsClass(foundResource)) {
                            return history.push(getNavigateToOntologyLink(ontologyId, resourceId, location, undefined, CLASS_VIEW));
                        } else if (isObjectProperty(foundResource)){
                            return history.push(getNavigateToOntologyLink(ontologyId, resourceId, location, undefined, OBJECT_VIEW));
                        } else if (isDatatypeProperty(foundResource)) {
                            return history.push(getNavigateToOntologyLink(ontologyId, resourceId, location, undefined, DATATYPE_VIEW));
                        } else if (isAnnotationProperty(foundResource)) {
                            return history.push(getNavigateToOntologyLink(ontologyId, resourceId, location, undefined, ANNOTATION_VIEW));
                        }
                        return history.push(getResourceUrl(location, resourceId));
                    } else {
                        return history.push(getResourceUrl(location, resourceId));
                    }
                }}
            />
        </div>;
    }

    render() {
        let {theme, location, headerProvider, settings, aliasesMap, aliasesToIRIMap, browseLanguage, ontology} = this.props;
        let {concept, conceptSchemeTitle, conceptSchemeThumbnail, conceptScheme} = this.state;
        return <React.Fragment key={this.getConceptSchemeId()}><AllPartsLayout
                    resizeable={true}
                    header={headerProvider()}
                    fullScreenStyle={{
                    }}
                    leftComponentScroll={{y: 'hidden', x: 'hidden'}}
                    leftComponentStyle={{paddingTop: '0px'}}
                    leftStyle={{paddingTop: '8px',height : `calc(100% - ${0}px)`}}
                    leftComponentContainerStyle={{paddingRight: '0px'}}
                    middleStyle={{paddingTop: '8px',height : `calc(100% - ${0}px)`, maxWidth : 'calc(100% - 48px)'}}
                    leftMainHeader={<>
                        <NavLink datatest={'taxonomyHomeLink'} to={getNavigateToConceptLink(this.getConceptSchemeId(), undefined, location, undefined)}>
                            {TreeHeader(conceptSchemeThumbnail, conceptSchemeTitle, theme, conceptScheme)}
                        </NavLink>
                        {this.getViewButtonGroup()}
                        <React.Fragment key={this.getTreeTabValue(concept)}>
                            <Search
                                conceptSchemeId={this.getConceptSchemeId()}
                                aliasesMap={aliasesMap}
                                browseLanguage={browseLanguage}
                                settings={settings}
                                ontology={ontology}
                                aliasesToIRIMap={aliasesToIRIMap}
                                location={location}
                                onSelect={(foundConcept) => {
                                    return history.push(getNavigateToConceptLink(this.getConceptSchemeId(), getResourceId(foundConcept), location, undefined, undefined));
                                }}
                                treeTab={this.getTreeTabValue(concept)}
                            />
                        </React.Fragment>
                    </>}
                    leftComponent={this.getLeftContent()}
                    middleActions={undefined}
                    middleComponent={this.middleContent()}
        /></React.Fragment>;
    }
}

TaxonomyViewInternal.propTypes = {
    browseLanguage: PropTypes.any,
    location: PropTypes.any,
    configurations: PropTypes.any,
    ontology: PropTypes.any,
    settings: PropTypes.any,
    aliasesMap: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    viewProperties: PropTypes.any,
    graphViewProvider:PropTypes.func,
    headerProvider: PropTypes.func
};

export const TaxonomyView = withStyles(styles, {withTheme:true})(withRouter(TaxonomyViewInternal));

TaxonomyView.propTypes = {
    browseLanguage: PropTypes.any,
    location: PropTypes.any,
    configurations: PropTypes.any,
    ontology: PropTypes.any,
    settings: PropTypes.any,
    aliasesMap: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    viewProperties: PropTypes.any,
    graphViewProvider:PropTypes.func,
    headerProvider: PropTypes.func
};
