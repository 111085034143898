import React, {useEffect, useState} from 'react';
import {Resizable} from "re-resizable";
import dragBG from "../../images/more_horiz.png";
import {
    AISettings,
    AppBarSettings,
    BannerContainerSettings,
    BannerContentContainerSettings,
    BannerTitleSettings,
    ButtonsCardSetting,
    CreateResourceButtonSetting,
    DatatypeSettings,
    DataVisualisationButtonSetting,
    GraphVisualisationButtonSetting,
    isButtonsCardDisabled,
    isResourceViewTypeSimple,
    LanguageCodeSettings,
    LanguageNameSettings,
    LogoImageSettings,
    ResourceViewSettings,
    SearchContainerSettings,
    SheetsViewButtonSetting,
    withWhiteBorder
} from "./BasicSetup";
import Button from "@material-ui/core/Button";
import {
    SETTINGS_EDIT_ACTION_AI_SETTINGS,
    SETTINGS_EDIT_ACTION_BANNER_CONTAINER,
    SETTINGS_EDIT_ACTION_BANNER_CONTENT_CONTAINER,
    SETTINGS_EDIT_ACTION_BANNER_SEARCH,
    SETTINGS_EDIT_ACTION_BANNER_SEARCH_CONTAINER,
    SETTINGS_EDIT_ACTION_BANNER_SEARCH_PAPER_TITLE,
    SETTINGS_EDIT_ACTION_BANNER_TITLE,
    SETTINGS_EDIT_ACTION_LOGO_EDIT,
    SETTINGS_EDIT_ACTION_SEARCH_LEFT_CARD_ALL,
    SETTINGS_EDIT_ACTION_SEARCH_LEFT_CARD_FILTER,
    SETTINGS_EDIT_ACTION_TRANSLATION, SETTINGS_EDIT_APP_BAR,
    SETTINGS_EDIT_BUTTONS_CARD,
    SETTINGS_EDIT_CARD_DESCRIPTION,
    SETTINGS_EDIT_CARD_IMAGE,
    SETTINGS_EDIT_CARD_OTHER_PROPERTIES,
    SETTINGS_EDIT_CARD_SUMMARY_PROPERTIES,
    SETTINGS_EDIT_CARD_TITLE,
    SETTINGS_EDIT_HOME_PAGE_EDIT_ALL,
    SETTINGS_EDIT_HOME_PAGE_EDIT_PART,
    SETTINGS_EDIT_HOME_PAGE_TEMPLATE,
    SETTINGS_EDIT_LANGUAGES, SETTINGS_EDIT_MENU_OTHER_PAGE_TEMPLATE,
    SETTINGS_EDIT_MENU_PAGES,
    SETTINGS_EDIT_PROPERTY,
    SETTINGS_EDIT_RESOURCE_VIEW
} from "./Workspace";
import H2Title from "../../components/H2Title";
import SearchFilter, {SEARCH_COMPONENT_BUILDER_MODE, SEARCH_LABEL_KEY_PATH} from "../apiplayground/SearchFilter";
import {getDefaultLanguage, initSettingPath} from "./WorkspaceSettingsDialog";
import UILabel, {UI_LABELS} from "./UILabel";
import {HomePageTemplateSetting} from "./HomePageQueriesSetup";
import PageEditor, {PageWidthSettings} from "./PageEditor";
import {centerVertically, getShapesData, toArray} from "../../components/util";
import {ALIAS_MANAGEMENT_SETTINGS, AT_CONTEXT} from "../../Constants";
import {getManagementContextURL, patchManagementGraph} from "../../service/graph-api";
import PagesSetup, {HAS_PARTS, processForSave} from "./PagesSetup";
import {MultilingualSettings} from "./LanguageSetup";
import {
    ClassBasicSetup,
    ClassImagePrecedence,
    ClassLevelImageSettings,
    DESCRIPTION_CONFIG,
    getPropertyTreeData,
    handlePropertyChange,
    HiddenPropertySettings,
    IMAGE_CONFIG,
    initLabelPropertiesIfRequired,
    OtherPropertiesSettings,
    PropertyOrderSettings,
    SUMMARY_PROPERTIES,
    SummarySettings,
    TITLE_CONFIG
} from "./DataViewSetup";
import {getTypeIRIs} from "./TaxonomyView";
import FieldContainer from "../../components/FieldContainer";
import H3Title from "../../components/H3Title";
import H4Title from "../../components/H4Title";
import {Typography} from "@material-ui/core";
import {ExpandLessOutlined, ExpandMoreOutlined} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import uuid4 from "uuid/v4";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import JSONEditorReact from "../../components/JSONEditorReact";

function WithCollapse({aliasesMap, classIRI, children, containerStyle={}, title, defaultCollapsed, datatest}) {
    const [collapsed, setCollapsed] = useState(defaultCollapsed || false);

    return <FieldContainer datatest={datatest} style={{marginTop :'16px', ...containerStyle}}>
        <div style={{display : "flex", margin : '0px 0px 8px 0px'}}>
            {centerVertically(title)}
            {
                classIRI &&
                <div>
                    <H3Title>{aliasesMap[classIRI] || classIRI}</H3Title>
                    <Typography variant={'caption'}>{classIRI}</Typography>
                </div>
            }
            <div style={{flexGrow :1}}></div>
            {
                centerVertically(
                    <IconButton
                        style={{}}
                        size={'small'}
                        onClick={() => setCollapsed(!collapsed)}>
                        {collapsed ? <ExpandMoreOutlined/> : <ExpandLessOutlined/>}
                    </IconButton>
                )
            }
        </div>
        <Collapse in={!collapsed}>{children}</Collapse>
    </FieldContainer>
}

function ImageSettingsBlock({aliasesMap, classIRI, workspace, settings, theme, configurations}) {
    const [refresh, setRefresh] = useState();

    const doNothing = () => {
        setRefresh(uuid4());
    }

    return <WithCollapse classIRI={classIRI} aliasesMap={aliasesMap}>
        {
            withWhiteBorder(
                <FieldContainer datatest={'forClass-'+classIRI}>
                    <H4Title>For Class</H4Title>
                    <ClassLevelImageSettings
                        workspace={workspace}
                        settings={settings}
                        classIRI={classIRI}
                    />
                    <FieldContainer>
                        <ClassImagePrecedence
                            settings={settings}
                            classIRI={classIRI}
                            onChange={doNothing}
                        />
                    </FieldContainer>
                </FieldContainer>
            )
        }
        {
            withWhiteBorder(
                <FieldContainer>
                    <H4Title>From Class Instance Data Property</H4Title>
                    <FieldContainer>
                        <ClassBasicSetup
                            cardConfig={IMAGE_CONFIG}
                            settings={settings}
                            classIRI={classIRI}
                            onChange={doNothing}
                            theme={theme}
                            configurations={configurations}
                            aliasesMap={aliasesMap}
                        />
                    </FieldContainer>
                </FieldContainer>
            )
        }
    </WithCollapse>;
}

export function ResourceViewSettingsBlock({theme, classes, configurations, ontology, aliasesMap, aliasesToIRIMap, settings, settingsEditData, onChange}) {
    const [propertyTreeData, setPropertyTreeData] = useState([]);

    useEffect(() => {
        getPropertyTreeData(configurations, settings, aliasesMap, ontology).then(data => {
            setPropertyTreeData(data)
        })
    }, [configurations, settings, aliasesMap, ontology])

    return <>
        <div>
        <WithCollapse aliasesMap={aliasesMap} title={<H3Title>View Setings</H3Title>}>
            <ResourceViewSettings
                settings={settings}
                onChange={onChange}
            />
            {isResourceViewTypeSimple(settings) && <DatatypeSettings settings={settings} onChange={onChange}/>}
            {isResourceViewTypeSimple(settings) && <LanguageCodeSettings settings={settings} onChange={onChange}/>}
            {isResourceViewTypeSimple(settings) && <LanguageNameSettings settings={settings} onChange={onChange}/>}
        </WithCollapse>
        </div>
        <div datatest={'propertyOrder'} style={{marginTop :'16px'}}>
            <H2Title>Property Order</H2Title>
            {
                getTypeIRIs(settingsEditData.resource, aliasesToIRIMap).map(classIRI => {
                    return <WithCollapse classIRI={classIRI} aliasesMap={aliasesMap}>
                        <FieldContainer>
                            <PropertyOrderSettings
                                settings={settings}
                                classIRI={classIRI}
                                theme={theme}
                                propertyTreeData={propertyTreeData}
                                classes={classes}
                                onChange={onChange}
                                onSwitchChange={onChange}
                            />
                        </FieldContainer>
                    </WithCollapse>;
                })
            }
        </div>
        <div datatest={'hiddenProperties'} style={{marginTop :'16px'}}>
            <H2Title>Hidden Properties</H2Title>
            <WithCollapse aliasesMap={aliasesMap}>
                <>
                    <HiddenPropertySettings
                        disableTitle={true}
                        settings={settings}
                        aliasesMap={aliasesMap}
                        onChange={onChange}
                    />
                </>
            </WithCollapse>
        </div>
    </>;
}

function WithTitle({title, resource, children, datatest}) {
    return <div datatest={datatest}>
        {title && <H2Title style={{margin :'16px 0px'}} title={title} color={'primary'}/>}
        {children}
        { resource &&
            <WithCollapse datatest={'resourceData'} title={<H3Title>Resource Data</H3Title>} defaultCollapsed={true}>
                <JSONEditorReact
                    modes={['code']}
                    enableSort={false}
                    text={JSON.stringify(resource, null, 4)}
                    indentation={4}
                    history={true}
                    height={'350px'}
                    expandAll={true}
                    search={false}
                    onEditable={() => {
                        return false
                    }}
                />
            </WithCollapse>
        }
    </div>
}

const SettingsEditPanel = ({theme, classes, workspace, settings, configurations, aliasesMap, aliasesToIRIMap, ontology, location, browseLanguage, onExpand, settingsHeight, settingsEditData, onResizeStop, onApply, onCancel, onPageEditSave, onMenuPageChange}) => {
    const [refresh, setRefresh] = useState();

    const doNothing = () => {
        setRefresh(uuid4());
    }

    const handleSaveWorkspace = async () => {
        const {pageWorkspace, pageWorkspaceSettings} = settingsEditData;
        if(pageWorkspace) {
            delete pageWorkspace.dirty;
            pageWorkspaceSettings && toArray(pageWorkspaceSettings[HAS_PARTS]).map((part, index) => {
                processForSave(part);
            });
            pageWorkspace[ALIAS_MANAGEMENT_SETTINGS] = JSON.stringify(pageWorkspaceSettings);
            pageWorkspace[AT_CONTEXT] = getManagementContextURL();
            let response = await patchManagementGraph(JSON.stringify(pageWorkspace));
            onPageEditSave()
        } else {
            onApply()
        }
    }


    const renderEditor = () => {
        let search = settings?.searchRequestObject?.search;
        const pageWorkspace = settingsEditData.pageWorkspace;
        switch (settingsEditData.action) {
            case SETTINGS_EDIT_ACTION_LOGO_EDIT:
                return <WithTitle title={'Logo Settings'}>
                    <LogoImageSettings
                        theme={theme}
                        workspace={workspace}
                        settings={settings}
                        onFileUpload={doNothing}
                        onTextChange={(objectKey, valueKey, onChange) => {
                            return (error) => {
                            };
                        }}
                    />
                </WithTitle>;
            case SETTINGS_EDIT_ACTION_BANNER_CONTAINER :
                return <WithTitle datatest={'bannerContainerSettings'} title={'Banner Settings'}>
                    <BannerContainerSettings
                        theme={theme}
                        workspace={workspace}
                        settings={settings}
                        onChange={doNothing}
                        updateState={doNothing}
                    />
                </WithTitle>;
            case SETTINGS_EDIT_ACTION_BANNER_CONTENT_CONTAINER :
                return <WithTitle datatest={'bannerContentContainerSettings'} title={'Banner Content Container Settings'}>
                    <BannerContentContainerSettings
                        theme={theme}
                        settings={settings}
                        onChange={doNothing}
                    />
                </WithTitle>;
            case SETTINGS_EDIT_ACTION_BANNER_TITLE:
                return <WithTitle datatest={'bannerTitleSettings'} title={'Banner Title Settings'}>
                    <BannerTitleSettings
                        settings={settings}
                        theme={theme}
                        onTitleChange={doNothing}
                        updateState={doNothing}
                    />
                </WithTitle>;
            case SETTINGS_EDIT_ACTION_BANNER_SEARCH_CONTAINER:
                return <WithTitle datatest={'bannerSearchContainerSettings'} title={'Banner Search Container Settings'}>
                    <SearchContainerSettings
                        settings={settings}
                        updateState={doNothing}
                    />
                </WithTitle>;
            case SETTINGS_EDIT_ACTION_BANNER_SEARCH_PAPER_TITLE:
                let {textSearch} = settings?.searchRequestObject?.search;
                return <WithTitle datatest={'bannerSearchPaperSettings'} title={'Banner Search Paper Title Settings'}>
                    <FieldContainer>
                        <UILabel
                            settings={settings}
                            onChange={doNothing}
                            helpText={''}
                            valueObject={initSettingPath(textSearch, SEARCH_LABEL_KEY_PATH)}
                        />
                    </FieldContainer>
                </WithTitle>;
            case SETTINGS_EDIT_ACTION_BANNER_SEARCH:
                return <WithTitle datatest={'bannerSearchSettings'} title={'Banner Search Settings'}>
                    <SearchFilter
                        browseLanguage={getDefaultLanguage(settings)}
                        settings={settings}
                        customizations={{hideFilters : true, hideLabelSettings : true, mode: SEARCH_COMPONENT_BUILDER_MODE}}
                        configurations={configurations}
                        aliasesMap={aliasesMap}
                        search={search}
                        onChange={doNothing}
                    />
                </WithTitle>;
            case SETTINGS_EDIT_ACTION_AI_SETTINGS:
                return <WithTitle datatest={'aiSettings'} title={'AI Settings'}>
                    <AISettings
                        settings={settings}
                        onChange={doNothing}
                        onRadioChange={doNothing}
                    />
                </WithTitle>;
            case SETTINGS_EDIT_ACTION_SEARCH_LEFT_CARD_ALL:
                return <WithTitle title={'Search Card Settings'}>
                    <SearchFilter
                        browseLanguage={getDefaultLanguage(settings)}
                        settings={settings}
                        customizations={{mode: SEARCH_COMPONENT_BUILDER_MODE, hideTextSearch : (settingsEditData?.textSearch?.showInFilter === true ? false : true) }}
                        configurations={configurations}
                        aliasesMap={aliasesMap}
                        search={search}
                        onChange={doNothing}
                    />
                </WithTitle>;
            case SETTINGS_EDIT_ACTION_TRANSLATION:
                if(!settings[UI_LABELS][settingsEditData.id]) {
                    settings[UI_LABELS][settingsEditData.id] = {
                        'en': settingsEditData.id
                    }
                }
                let valueObject = settings[UI_LABELS][settingsEditData.id];
                return <WithTitle title={'Label Translation'}>
                    <FieldContainer>
                        <UILabel
                            key={settingsEditData.id}
                            settings={settings}
                            valueObject={valueObject}
                            onChange={doNothing}
                        />
                    </FieldContainer>
                </WithTitle>;
            case SETTINGS_EDIT_ACTION_SEARCH_LEFT_CARD_FILTER:
                return <WithTitle title={'Search Filter Settings'}>
                    <SearchFilter
                        browseLanguage={getDefaultLanguage(settings)}
                        settings={settings}
                        customizations={{hideRemoveBlockButton: true, hideTextSearch: true, filterToShow: settingsEditData.filter, hideAddFilter : true, mode: SEARCH_COMPONENT_BUILDER_MODE}}
                        configurations={configurations}
                        aliasesMap={aliasesMap}
                        search={search}
                        onChange={doNothing}
                    />
                </WithTitle>;
            case SETTINGS_EDIT_HOME_PAGE_TEMPLATE:
                return <WithTitle title={'Page Template Settings'}>
                    <HomePageTemplateSetting
                        settings={settings}
                        onChange={doNothing}
                    />
                </WithTitle>;
            case SETTINGS_EDIT_MENU_OTHER_PAGE_TEMPLATE:
                let {pageWorkspaceSettings} = settingsEditData;
                return <WithTitle title={'Page Template Settings'}>
                    <PageWidthSettings pageWorkspaceSettings={pageWorkspaceSettings}/>
                    <PageEditor
                        dialog={false}
                        editPageDialogOpen={true}
                        pageWorkspace={pageWorkspace}
                        pageWorkspaceSettings={settingsEditData.pageWorkspaceSettings}
                        workspace={workspace}
                        settings={settings}
                        aliasesMap={aliasesMap}
                        aliasesToIRIMap={aliasesToIRIMap}
                        browseLanguage={browseLanguage}
                        configurations={configurations}
                        ontology={ontology}
                        shapes={getShapesData(configurations)}
                        location={location}
                        onSave={handleSaveWorkspace}
                        onExpand={onExpand}
                        onClose={onCancel}
                        partsProvider={pageWorkspace ? undefined :() => toArray(settings.homePage?.queries)}
                    />
                </WithTitle>;
            case SETTINGS_EDIT_HOME_PAGE_EDIT_PART:
                return <WithTitle datatest={'pageEditPart'} title={'Page Content Item Settings'}>
                    <PageEditor
                        partIndex={settingsEditData.index}
                        dialog={false}
                        editPageDialogOpen={true}
                        pageWorkspace={pageWorkspace}
                        pageWorkspaceSettings={settingsEditData.pageWorkspaceSettings}
                        workspace={workspace}
                        settings={settings}
                        aliasesMap={aliasesMap}
                        aliasesToIRIMap={aliasesToIRIMap}
                        browseLanguage={browseLanguage}
                        configurations={configurations}
                        ontology={ontology}
                        shapes={getShapesData(configurations)}
                        location={location}
                        onSave={handleSaveWorkspace}
                        onExpand={onExpand}
                        onClose={onCancel}
                        partsProvider={pageWorkspace ? undefined : () => toArray(settings.homePage?.queries)}
                    />
                </WithTitle>;
            case SETTINGS_EDIT_HOME_PAGE_EDIT_ALL:
                return <WithTitle title={'Page Content Settings'}>
                    <PageEditor
                        dialog={false}
                        editPageDialogOpen={true}
                        pageWorkspace={pageWorkspace}
                        pageWorkspaceSettings={settingsEditData.pageWorkspaceSettings}
                        workspace={workspace}
                        settings={settings}
                        aliasesMap={aliasesMap}
                        aliasesToIRIMap={aliasesToIRIMap}
                        browseLanguage={browseLanguage}
                        configurations={configurations}
                        ontology={ontology}
                        shapes={getShapesData(configurations)}
                        location={location}
                        onSave={handleSaveWorkspace}
                        onExpand={onExpand}
                        onClose={onCancel}
                        partsProvider={pageWorkspace ? undefined :() => toArray(settings.homePage?.queries)}
                    />
                </WithTitle>;
            case SETTINGS_EDIT_MENU_PAGES:
                return <WithTitle title={'Menu & Other Pages Settings'}>
                    <FieldContainer datatest={'menuPages'}>
                        <H3Title style={{margin :'16px 0px'}} title={'Menu Pages'} color={'primary'}/>
                    <PagesSetup
                        isQuickPageEdit={true}
                        workspace={workspace}
                        settings={settings}
                        pageArrayKey={'pages'}
                        onChange={onMenuPageChange}
                        aliasesToIRIMap={aliasesToIRIMap}
                        aliasesMap={aliasesMap}
                        browseLanguage={browseLanguage}
                        configurations={configurations}
                        ontology={ontology}
                        location={location}
                        shapes={getShapesData(configurations)}
                        onExpand={onExpand}
                    />
                    </FieldContainer>
                    <FieldContainer datatest={'otherPages'} style={{margin :'16px 0px'}}>
                    <H3Title style={{margin :'16px 0px'}} title={'Other Pages'} color={'primary'}/>
                    <PagesSetup
                        isQuickPageEdit={true}
                        workspace={workspace}
                        settings={settings}
                        pageArrayKey={'otherPages'}
                        onChange={onMenuPageChange}
                        aliasesToIRIMap={aliasesToIRIMap}
                        aliasesMap={aliasesMap}
                        browseLanguage={browseLanguage}
                        configurations={configurations}
                        ontology={ontology}
                        location={location}
                        shapes={getShapesData(configurations)}
                        onExpand={onExpand}
                    />
                    </FieldContainer>
                </WithTitle>;
            case SETTINGS_EDIT_LANGUAGES:
                return <WithTitle title={'Language Settings'}>
                    <MultilingualSettings
                        theme={theme}
                        settings={settings}
                        onChange={doNothing}
                        title={' '}
                    />
                </WithTitle>;
            case SETTINGS_EDIT_CARD_IMAGE:
                const classIRIs = getTypeIRIs(settingsEditData.resource, aliasesToIRIMap);
                return <WithTitle title={'Image Setup'} resource={settingsEditData.resource}>
                    {
                    classIRIs.map(classIRI => {
                        return <ImageSettingsBlock
                            aliasesMap={aliasesMap}
                            classIRI={classIRI}
                            workspace={workspace}
                            settings={settings}
                            theme={theme}
                            configurations={configurations}
                        />;
                    })
                }</WithTitle>;
            case SETTINGS_EDIT_CARD_TITLE:
                return <WithTitle title={'Title Setup'} resource={settingsEditData.resource}>
                    {
                        getTypeIRIs(settingsEditData.resource, aliasesToIRIMap).map(classIRI => {
                            return <WithCollapse classIRI={classIRI} aliasesMap={aliasesMap}>
                                <FieldContainer>
                                    <ClassBasicSetup
                                        cardConfig={TITLE_CONFIG}
                                        settings={settings}
                                        classIRI={classIRI}
                                        onChange={doNothing}
                                        theme={theme}
                                        configurations={configurations}
                                        aliasesMap={aliasesMap}
                                    />
                                </FieldContainer>
                            </WithCollapse>  ;
                        })
                    }</WithTitle>;
            case SETTINGS_EDIT_CARD_DESCRIPTION:
                return <WithTitle title={'Description Setup'} resource={settingsEditData.resource}>
                    {
                        getTypeIRIs(settingsEditData.resource, aliasesToIRIMap).map(classIRI => {
                            return <WithCollapse classIRI={classIRI} aliasesMap={aliasesMap}>
                                <FieldContainer>
                                    <ClassBasicSetup
                                        cardConfig={DESCRIPTION_CONFIG}
                                        settings={settings}
                                        classIRI={classIRI}
                                        onChange={doNothing}
                                        theme={theme}
                                        configurations={configurations}
                                        aliasesMap={aliasesMap}
                                    />
                                </FieldContainer>
                            </WithCollapse>  ;
                        })
                    }</WithTitle>;
            case SETTINGS_EDIT_CARD_OTHER_PROPERTIES:
                return <WithTitle title={'Other Properties Setup'} resource={settingsEditData.resource}>
                    {
                        getTypeIRIs(settingsEditData.resource, aliasesToIRIMap).map(classIRI => {
                            initLabelPropertiesIfRequired(settings, classIRI);
                            let summaryProperties = toArray(settings.labelProperties[classIRI][SUMMARY_PROPERTIES]);

                            return <WithCollapse classIRI={classIRI} aliasesMap={aliasesMap}>
                                <FieldContainer>
                                    <OtherPropertiesSettings
                                        theme={theme}
                                        summaryProperties={summaryProperties}
                                        configurations={configurations}
                                        aliasesMap={aliasesMap}
                                        classes={classes}
                                        onChange={doNothing}
                                        settings={settings}
                                        classIRI={classIRI}
                                        onPropertySelect={doNothing}
                                        onBlockRemove={doNothing}
                                        onSwitchChange={doNothing}
                                        onAddProperty={doNothing}
                                    />
                                </FieldContainer>
                            </WithCollapse>  ;
                        })
                    }</WithTitle>;
            case SETTINGS_EDIT_CARD_SUMMARY_PROPERTIES:
                return <WithTitle title={'Summary Setup'} resource={settingsEditData.resource}>
                    {
                        getTypeIRIs(settingsEditData.resource, aliasesToIRIMap).map(classIRI => {
                            initLabelPropertiesIfRequired(settings, classIRI);

                            return <WithCollapse classIRI={classIRI} aliasesMap={aliasesMap}>
                                <FieldContainer>
                                    <SummarySettings
                                        classIRI={classIRI}
                                        theme={theme}
                                        settings={settings}
                                        onChange={doNothing}
                                        configurations={configurations}
                                        aliasesMap={aliasesMap}
                                        onSwitchChange={doNothing}
                                        onFilterChange={doNothing}
                                    />
                                </FieldContainer>
                            </WithCollapse>  ;
                        })
                    }</WithTitle>;
            case SETTINGS_EDIT_RESOURCE_VIEW:
                return <WithTitle title={'Resource View Settings'}>
                    <ResourceViewSettingsBlock
                        theme={theme}
                        classes={classes}
                        aliasesMap={aliasesMap}
                        settings={settings}
                        onChange={doNothing}
                        ontology={ontology}
                        configurations={configurations}
                        settingsEditData={settingsEditData}
                        aliasesToIRIMap={aliasesToIRIMap}
                    />
                </WithTitle>;
            case SETTINGS_EDIT_BUTTONS_CARD:
                return <WithTitle datatest={'buttonsCardSettings'} title={'Buttons Card Settings'}>
                    <ButtonsCardSetting
                        settings={settings}
                        onChange={doNothing}
                    />
                    {isButtonsCardDisabled(settings) || <CreateResourceButtonSetting
                        settings={settings}
                        onChange={doNothing}
                    />}
                    {isButtonsCardDisabled(settings) || <SheetsViewButtonSetting
                        settings={settings}
                        onChange={doNothing}
                    />}
                    {isButtonsCardDisabled(settings) || <GraphVisualisationButtonSetting
                        settings={settings}
                        onChange={doNothing}
                    />}
                    {isButtonsCardDisabled(settings) || <DataVisualisationButtonSetting
                        settings={settings}
                        onChange={doNothing}
                    />}
                </WithTitle>;
            case SETTINGS_EDIT_PROPERTY:
                let {propertyKey, propertyName} = settingsEditData;
                let propertyIRI = aliasesToIRIMap[propertyKey];
                return <WithTitle title={'Property View Settings'}>
                    <FieldContainer>
                    {
                        withWhiteBorder(
                            <div>
                                <div>
                                    <div><H4Title title={propertyName}></H4Title></div>
                                    <Typography style={{marginTop: '8px'}} variant={'caption'}>{propertyIRI}</Typography>
                                </div>
                                <div style={{marginTop: '8px'}}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                datatest={'propertyHidden'}
                                                size={"small"}
                                                defaultValue={false}
                                                onChange={(ev) => {
                                                    handlePropertyChange(ev, settings);
                                                }}
                                                name="propertyHidden"
                                                value={propertyIRI}
                                            ></Switch>
                                        }
                                        label={<Typography>Hide this property</Typography>}
                                    />
                                </div>
                            </div>, {marginBottom :'0px'}
                        )
                    }</FieldContainer>
                    <div style={{marginTop :'16px'}}>
                        <H2Title>All Hidden Properties</H2Title>
                        <WithCollapse aliasesMap={aliasesMap}>
                            <>
                                <HiddenPropertySettings
                                    disableTitle={true}
                                    settings={settings}
                                    aliasesMap={aliasesMap}
                                    onChange={doNothing}
                                />
                            </>
                        </WithCollapse>
                    </div>
                </WithTitle>;
            case SETTINGS_EDIT_APP_BAR:
                return <WithTitle title={'App Bar Settings'}>
                    <AppBarSettings theme={theme} settings={settings} onChange={doNothing}/>
                </WithTitle>
            default:
                return <H2Title>Please hover over a section in the page and then click setting button.</H2Title>
        }
    }

    return <>
        <Resizable
            datatest={'resizeableContainer'}
            style={{
                background: theme.palette.grey.background,
                position: 'fixed',
                bottom: '0px',
                zIndex: '1299'
            }}
            defaultSize={{
                width: '100%',
                height: settingsHeight,
            }}
            onResizeStop={onResizeStop}
            maxHeight={'96%'}
            minHeight={'10%'}
        >
            <div style={{
                height: '8px',
                backgroundImage: `url(${dragBG})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPositionX: 'center',
                width: '100%',
                border: '1px solid',
                borderColor: theme.palette.grey.level2,
                borderRadius: '2px',
                backgroundColor: theme.palette.grey.levelE0,
                marginTop :'-5px'
            }}></div>
            <div style={{height: 'calc(100% - 48px)', overflow: "scroll"}}>
                <div style={{padding : '16px'}}>
                {renderEditor()}
                </div>
            </div>
            <div style={{width : '100%', backgroundColor : theme.palette.white.main, height : '48px'}}>
            <div style={{position: "fixed", marginTop : '6px', right: '64px'}}>
                <Button
                    color={'secondary'}
                    size={'small'}
                    style={{marginRight : '16px'}}
                    variant={'outlined'}
                    onClick={onCancel}
                    datatest={'cancelQuickEdit'}
                >{settingsEditData?.action === SETTINGS_EDIT_MENU_PAGES ? 'Close' : 'Cancel'}</Button>
                {   [SETTINGS_EDIT_MENU_PAGES].includes(settingsEditData.action) ? <></> :
                    <Button
                        color={'secondary'}
                        size={'small'}
                        variant={'contained'}
                        onClick={handleSaveWorkspace}
                        datatest={'applyQuickEdit'}
                    >Apply</Button>
                }
            </div>
            </div>
        </Resizable>
        <div key={settingsHeight} style={{height: settingsHeight}}></div>
    </>
};

export default SettingsEditPanel;