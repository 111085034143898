import React, {PureComponent} from "react";
import {TextField as MUITextField} from "@material-ui/core";
import {restrictMaximumCharacters, toArray} from "../components/util";
import {GreyLockIcon} from "../components/GreyStyleIcon";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../components/styles";
import {ConfirmButton} from "../components/ConfirmButton";
import {StyledTooltip} from "../components/TooltipError";
import ReactKeyByIndex from "./ReactKeyByIndex";


class EditableTextField extends PureComponent {
    constructor(props) {
        super(props);
        let {value} = props;
        this.state = {
            value : value,
            error : ''
        }
    }

    handleKeyEvent = (ev) => {
        let {error, value} = this.state;
        let {label, validator, maxLength} = this.props;

        if (ev.key === 'Enter' && !error) {
            let error = validator ? validator(value, label, maxLength) : undefined;
            if (!error) {
                this.props.onConfirm(label, value, ev);
            }
        } else if(ev.key === 'Escape') {
            this.handleCancelEdit(ev);
        }
    }

    handleCancelEdit = () => {
        let {value} = this.state;
        if(value !== this.props.value) {
            this.setState({value: this.props.value});
        }
        this.setState({error : ''});
    }

    render() {
        let {style, helpMessage, inputProps, confirmButton, theme, variant, disableUnderline, label, fullWidth, checkConfirmOnKeyDown, readOnly, validator, maxLength, autoFocus, otherEndAdornments} = this.props;
        let {value, error} = this.state;
        let helpOpen = helpMessage && !readOnly && !error && this.props.value !== value;

        let endAdornment = [
            readOnly
                ? <GreyLockIcon datatest={'readOnly'} key={'readOnly'} theme={theme}/>
                : (error || this.props.value === value)
                    ? undefined
                    : confirmButton ? confirmButton(label, value) : <ConfirmButton key={'confirm'} onClick={() => {
                        this.props.onConfirm(label, value)
                    }}
                    />
        ];
        if(otherEndAdornments) {
            toArray(otherEndAdornments).forEach(ad => {
                endAdornment.push(ad);
            })
        }


        return <StyledTooltip open={error || helpOpen ? true : false} placement={'bottom'} title={error || helpMessage || ""}>
            <MUITextField
                variant={variant}
                autoFocus={autoFocus}
                datatest={'textField-'+(label || '')}
                error={error ? true : false}
                style={style}
                fullWidth={fullWidth === undefined ? true : fullWidth}
                label={label}
                value={value}
                onChange={(event) => {
                    let newEvent = restrictMaximumCharacters(event, maxLength);
                    const {target: {name, value}} = newEvent;
                    let error = validator ? validator(value, label, maxLength) : undefined;
                    this.setState({error : error, value: value});
                }}
                onKeyDown={(ev) => {
                    if(checkConfirmOnKeyDown !== true) {
                        return;
                    }
                    this.handleKeyEvent(ev);
                }}
                onKeyPress={(ev) => {
                    if(checkConfirmOnKeyDown === true) {
                        return;
                    }
                    this.handleKeyEvent(ev);
                }}
                onBlur={this.handleCancelEdit}
                InputProps={{
                    endAdornment : <ReactKeyByIndex data={endAdornment}/>,
                    readOnly : readOnly,
                    disableUnderline: disableUnderline,
                }}
                inputProps={inputProps}
                InputLabelProps={{shrink: true}}
            />
        </StyledTooltip>;
    }

}

EditableTextField.propTypes = {
    style: PropTypes.object,
    autoFocus: PropTypes.bool,
    readOnly: PropTypes.bool,
    value: PropTypes.any,
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
    validator: PropTypes.func,
    maxLength: PropTypes.number,
    onConfirm: PropTypes.func,
    checkConfirmOnKeyDown: PropTypes.bool,
    otherEndAdornments: PropTypes.any,
    disableUnderline: PropTypes.bool,
    variant: PropTypes.string,
    inputProps: PropTypes.any,
    helpMessage: PropTypes.string,

};

export default withStyles(styles, {withTheme: true})(EditableTextField);
