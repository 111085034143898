import React from 'react'
import PropTypes from 'prop-types';

export const fieldContainerDefaultBackgroundColor = "rgb(238,238,238)";

function FieldContainer({ children, style, disableLeftRightPadding, datatest, key }) {

  return (
    <div
        key={key}
        datatest={datatest}
    style={{
        borderRadius: "4px",
      padding: disableLeftRightPadding ? '12px 0px': "12px",
      backgroundColor: fieldContainerDefaultBackgroundColor,
        ...style,
    }}
  >
    {children}
    </div>
  )
}

FieldContainer.defaultProps = {
  style: {},
};

FieldContainer.propTypes = {
    style: PropTypes.object,
    disableLeftRightPadding: PropTypes.bool
}

export default FieldContainer;

