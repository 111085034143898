import fetch from 'isomorphic-fetch'
import Bottleneck from 'bottleneck/es5'
import qs from 'qs'
import {
  ADMINISTRATION,
  ADMINISTRATION_ALIASES,
  ADMINISTRATION_BOOTSTRAP,
  ADMINISTRATION_CONFIGURATION,
  ADMINISTRATION_IMPORT_CONFIGURATION,
  ADMINISTRATION_ONTOLOGY,
  ADMINISTRATION_ONTOLOGY_BOOTSTRAP,
  ADMINISTRATION_SHACL,
  ALIAS_MANAGEMENT_TYPE_INSTANCE_CONFIGURATION,
  ALIAS_SYS_ETAG,
  ALIAS_SYS_RESULTS,
  COOKIE_ACCOUNT_TOKEN,
  COOKIE_MANAGEMENT_TOKEN,
  DATA,
  ETAG_VALUE_PAGE,
  HTTP_HEADER_AUTHORIZATION,
  HTTP_HEADER_CONTENT_TYPE,
  HTTP_METHOD_DELETE,
  HTTP_METHOD_GET,
  HTTP_METHOD_PATCH,
  HTTP_METHOD_POST,
  IRI_MANAGEMENT_INSTANCE_CONFIGURATION,
  MEDIA_TYPE_JSON,
  MEDIA_TYPE_JSON_LD,
  MEDIA_TYPE_NTRIPLES,
  MEDIA_TYPE_TEXT_TURTLE,
  MIXIN,
  ROUTE_MANAGEMENT_LOGIN,
  TYPE
} from "../Constants";
import {
  getBackendBaseEndpoint,
  getSPARQLEndpointPathFromAppConfig,
  isAuthenticationDisabled,
  loginWithLinkedInPage
} from "../Configs";
import {
  getCookie,
  getDataContextFileName,
  getDatasetLabel,
  getHeaderMap,
  getInstance,
  getRouteWithInstanceAndDataset,
  hasAnyDatasetLevelAuthenticationDisabled,
  isAnyAccountRouteOrHome
} from "../components/util";
import {isObject} from 'lodash';
import {
  BACKEND_PATH_ACCOUNT,
  BACKEND_PATH_ACCOUNT_LOGIN,
  BACKEND_PATH_ACCOUNT_TRIAL_DOWNLOAD_URL,
  BACKEND_PATH_ACCOUNT_TRIAL_IN_AWS_URL,
  BACKEND_PATH_DATA_IMPORT, BACKEND_PATH_DATA_PREFIXES,
  BACKEND_PATH_EG_CONTEXT,
  BACKEND_PATH_MANAGEMENT,
  BACKEND_PATH_MANAGEMENT_GRAPH, BACKEND_PATH_MANAGEMENT_GRAPH_BATCH,
  BACKEND_PATH_MANAGEMENT_INSTANCE_CONFIGURATION,
  BACKEND_PATH_MANAGEMENT_LOGIN,
  BACKEND_PATH_MANAGEMENT_OBJECT,
  BACKEND_PATH_OPS_MANAGEMENT_METRICS,
  BACKEND_PATH_RESET_DATA,
  BACKEND_PATH_SPARQL
} from "./backend-paths";
import queryString from "query-string";
import { encode } from 'js-base64';

const httpLimiter = new Bottleneck({
  maxConcurrent: 10,
  reservoir: 100,
  reservoirRefreshAmount: 100,
  reservoirRefreshInterval: 30 * 1000
})


export function getBaseEndpoint(dataset=true) {
  let backendBaseEndpoint = getBackendBaseEndpoint();
  let endpoint = !backendBaseEndpoint || backendBaseEndpoint === '' ? window.location.origin + '/' : backendBaseEndpoint;
  let instance = getInstance();
  let datasetLabel = dataset && getDatasetLabel();
  let path = `${instance ? "/"+instance: ''}${datasetLabel ? "/"+datasetLabel : ''}`;
  path = path ? `${endpoint.endsWith('/') ? '' : '/'}${path.substring(1, path.length)}/` : '';
  return endpoint+path;
}

export function getBaseEndpointWithInstance() {
  return getBaseEndpoint(false);
}

export function getManagementContextURL() {
  return getBaseEndpointWithInstance()  + BACKEND_PATH_MANAGEMENT + "/"+ BACKEND_PATH_EG_CONTEXT + `/api.jsonld`;
}

export function getAccountContextURL() {
  return getBaseEndpoint()  + BACKEND_PATH_ACCOUNT + "/" + BACKEND_PATH_EG_CONTEXT + `/api.jsonld`;
}

export async function getInstanceConfiguration() {
  let endpoint = getBaseEndpointWithInstance();
  const url = `${endpoint}${BACKEND_PATH_MANAGEMENT_INSTANCE_CONFIGURATION}`
  let res = await fetch( url, {}).catch(e => {
    console.log("Failed to connect to backend");
  });
  if(res) {
    let json = await res.json();
    let results = json[ALIAS_SYS_RESULTS];
    let instanceConfiguration = results.find(r => r[TYPE] === ALIAS_MANAGEMENT_TYPE_INSTANCE_CONFIGURATION || r[TYPE] === IRI_MANAGEMENT_INSTANCE_CONFIGURATION);
    return instanceConfiguration;
  }
}

export function getEndpointWithInstanceAndDataset() {
  let endpoint = getBaseEndpoint();
  return endpoint;
}


export function getEndpointForGraphiQL() {
  return getEndpointWithInstanceAndDataset()+"graphql"
}


export function getSPARQLEndpoint() {
  return getEndpointWithInstanceAndDataset()+getSPARQLEndpointPathFromAppConfig();
}

export function getGraphEndpoint() {
  return getEndpointWithInstanceAndDataset() + "graph";
}

export function getBatchEndpoint() {
  return getEndpointWithInstanceAndDataset() + "graph/batch" ;
}

export function getFeedbackEndpoint() {
  return getEndpointWithInstanceAndDataset() + "feedback" ;
}

export function getObjectStoreEndpoint() {
  return getBaseEndpointWithInstance() + BACKEND_PATH_MANAGEMENT_OBJECT ;
}

export function getResetDataEndpoint() {
  return getEndpointWithInstanceAndDataset() + BACKEND_PATH_RESET_DATA;
}

export function getAuthorization(path) {
  let isAccountsApp = path.includes(BACKEND_PATH_ACCOUNT) || isAnyAccountRouteOrHome();
  let cookie = isAccountsApp ? getCookie(COOKIE_ACCOUNT_TOKEN) : getCookie(COOKIE_MANAGEMENT_TOKEN);
  return cookie ? {[HTTP_HEADER_AUTHORIZATION] :  `Bearer ${cookie}`} : {};
}

export function graphQLFetcher(graphQLParams) {
  return graphQLPost(graphQLParams).then(response => response.json());
}

export function graphQLPost(graphQLParams) {
  return executeFetch(getEndpointWithInstanceAndDataset(), {
    method: HTTP_METHOD_POST,
    headers: {
      [HTTP_HEADER_CONTENT_TYPE] : MEDIA_TYPE_JSON,
      ...getAuthorization("graphql")
    },
    body: stringifyIfObject(graphQLParams),
  }, "graphql");
}

export async function executeFetch(endpoint, requestOptions, path, redirectToLogin = true) {

  const url = endpoint ? `${endpoint}${path}` : `${path.startsWith('/') ? '' : '/'}${path}`

  let request = {}
  if(requestOptions && !requestOptions.headers) {
    requestOptions.headers = {}
  }
  let auth = getAuthorization(url)[HTTP_HEADER_AUTHORIZATION]
  if(auth) {
    requestOptions.headers[HTTP_HEADER_AUTHORIZATION] = auth
  }

  //requestOptions.headers.UserName = 'TestUserA'
  let startTime = new Date();
/*
  const res = await httpLimiter.schedule(() => {
      let res = fetch(url, requestOptions)
      return res;
  })
*/
  let res = await fetch(url, requestOptions)
  request.fetchEndTime = new Date()
  if(res.status === 401 && redirectToLogin === true && !isAuthenticationDisabled() && !hasAnyDatasetLevelAuthenticationDisabled()) {
    let location = isAnyAccountRouteOrHome()
        ? loginWithLinkedInPage()
        : getRouteWithInstanceAndDataset(ROUTE_MANAGEMENT_LOGIN) + `?${queryString.stringify({ RelayState: encode(window.location.href) })}`  ;
    window.location = location;
    return ;
  }
  /*
  else if(res.status >= 400) {
    let message = ''
    try {
      message  = await res.text()
    } catch (e) {
    }
    throw createError(res.status, message)
  } else {
    */
    request.url = url
    request.options = requestOptions
    request.startTime = startTime
    res.request = request
    return res

  /*
  if (res.status >= 500) {
    let body
    try {
      body = `HTTP: ${res.status}, ${await res.text()}`
    } catch (e) {
      body = `HTTP: ${res.status}, <response body unavaliable>`
    }
    throw createError(res.status, body)
  }*/
}

export function configurationFetch(config, requestOptions, path, params) {
  return executeFetch(getEndpointWithInstanceAndDataset(), requestOptions, path, params)
}


export function stringifyIfObject(payload) {
  return isObject(payload) ? JSON.stringify(payload) : payload
}

export function bootstrap(payload) {
  const contentType = MEDIA_TYPE_JSON_LD
  const data = {
    method: HTTP_METHOD_POST,
    body: stringifyIfObject(payload),
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: contentType,
    }
  }
  return executeFetch(getEndpointWithInstanceAndDataset(), data, ADMINISTRATION_BOOTSTRAP)
}

export function loginToAccount(payload) {
  const contentType = MEDIA_TYPE_JSON_LD
  const data = {
    method: HTTP_METHOD_POST,
    body: stringifyIfObject(payload),
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: contentType,
    }
  }
  return executeFetch(getEndpointWithInstanceAndDataset(), data, BACKEND_PATH_ACCOUNT_LOGIN,  false)
}

export function loginToInstance(payload) {
  const contentType = MEDIA_TYPE_JSON_LD
  const data = {
    method: HTTP_METHOD_POST,
    body: stringifyIfObject(payload),
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: contentType,
    }
  }
  return executeFetch(getBaseEndpointWithInstance(), data, BACKEND_PATH_MANAGEMENT_LOGIN, false)
}

export function postData(baseEndpoint, path, payload, paramsMap = {}) {
  const contentType = MEDIA_TYPE_JSON_LD
  const data = {
    method: HTTP_METHOD_POST,
    body: payload && stringifyIfObject(payload),
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: contentType,
    }
  }
  const pathWithParams = `${path}?${qs.stringify(paramsMap, {
    indices: false
  })}`

  return executeFetch(baseEndpoint, data, pathWithParams)
}

export function patchData(baseEndpoint, path, payload, redirectToLogin = true) {
  const contentType = MEDIA_TYPE_JSON_LD
  const data = {
    method: HTTP_METHOD_PATCH,
    body: stringifyIfObject(payload),
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: contentType,
    }
  }
  return executeFetch(baseEndpoint, data, path, redirectToLogin)
}

export function deleteData(baseEndpoint, path, payload) {
  const contentType = MEDIA_TYPE_JSON_LD
  const data = {
    method: HTTP_METHOD_DELETE,
    body: stringifyIfObject(payload),
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: contentType,
    }
  }
  return executeFetch(baseEndpoint, data, path)
}

export function getData(baseEndpoint, path, paramsMap = {}, headerMap = {}, redirectToLogin = true) {
  const contentType = MEDIA_TYPE_JSON_LD
  const data = {
    method: HTTP_METHOD_GET,
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: contentType,
      ...headerMap
    }
  }
  const pathWithParams = `${path}?${qs.stringify(paramsMap, {
    indices: false
  })}`


  return executeFetch(baseEndpoint, data, pathWithParams, redirectToLogin)
}

export function getDataPrefixes() {
  const contentType = MEDIA_TYPE_JSON_LD
  const data = {
    method: HTTP_METHOD_GET,
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: contentType
    }
  }

  return executeFetch(getEndpointWithInstanceAndDataset(), data, BACKEND_PATH_DATA_PREFIXES)
}


function graphInternal(method, path, payload, mediaType) {
  const contentType = mediaType ? mediaType : MEDIA_TYPE_JSON_LD
  const data = {
    method: method,
    body: payload,
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: contentType,
    }
  }
  return executeFetch(getEndpointWithInstanceAndDataset(), data, path)
}

export function postGraph(payload, mediaType) {
  return graphInternal(HTTP_METHOD_POST, 'graph',payload, mediaType);
}

export function adminPostGraph(payload, mediaType) {
  return graphInternal(HTTP_METHOD_POST, ADMINISTRATION+'/graph', stringifyIfObject(payload), mediaType);
}

export function adminDeleteGraph(payload, mediaType) {
  return graphInternal(HTTP_METHOD_DELETE, ADMINISTRATION+'/graph', stringifyIfObject(payload), mediaType);
}

export function adminPatchGraph(payload, mediaType) {
  return graphInternal(HTTP_METHOD_PATCH, ADMINISTRATION+'/graph', stringifyIfObject(payload), mediaType);
}

export function postBatch(payload, headerMap) {
  const data = {
    method: HTTP_METHOD_POST,
    body: payload,
    headers: headerMap
  }
  return executeFetch(getBatchEndpoint(), data, "")
}

export function shacl(payload, mediaType) {
  const contentType = mediaType ? mediaType : MEDIA_TYPE_JSON_LD
  const data = {
    method: HTTP_METHOD_POST,
    body: contentType === MEDIA_TYPE_JSON_LD ? stringifyIfObject(payload) : payload,
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: contentType,
    }
  }
  return executeFetch(getEndpointWithInstanceAndDataset(), data, ADMINISTRATION_SHACL);
}

export function aliases(payload, apiConfigIRI, mediaType) {
  const contentType = mediaType ? mediaType : MEDIA_TYPE_JSON_LD
  const data = {
    method: HTTP_METHOD_POST,
    body: contentType === MEDIA_TYPE_JSON_LD ? stringifyIfObject(payload): payload,
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: contentType,
    }
  }
  const params = {
    apiConfigIRI: apiConfigIRI
  }
  const path = `${ADMINISTRATION_ALIASES}?${qs.stringify(params, {
    indices: false
  })}`

  return executeFetch(getEndpointWithInstanceAndDataset(), data, path)
}

export function data(payload, mediaType, acceptMediaType) {
  const contentType = mediaType ? mediaType : MEDIA_TYPE_TEXT_TURTLE
  const data = {
    method: HTTP_METHOD_POST,
    body: contentType === MEDIA_TYPE_JSON_LD ? stringifyIfObject(payload): payload,
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: contentType,
      "Accept" : acceptMediaType ? acceptMediaType : MEDIA_TYPE_NTRIPLES
    }
  }
  const path = `administration/data`

  return executeFetch(getEndpointWithInstanceAndDataset(), data, path)
}

export function ontology(payload, mediaType, acceptMediaType, type) {
  const contentType = mediaType ? mediaType : MEDIA_TYPE_TEXT_TURTLE
  const data = {
    method: HTTP_METHOD_POST,
    body: contentType === MEDIA_TYPE_JSON_LD ? stringifyIfObject(payload): payload,
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: contentType,
      "Accept" : acceptMediaType ? acceptMediaType : MEDIA_TYPE_NTRIPLES
    }
  }

  let params = {
  }

  if(type) {
    params.type = type
  }

  const path = `${ADMINISTRATION_ONTOLOGY}?${qs.stringify(params, {
    indices: false
  })}`

  return executeFetch(getEndpointWithInstanceAndDataset(), data, path)
}

export function ontologyBootstrap(formData) {
  const data = {
    method: "POST",
    body: formData
  }
  return executeFetch(getEndpointWithInstanceAndDataset(), data, ADMINISTRATION_ONTOLOGY_BOOTSTRAP);
}


export function putConfiguration(payload, mediaType) {
  const contentType = mediaType ? mediaType : MEDIA_TYPE_JSON_LD
  const data = {
    method: "PUT",
    body: contentType === MEDIA_TYPE_JSON_LD ? stringifyIfObject(payload): payload,
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: contentType,
    }
  }
  return executeFetch(getEndpointWithInstanceAndDataset(), data, ADMINISTRATION_CONFIGURATION)
}

export function putImportData(file, mediaType, params={}) {
  const contentType = mediaType ? mediaType : MEDIA_TYPE_JSON_LD
  const data = {
    method: "PUT",
    body: file,
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: contentType,
    }
  }

  const path = `${BACKEND_PATH_DATA_IMPORT}?${qs.stringify(params, {
    indices: false
  })}`

  return executeFetch(getEndpointWithInstanceAndDataset(), data, path)
}

export function postManagementGraph(payload, mediaType) {
  const contentType = mediaType ? mediaType : MEDIA_TYPE_JSON_LD
  const data = {
    method: "POST",
    body: payload,
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: contentType,
    }
  }
  return executeFetch(getBaseEndpointWithInstance() , data, BACKEND_PATH_MANAGEMENT_GRAPH)
}

export function patchManagementGraph(payload, mediaType) {
  const contentType = mediaType ? mediaType : MEDIA_TYPE_JSON_LD
  const data = {
    method: "PATCH",
    body: payload,
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: contentType,
    }
  }
  return executeFetch(getBaseEndpointWithInstance() , data, BACKEND_PATH_MANAGEMENT_GRAPH)
}

export function deleteManagementGraph(payload, mediaType) {
  const contentType = mediaType ? mediaType : MEDIA_TYPE_JSON_LD
  const data = {
    method: "DELETE",
    body: stringifyIfObject(payload),
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: contentType,
    }
  }
  return executeFetch(getBaseEndpointWithInstance() , data, BACKEND_PATH_MANAGEMENT_GRAPH)
}

export function postBatchManagementGraph(payload, mediaType) {
  const contentType = mediaType ? mediaType : MEDIA_TYPE_JSON_LD
  const data = {
    method: "POST",
    body: stringifyIfObject(payload),
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: contentType,
    }
  }
  return executeFetch(getBaseEndpointWithInstance() , data, BACKEND_PATH_MANAGEMENT_GRAPH_BATCH)
}

export function postBulkTriples(payload, mediaType) {
  const contentType = mediaType ? mediaType : MEDIA_TYPE_JSON_LD
  const data = {
    method: HTTP_METHOD_POST,
    body: contentType === MEDIA_TYPE_JSON_LD ? stringifyIfObject(payload): payload,
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: contentType,
    }
  }
  return executeFetch(getEndpointWithInstanceAndDataset(), data, 'administration/bulk/triples')
}

export function importConfiguration(payload, mediaType) {
  const contentType = mediaType ? mediaType : MEDIA_TYPE_JSON_LD
  const data = {
    method: HTTP_METHOD_POST,
    body: payload,
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: contentType,
    }
  }
  return executeFetch(getEndpointWithInstanceAndDataset(), data, ADMINISTRATION_IMPORT_CONFIGURATION)
}

export function deleteConfiguration(iri) {
  const contentType = MEDIA_TYPE_JSON_LD
  const data = {
    method: "DELETE",
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: contentType,
    }
  }
  const params = {
    iri: iri
  }
  const path = `${ADMINISTRATION_CONFIGURATION}?${qs.stringify(params, {
    indices: false
  })}`

  return executeFetch(getEndpointWithInstanceAndDataset(), data, path)
}

export function resetData() {
  const data = {
    method: "DELETE"
  }
  const path = `${BACKEND_PATH_RESET_DATA}`
  return executeFetch(getEndpointWithInstanceAndDataset(), data, path)
}

export function updateGraph(payload, mediaType = MEDIA_TYPE_JSON_LD, verb = HTTP_METHOD_PATCH) {
  const data = {
    method: verb,
    body: stringifyIfObject(payload),
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: mediaType,
    }
  }
  return executeFetch(getEndpointWithInstanceAndDataset(), data, 'graph')
}

export function getJSONLDConflicts(apiConfigIRI) {
  const data = {
    method: "GET",
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: "application/ld+json",
    }
  }
  const params = {
    apiConfigIRI: apiConfigIRI
  }
  const path = `administration/jsonldconflicts?${qs.stringify(params, {
    indices: false
  })}`
  return executeFetch(getEndpointWithInstanceAndDataset(), data, path).then(function(response) {
    return response.json();
  })
}

export function getJSONLDContext() {
  const data = {
    method: "GET",
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: "application/ld+json",
    }
  }
  const path = `administration/jsonldcontext`

  return executeFetch(getEndpointWithInstanceAndDataset(), data, path).then(function(response) {
    return response.json();
  })
}

export function getDataContextJSONLD() {
  const data = {
    method: "GET",
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: "application/ld+json",
    }
  }
  const path = BACKEND_PATH_EG_CONTEXT + `/` + getDataContextFileName()

  return executeFetch(getEndpointWithInstanceAndDataset(), data, path).then(function(response) {
    return response.json();
  })
}

function graphSearchInternal(endpoint, path ,queryString, facet, mixin, mixinType, etagAll, pageSize, page , countUpto, sortBy, sortDirection) {
  const data = {
    method: "GET",
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: "application/ld+json",
    }
  }
  const params = {
  }
  if(page && page !== '') {
    params.page = page
  }
  if(pageSize && pageSize !== '') {
    params.pageSize = pageSize
  }
  if(countUpto && countUpto !== '') {
    params.countUpto = countUpto
  }
  if(queryString) {
    params.query = queryString
  }
  if(facet) {
    params.facet = facet
  }
  if(sortBy) {
    params.sortBy = sortBy
  }
  if(sortDirection) {
    params.sortDirection = sortDirection
  }
  if(mixin) {
    data.headers.Prefer = `return=representation;${MIXIN}="${mixin}"${mixinType ? `;${DATA}=${mixinType}` : '' }`
  }
  if(etagAll && etagAll === true) {
    data.headers.Prefer = (data.headers.Prefer ? data.headers.Prefer+',' : '')+`${ALIAS_SYS_ETAG}=${ETAG_VALUE_PAGE}`
  }
  const pathAndQuery = `${path}?${qs.stringify(params, {
    indices: false
  })}`

  return executeFetch(endpoint, data, pathAndQuery)

}

export function graphSearch(queryString, facet, mixin, mixinType, etagAll, pageSize, page , countUpto, sortBy, sortDirection) {
  return graphSearchInternal(getEndpointWithInstanceAndDataset(), 'graph/search', queryString, facet, mixin, mixinType, etagAll, pageSize, page , countUpto, sortBy, sortDirection)
}

export function graphSearchByMaps(paramsMap, headerMap, redirectToLogin = true) {
  const data = {
    method: "GET",
    headers: headerMap,
  }

  const path = `graph/search?${qs.stringify(paramsMap, {
    indices: false
  })}`

  return executeFetch(getEndpointWithInstanceAndDataset(), data, path, redirectToLogin)
}

export function graphDownloadByMaps(paramsMap, headerMap, redirectToLogin = true) {
  const data = {
    method: "GET",
    headers: headerMap,
  }

  const path = `graph/download?${qs.stringify(paramsMap, {
    indices: false
  })}`

  return executeFetch(getEndpointWithInstanceAndDataset(), data, path, redirectToLogin)
}

export function getAllConfigurations(types, mediaType=MEDIA_TYPE_JSON_LD, acceptMediaType=MEDIA_TYPE_JSON_LD, redirectToLogin) {
  const data = {
    method: "GET",
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: !mediaType ? MEDIA_TYPE_JSON_LD : mediaType,
      "Accept" : acceptMediaType ? acceptMediaType : MEDIA_TYPE_JSON_LD
    }
  }
  const params = {
  }
  if(types) {
    params.type = types
  }
  const path = `${ADMINISTRATION_CONFIGURATION}?${qs.stringify(params, {indices: false})}`

  let response = executeFetch(getEndpointWithInstanceAndDataset(), data, path, redirectToLogin).then(function(response) {
    if(response.status >= 500) {
      return new Promise((resolve, reject) => {
        try {
          response.json().then((j) => reject(j))
        } catch (e) {
          reject(response.text())
        }
      });
    } else {
      return acceptMediaType === MEDIA_TYPE_JSON_LD ? response.json() : response.text();
    }
  })

  return response

}

export function getDownloadURL() {
  const data = {
    method: "GET"
  }
  const path = BACKEND_PATH_ACCOUNT_TRIAL_DOWNLOAD_URL

  let response = executeFetch(getEndpointWithInstanceAndDataset(), data, path).then(function(response) {
    return response.json();
  })
  return response

}


export function getTrialInAWSData(payload) {
  const data = {
    method: "POST",
    body: stringifyIfObject(payload)
  }
  const path = BACKEND_PATH_ACCOUNT_TRIAL_IN_AWS_URL;

  let response = executeFetch(getEndpointWithInstanceAndDataset(), data, path).then(function(response) {
    return response.json();
  })
  return response

}

export function getMetric(name, tag) {
  const data = {
    method: "GET",
    headers : {
      "EgStickiness" : "None"
    }
  }
  const params = {
    tag:tag
  }
  const path = `${BACKEND_PATH_OPS_MANAGEMENT_METRICS}/${name}?${qs.stringify(params, {indices: false})}`

  let response = executeFetch(getBaseEndpointWithInstance(), data, path).then(function(response) {
    return new Promise((resolve, reject) => {
      response.json().then(j => {
        let responseHeaders = getHeaderMap(response);
        return resolve({
          serverId : responseHeaders['eg-server'],
          ...j
        })
      });
    });
  })
  return response
}

export function submitFeedback(formData) {
  const data = {
    method: "POST",
    body: formData
  }
  return executeFetch(getFeedbackEndpoint(), data, '');
}

export function uploadObject(object, params = {}) {
  const data = {
    method: "PUT",
    headers: {
      [HTTP_HEADER_CONTENT_TYPE]: object.type
    },
    body: object
  }
  let paramString = qs.stringify(params, {indices: false });
  let path = BACKEND_PATH_MANAGEMENT_OBJECT+"?"+paramString;
  return executeFetch(getBaseEndpointWithInstance(), data, path);
}
