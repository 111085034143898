export const goCustom = `
@prefix owl: <http://www.w3.org/2002/07/owl#> .
@prefix dcterms: <http://purl.org/dc/terms/> .
@prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#> .
@prefix skos: <http://www.w3.org/2004/02/skos/core#> .
@prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#> .
@prefix skosxl: <http://www.w3.org/2008/05/skos-xl#> .
@prefix xsd: <http://www.w3.org/2001/XMLSchema#> .
@prefix ns0: <http://purl.org/dc/dcam/> .
@prefix foaf: <http://xmlns.com/foaf/0.1/> .
@prefix gs:    <https://grafsync.graphifi.com/schema/gs/> .
@prefix xkos:    <https://graphifi.com/schema/xkos/> .

<https://grafsync.graphifi.com/schema/gs/>
  a owl:Ontology ;
  dcterms:title "GrafSync ontology"@en .

# SKOS

skos:Concept
  rdfs:label "Concept"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "An idea or notion; a unit of thought."@en ;
  a owl:Class .

skos:ConceptScheme
  rdfs:label "Concept Scheme"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "A set of concepts, optionally including statements about semantic relationships between those concepts."@en ;
  skos:scopeNote "A concept scheme may be defined to include concepts from different sources."@en ;
  skos:example "Thesauri, classification schemes, subject heading lists, taxonomies, 'folksonomies', and other types of controlled vocabulary are all examples of concept schemes. Concept schemes are also embedded in glossaries and terminologies."@en ;
  a owl:Class ;
  owl:disjointWith skos:Concept .

skos:Collection
  rdfs:label "Collection"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "A meaningful collection of concepts."@en ;
  skos:scopeNote "Labelled collections can be used where you would like a set of concepts to be displayed under a 'node label' in the hierarchy."@en ;
  a owl:Class ;
  owl:disjointWith skos:Concept, skos:ConceptScheme .

skos:OrderedCollection
  rdfs:label "Ordered Collection"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "An ordered collection of concepts, where both the grouping and the ordering are meaningful."@en ;
  skos:scopeNote "Ordered collections can be used where you would like a set of concepts to be displayed in a specific order, and optionally under a 'node label'."@en ;
  a owl:Class ;
  rdfs:subClassOf skos:Collection .

skos:inScheme
  rdfs:label "is in scheme"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "Relates a resource (for example a concept) to a concept scheme in which it is included."@en ;
  skos:scopeNote "A concept may be a member of more than one concept scheme."@en ;
  a owl:ObjectProperty, rdf:Property ;
  rdfs:range skos:ConceptScheme .

skos:hasTopConcept
  rdfs:label "has top concept"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "Relates, by convention, a concept scheme to a concept which is topmost in the broader/narrower concept hierarchies for that scheme, providing an entry point to these hierarchies."@en ;
  a owl:ObjectProperty, rdf:Property ;
  rdfs:domain skos:ConceptScheme ;
  rdfs:range skos:Concept ;
  owl:inverseOf skos:topConceptOf .

skos:topConceptOf
  rdfs:label "is top concept in scheme"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "Relates a concept to the concept scheme that it is a top level concept of."@en ;
  a owl:ObjectProperty, rdf:Property ;
  rdfs:subPropertyOf skos:inScheme ;
  owl:inverseOf skos:hasTopConcept ;
  rdfs:domain skos:Concept ;
  rdfs:range skos:ConceptScheme .

skos:prefLabel
  rdfs:label "preferred label"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "The preferred lexical label for a resource, in a given language."@en ;
  a owl:AnnotationProperty, rdf:Property ;
  rdfs:subPropertyOf rdfs:label ;
  rdfs:comment "A resource has no more than one value of skos:prefLabel per language tag, and no more than one value of skos:prefLabel without language tag."@en, "The range of skos:prefLabel is the class of RDF plain literals."@en, """skos:prefLabel, skos:altLabel and skos:hiddenLabel are pairwise
      disjoint properties."""@en .

skos:altLabel
  rdfs:label "alternative label"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "An alternative lexical label for a resource."@en ;
  skos:example "Acronyms, abbreviations, spelling variants, and irregular plural/singular forms may be included among the alternative labels for a concept. Mis-spelled terms are normally included as hidden labels (see skos:hiddenLabel)."@en ;
  a owl:AnnotationProperty, rdf:Property ;
  rdfs:subPropertyOf rdfs:label ;
  rdfs:comment "The range of skos:altLabel is the class of RDF plain literals."@en, "skos:prefLabel, skos:altLabel and skos:hiddenLabel are pairwise disjoint properties."@en .

skos:hiddenLabel
  rdfs:label "hidden label"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "A lexical label for a resource that should be hidden when generating visual displays of the resource, but should still be accessible to free text search operations."@en ;
  a owl:AnnotationProperty, rdf:Property ;
  rdfs:subPropertyOf rdfs:label ;
  rdfs:comment "The range of skos:hiddenLabel is the class of RDF plain literals."@en, "skos:prefLabel, skos:altLabel and skos:hiddenLabel are pairwise disjoint properties."@en .

skos:notation
  rdfs:label "notation"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "A notation, also known as classification code, is a string of characters such as \\"T58.5\\" or \\"303.4833\\" used to uniquely identify a concept within the scope of a given concept scheme."@en ;
  skos:scopeNote "By convention, skos:notation is used with a typed literal in the object position of the triple."@en ;
  a owl:DatatypeProperty, rdf:Property .

skos:note
  rdfs:label "note"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "A general note, for any purpose."@en ;
  skos:scopeNote "This property may be used directly, or as a super-property for more specific note types."@en ;
  a owl:AnnotationProperty, rdf:Property .

skos:changeNote
  rdfs:label "change note"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "A note about a modification to a concept."@en ;
  a owl:AnnotationProperty, rdf:Property ;
  rdfs:subPropertyOf skos:note .

skos:definition
  rdfs:label "definition"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "A statement or formal explanation of the meaning of a concept."@en ;
  a owl:AnnotationProperty, rdf:Property ;
  rdfs:subPropertyOf skos:note .

skos:editorialNote
  rdfs:label "editorial note"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "A note for an editor, translator or maintainer of the vocabulary."@en ;
  a owl:AnnotationProperty, rdf:Property ;
  rdfs:subPropertyOf skos:note .

skos:example
  rdfs:label "example"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "An example of the use of a concept."@en ;
  a owl:AnnotationProperty, rdf:Property ;
  rdfs:subPropertyOf skos:note .

skos:historyNote
  rdfs:label "history note"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "A note about the past state/use/meaning of a concept."@en ;
  a owl:AnnotationProperty, rdf:Property ;
  rdfs:subPropertyOf skos:note .

skos:scopeNote
  rdfs:label "scope note"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "A note that helps to clarify the meaning and/or the use of a concept."@en ;
  a owl:AnnotationProperty, rdf:Property ;
  rdfs:subPropertyOf skos:note .

skos:semanticRelation
  rdfs:label "is in semantic relation with"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "Links a concept to a concept related by meaning."@en ;
  skos:scopeNote "This property should not be used directly, but as a super-property for all properties denoting a relationship of meaning between concepts."@en ;
  a owl:ObjectProperty, rdf:Property ;
  rdfs:domain skos:Concept ;
  rdfs:range skos:Concept .

skos:broader
  rdfs:label "has broader"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "Relates a concept to a concept that is more general in meaning."@en ;
  rdfs:comment "Broader concepts are typically rendered as parents in a concept hierarchy (tree)."@en ;
  skos:scopeNote "By convention, skos:broader is only used to assert an immediate (i.e. direct) hierarchical link between two conceptual resources."@en ;
  a owl:ObjectProperty, rdf:Property ;
  rdfs:subPropertyOf skos:broaderTransitive ;
  owl:inverseOf skos:narrower .

skos:narrower
  rdfs:label "has narrower"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "Relates a concept to a concept that is more specific in meaning."@en ;
  skos:scopeNote "By convention, skos:broader is only used to assert an immediate (i.e. direct) hierarchical link between two conceptual resources."@en ;
  rdfs:comment "Narrower concepts are typically rendered as children in a concept hierarchy (tree)."@en ;
  a owl:ObjectProperty, rdf:Property ;
  rdfs:subPropertyOf skos:narrowerTransitive ;
  owl:inverseOf skos:broader .

skos:related
  rdfs:label "has related"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "Relates a concept to a concept with which there is an associative semantic relationship."@en ;
  a owl:ObjectProperty, owl:SymmetricProperty, rdf:Property ;
  rdfs:subPropertyOf skos:semanticRelation ;
  rdfs:comment "skos:related is disjoint with skos:broaderTransitive"@en .

skos:broaderTransitive
  rdfs:label "has broader transitive"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "skos:broaderTransitive is a transitive superproperty of skos:broader." ;
  skos:scopeNote "By convention, skos:broaderTransitive is not used to make assertions. Rather, the properties can be used to draw inferences about the transitive closure of the hierarchical relation, which is useful e.g. when implementing a simple query expansion algorithm in a search application."@en ;
  a owl:ObjectProperty, owl:TransitiveProperty, rdf:Property ;
  rdfs:subPropertyOf skos:semanticRelation ;
  owl:inverseOf skos:narrowerTransitive .

skos:narrowerTransitive
  rdfs:label "has narrower transitive"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "skos:narrowerTransitive is a transitive superproperty of skos:narrower." ;
  skos:scopeNote "By convention, skos:narrowerTransitive is not used to make assertions. Rather, the properties can be used to draw inferences about the transitive closure of the hierarchical relation, which is useful e.g. when implementing a simple query expansion algorithm in a search application."@en ;
  a owl:ObjectProperty, owl:TransitiveProperty, rdf:Property ;
  rdfs:subPropertyOf skos:semanticRelation ;
  owl:inverseOf skos:broaderTransitive .

skos:member
  rdfs:label "has member"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "Relates a collection to one of its members."@en ;
  a owl:ObjectProperty, rdf:Property ;
  rdfs:domain skos:Collection ;
  rdfs:range [
    a owl:Class ;
    owl:unionOf (
     skos:Concept
     skos:Collection
   )
  ] .

skos:memberList
  rdfs:label "has member list"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "Relates an ordered collection to the RDF list containing its members."@en ;
  a owl:ObjectProperty, owl:FunctionalProperty, rdf:Property ;
  rdfs:domain skos:OrderedCollection ;
  rdfs:range rdf:List ;
  rdfs:comment """For any resource, every item in the list given as the value of the
      skos:memberList property is also a value of the skos:member property."""@en .

skos:mappingRelation
  rdfs:label "is in mapping relation with"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "Relates two concepts coming, by convention, from different schemes, and that have comparable meanings"@en ;
  rdfs:comment "These concept mapping relations mirror semantic relations, and the data model defined below is similar (with the exception of skos:exactMatch) to the data model defined for semantic relations. A distinct vocabulary is provided for concept mapping relations, to provide a convenient way to differentiate links within a concept scheme from links between concept schemes. However, this pattern of usage is not a formal requirement of the SKOS data model, and relies on informal definitions of best practice."@en ;
  a owl:ObjectProperty, rdf:Property ;
  rdfs:subPropertyOf skos:semanticRelation .

skos:broadMatch
  rdfs:label "has broader match"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "skos:broadMatch is used to state a hierarchical mapping link between two conceptual resources in different concept schemes."@en ;
  a owl:ObjectProperty, rdf:Property ;
  rdfs:subPropertyOf skos:mappingRelation, skos:broader ;
  owl:inverseOf skos:narrowMatch .

skos:narrowMatch
  rdfs:label "has narrower match"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "skos:narrowMatch is used to state a hierarchical mapping link between two conceptual resources in different concept schemes."@en ;
  a owl:ObjectProperty, rdf:Property ;
  rdfs:subPropertyOf skos:mappingRelation, skos:narrower ;
  owl:inverseOf skos:broadMatch .

skos:relatedMatch
  rdfs:label "has related match"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "skos:relatedMatch is used to state an associative mapping link between two conceptual resources in different concept schemes."@en ;
  a owl:ObjectProperty, owl:SymmetricProperty, rdf:Property ;
  rdfs:subPropertyOf skos:mappingRelation, skos:related .

skos:exactMatch
  rdfs:label "has exact match"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "skos:exactMatch is used to link two concepts, indicating a high degree of confidence that the concepts can be used interchangeably across a wide range of information retrieval applications. skos:exactMatch is a transitive property, and is a sub-property of skos:closeMatch."@en ;
  a owl:ObjectProperty, owl:SymmetricProperty, owl:TransitiveProperty, rdf:Property ;
  rdfs:subPropertyOf skos:closeMatch ;
  rdfs:comment "skos:exactMatch is disjoint with each of the properties skos:broadMatch and skos:relatedMatch."@en .

skos:closeMatch
  rdfs:label "has close match"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
  skos:definition "skos:closeMatch is used to link two concepts that are sufficiently similar that they can be used interchangeably in some information retrieval applications. In order to avoid the possibility of \\"compound errors\\" when combining mappings across more than two concept schemes, skos:closeMatch is not declared to be a transitive property."@en ;
  a owl:ObjectProperty, owl:SymmetricProperty, rdf:Property ;
  rdfs:subPropertyOf skos:mappingRelation .

# SKOS-XL

skosxl:Label
  rdfs:label "Label"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2008/05/skos-xl> ;
  skos:definition "A special class of lexical entities."@en ;
  a owl:Class ;
  owl:disjointWith skos:Concept, skos:Collection, skos:ConceptScheme ;
  rdfs:subClassOf [
    a owl:Restriction ;
    owl:onProperty skosxl:literalForm ;
    owl:cardinality "1"^^xsd:nonNegativeInteger
  ] .

skosxl:literalForm
  rdfs:label "literal form"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2008/05/skos-xl> ;
  skos:definition "The property skosxl:literalForm is used to give the literal form of an skosxl:Label."@en ;
  rdfs:comment "If two instances of the class skosxl:Label have the same literal form, they are not necessarily the same resource."@en, "The range of skosxl:literalForm is the class of RDF plain literals."@en ;
  a owl:DatatypeProperty, rdf:Property ;
  rdfs:domain skosxl:Label .

skosxl:prefLabel
  rdfs:label "preferred label"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2008/05/skos-xl> ;
  skos:definition "The property skosxl:prefLabel is used to associate an skosxl:Label with a skos:Concept. The property is analogous to skos:prefLabel."@en ;
  rdfs:seeAlso skos:prefLabel ;
  a owl:ObjectProperty, rdf:Property ;
  rdfs:range skosxl:Label ;
  rdfs:comment "If C skosxl:prefLabel L and L skosxl:literalForm V, then X skos:prefLabel V."@en .

skosxl:altLabel
  rdfs:label "alternative label"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2008/05/skos-xl> ;
  skos:definition "The property skosxl:altLabel is used to associate an skosxl:Label with a skos:Concept. The property is analogous to skos:altLabel."@en ;
  rdfs:seeAlso skos:altLabel ;
  a owl:ObjectProperty, rdf:Property ;
  rdfs:range skosxl:Label ;
  rdfs:comment "If C skosxl:altLabel L and L skosxl:literalForm V, then X skos:altLabel V."@en .

skosxl:hiddenLabel
  rdfs:label "hidden label"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2008/05/skos-xl> ;
  skos:definition "The property skosxl:hiddenLabel is used to associate an skosxl:Label with a skos:Concept. The property is analogous to skos:hiddenLabel."@en ;
  rdfs:seeAlso skos:hiddenLabel ;
  a owl:ObjectProperty, rdf:Property ;
  rdfs:range skosxl:Label ;
  rdfs:comment "If C skosxl:hiddenLabel L and L skosxl:literalForm V, then C skos:hiddenLabel V."@en .

skosxl:labelRelation
  rdfs:label "label relation"@en ;
  rdfs:isDefinedBy <http://www.w3.org/2008/05/skos-xl> ;
  skos:definition "The property skosxl:labelRelation is used for representing binary ('direct') relations between instances of the class skosxl:Label."@en ;
  skos:scopeNote "skosxl:labelRelation is not intended to be used directly, but rather as the basis for a design pattern which can be refined for more specific labeling scenarios."@en ;
  a owl:ObjectProperty, owl:SymmetricProperty, rdf:Property ;
  rdfs:domain skosxl:Label ;
  rdfs:range skosxl:Label .  

# Dublin Core
dcterms:created
  rdfs:label "Date Created"@en ;
  rdfs:comment "Date of creation of the resource."@en ;
  dcterms:description "Recommended practice is to describe the date, date/time, or period of time as recommended for the property Date, of which this is a subproperty."@en ;
  a rdf:Property ;
  rdfs:range rdfs:Literal ;
  dcterms:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dcterms: .

dcterms:creator
  rdfs:label "Creator"@en ;
  rdfs:comment "An entity responsible for making the resource."@en ;
  dcterms:description "Recommended practice is to identify the creator with a URI.  If this is not possible or feasible, a literal value that identifies the creator may be provided."@en ;
  a rdf:Property ;
  ns0:rangeIncludes dcterms:Agent ;
  owl:equivalentProperty foaf:maker ;
  dcterms:issued "2008-01-14"^^xsd:date ;
  rdfs:isDefinedBy dcterms: .

dcterms:title
  rdfs:label "Title"@en ;
  rdfs:comment "A name given to the resource."@en ;
  a rdf:Property ;
  rdfs:range rdfs:Literal ;
  dcterms:issued "2008-01-14"^^xsd:date ;
  rdfs:isDefinedBy dcterms: .

#gs
gs:revisionNo a owl:DatatypeProperty, rdf:Property .

gs:useSlashIRI a owl:DatatypeProperty, rdf:Property .

gs:useUuidIRI a owl:DatatypeProperty, rdf:Property .

xkos:isXlPrefLabelOf  a owl:ObjectProperty, rdf:Property .

gs:Dataset a owl:Class .

gs:Resource a owl:Class .

#rdfs

rdfs:label a rdf:Property ;
    rdfs:isDefinedBy <http://www.w3.org/2000/01/rdf-schema#> ;
    rdfs:label "label" ;
    rdfs:comment "A human-readable name for the subject." ;
    rdfs:domain rdfs:Resource ;
    rdfs:range rdfs:Literal .

`