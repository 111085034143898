import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import H2Title from "../../components/H2Title";
import DialogContent from "@material-ui/core/DialogContent";
import BackendErrorDialog from "../../components/BackendErrorDialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import SearchCard, {GetTextSearchField} from "./SearchCard";
import {cloneDeep, debounce} from "lodash";
import {
    centerVertically,
    computeShapesForResource,
    computeShapesForTypes,
    excludeHidden,
    findShapePropertyForAlias,
    getBrowseLanguageCode,
    getDataContextURL,
    getPropertyName,
    getPropertyTypes,
    getQuery,
    getRouteWithInstanceAndDataset,
    getSearchResult,
    getTextQuery,
    getUiLabelTranslationFromContext,
    isEmptyArray,
    isObjectOnly,
    isValidIRI,
    sort,
    toArray
} from "../../components/util";
import {nonEmptyToUndefinedQuery, updateSearchRequest} from "../apiplayground/SearchRequest";
import {
    ALIAS_SH_MAX_COUNT,
    ALIAS_SH_MIN_COUNT,
    ALIAS_SH_PATH,
    ALIAS_SYS_ETAG,
    ALIAS_SYS_RESULTS,
    AT_CONTEXT,
    ID,
    LABEL_PROPERTY_LANG,
    PAGE,
    PAGE_SIZE,
    QUERY,
    ROUTE_APPS_EXPLORER_SITE,
    TYPE
} from "../../Constants";
import qs from "qs";
import history from "../../history";
import {graphSearchByMaps} from "../../service/graph-api";
import {primeFromSearchResult} from "../../service/data-loader";
import {CircularProgress, LinearProgress, RadioGroup, TextField, Tooltip, Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {getValuesObject} from "./SearchResultItem";
import InstructionForAction from "../../components/InstructionForAction";
import {
    getUiLabelTranslation,
    UI_LABELS_ADD,
    UI_LABELS_ADD_CONNECTION,
    UI_LABELS_ADD_ID,
    UI_LABELS_ALREADY_CONNECTED,
    UI_LABELS_CANCEL,
    UI_LABELS_DELETE_HELP,
    UI_LABELS_EXISTING,
    UI_LABELS_INVALID_IRI_VALUE,
    UI_LABELS_LINK_BY_SEARCH,
    UI_LABELS_LINK_PREVIEW,
    UI_LABELS_NEW,
    UI_LABELS_NO_RESULTS,
    UI_LABELS_REMOVE,
    UI_LABELS_REMOVE_ALL,
    UI_LABELS_SAVE,
    UI_LABELS_SELECTED,
    UI_LABELS_UNDO
} from "./UILabel";
import {
    AddBoxOutlined,
    DeleteOutlined,
    IndeterminateCheckBoxOutlined,
    LinkOutlined,
    ListAltOutlined,
    UndoOutlined
} from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import GlobalsContext from "../../components/GlobalsContext";
import {loadResourceAndGet, reloadResourceAndPublishRefreshEvent, withEvent} from "./Event";
import FieldContainer from "../../components/FieldContainer";
import {patchResourceAndHandleMerge} from "./NodeContainer";
import {getSourceResourceDetails, renderProperty} from "./AddDataPropertyValueDialog";
import {PermissionService, withPermissions} from "../../service/permission-service";
import ListResultItem from "./ListResultItem";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import EditableTextField from "../../components/EditableTextField";

const styles = {
    dialogPaper: {
        minHeight: '90vh',
        maxHeight: '90vh',
    },
};



class LinkResourceDialog extends Component {
    static contextType = GlobalsContext;

    constructor(props) {
        super(props);
        let {sourceResource, linkProperty, aliasesToIRIMap, aliasesMap, configurations, ontology} = props;
        let shapeProperty = this.getShapeProperty(props);
        let propertyTypes = getPropertyTypes(shapeProperty, configurations);
        let typeValueRequired = false;
        if(!isEmptyArray(propertyTypes)) {
            typeValueRequired = true;
        }
        let typeFilterOptions = propertyTypes.map(t => {
            return {
                label : aliasesMap[t],
                value : t,
                tooltip : t
            }
        });
        let searchRequestObject = cloneDeep(props.settings.searchRequestObject);
        let {filters, textSearch} = searchRequestObject.search;
        //Allow text search on all properties
        textSearch.props = [];
        textSearch.showInFilter = true;

        //Set types filter to valid values
        let typeFilter = excludeHidden(filters).find(fl => {
            let {property} = fl;
            return property.value === TYPE;
        });
        if(typeFilter) {
            typeFilter.value = typeFilterOptions;
        }
        //If other filters are not relevant for types then remove
        let shapesForTypeFilterOptions = computeShapesForTypes(propertyTypes, aliasesToIRIMap, configurations, ontology);
        let newFilters = excludeHidden(filters).filter(fl => {
            let {property} = fl;
            let value = property.value;
            if(value === TYPE || value === ID) {
                return true;
            }
            if(value) {
                let path = value[ALIAS_SH_PATH];
                let shapeProperty = findShapePropertyForAlias(shapesForTypeFilterOptions, path, aliasesToIRIMap);
                return shapeProperty ? true : false;
            }
            return false;
        });
        searchRequestObject.search.filters = newFilters;

        this.state = {
            searchRequestObject : searchRequestObject,
            connectIds : [...toArray(sourceResource[linkProperty])],
            typeFilterOptions,
            typeValueRequired,
            previewMap : {}
        }
        this.debouncedSearch = debounce(this.debouncedSearchInner, 800);

    }

    getShapeProperty(props) {
        let {mode, sourceResource, linkProperty, aliasesToIRIMap, aliasesMap, configurations, ontology} = props;
        if(mode && mode === 'create') {
            return props.shapeProperty;
        }
        let shapesForResource = computeShapesForResource(sourceResource, aliasesToIRIMap, configurations, ontology);
        let shapeProperty = findShapePropertyForAlias(shapesForResource, linkProperty, aliasesToIRIMap);
        return shapeProperty;
    }

    componentDidMount() {
        let {sourceResource, aliasesToIRIMap, ontology, settings, configurations, aliasesMap, browseLanguage} = this.props;

        this.search();
        getValuesObject(sourceResource, settings, aliasesToIRIMap, browseLanguage, ontology).then((vo) => {
            let {title} = vo;
            this.setState({sourceResourceTitle : title})
        })
    }

    searchForRequest = (searchRequest, pushToHistory) => {
        this.setState({searchLoading : true});
        let facetFilters = searchRequest.search.filters.filter(f => f.enableFacet);
        let mapped = facetFilters.filter(f => f.property).map(f => {
            let {value, label} = f.property;
            if(isObjectOnly(value)) {
                value = label;
            }
            return { value, label , labelProperty : f.labelProperty};
        });
        searchRequest.facet = {
            "classIRIs": [],
            filters : mapped
        }
        updateSearchRequest(searchRequest, searchRequest.search, searchRequest.facet, searchRequest.mixin, searchRequest.sort, searchRequest.otherOptions);
        this.debouncedSearch(searchRequest, pushToHistory);
    }

    getBrowseLanguageObject = () => {
        let {browseLanguage} = this.props;
        return browseLanguage;
    }

    debouncedSearchInner = async (searchRequest, pushToHistory, page, pageSize = 12, loadMore = false ) => {

        let functionToRun = async () => {
            //If user has filters then reset query to user filters by excluding non default hidden filters including
            let search = searchRequest.search;
            let userFilters = excludeHidden(search.filters);
            let userFiltersQuery = nonEmptyToUndefinedQuery(`{${getTextQuery(search.textSearch)} ${getQuery(userFilters, '')} }`);
            if(userFiltersQuery) {
                let allUserFilterPlusHiddenFilter = search.filters.filter(f => f.applyOnEmpty !== true);
                let newQuery = `{${getTextQuery(search.textSearch)} ${getQuery(allUserFilterPlusHiddenFilter, '')} }`;
                searchRequest.paramMap[QUERY] = newQuery;
            }

            let {paramMap, headerMap} = searchRequest;
            let {searchResult} = this.state;
            if (page !== undefined) {
                paramMap[PAGE] = page;
            }
            paramMap[PAGE_SIZE] = pageSize;
            paramMap[LABEL_PROPERTY_LANG] = getBrowseLanguageCode(this.getBrowseLanguageObject())
            try {
                let o = await this.searchAndCache(paramMap, headerMap);
                let {json, response} = o;
                if (loadMore === true) {
                    json[ALIAS_SYS_RESULTS] = [
                        ...getSearchResult(searchResult),
                        ...getSearchResult(json)
                    ];
                }
                // If there are more requests then don't bother about updating page just run the  next request
                if (!this.latestFunction ) {
                    this.setState({
                        searchResult: json,
                        searchResultPage: paramMap[PAGE],
                        searchResultPageSize: paramMap[PAGE_SIZE],
                        searchLoading: false,
                        loadMore: false,
                        searchResponse: response
                    });

                    if (pushToHistory) {
                        //Only push user filters to history
                        let userParamMap = {
                            [QUERY] : userFiltersQuery
                        }
                        let userParamMapURL = `search?${qs.stringify(userParamMap, {indices: false})}`;
                        let siteLabel = this.getSiteLabel();
                        history.push(`${getRouteWithInstanceAndDataset(ROUTE_APPS_EXPLORER_SITE)}/${siteLabel}/${userParamMapURL}`);
                    }
                } else {
                    functionToRun = this.latestFunction.functionToRun;
                    this.latestFunction = undefined;
                    await functionToRun();
                }
            } catch (e) {
                this.setState({searchLoading: false, loadMore : false, loading: false, apiError: true, apiErrorResponse: e});
            }
        };

        //If a request is already in progress then just update latest
        if(this.inProgress === true) {
            this.latestFunction = {
                token :  Date.now(),
                functionToRun : functionToRun
            };
        } else {
            this.inProgress = true;
            await functionToRun();
            this.inProgress = false;
        }
    }

    searchAndCache = async (paramMap, headerMap, latestRequestToken) => {
        let response = await graphSearchByMaps(paramMap, headerMap);
        try {
            let json = await response.json();
            primeFromSearchResult(json);
            return {
                json,
                response,
                latestRequestToken
            };
        } catch (e) {
            return Promise.reject(response);
        }
    }

    search = (pushToHistory = false) => {
        let searchRequest = this.state.searchRequestObject;
        searchRequest && this.searchForRequest(searchRequest, pushToHistory);
    }

    getSearchCard = () => {
        let {aliasesToIRIMap, ontology, settings, configurations, aliasesMap, browseLanguage} = this.props;
        let {searchResult, searchRequestObject, typeFilterOptions, typeValueRequired} = this.state;
        if(!settings || !aliasesMap || !configurations) {
            return <></>;
        }
        return <SearchCard
            settings={settings}
            browseLanguage={browseLanguage}
            configurations={configurations}
            aliasesMap={aliasesMap}
            aliasesToIRIMap={aliasesToIRIMap}
            ontology={ontology}
            searchRequestObject={searchRequestObject}
            onChange={this.search}
            searchResult={searchResult}
            typeFilterOptions={typeFilterOptions}
            typeValueRequired={typeValueRequired}
        />;
    }

    getBanner = () => {
        let {settings, browseLanguage, location} = this.props;
        let {searchRequestObject} = this.state;
        return <div>
            <GetTextSearchField
                textSearch={searchRequestObject.search.textSearch}
                browseLanguage={browseLanguage}
                onChange={this.search}
                settings={settings}
                languageStyle={{minWidth: '160px', maxWidth: '160px'}}
                location={location}
            />

        </div>
    }

    isAlreadyConnected = (id) => {
        let {sourceResource, linkProperty} = this.props;
        let existing = toArray(sourceResource[linkProperty]);
        return existing.includes(id);
    }

    renderSearchResults = () => {
        let {searchResult, connectIds} = this.state;
        let {theme, configurations, apiErrorResponse, linkProperty, sourceResource, location, settings, browseLanguage, aliasesMap, aliasesToIRIMap, ontology} = this.props;

        let searchResultResources = getSearchResult(searchResult);
        return <div>
                        {
                            searchResult && isEmptyArray(searchResultResources) &&
                            <div>
                                <InstructionForAction text={
                                    getUiLabelTranslation(settings, UI_LABELS_NO_RESULTS, browseLanguage, 'No results found.')
                                }/>
                            </div>
                        }
                        {
                            searchResultResources.map((r, index) => {
                                let isExistingConnection = this.isAlreadyConnected(r[ID]);
                                return <React.Fragment key={r[ID]+getBrowseLanguageCode(browseLanguage)+index}>
                                <ListResultItem
                                    viewProperties={[]}
                                    hideTreeActions={true}
                                    customizations={{readOnly : true}}
                                    selected={connectIds.find(id => id === r[ID])}
                                    resource={r}
                                    theme={theme}
                                    configurations={configurations}
                                    aliasesMap={aliasesMap}
                                    aliasesToIRIMap={aliasesToIRIMap}
                                    settings={settings}
                                    location={location}
                                    browseLanguage={browseLanguage}
                                    ontology={ontology}
                                    linkProperty={linkProperty}
                                    sourceResource={sourceResource}
                                    secondaryInfoProvider={() => {
                                        return isExistingConnection ? <div style={{display : 'flex', paddingLeft : '24px', paddingBottom : '8px'}}>
                                            {centerVertically(<InfoOutlinedIcon color={'secondary'}/>)}
                                            {centerVertically(<Typography style={{marginLeft : '24px'}} color={'secondary'}>{getUiLabelTranslation(settings, UI_LABELS_ALREADY_CONNECTED, browseLanguage, UI_LABELS_ALREADY_CONNECTED)}</Typography>)}
                                        </div> : <></>;

                                    }}
                                    onSelectChange={(checked) => {
                                        if(checked) {
                                            if(connectIds.includes(r[ID]) === false) {
                                                connectIds.push(r[ID]);
                                            }
                                        } else {
                                            connectIds = connectIds.filter(id => id !== r[ID]);
                                        }
                                        this.setState({connectIds})
                                    }}
                                /></React.Fragment>;
                            })
                        }
                </div>;
    }

    renderConnectBySearch = () => {
        let {searchLoading} = this.state;
        let {settings, browseLanguage} = this.props;
        let {searchRequestObject} = this.state;

        return  <div style={{margin: '0px 32px'}}>
            <div style={{display: 'flex', marginTop: '16px', maxWidth : '100%'}}>
                <div style={{minWidth: '320px', maxWidth: '320px'}}>
                    {this.getSearchCard()}
                </div>
                <div style={{flexGrow: '1', marginLeft: '32px'}}>
                    {searchLoading && <LinearProgress color={'secondary'}/>}
                    {this.renderSearchResults()}
                </div>
            </div>
        </div>;
    }

    loadConnectedResourcesForPreview = async () => {
        let {connectIds, previewMap} = this.state;
        let all = connectIds.map(async id => {
            let r = await loadResourceAndGet(id);
            if(r) {
                previewMap[id] = r;
            }
        });
        Promise.all(all).then(() => {
            this.setState({previewMap});
        })
    }

    getRemovalIds = () => {
        let {connectIds} = this.state;
        let {sourceResource, linkProperty} = this.props;

        return toArray(sourceResource[linkProperty]).filter(p => connectIds.includes(p) === false);
    }

    renderConnectById = () => {
        let {connectIds, connectById, previewMap} = this.state;
        let {theme, sourceResource, linkProperty, location, configurations, aliasesMap, aliasesToIRIMap, ontology, settings, browseLanguage} = this.props;
        let sorted = sort(toArray(connectIds).map((id, index) => ({ [ID] : id, index , isExistingConnection : this.isAlreadyConnected(id)})), 'isExistingConnection', 'desc');
        const containerStyle = {marginBottom : '12px', backgroundColor : theme.palette.white.main};
        return <div>
                <FieldContainer style={{margin: 'auto', maxWidth : '600px'}}>
                    <div style={{display : 'flex', marginBottom  :'16px'}}>
                        <div style={{flexGrow : '1'}}/>
                        <Button
                            datatest={'removeAllButton'}
                            color={'secondary'}
                            variant={'outlined'}
                            disabled={isEmptyArray(connectIds)}
                            onClick={() => this.setState({connectIds: []})}
                        >{getUiLabelTranslation(settings, UI_LABELS_REMOVE_ALL, browseLanguage, UI_LABELS_REMOVE_ALL)}</Button>
                    </div>
                <div>
                    {
                        this.getRemovalIds(sourceResource, linkProperty, connectIds).map((id, index) => {
                            return <FieldContainer datatest={'removalBlock-'+index} style={containerStyle} key={index+id}><div  style={{display : 'flex'}}>
                                {centerVertically(<IndeterminateCheckBoxOutlined style={{color : theme.palette.warning.main}}/>)}
                                <TextField
                                    disabled={true}
                                    fullWidth={true}
                                    value={id || ""}
                                ></TextField>

                                {
                                    centerVertically(
                                        <Tooltip title={getUiLabelTranslation(settings, UI_LABELS_UNDO, browseLanguage, UI_LABELS_UNDO)}>
                                            <IconButton
                                                size={'small'}
                                                onClick={() => {
                                                    connectIds.push(id);
                                                    this.setState({connectIds});
                                                }}
                                            ><UndoOutlined/></IconButton>
                                        </Tooltip>, {marginLeft : '24px'})
                                }
                            </div></FieldContainer>;
                        })
                    }

                    {
                        sorted.map((idObj, index) => {
                            let {id, isExistingConnection} = idObj;
                            let vo = previewMap[id];

                            return <FieldContainer datatest={'sortedBlock-'+index} style={containerStyle} key={index+id}>
                                <div key={index+id} style={{display : 'flex'}}>
                                    {centerVertically(isExistingConnection ? <ListAltOutlined/> : <AddBoxOutlined style={{color : theme.palette.success.main}}/>)}
                                    {
                                        <EditableTextField
                                                fullWidth={true}
                                                value={id}
                                                validator={this.validateIRI}
                                                onConfirm={((label, value) => {
                                                    connectIds[idObj.index] = value;
                                                    this.setState({connectIds});
                                                })}
                                            />
                                    }
                                    {
                                        centerVertically(
                                            <Tooltip title={getUiLabelTranslation(settings, UI_LABELS_DELETE_HELP, browseLanguage, UI_LABELS_DELETE_HELP)}>
                                            <IconButton
                                                size={'small'}
                                                onClick={() => {
                                                    let newConnectIds = connectIds.filter(cid => cid !== id);
                                                    this.setState({connectIds: newConnectIds});
                                                }}
                                            ><DeleteOutlined></DeleteOutlined></IconButton>
                                            </Tooltip>, {marginLeft : '24px'})
                                    }
                                </div>
                                {vo ?
                                    <div>
                                        <ListResultItem
                                            containerStyle={{overflow : 'auto', border : '1px solid', borderColor :  theme.palette.grey.level2, marginBottom : '0px'}}
                                            viewProperties={[]}
                                            hideTreeActions={true}
                                            customizations={{readOnly: true}}
                                            resource={vo}
                                            theme={theme}
                                            configurations={configurations}
                                            aliasesMap={aliasesMap}
                                            aliasesToIRIMap={aliasesToIRIMap}
                                            settings={settings}
                                            location={location}
                                            browseLanguage={browseLanguage}
                                            ontology={ontology}
                                            linkProperty={linkProperty}
                                            sourceResource={sourceResource}
                                            disableSelection={true}
                                            hideIdInTitle={true}
                                        />

                                    </div>
                                    : <div style={{width : '100%', height : '80px'}}><iframe style={{borderRadius : '4px', border : '1px solid', borderColor :  theme.palette.grey.level2, width : '100%'}}
                                              height="80"
                                              src={id}>
                                    </iframe></div>

                                }
                            </FieldContainer>;
                        })
                    }
                    {
                        toArray(connectById).map((id , index) => {
                            return <FieldContainer datatest={'connectById-'+index} style={containerStyle} key={index+id}>
                                <EditableTextField
                                    validator={this.validateIRI}
                                    fullWidth={true}
                                    value={id}
                                    onConfirm={((label, value) => {
                                        connectIds.push(value);
                                        connectById.splice(index, 1);
                                        this.setState({
                                            connectById,
                                            connectIds
                                        }, this.loadConnectedResourcesForPreview);
                                    })}
                                    autoFocus={true}
                                />
                            </FieldContainer>;
                        })
                    }

                </div>
                <div style={{marginTop : '16px', display : 'flex'}}>
                    <div style={{flexGrow : '1'}}></div>
                    <Button datatest={'addIdButton'} disabled={this.getValidationError() ? true : false} color={'secondary'} variant={'text'} onClick={() => {
                        let {connectById} = this.state;
                        connectById = toArray(connectById);
                        connectById.push("");
                        this.setState({connectById});
                    }}>{getUiLabelTranslation(settings, UI_LABELS_ADD_ID, browseLanguage, UI_LABELS_ADD_ID)}</Button>
                </div>
        </FieldContainer>
        </div>;
    }


    validateIRI = (value) => {
        let {settings, browseLanguage} = this.props;
        return isValidIRI(value) ? undefined : getUiLabelTranslation(settings, UI_LABELS_INVALID_IRI_VALUE, browseLanguage, UI_LABELS_INVALID_IRI_VALUE);
    }

    isConnectById = () => {
        let {connectionBy} = this.state;
        return connectionBy === UI_LABELS_LINK_PREVIEW;
    }

    onSave = async () => {
        let { sourceResource, linkProperty, onSaveSuccess, onSaveFailure, publishEvent, settings, browseLanguage} = this.props;
        let {connectIds} = this.state;
        if(this.isCreateMode()) {
            onSaveSuccess?.(connectIds);
            return;
        }
        if(this.hasAnyChange() === false) {
            return;
        }
        this.setState({saveLoading : true});

        let resourceId = sourceResource[ID];
        let patchPayload = {
            [AT_CONTEXT] : getDataContextURL(),
            [ID] : resourceId,
            [ALIAS_SYS_ETAG] : sourceResource[ALIAS_SYS_ETAG],
            [linkProperty] : connectIds.length > 0 ? [...connectIds] : null
        };
        let {updateFailed} = await patchResourceAndHandleMerge(patchPayload, publishEvent, settings, browseLanguage);
        if(updateFailed) {
            this.setState({saveLoading : false});
            onSaveFailure?.(updateFailed);
        } else {
            let allPromises = connectIds.map(async id => await reloadResourceAndPublishRefreshEvent(publishEvent, id));
            Promise.all(allPromises).then(() => {
                onSaveSuccess?.();
                this.setState({saveLoading : false});
            });
        }
    }

    isCreateMode = () => {
        let {mode} = this.props;
        return mode && mode === 'create';
    }

    isMaxCountError = () => {
        let {connectIds} = this.state;
        let {shapeProperty} = this.props;
        let maxCount = shapeProperty[ALIAS_SH_MAX_COUNT];
        return maxCount && connectIds.length > Number(maxCount);
    }

    getValidationError = () => {
        let {connectIds, connectById} = this.state;
        let {shapeProperty} = this.props;
        let minCount = shapeProperty[ALIAS_SH_MIN_COUNT];
        let maxCount = shapeProperty[ALIAS_SH_MAX_COUNT];
        if(minCount) {
            if(connectIds.length < Number(minCount)) {
                return 'Minimum '+minCount+' resources required.';
            }
        }
        if(this.isMaxCountError()) {
                return 'Maximum '+maxCount+' resources allowed.';
        }
        if(this.isConnectById()) {
            return toArray(connectById).map(v => this.validateIRI(v)).find(er => er);
        }
        return undefined;

    }

    hasAnyChange = () => {
        let {sourceResource, linkProperty} = this.props;
        let {connectIds} = this.state;
        let currentValue = sourceResource[linkProperty];
        let currentValueArray = toArray(currentValue);
        let newValueArray = toArray(connectIds);
        if(isEmptyArray(currentValueArray) && isEmptyArray(newValueArray)) {
            return false;
        }
        if(currentValueArray.length !== newValueArray.length) {
            return true;
        }
        let found = currentValueArray.find(cv => !newValueArray.includes(cv));
        return found ? true : false;
    }


    render() {
        let {onClose, classes, theme, mode} = this.props;
        let {apiError, apiErrorResponse, linkProperty, sourceResource, location, settings, browseLanguage, aliasesMap, aliasesToIRIMap, ontology} = this.props;
        let {connectIds, sourceResourceTitle, connectionBy, saveLoading} = this.state;
        const removalCount = this.getRemovalIds().length;
        const existingCount = connectIds.filter(id => this.isAlreadyConnected(id)).length;
        const newCount = connectIds.filter(id => this.isAlreadyConnected(id) === false).length;
        let validationError = this.getValidationError();

        const isAddOnly = toArray(sourceResource[linkProperty]).length === 0;
        return <>
            {
                <Dialog
                    fullWidth={true}
                    maxWidth={'lg'}
                    open={true}
                    datatest={'linkResourceDialog'}
                    classes={{ paper: classes.dialogPaper }}
                    onKeyDown={(ev) => {
                        if(ev.ctrlKey && ev.key === 'Enter') {
                            this.onSave().catch();
                        } else if (ev.key === 'Escape') {
                            onClose();
                        } else if(ev.key === 'Enter') {
                            //Only stop propagation but do not preventDefault as we want to add new line in text field
                            ev.stopPropagation();
                        }
                    }}
                    scroll={'paper'}

                >
                    <DialogTitle id="form-dialog-title">
                        <div style={{display : 'flex'}}>
                            {
                                this.isCreateMode() ? <>
                                        {centerVertically(<H2Title title={getUiLabelTranslation(settings, UI_LABELS_ADD_CONNECTION, browseLanguage, UI_LABELS_ADD_CONNECTION)}/>)}
                                        {centerVertically(renderProperty(<LinkOutlined style={{marginLeft: '16px', marginRight: '4px', color: theme.palette.link.main}}/>, getPropertyName(aliasesToIRIMap, linkProperty, ontology, browseLanguage)))}
                                    </>
                                : <>
                                    {centerVertically(<H2Title title={'Update'}/>)}
                                    {getSourceResourceDetails(sourceResourceTitle, sourceResource, getPropertyName(aliasesToIRIMap, linkProperty, ontology, browseLanguage),
                                        <LinkOutlined style={{marginRight: '4px', color: theme.palette.link.main}}/>)}
                                </>
                            }
                            {
                                this.isCreateMode() ||
                                centerVertically(
                                    <div>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                datatest="connectionBy"
                                                style={{marginLeft : '8px'}}
                                                value={connectionBy || UI_LABELS_LINK_BY_SEARCH}
                                                onChange={(event, value) => {
                                                    if(value === UI_LABELS_LINK_PREVIEW) {
                                                        this.loadConnectedResourcesForPreview().catch();
                                                    }
                                                    this.setState({connectionBy : value});
                                                }}
                                                row={true}
                                            >
                                                <FormControlLabel
                                                    value={UI_LABELS_LINK_BY_SEARCH}
                                                    control={<Radio/>}
                                                    label={getUiLabelTranslationFromContext(this, UI_LABELS_LINK_BY_SEARCH)}
                                                />
                                                <FormControlLabel
                                                    value={UI_LABELS_LINK_PREVIEW}
                                                    control={<Radio/>}
                                                    label={getUiLabelTranslationFromContext(this, UI_LABELS_LINK_PREVIEW)}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                )
                            }

                        </div>
                    </DialogTitle>
                    <DialogContent tabIndex={-1} style={{backgroundColor : theme.palette.grey.background}}>
                        {
                            this.isConnectById()
                                ? this.renderConnectById()
                                : this.renderConnectBySearch()

                        }
                        {apiError && <BackendErrorDialog handleClose={() => this.setState({apiError : undefined, apiErrorResponse : undefined})}  open={true} error={apiErrorResponse} />}
                    </DialogContent>
                    <DialogActions>
                            <Button
                                datatest={'cancelButton'}
                                onClick={() => {

                                    onClose();
                                }}
                                variant={"outlined"}
                                color="secondary"
                            >{getUiLabelTranslation(settings, UI_LABELS_CANCEL, browseLanguage, UI_LABELS_CANCEL)}</Button>
                            <div style={{flexGrow : '1'}}></div>

                        {
                            isAddOnly ? <Typography>{getUiLabelTranslation(settings, UI_LABELS_SELECTED, browseLanguage, UI_LABELS_SELECTED)} {newCount}</Typography> :<>
                                <Typography>{getUiLabelTranslation(settings, UI_LABELS_REMOVE, browseLanguage, UI_LABELS_REMOVE)} {removalCount}</Typography>
                                <Typography>{getUiLabelTranslation(settings, UI_LABELS_EXISTING, browseLanguage, UI_LABELS_EXISTING)} {existingCount}</Typography>
                                <Typography>{getUiLabelTranslation(settings, UI_LABELS_NEW, browseLanguage, UI_LABELS_NEW)} {newCount}</Typography>
                            </>
                        }
                        {validationError && <Typography style={{color : theme.palette.error.main}}>{validationError}</Typography>}
                        <Button
                            style={{marginLeft : '24px'}}
                            disabled={saveLoading || validationError || this.hasAnyChange() === false ? true : false}
                            datatest={'addButton'}
                            variant={"contained"}
                            color="secondary"
                            onClick={this.onSave}
                        >{saveLoading && <CircularProgress color={'secondary'} size={24}/>}{
                            this.isCreateMode()
                                ? getUiLabelTranslation(settings, UI_LABELS_ADD, browseLanguage, UI_LABELS_ADD)
                                : getUiLabelTranslation(settings, UI_LABELS_SAVE, browseLanguage, UI_LABELS_SAVE)
                        }</Button>
                    </DialogActions>
                </Dialog>
            }
        </>;

    }

}

LinkResourceDialog.propTypes = {
    permissionService: PropTypes.instanceOf(PermissionService),
    settings: PropTypes.any,
    publishEvent: PropTypes.any,
    aliasesMap: PropTypes.any,
    aliasesToIRIMap: PropTypes.any,
    location: PropTypes.any,
    onClose: PropTypes.any,
    onSaveSuccess: PropTypes.any,
    onSaveFailure: PropTypes.any,
    sourceResource : PropTypes.any,
    linkProperty : PropTypes.any,
    shapeProperty : PropTypes.any,
    configurations: PropTypes.object,
    ontology: PropTypes.any,
    browseLanguage: PropTypes.any,
    mode : PropTypes.any
};

export default  withEvent(withStyles(styles, {withTheme: true})(withPermissions(LinkResourceDialog)));

