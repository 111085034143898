import React, {Component} from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    RadioGroup,
    TextField as MuiTextField
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {STYLE_GRID_ITEM_SPACING} from "../../Constants";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import {withEvent} from "./Event";
import {withPermissions} from "../../service/permission-service";
import Radio from "@material-ui/core/Radio";

export const ACTION_UPDATE = 'update';

const ACTION_NEW = 'new';

class SaveDialogWithTitle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saveOption : ACTION_NEW,
            saveVisualisationTitle : props.defaultTitleValue || props.title
        }
    }

    showSaveVisualisationDialog = () => {
        const {saveVisualisationTitle, saveVisualisationTitleError, saveOption} = this.state;
        const {theme, onCancel, onSave, title, titleProvider} = this.props;
        return <Dialog
            aria-labelledby="Save"
            open={true}
            fullWidth={true}
            maxWidth={'xs'}
            datatest={'saveDialog'}
        >
            <DialogTitle id="form-dialog-title">
                {titleProvider && titleProvider()}
                {
                    title ?
                    <RadioGroup row={true} datatest={'radioGroup'} value={saveOption} onChange={(event) => {
                        let newValue = event.target.value;
                        if (newValue === ACTION_UPDATE) {
                            this.setState({saveVisualisationTitle: this.props.title});
                        }
                        this.setState({saveOption: newValue});
                    }}>
                        <FormControlLabel
                            value="new"
                            control={<Radio datatest={ACTION_NEW} color="secondary"/>}
                            label="Save As New"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value={ACTION_UPDATE}
                            control={<Radio datatest={ACTION_UPDATE} color="secondary"/>}
                            label="Update"
                            labelPlacement="end"
                        />
                    </RadioGroup>
                        : titleProvider ?'' : 'Save'
                }
            </DialogTitle>
            <DialogContent>
                <MuiTextField
                    disabled={saveOption === ACTION_UPDATE}
                    datatest={'textField'}
                    fullWidth={true}
                    label={'Title'}
                    value={saveVisualisationTitle||""}
                    error={saveVisualisationTitleError}
                    helperText={saveVisualisationTitleError}
                    onChange={(event) => {
                        let saveVisualisationTitleError = '';
                        const {target: {value}} = event;
                        if(!value) {
                            saveVisualisationTitleError = 'Value required.'
                        } else if (value.length > 200) {
                            saveVisualisationTitleError = 'Maximum 200 characters.'
                        } else {
                            saveVisualisationTitleError = ''
                        }
                        this.setState({saveVisualisationTitleError, saveVisualisationTitle : value});
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button datatest={'cancelButton'} variant={"outlined"} onClick={onCancel} color="secondary">Cancel</Button>
                <Button disabled={!saveVisualisationTitle || saveVisualisationTitleError} datatest={'saveButton'} style={{marginLeft: theme.spacing(STYLE_GRID_ITEM_SPACING)}} variant={"contained"} color="secondary"
                        onClick={() => onSave(saveVisualisationTitle, saveOption)}>{ saveOption === ACTION_NEW ? 'Save As New' : 'Update'}</Button>

            </DialogActions>
        </Dialog>;
    }


    render() {
        return this.showSaveVisualisationDialog();
    }
}

SaveDialogWithTitle.propTypes = {
    title: PropTypes.func,
    defaultTitleValue: PropTypes.string,
    titleProvider: PropTypes.func,
    onCancel: PropTypes.func,
    onSave: PropTypes.func
};

export default withStyles(styles, {withTheme: true})(withEvent(withPermissions(SaveDialogWithTitle)));
