export const APPLICATION_NAME = 'EasyGraph'

export const HTML_SPACE = '\u00A0';


export const PRIMARY_COLOR = '#13294B'
export const RED_COLOR = '#C62828'
export const SECONDARY_COLOR = '#CF4520'
export const SECONDARY_COLOR_RGB = 'rgb(207,69,32)'
export const SECONDARY_COLOR_DARK = '#b83d1c'
export const SUCCESS_COLOR = '#40C1AC'
export const SUCCESS_COLOR_BUTTON = '#00796B'
export const LINK_COLOR = '#0066CC'
export const FONT_1 = 'Rajdhani, sans-serif'
export const FONT_2 = 'Roboto'
export const FONT_3 = 'Roboto'

export const ETAG = 'eTag'

export const VALIDATION_ONTOLOGY_FILE_MAX_SIZE = 2097152;
export const VALIDATION_EXAMPLE_SET_FILE_MAX_SIZE = 5242880;
export const VALIDATION_SITE_FILE_MAX_SIZE = 10485760;
export const VALIDATION_MODEL_FILE_MAX_SIZE = 5242880 + (1024 * 1024);
export const VALIDATION_SCENARIO_FILE_MAX_SIZE = 5242880;
export const VALIDATION_FEEDBACK_FILE_MAX_SIZE = 5242880;

export const MEDIA_TYPE_JSON = 'application/json'
export const MEDIA_TYPE_JSON_LD = 'application/ld+json'
export const MEDIA_TYPE_RDF_XML = 'application/rdf+xml'
export const MEDIA_TYPE_NTRIPLES = 'application/n-triples'
export const MEDIA_TYPE_TEXT_TURTLE = 'text/turtle'
export const MEDIA_TYPE_TRIG = 'application/trig'
export const EASYGRAPH_DATA_NAMESPACE = 'https://easygraph.graphifi.com/data/'
export const EASYGRAPH_DATA_AUTHENTICATION_NAMESPACE = EASYGRAPH_DATA_NAMESPACE+'authentication/'
export const EASYGRAPH_DATA_USERSTATUS_NAMESPACE = EASYGRAPH_DATA_NAMESPACE+'userstatus/'
export const EASYGRAPH_DATA_USERSTATUS_DISABLED = EASYGRAPH_DATA_USERSTATUS_NAMESPACE+'disabled'
export const EASYGRAPH_DATA_USERSTATUS_ACTIVE = EASYGRAPH_DATA_USERSTATUS_NAMESPACE+'active'

export const EASYGRAPH_DATA_FEEDBACKSTATUS_NAMESPACE = EASYGRAPH_DATA_NAMESPACE+'feedbackstatus/'
export const EASYGRAPH_DATA_FEEDBACKSTATUS_NEW = EASYGRAPH_DATA_FEEDBACKSTATUS_NAMESPACE+'new'
export const EASYGRAPH_DATA_FEEDBACKSTATUS_REJECTED = EASYGRAPH_DATA_FEEDBACKSTATUS_NAMESPACE+'rejected'
export const EASYGRAPH_DATA_FEEDBACKSTATUS_ACCEPTED = EASYGRAPH_DATA_FEEDBACKSTATUS_NAMESPACE+'accepted'

export const EASYGRAPH_DATA_AUTHENTICATION_ALL_REQUESTS = EASYGRAPH_DATA_AUTHENTICATION_NAMESPACE+'allRequests'
export const EASYGRAPH_DATA_AUTHENTICATION_ALL_UPDATES = EASYGRAPH_DATA_AUTHENTICATION_NAMESPACE+'allUpdates'
export const EASYGRAPH_DATA_AUTHENTICATION_DISABLED = EASYGRAPH_DATA_AUTHENTICATION_NAMESPACE+'disabled'
export const EASYGRAPH_NAMESPACE = 'https://easygraph.graphifi.com/schema/eg/'
export const EASYGRAPH_ACCOUNT_NAMESPACE = 'https://easygraph.graphifi.com/schema/account/'
export const EASYGRAPH_MANAGEMENT_NAMESPACE = 'https://easygraph.graphifi.com/schema/management/'
export const EASYGRAPH_IDENTIFIER = EASYGRAPH_NAMESPACE+'identifier'
export const EASYGRAPH_LABEL = EASYGRAPH_NAMESPACE+'label'

export const EASYGRAPH_DATA_APPLICATION_PLAYGROUND = EASYGRAPH_DATA_NAMESPACE+'application/'+'playground'
export const EASYGRAPH_DATA_APPLICATION_EXPLORER = EASYGRAPH_DATA_NAMESPACE+'application/'+'explorer'
export const EASYGRAPH_DATA_APPLICATION_EXPLORER_PAGE = EASYGRAPH_DATA_NAMESPACE+'application/'+'explorer/page'
export const EASYGRAPH_DATA_APPLICATION_EXPLORER_GRAPH_VIEW = EASYGRAPH_DATA_NAMESPACE+'application/'+'explorer/graphView'
export const EASYGRAPH_DATA_APPLICATION_EXPLORER_DATA_VISUALISATION = EASYGRAPH_DATA_NAMESPACE+'application/'+'explorer/dataVisualisation'
export const EASYGRAPH_DATA_APPLICATION_EXPLORER_SPREADSHEET = EASYGRAPH_DATA_NAMESPACE+'application/'+'explorer/spreadsheet'
export const EASYGRAPH_DATA_PUBLIC_KEY_STATUS_ACTIVE = EASYGRAPH_DATA_NAMESPACE+'publickey/'+'active'
export const EASYGRAPH_DATA_PUBLIC_KEY_STATUS_DISABLED = EASYGRAPH_DATA_NAMESPACE+'publickey/'+'disabled'

export const OBJECT_LINKING_PROPERTY = EASYGRAPH_NAMESPACE + 'ObjectLinkingProperty';

export const API_CONFIGURATION_RESOURCE_BASE_IRI = EASYGRAPH_DATA_NAMESPACE + 'api-configuration/';
export const WRITABLE_CLASS_BASE_IRI = EASYGRAPH_DATA_NAMESPACE + 'class/';
export const API_CONFIGURATION_RESOURCE_TYPE = EASYGRAPH_NAMESPACE + 'ApiConfiguration';
export const EASYGRAPH_ETAG = EASYGRAPH_NAMESPACE + ETAG
export const EASYGRAPH_UUID = EASYGRAPH_NAMESPACE + 'uuid'
export const EASYGRAPH_API_ACTIVITY = EASYGRAPH_NAMESPACE + 'ApiActivity'
export const EASYGRAPH_IS_CONFIGURATION_RESOURCE_OF = EASYGRAPH_NAMESPACE + 'isConfigurationResourceOf'
export const TYPE_OWL_CLASS = 'http://www.w3.org/2002/07/owl#Class'
export const TYPE_OWL_THING = 'http://www.w3.org/2002/07/owl#Thing'
export const TYPE_OWL_OBJECT_PROPERTY = 'http://www.w3.org/2002/07/owl#ObjectProperty'
export const TYPE_OWL_DATATYPE_PROPERTY = 'http://www.w3.org/2002/07/owl#DatatypeProperty'
export const TYPE_RDFS_CLASS = 'http://www.w3.org/2000/01/rdf-schema#Class'
export const TYPE_RDFS_LITERAL = 'http://www.w3.org/2000/01/rdf-schema#Literal'
export const TYPE_RDF_PROPERTY = 'http://www.w3.org/1999/02/22-rdf-syntax-ns#Property'
export const TYPE_RDF_LIST = 'http://www.w3.org/1999/02/22-rdf-syntax-ns#List'
export const TYPE_RDF_TYPE = 'http://www.w3.org/1999/02/22-rdf-syntax-ns#type'
export const TYPE_SHACL_SHAPE = 'http://www.w3.org/ns/shacl#Shape'
export const TYPE_SKOS_CONCEPT_SCHEME = 'http://www.w3.org/2004/02/skos/core#ConceptScheme';
export const TYPE_SKOS_CONCEPT = 'http://www.w3.org/2004/02/skos/core#Concept';
export const TYPE_SKOS_COLLECTION = 'http://www.w3.org/2004/02/skos/core#Collection';
export const TYPE_SKOS_ORDERED_COLLECTION = 'http://www.w3.org/2004/02/skos/core#OrderedCollection';
export const TYPE_SKOS_XL_LABEL = 'http://www.w3.org/2008/05/skos-xl#Label';
export const TYPE_OWL_ONTOLOGY = 'http://www.w3.org/2002/07/owl#Ontology';

export const TYPE_CLASS_BASED_PERMISSION = EASYGRAPH_NAMESPACE+'ClassBasedPermission'
export const TYPE_OPERATION_BASED_PERMISSION = EASYGRAPH_NAMESPACE+'OperationBasedPermission'
export const TYPE_ENDPOINT_BASED_PERMISSION = EASYGRAPH_NAMESPACE+'EndpointBasedPermission'


export const RDF_FIRST = 'http://www.w3.org/1999/02/22-rdf-syntax-ns#first';
export const RDF_LANGSTRING = 'http://www.w3.org/1999/02/22-rdf-syntax-ns#langString';

export const RDF_SUBJECT = 'http://www.w3.org/1999/02/22-rdf-syntax-ns#subject';
export const RDF_PREDICATE = 'http://www.w3.org/1999/02/22-rdf-syntax-ns#predicate';

export const RDFS_LITERAL = 'http://www.w3.org/2000/01/rdf-schema#Literal';
export const RDFS_SUB_PROPERTY_OF = 'http://www.w3.org/2000/01/rdf-schema#subPropertyOf';
export const RDFS_SUB_CLASS_OF = 'http://www.w3.org/2000/01/rdf-schema#subClassOf';
export const RDFS_DOMAIN = 'http://www.w3.org/2000/01/rdf-schema#domain';
export const RDFS_RANGE = 'http://www.w3.org/2000/01/rdf-schema#range';
export const RDFS_SEE_ALSO = 'http://www.w3.org/2000/01/rdf-schema#seeAlso';
export const RDFS_IS_DEFINED_BY = 'http://www.w3.org/2000/01/rdf-schema#isDefinedBy';
export const RDFS_MEMBER = 'http://www.w3.org/2000/01/rdf-schema#member';


export const XSD_STRING = 'http://www.w3.org/2001/XMLSchema#string';
export const XSD_DECIMAL = 'http://www.w3.org/2001/XMLSchema#decimal';
export const XSD_DOUBLE = 'http://www.w3.org/2001/XMLSchema#double';
export const XSD_FLOAT = 'http://www.w3.org/2001/XMLSchema#float';
export const XSD_DURATION = 'http://www.w3.org/2001/XMLSchema#duration';
export const XSD_INTEGER = 'http://www.w3.org/2001/XMLSchema#integer';
export const XSD_NEGATIVE_INTEGER = 'http://www.w3.org/2001/XMLSchema#negativeInteger ';
export const XSD_NON_NEGATIVE_INTEGER = 'http://www.w3.org/2001/XMLSchema#nonNegativeInteger';
export const XSD_NON_POSITIVE_INTEGER = 'http://www.w3.org/2001/XMLSchema#nonPositiveInteger';
export const XSD_POSITIVE_INTEGER = 'http://www.w3.org/2001/XMLSchema#positiveInteger';
export const XSD_BYTE = 'http://www.w3.org/2001/XMLSchema#byte';
export const XSD_INT = 'http://www.w3.org/2001/XMLSchema#int';
export const XSD_SHORT = 'http://www.w3.org/2001/XMLSchema#short';
export const XSD_LONG = 'http://www.w3.org/2001/XMLSchema#long';
export const XSD_UNSIGNED_BYTE = 'http://www.w3.org/2001/XMLSchema#unsignedByte';
export const XSD_UNSIGNED_INT = 'http://www.w3.org/2001/XMLSchema#unsignedInt';
export const XSD_UNSIGNED_SHORT = 'http://www.w3.org/2001/XMLSchema#unsignedShort';
export const XSD_UNSIGNED_LONG = 'http://www.w3.org/2001/XMLSchema#unsignedLong';
export const XSD_DATETIME = 'http://www.w3.org/2001/XMLSchema#dateTime';
export const XSD_DATE = 'http://www.w3.org/2001/XMLSchema#date';
export const XSD_TIME = 'http://www.w3.org/2001/XMLSchema#time';
export const XSD_BOOLEAN = 'http://www.w3.org/2001/XMLSchema#boolean';
export const XSD_G_DAY = 'http://www.w3.org/2001/XMLSchema#gDay';
export const XSD_G_MONTH = 'http://www.w3.org/2001/XMLSchema#gMonth';
export const XSD_G_YEAR = 'http://www.w3.org/2001/XMLSchema#gYear';
export const XSD_G_MONTH_DAY = 'http://www.w3.org/2001/XMLSchema#gMonthDay';
export const XSD_G_YEAR_MONTH = 'http://www.w3.org/2001/XMLSchema#gYearMonth';
export const XSD_TOKEN = 'http://www.w3.org/2001/XMLSchema#token';
export const XSD_NORMALIZED_STRING = 'http://www.w3.org/2001/XMLSchema#normalizedString';
export const XSD_ANY_URI = 'http://www.w3.org/2001/XMLSchema#anyURI';
export const XSD_LANGUAGE = 'http://www.w3.org/2001/XMLSchema#language';
export const SH_IRI = 'http://www.w3.org/ns/shacl#IRI';
export const SH_LITERAL = 'http://www.w3.org/ns/shacl#Literal';

export const IRI_TEST_ONTOLOGY_ORG_PERSON_IMAGE = 'http://example.com/ontology/testOrganisationPersonImage';
export const IRI_TEST_ONTOLOGY_ORG_PERSON_IMAGE_CLASS_ORG = 'http://example.com/Organisation';
export const IRI_TEST_ONTOLOGY_ORG_PERSON_IMAGE_CLASS_PERSON = 'http://example.com/Person';
export const IRI_TEST_ONTOLOGY_ORG_PERSON_IMAGE_CLASS_SQIMAGE = 'http://example.com/SquareImageObject';
export const IRI_TEST_ONTOLOGY_ORG_PERSON_IMAGE_CLASS_IMAGE = 'http://example.com/ImageObject';
export const IRI_TEST_ONTOLOGY_ORG_PERSON_IMAGE_PROP_MEMBER = 'http://example.com/member';
export const IRI_TEST_ONTOLOGY_ORG_PERSON_IMAGE_PROP_IMAGE = 'http://example.com/image';

export const IRI_SYS_USERNAME = EASYGRAPH_NAMESPACE+'username';
export const IRI_SYS_PASSWORD = EASYGRAPH_NAMESPACE+'password';
export const IRI_SYS_LABEL = EASYGRAPH_NAMESPACE+'label';
export const IRI_SYS_DESCRIPTION = EASYGRAPH_NAMESPACE+'description';
export const IRI_SYS_ROLE_SUPERADMIN = EASYGRAPH_DATA_NAMESPACE+'role/superadmin';
export const IRI_SYS_ROLE_ROOT_USER = EASYGRAPH_DATA_NAMESPACE+'role/rootuser';

export const API_MODE_HTTP_API = {
    value: 'Http',
    label: 'Http'
};
export const API_MODE_GRAPHQL = {
    value: 'GraphQL',
    label: 'GraphQL'
};

export const API_MODE = [
    API_MODE_HTTP_API,
    API_MODE_GRAPHQL
];

export const GRAPHQL_CREATE_OPERATION = 'create';
export const GRAPHQL_SEARCH_OPERATION = 'search';
export const GRAPHQL_UPDATE_OPERATION = 'update';
export const GRAPHQL_DELETE_OPERATION = 'delete';

export const contentTypeMap = {
    'trig': MEDIA_TYPE_TRIG,
    'ttl': MEDIA_TYPE_TEXT_TURTLE,
    'jsonld': MEDIA_TYPE_JSON_LD,
    'json': MEDIA_TYPE_JSON_LD,
    'nt': MEDIA_TYPE_NTRIPLES,
    'owl': MEDIA_TYPE_RDF_XML,
    'xml': MEDIA_TYPE_RDF_XML,
    'rdf': MEDIA_TYPE_RDF_XML,
    'rdfs': MEDIA_TYPE_RDF_XML,
}

export const ONTOLOGY_ALLOWED_FORMATS = [".ttl", ".xml", ".owl", ".rdf", ".rdfs", ".nt", ".jsonld"]
export const LABEL_NODE_KINDS = {
    [SH_IRI] : 'IRI',
    [SH_LITERAL] : 'Literal'
}


export const HTTP_HEADER_CONTENT_TYPE = 'Content-Type'
export const HTTP_HEADER_ACCEPT = 'Accept'
export const HTTP_HEADER_AUTHORIZATION = 'Authorization'
export const HTTP_HEADER_EGDATASET = 'EGDataset'
export const HTTP_HEADER_PREFER = 'Prefer'
export const HTTP_HEADER_ETAG = 'etag'
export const HTTP_HEADER_IF_NONE_MATCH = 'If-None-Match'
export const EG_SERVER_TIME_HEADER = "EGServerTimeToProcessRequestInMillis"
export const HTTP_METHOD_POST = 'POST'
export const HTTP_METHOD_PUT = 'PUT'
export const HTTP_METHOD_GET = 'GET'
export const HTTP_METHOD_PATCH = 'PATCH'
export const HTTP_METHOD_DELETE = 'DELETE'

export const SPARQL_QUERY = 'QUERY'
export const SPARQL_UPDATE = 'UPDATE'

export const COOKIE_DATASET = 'dataset'
export const COOKIE_DATASET_ID = 'datasetId'
export const COOKIE_DATASET_ID_FROM_DB = 'datasetIdFromDB'
export const COOKIE_MANAGEMENT_TOKEN = 'egmToken'
export const COOKIE_ACCOUNT_TOKEN = 'egaToken'
export const COOKIE_SINGLE_SIGN_IN_ID = 'id'
export const COOKIE_INSTANCE = 'instance'
export const COOKIE_INSTANCE_ID = 'instanceId'
export const COOKIE_SYSTEM = 'system'
export const COOKIE_HASH = 'hash'
export const COOKIE_FIRSTNAME = 'firstName'
export const COOKIE_LASTNAME = 'lastName'
export const COOKIE_IMAGE_URL = 'imageUrl'

export const ADMINISTRATION = 'administration'
export const ADMINISTRATION_BOOTSTRAP = ADMINISTRATION+'/bootstrap'
export const ADMINISTRATION_CONFIGURATION = ADMINISTRATION+'/configuration'
export const ADMINISTRATION_IMPORT_CONFIGURATION = ADMINISTRATION+'/import/configuration'
export const ADMINISTRATION_ONTOLOGY = ADMINISTRATION+'/ontology'
export const ADMINISTRATION_ONTOLOGY_BOOTSTRAP = ADMINISTRATION_ONTOLOGY+'/bootstrap'
export const ADMINISTRATION_SHACL = ADMINISTRATION+'/shacl'
export const ADMINISTRATION_ALIASES = ADMINISTRATION+'/aliases'

export const ROUTE_APP = '/app'

export const ROUTE_ACCOUNT = ROUTE_APP+'/account'
export const ROUTE_ACCOUNT_LOGIN = ROUTE_ACCOUNT+'/login'
export const ROUTE_ACCOUNT_SIGNUP = ROUTE_ACCOUNT+'/signup'
export const ROUTE_ACCOUNT_FORGOTTEN_USERNAME = ROUTE_ACCOUNT+'/forgotten/username'
export const ROUTE_ACCOUNT_FORGOTTEN_PASSWORD = ROUTE_ACCOUNT+'/forgotten/password'
export const ROUTE_ACCOUNT_HOME = ROUTE_ACCOUNT+'/home'
export const ROUTE_ACCOUNT_INSTANCES = ROUTE_ACCOUNT+'/instances'
export const ROUTE_ACCOUNT_DETAILS = ROUTE_ACCOUNT+'/details'
export const ROUTE_ACCOUNT_MONITORING = ROUTE_ACCOUNT+'/monitoring';

export const ROUTE_MANAGEMENT = ROUTE_APP+'/management';
export const ROUTE_MANAGEMENT_MONITORING = ROUTE_MANAGEMENT+'/monitoring';
export const ROUTE_MANAGEMENT_LOGIN = ROUTE_MANAGEMENT+'/login';
export const ROUTE_MANAGEMENT_LOGOUT = ROUTE_MANAGEMENT+'/logout';
export const ROUTE_MANAGEMENT_ADMIN_LOGIN = ROUTE_MANAGEMENT+'/admin-login';
export const ROUTE_MANAGEMENT_HOME = ROUTE_MANAGEMENT+'/home'
export const ROUTE_MANAGEMENT_USER = ROUTE_MANAGEMENT+'/users';
export const ROUTE_MANAGEMENT_USER_PROFILE = ROUTE_MANAGEMENT+'/profile';
export const ROUTE_MANAGEMENT_DATASET = ROUTE_MANAGEMENT+'/datasets';

export const ROUTE_ADMIN = ROUTE_APP+'/admin'
export const ROUTE_CONFIGURATION_MANAGER = ROUTE_ADMIN+'/configurationmanager'
export const ROUTE_APPLICATIONS = ROUTE_ADMIN+'/applications'
export const ROUTE_BOOTSTRAP = ROUTE_ADMIN+'/bootstrap'
export const ROUTE_SCHEMA_BUILDER = ROUTE_ADMIN+'/schemabuilder'
export const ROUTE_SCHEMA_BUILDER_SCHEMA = ROUTE_SCHEMA_BUILDER+'/schema'
export const ROUTE_SITE = ROUTE_ADMIN+'/site'


export const ROUTE_API_PLAYGROUND = ROUTE_ADMIN+'/apiplayground'
export const ROUTE_SPARQL_EDITOR = ROUTE_ADMIN+'/sparqleditor'
export const ROUTE_DATA_IMPORT_EXPORT = ROUTE_ADMIN+'/data'
export const ROUTE_HELP = '/docs/docs/easygraph.html'
export const ROUTE_TESTS = '/test-reports/rdf4j_inmem_triplestore/cucumber-html-reports/overview-features.html'

export const ROUTE_APPS = ROUTE_APP+'/apps'
export const ROUTE_APPS_EXPLORER = ROUTE_APPS+'/explorer'
export const SITE = 'site'
export const ROUTE_APPS_EXPLORER_SITE = ROUTE_APPS_EXPLORER+'/'+SITE


export const ROUTE_CONFIGURATION_MANAGER_HOME = ROUTE_CONFIGURATION_MANAGER+'/home'
export const ROUTE_CONFIGURATION_MANAGER_CONTAINER = ROUTE_CONFIGURATION_MANAGER+'/container'
export const ROUTE_CONFIGURATION_MANAGER_ONTOLOGY = ROUTE_CONFIGURATION_MANAGER+'/ontology'
export const ROUTE_CONFIGURATION_MANAGER_SHAPE = ROUTE_CONFIGURATION_MANAGER+'/shape'
export const ROUTE_CONFIGURATION_MANAGER_INVERSE = ROUTE_CONFIGURATION_MANAGER+'/inverse'
export const ROUTE_CONFIGURATION_MANAGER_RULE = ROUTE_CONFIGURATION_MANAGER+'/rule'
export const ROUTE_CONFIGURATION_MANAGER_IMPORT = ROUTE_CONFIGURATION_MANAGER+'/import'
export const ROUTE_CONFIGURATION_MANAGER_EXPORT = ROUTE_CONFIGURATION_MANAGER+'/export'
export const ROUTE_CONFIGURATION_MANAGER_SECURITY = ROUTE_CONFIGURATION_MANAGER+'/security'
export const ROUTE_CONFIGURATION_MANAGER_SECURITY_ROLES = ROUTE_CONFIGURATION_MANAGER_SECURITY+'/roles'
export const ROUTE_CONFIGURATION_MANAGER_SECURITY_AUTHORISATION = ROUTE_CONFIGURATION_MANAGER_SECURITY+'/authorisation'
export const ROUTE_CONFIGURATION_MANAGER_SECURITY_AUTHENTICATION = ROUTE_CONFIGURATION_MANAGER_SECURITY+'/authentication'
export const ROUTE_CONFIGURATION_MANAGER_PREFIXES = ROUTE_CONFIGURATION_MANAGER_SECURITY+'/prefixes'
export const ROUTE_CONFIGURATION_MANAGER_ROLES = ROUTE_CONFIGURATION_MANAGER+'/roles'

export const ROUTE_API_PLAYGROUND_CONCEPTS = ROUTE_API_PLAYGROUND+'/concepts'
export const ROUTE_API_PLAYGROUND_CREATE = ROUTE_API_PLAYGROUND+'/create'
export const ROUTE_API_PLAYGROUND_UPDATE = ROUTE_API_PLAYGROUND+'/update'
export const ROUTE_API_PLAYGROUND_DELETE = ROUTE_API_PLAYGROUND+'/delete'
export const ROUTE_API_PLAYGROUND_SEARCH = ROUTE_API_PLAYGROUND+'/search'
export const ROUTE_API_PLAYGROUND_EXAMPLES = ROUTE_API_PLAYGROUND+'/examples'
export const ROUTE_API_PLAYGROUND_MOCK_CONFIGS = ROUTE_API_PLAYGROUND+'/mockconfigs'
export const ROUTE_API_PLAYGROUND_ASYNC = ROUTE_API_PLAYGROUND+'/async'
export const ROUTE_API_PLAYGROUND_BULK = ROUTE_API_PLAYGROUND+'/bulk'

export const INSTANCE_CONFIGURATION_SETTING_HOME_PAGE = "homePage";

export const DATATYPES= ['xsd:ENTITY', 'xsd:ID', 'xsd:IDREF', 'xsd:NCName', 'xsd:NMTOKEN', 'xsd:NOTATION',
    'xsd:Name', 'xsd:QName', 'xsd:anySimpleType', 'xsd:anyURI', 'xsd:base64Binary',
    'xsd:boolean', 'xsd:byte', 'xsd:date', 'xsd:dateTime', 'xsd:decimal', 'xsd:double',
    'xsd:duration', 'xsd:float', 'xsd:gDay', 'xsd:gMonth', 'xsd:gMonthDay', 'xsd:gYear',
    'xsd:gYearMonth', 'xsd:hexBinary', 'xsd:int', 'xsd:integer', 'xsd:language', 'xsd:long',
    'xsd:negativeInteger', 'xsd:nonNegativeInteger', 'xsd:nonPositiveInteger', 'xsd:normalizedString',
    'xsd:positiveInteger', 'xsd:short', 'xsd:string', 'xsd:time', 'xsd:token', 'xsd:unsignedByte',
    'xsd:unsignedInt', 'xsd:unsignedLong', 'xsd:unsignedShort', 'rdf:HTML', 'rdf:langString', 'rdf:PlainLiteral', 'rdf:XMLLiteral']

export const LABEL_LANGUAGE_TAGGED_STRING = 'Language Tagged String';
export const LABEL_STRING = 'String';
export const LABEL_BOOLEAN = 'Boolean';
export const DATATYPE_SUGGESTIONS = [
    {label: LABEL_LANGUAGE_TAGGED_STRING, value: 'http://www.w3.org/1999/02/22-rdf-syntax-ns#langString'},
    {label: LABEL_BOOLEAN, value: 'http://www.w3.org/2001/XMLSchema#boolean'},
    {label: 'Any URI', value: 'http://www.w3.org/2001/XMLSchema#anyURI'},
    {label: 'Byte', value: 'http://www.w3.org/2001/XMLSchema#byte'},
    {label: 'Date', value: 'http://www.w3.org/2001/XMLSchema#date'},
    {label: 'Date/Time', value: 'http://www.w3.org/2001/XMLSchema#dateTime'},
    {label: 'Decimal', value: 'http://www.w3.org/2001/XMLSchema#decimal'},
    {label: 'Double', value: 'http://www.w3.org/2001/XMLSchema#double'},
    {label: 'Duration', value: 'http://www.w3.org/2001/XMLSchema#duration'},
    {label: 'Float', value: 'http://www.w3.org/2001/XMLSchema#float'},
    {label: 'HTML', value: 'http://www.w3.org/1999/02/22-rdf-syntax-ns#HTML'},
    {label: 'Day', value: 'http://www.w3.org/2001/XMLSchema#gDay'},
    {label: 'Month', value: 'http://www.w3.org/2001/XMLSchema#gMonth'},
    {label: 'Month Day', value: 'http://www.w3.org/2001/XMLSchema#gMonthDay'},
    {label: 'Year', value: 'http://www.w3.org/2001/XMLSchema#gYear'},
    {label: 'Year Month', value: 'http://www.w3.org/2001/XMLSchema#gYearMonth'},
    {label: 'Hex/Binary', value: 'http://www.w3.org/2001/XMLSchema#hexBinary'},
    {label: 'Integer', value: 'http://www.w3.org/2001/XMLSchema#integer'},
    {label: 'Int', value: 'http://www.w3.org/2001/XMLSchema#int'},
    {label: 'Long', value: 'http://www.w3.org/2001/XMLSchema#long'},
    {label: 'Negative Integer', value: 'http://www.w3.org/2001/XMLSchema#negativeInteger'},
    {label: 'Non Negative Integer', value: 'http://www.w3.org/2001/XMLSchema#nonNegativeInteger'},
    {label: 'Non Positive Integer', value: 'http://www.w3.org/2001/XMLSchema#nonPositiveInteger'},
    {label: 'Normalized String', value: 'http://www.w3.org/2001/XMLSchema#normalizedString'},
    {label: 'Positive Integer', value: 'http://www.w3.org/2001/XMLSchema#positiveInteger'},
    {label: 'Short', value: 'http://www.w3.org/2001/XMLSchema#short'},
    {label: LABEL_STRING, value: 'http://www.w3.org/2001/XMLSchema#string'},
    {label: 'Time', value: 'http://www.w3.org/2001/XMLSchema#time'},
    {label: 'Token', value: 'http://www.w3.org/2001/XMLSchema#token'},
    {label: 'Unsigned Byte', value: 'http://www.w3.org/2001/XMLSchema#unsignedByte'},
    {label: 'Unsigned Int', value: 'http://www.w3.org/2001/XMLSchema#unsignedInt'},
    {label: 'Unsigned Long', value: 'http://www.w3.org/2001/XMLSchema#unsignedLong'},
    {label: 'Unsigned Short', value: 'http://www.w3.org/2001/XMLSchema#unsignedShort'},
    {label: 'XML Literal', value: 'http://www.w3.org/1999/02/22-rdf-syntax-ns#XMLLiteral'},
].map(suggestion => ({
    value: suggestion.value,
    label: suggestion.label,
    tooltip : suggestion.value
}));

export const ID = 'id'
export const AT_ID = '@id'
export const AT_DEFAULT = '@default'
export const TYPE = 'type'
export const AT_TYPE = '@type'
export const GRAPH = 'graph'
export const AT_GRAPH = '@graph'
export const LANG = 'lang'
export const VALUE = 'value'
export const AT_VALUE = '@value'
export const AT_CONTEXT = '@context'

export const UUID = 'uuid'
export const ALIAS_OWL_CLASS = 'OwlClass'
export const ALIAS_OWL_OBJECT_PROPERTY = 'OwlObjectProperty'
export const ALIAS_OWL_DATATYPE_PROPERTY = 'OwlDatatypeProperty'
export const ALIAS_OWL_ANNOTATION_PROPERTY = 'OwlAnnotationProperty'
export const ALIAS_OWL_INVERSE_OF = 'owlInverseOf'
export const ALIAS_OWL_CARDINALITY = 'owlCardinality'
export const ALIAS_OWL_MIN_CARDINALITY = 'owlMinCardinality'
export const ALIAS_OWL_MAX_CARDINALITY = 'owlMaxCardinality'
export const ALIAS_OWL_ON_PROPERTY = 'owlOnProperty'

export const ALIAS_RDFS_CLASS = 'RdfsClass'
export const ALIAS_RDFS_SUBCLASS_OF = 'rdfsSubClassOf'
export const ALIAS_RDFS_DOMAIN = 'rdfsDomain'
export const ALIAS_RDFS_RANGE = 'rdfsRange'
export const ALIAS_RDFS_LABEL = 'rdfsLabel'
export const ALIAS_RDFS_COMMENT = 'rdfsComment'
export const ALIAS_RDF_PROPERTY = 'rdfProperty'

export const ALIAS_SH_SHAPE = 'ShShape'
export const ALIAS_SH_CLASS = 'shClass'
export const ALIAS_SH_DATATYPE = 'shDatatype'
export const ALIAS_SH_NODE_KIND = 'shNodeKind'
export const ALIAS_SH_PATTERN = 'shPattern'
export const ALIAS_SH_TARGET_CLASS = 'shTargetClass'
export const ALIAS_SH_MIN_COUNT = 'shMinCount'
export const ALIAS_SH_MIN_LENGTH = 'shMinLength'
export const ALIAS_SH_MAX_COUNT = 'shMaxCount'
export const ALIAS_SH_MAX_LENGTH = 'shMaxLength'
export const ALIAS_SH_OR = 'shOR'
export const ALIAS_SH_PROPERTY = 'shProperty'
export const ALIAS_SH_PATH = 'shPath'
export const ALIAS_SH_RESULT = 'shResult'
export const ALIAS_SH_RESULT_PATH = 'shResultPath'
export const ALIAS_SH_RESULT_MESSAGE = 'shResultMessage'
export const ALIAS_SH_VALUE = 'shValue'

export const ALIAS_API_CONFIGURATION = 'EgApiConfiguration'
export const ALIAS_CONTAINER = 'EgClass'
export const ALIAS_SYS_EXTENSION_RULE = 'egRule'
export const ALIAS_SYS_RESULTS = 'egResults'
export const ALIAS_SYS_FACETS = 'egFacets'
export const ALIAS_SYS_FACET_LABEL = 'egFacetLabel'
export const ALIAS_SYS_FACET_RESULTS = 'egFacetResults'
export const ALIAS_SYS_FACET_VALUE = 'egFacetValue'
export const ALIAS_SYS_FACET_VALUE_LABEL = 'egFacetValueLabel'
export const ALIAS_SYS_FACET_VALUE_COUNT = 'egFacetValueCount'
export const ALIAS_SYS_HAS_PATH_TO = 'egIsConnectedTo'
export const ALIAS_SYS_API_ACTIVITY = 'EgApiActivity'
export const ALIAS_SYS_ACTIVITY_CONTEXT = 'egActivityContext'
export const ALIAS_SYS_ALIAS_MAPPING = 'egAliasMapping'
export const ALIAS_SYS_PREFIX_MAPPING = 'egPrefixMapping'
export const ALIAS_SYS_ALIAS = 'egAlias'
export const ALIAS_SYS_CLASS_IRI = 'egClassIRI'
export const ALIAS_SYS_ENABLE_DATA_RESET = 'egEnableDataReset'
export const ALIAS_SYS_ID_LABEL = 'egLabel'
export const ALIAS_SYS_DESCRIPTION = 'egDescription'
export const ALIAS_SYS_MODIFIED_DATE = 'egModifiedDate'
export const ALIAS_SYS_IDENTIFIER = 'egIdentifier'
export const ALIAS_SYS_IRI = 'egIRI'
export const ALIAS_SYS_BASE_IRI = 'egBaseIRI'
export const ALIAS_SYS_BASE_IRI_PREFIX = 'egBaseIRIPrefix'
export const ALIAS_SYS_PREFIX = 'egPrefix'
export const ALIAS_SYS_API_CONFIGURATION = 'EgApiConfiguration'
export const ALIAS_SYS_BOOTSTRAP_COMPLETE = 'egBootstrapComplete'
export const ALIAS_SYS_DATA_DOMAIN_BASE_IRI = 'egDataBaseIRI'
export const ALIAS_SYS_CONFIGURATION_DOMAIN_BASE_IRI = 'egSchemaBaseIRI'
export const ALIAS_SYS_DEFAULT_LANGUAGE = 'egDefaultLanguage'
export const ALIAS_SYS_USERNAME = 'egUsername'
export const ALIAS_SYS_DATASET = 'egDataset'
export const ALIAS_SYS_PASSWORD = 'egPassword'
export const ALIAS_SYS_TOKEN = 'egToken'
export const ALIAS_SYS_ETAG = 'egETag'
export const ALIAS_SYS_HTTP_METHOD = 'egHttpMethod'
export const ALIAS_SYS_INVERSE_PROPERTIES_EXCLUSION = 'egExcludedInverseProperty'
export const ALIAS_SYS_IS_SYSTEM_ALIAS = 'egIsSystemAlias'
export const ALIAS_SYS_IS_CONNECTED_TO = 'egIsConnectedTo'
export const ALIAS_SYS_JSONLD_CONTEXT = 'egJSONLDContext'
export const ALIAS_SYS_MEMBERSHIP_CLASS = 'egMembershipClass'
export const ALIAS_SYS_QUERY = 'egQuery'
export const ALIAS_SYS_GENERATED = 'egGenerated'
export const ALIAS_SYS_USED = 'egUsed'
export const ALIAS_SYS_INVALIDATED = 'egInvalidated'
export const ALIAS_SYS_STARTED_AT_TIME = 'egStartedAtTime'
export const ALIAS_SYS_PROPERTY_TYPE = 'egPropertyType'
export const ALIAS_SYS_RETRIEVABLE_BY = 'egRetrievableBy'
export const ALIAS_SYS_UPDATABLE_BY = 'egUpdatableBy'
export const ALIAS_SYS_DELETABLE_BY = 'egDeletableBy'
export const ALIAS_SYS_CRAETABLE_BY = 'egCreatableBy'
export const ALIAS_SYS_ASSIGNED_TO = 'egAssignedTo'
export const ALIAS_SYS_AUTHORIZATION = 'egAuthorization'
export const ALIAS_SYS_AUTHENTICATION = 'egAuthentication'

export const FROM_SHAPE = 'fromShape'
export const COMBINED_WITH_PROPERTY = 'combinedWithProperty'

export const ALIAS_SYS_POST = 'egPost'
export const ALIAS_SYS_PATCH = 'egPatch'
export const ALIAS_SYS_DELETE = 'egDelete'

export const ALIAS_SYS_HAS_NEXT_PAGE = 'egHasNextPage'
export const ALIAS_SYS_TOTAL_COUNT = 'egTotalCount'
export const ALIAS_SYS_HAS_MORE_TO_COUNT = 'egHasMoreToCount'

export const ALIAS_SYS_EXAMPLE_TYPE_POST = 'EgHttpAPIPost'
export const ALIAS_SYS_EXAMPLE_TYPE_GRAPHQL_POST = 'EgGraphQLPost'
export const ALIAS_SYS_EXAMPLE_TYPE_PATCH = 'EgHttpAPIPatch'

export const ALIAS_SYS_VARIABLE_TYPE_RESPONSE = 'EgResponseVariable'
export const ALIAS_SYS_VARIABLE_TYPE_HEADER = 'EgHeaderVariable'

export const ALIAS_SYS_EXAMPLE_TYPE_RESET_DATA = 'EgResetData'
export const ALIAS_SYS_EXAMPLE_TYPE_CREATE = 'EgAPICreate'
export const ALIAS_SYS_EXAMPLE_TYPE_UPDATE = 'EgAPIUpdate'
export const ALIAS_SYS_EXAMPLE_TYPE_DELETE = 'EgAPIDelete'
export const ALIAS_SYS_EXAMPLE_TYPE_SEARCH = 'EgAPISearch'
export const ALIAS_SYS_EXAMPLE_TYPE_BATCH = 'EgAPIBatch'

export const ALIAS_SYS_EXAMPLE_TYPE_COLLECTION = 'EgExampleCollection'
export const ALIAS_SYS_EXAMPLE_TYPE_EXAMPLE_SET = 'EgExampleSet'
export const ALIAS_SYS_EXAMPLE_TYPE_REGEX_MOCKING_SETUP = 'EgRegExMockingSetup'
export const ALIAS_SYS_EXAMPLE_TYPE_VALUES_LIST_MOCKING_SETUP = 'EgValuesListMockingSetup'
export const ALIAS_SYS_EXAMPLE_EXAMPLE_SET = 'egExampleSet'
export const ALIAS_SYS_EXAMPLE_COLLECTION = 'egExampleCollection'
export const ALIAS_SYS_IS_MODEL_RESOURCE_OF = 'egIsModelResourceOf'
export const ALIAS_SYS_METHOD = 'egMethod'
export const ALIAS_SYS_SPARQL_QUERY_TYPE = 'egSPARQLQueryType'
export const ALIAS_SYS_PATH = 'egPath'
export const ALIAS_SYS_EXECUTABLE_BY = 'egExecutableBy'

export const ALIAS_SYS_TYPE_SCHEMA = 'EgSchema'
export const ALIAS_SYS_TYPE_PERMISSION = 'EgPermission'
export const ALIAS_SYS_TYPE_OPERATION_BASED_PERMISSION = 'EgOperationBasedPermission'
export const ALIAS_SYS_TYPE_CLASS_BASED_PERMISSION = 'EgClassBasedPermission'
export const ALIAS_SYS_TYPE_ENDPOINT_BASED_PERMISSION = 'EgEndpointBasedPermission'
export const ALIAS_SYS_TYPE_USER = 'EgUser'
export const ALIAS_SYS_TYPE_ROLE = 'EgRole'

export const ALIAS_ACCOUNT_TYPE_TRIAL_SUBSCRIPTION = 'TrialSubscription'
export const ALIAS_ACCOUNT_TYPE_DEVELOPER_SUBSCRIPTION = 'DeveloperSubscription'
export const ALIAS_ACCOUNT_TYPE_ENTERPRISE_SUBSCRIPTION = 'EnterpriseSubscription'
export const ALIAS_ACCOUNT_TYPE_STANDARD_SUBSCRIPTION = 'StandardSubscription'
export const ALIAS_ACCOUNT_TYPE_USER = 'User'
export const ALIAS_ACCOUNT_SINGLE_SIGN_ON_ID = 'singleSignOnId'
export const ALIAS_ACCOUNT_ID_LABEL = 'label'
export const ALIAS_ACCOUNT_REGION = 'region'
export const ALIAS_ACCOUNT_PATH = 'path'
export const ALIAS_ACCOUNT_DESCRIPTION = 'description'
export const ALIAS_ACCOUNT_BASE_URL = 'baseURL'
export const ALIAS_ACCOUNT_FIRSTNAME = 'firstName'
export const ALIAS_ACCOUNT_LASTNAME = 'lastName'
export const ALIAS_ACCOUNT_CONFIRMATION_CODE = 'confirmationCode'
export const ALIAS_ACCOUNT_EMAIL = 'email'
export const ALIAS_ACCOUNT_USERNAME = 'username'
export const ALIAS_ACCOUNT_PASSWORD = 'password'
export const ALIAS_ACCOUNT_NEW_PASSWORD = 'newPassword'
export const ALIAS_ACCOUNT_TOKEN = 'token'

export const IRI_ACCOUNT_USERNAME = EASYGRAPH_ACCOUNT_NAMESPACE+'username';
export const IRI_ACCOUNT_FIRSTNAME = EASYGRAPH_ACCOUNT_NAMESPACE+'firstName';
export const IRI_ACCOUNT_LASTNAME = EASYGRAPH_ACCOUNT_NAMESPACE+'lastName';
export const IRI_ACCOUNT_EMAIL = EASYGRAPH_ACCOUNT_NAMESPACE+'email';
export const IRI_ACCOUNT_PASSWORD = EASYGRAPH_ACCOUNT_NAMESPACE+'password';
export const IRI_ACCOUNT_LABEL = EASYGRAPH_ACCOUNT_NAMESPACE+'label';
export const IRI_ACCOUNT_DESCRIPTION = EASYGRAPH_ACCOUNT_NAMESPACE+'description';
export const IRI_TYPE_FEEDBACK = 'https://easygraph.graphifi.com/schema/feedback/Feedback';
export const IRI_FEEDBACK_DATE_CREATED = 'https://easygraph.graphifi.com/schema/feedback/dateCreated';
export const IRI_FEEDBACK_SUBJECT = 'https://easygraph.graphifi.com/schema/feedback/subject';
export const IRI_FEEDBACK_PREDICATE = 'https://easygraph.graphifi.com/schema/feedback/predicate';
export const IRI_FEEDBACK_OBJECT = 'https://easygraph.graphifi.com/schema/feedback/object';
export const IRI_FEEDBACK_DISPLAY_NAME = 'https://easygraph.graphifi.com/schema/feedback/displayName';
export const IRI_FEEDBACK_COMMENT = 'https://easygraph.graphifi.com/schema/feedback/comment';
export const IRI_FEEDBACK_CREATED_BY = 'https://easygraph.graphifi.com/schema/feedback/createdBy';

export const ALIAS_MANAGEMENT_TYPE_DATASET = 'Dataset'
export const ALIAS_MANAGEMENT_TYPE_USER = 'User'
export const ALIAS_MANAGEMENT_TYPE_PUBLIC_KEY = 'PublicKey'
export const ALIAS_MANAGEMENT_TYPE_INSTANCE_CONFIGURATION = 'InstanceConfiguration'
export const ALIAS_MANAGEMENT_TYPE_CONFIGURATION = 'Configuration'
export const ALIAS_MANAGEMENT_TYPE_SESSION = 'Session'
export const ALIAS_MANAGEMENT_TYPE_TRAINING_SET = 'TrainingSet'
export const ALIAS_MANAGEMENT_TYPE_AI_MODEL = 'AIModel'
export const ALIAS_MANAGEMENT_TYPE_OBJECT = 'Object'
export const IRI_MANAGEMENT_INSTANCE_CONFIGURATION = EASYGRAPH_MANAGEMENT_NAMESPACE+'InstanceConfiguration'

export const ALIAS_WS_MESSAGE_TYPE_SHEET_CELL_CHANGE = 'SheetCellChange'
export const ALIAS_WS_MESSAGE_TYPE_USER_CONNECTED = 'UserConnected'
export const ALIAS_WS_MESSAGE_TYPE_USER_DISCONNECTED = 'UserDisconnected'

export const ALIAS_MANAGEMENT_USERNAME = 'username'
export const ALIAS_MANAGEMENT_PASSWORD = 'password'
export const ALIAS_MANAGEMENT_NEW_PASSWORD = 'newPassword'
export const ALIAS_MANAGEMENT_TOKEN = 'token'
export const ALIAS_MANAGEMENT_TITLE = 'title'
export const ALIAS_MANAGEMENT_SETTINGS = 'settings'
export const ALIAS_MANAGEMENT_ID_LABEL = 'label'
export const ALIAS_MANAGEMENT_NAME = 'name'
export const ALIAS_MANAGEMENT_DESCRIPTION = 'description'
export const ALIAS_MANAGEMENT_IS_OWNED_BY = 'isOwnedBy'
export const ALIAS_MANAGEMENT_KEY_VALUE = 'keyValue'
export const ALIAS_MANAGEMENT_STATUS = 'status'
export const ALIAS_MANAGEMENT_HAS_ROLE = 'hasRole'
export const ALIAS_MANAGEMENT_CAN_VIEW = 'canView'
export const ALIAS_MANAGEMENT_FOR_APPLICATION = 'forApplication'
export const ALIAS_MANAGEMENT_LINKED_TO = 'linkedTo'
export const ALIAS_MANAGEMENT_FILE_NAME = 'fileName'
export const ALIAS_MANAGEMENT_HAS_PART = 'hasPart'
export const ALIAS_MANAGEMENT_IS_PART_OF = 'isPartOf'

export const IRI_MANAGEMENT_LABEL = EASYGRAPH_MANAGEMENT_NAMESPACE+'label';
export const IRI_MANAGEMENT_DESCRIPTION = EASYGRAPH_MANAGEMENT_NAMESPACE+'description';
export const IRI_MANAGEMENT_USERNAME = EASYGRAPH_MANAGEMENT_NAMESPACE+'username';
export const IRI_MANAGEMENT_PASSWORD = EASYGRAPH_MANAGEMENT_NAMESPACE+'password';


export const ALIAS_SYS_API_CONFIG_IRI = 'apiConfigIRI'

export const SYSTEM_DEFINED_PREFIXES = {
    owl : 'http://www.w3.org/2002/07/owl#',
    sh : 'http://www.w3.org/ns/shacl#',
    rdfs : 'http://www.w3.org/2000/01/rdf-schema#',
    rdf : 'http://www.w3.org/1999/02/22-rdf-syntax-ns#',
    eg: EASYGRAPH_NAMESPACE,
    skos: 'http://www.w3.org/2004/02/skos/core#',
    skosxl: 'http://www.w3.org/2008/05/skos-xl#',
    xsd: 'http://www.w3.org/2001/XMLSchema#',
    egf: 'https://easygraph.graphifi.com/schema/feedback/'
}



export const DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
export const DATETIME_FORMAT_CONTROL = "yyyy-MM-dd HH:mm:ss.SSS"

export const DATE_FORMAT = "yyyy-MM-dd"
export const DATE_FORMAT_CONTROL = "yyyy-MM-dd"

export const TIME_FORMAT = "HH:mm:ss"
export const TIME_FORMAT_CONTROL = "HH:mm:ss"

export const MAIN_CONFIGURATION = 'Basics'
export const LOAD_MODEL = 'Load Model'
export const CONFIGURE_PREFIXES = 'Prefixes'
export const CONFIGURE_ALIASES = 'Aliases'
export const PREVIEW_SHAPES = 'Preview Shapes'
export const CONFIGURE_CONTAINERS = 'ID Setup'
export const PREFIXES = 'Prefixes'
export const INVERSE_PROPERTIES = 'Inverses'
export const PREVIEW_JSONLD_CONTEXT = 'Preview JSON-LD Context'
export const SYSTEM_MANAGER = 'System Manager'

export const HOME = 'Home'
export const SHAPES = 'Shapes'
export const CLASSES = 'Classes'
export const ONTOLOGY = 'Ontology'
export const RULES = 'Rules'
export const USERS = 'Users'
export const ROLES = 'Roles'
export const SECURITY = 'Security'
export const AUTHORISATION = 'Authorisation'
export const AUTHENTICATION = 'Authentication'
export const IMPORT = 'Import'
export const EXPORT = 'Export'

export const HTTP_CREATE = 'Create'
export const HTTP_UPDATE = 'Update'
export const HTTP_DELETE = 'Delete'
export const HTTP_SEARCH = 'Search'
export const HTTP_BULK = 'Bulk'
export const HTTP_ASYNC = 'Async'


export const VIEW_MIDDLE_CARDS = 'viewCards';
export const VIEW_MIDDLE_JSONLD = 'viewJSONLD';

export const MESSAGE_ONTOLOGY_LOAD = 'A maximum of ten files are allowed and each file should be less than 2MB.'
export const MESSAGE_INVERSE = 'To enable materialisation of inverse properties, use the switches on items in the list on the left.'
export const MESSAGE_JSONLD = 'This is autogenerated JSON-LD context file.'
export const MESSAGE_SELECT_TO_VIEW_DETAILS = 'To view details select an item on the left.'

export const LABEL_SUBCLASSES = 'Subclasses'
export const LABEL_SUPERCLASSES = 'Is Subclass Of'
export const LABEL_MEMBERSHIP_CLASS = 'Membership Class';
export const LABEL_LABEL = 'Label';
export const LABEL_USERNAME = 'Username';
export const LABEL_FIRSTNAME = 'First Name';
export const LABEL_LASTNAME = 'Last Name';
export const LABEL_EMAIL = 'Email';
export const LABEL_DISABLE = 'Disable';
export const LABEL_IS_SUPER_ADMIN = 'Is Super Admin';
export const LABEL_PASSWORD = 'Password';
export const LABEL_CURRENT_PASSWORD = 'Current Password';
export const LABEL_NEW_PASSWORD = 'New Password';
export const LABEL_CONFIRM_PASSWORD = 'Confirm Password';
export const LABEL_CONFIRM_NEW_PASSWORD = 'Confirm New Password';
export const LABEL_RUN_ON = 'Run On';
export const LABEL_SPARQL_CONSTRUCT_QUERY = 'SPARQL Construct Query';
export const LABEL_PROPERTY_TYPE = 'Property Type';
export const LABEL_INVERSE_OF = 'Inverse Of';
export const LABEL_INHERITED_FROM = 'INHERITED FROM';
export const LABEL_INVERSE_OF_CAPS = 'INVERSE OF';
export const LABEL_PROPERTY_TYPE_CAPS = 'PROPERTY TYPE';
export const LABEL_SUBCLASS_OF = 'Subclass Of';

export const LABEL_NAME = 'Name';
export const LABEL_DESCRIPTION = 'Description';
export const LABEL_PUBLIC_KEY = 'Public Key';
export const LABEL_UPDATE_DATE = 'Updated On';
export const LABEL_PREFIX = 'Prefix';
export const LABEL_BASE_IRI = 'Base IRI';
export const LABEL_BASE_IRI_FOR_DATA = 'Base IRI for Data';
export const LABEL_BASE_IRI_FOR_SCHEMA = 'Base IRI for Schema';
export const LABEL_DEFAULT_LANGUAGE = 'Default Language';
export const LABEL_DATA_RESET = 'Enable Data Reset';
export const LABEL_DATA_RESET_TYPE_YES = LABEL_DATA_RESET  + ' (Type "YES")';
export const LABEL_IRI = 'IRI';
export const LABEL_TARGET_CLASS = 'Target Class';
export const LABEL_PATH = 'Path';
export const LABEL_CLASS = "Class";
export const LABEL_CLASS_FOR_RDF_LIST = "Class for RDF List";

export const LABEL_FULL_CONFIGURATION = 'Full configuration';
export const LABEL_DATA_TYPE = 'Data Type';
export const LABEL_NODE_KIND = 'Node Kind';
export const LABEL_OBJECT = 'Object';
export const LABEL_OBJECT_LINKING = 'Object Linking';
export const LABEL_LIST = 'List';
export const LABEL_MIN_COUNT = 'Min Count';
export const LABEL_MAX_COUNT = 'Max Count';
export const LABEL_MIN_LENGTH = 'Min Length';
export const LABEL_MAX_LENGTH = 'Max Length';
export const LABEL_PATTERN = 'Pattern';
export const LABEL_REGEX_PATTERN = 'Regex Pattern';
export const LABEL_OBJECT_TYPE = 'Object Type';
export const LABEL_TYPE_FOR_LIST = 'Type for List';
export const LABEL_SELECTED_FILES = 'Selected Files';

export const LABEL_SCHEMA_DASHBOARD = "Schema Dashboard";
export const LABEL_IMPORT_SCHEMA = "Import Schema";
export const LABEL_EXPORT_SCHEMA = "Export Schema";
export const LABEL_FROM_ONTOLOGY = "From Ontology";
export const LABEL_FROM_SYSTEM = "From System";
export const LABEL_ID_SETUP_READ_ONLY = "Clear All (Read Only API)";
export const LABEL_ID_SETUP_LOWEST_CLASSES = "Check All Lowest Classes";
export const LABEL_PLAYGROUND_DASHBOARD = "Playground Dashboard";
export const LABEL_IMPORT_WORKSPACE = "Import Workspace";
export const LABEL_EXPORT_WORKSPACE = "Export Workspace";
export const LABEL_AUTOGENERATE_WORKSPACE = "Autogenerate Workspace";
export const LABEL_IMPORT_SITE = "Import Site";

export const LABEL_HOME = "Home";
export const LABEL_ACCOUNT = "Account";
export const LABEL_MONITORING = "Monitoring";
export const LABEL_APPLICATIONS = 'Applications';
export const LABEL_MANAGE_DATASETS = 'Manage Datasets';
export const LABEL_USER_MANAGEMENT = 'User Management';
export const LABEL_PROFILE = "Profile";

export const LABEL_OPERATION_BASED_AUTHORISATION = "Operation Based Authorisation";
export const LABEL_CLASS_BASED_AUTHORISATION = "Class Based Authorisation";
export const LABEL_ENDPOINT_AUTHORISATION = "Endpoint Based Authorisation";
export const SPARQL_ENDPOINT_AUTHORISATION = "SPARQL Endpoint Authorisation";
export const LABEL_OTP = "OTP";


export const LABEL_ENABLE_MULTILINGUAL_SITE_BROWSE = 'Enable multilingual site browse';
export const LABEL_ENABLE_MULTILINGUAL_UI = 'Enable multilingual UI configuration';



export const LOAD_YOUR_ONTOLOGY = 'Load Your Ontology';
export const LOAD_SKOS_ONTOLOGY = 'SKOS';
export const LOAD_SKOSXL_ONTOLOGY = 'SKOS-XL';
export const LOAD_GRAPHOLOGI_ONTOLOGY = 'Graphologi Model (includes SKOS, SKOS-XL)';
export const LOAD_DUBLIN_CORE_ONTOLOGY = 'Dublin Core';
export const LOAD_TEST_ONTOLOGY = 'Example Ontology of Organisation, Person and Image';

export const DATATEST_loadYourOntology = 'loadYourOntology';
export const DATATEST_loadTestOntology = 'loadTestOntology';
export const DATATEST_loadGraphologiOntology = 'loadGraphologiOntology';
export const DATATEST_loadSKOSOntology = 'loadSKOSOntology';
export const DATATEST_loadSKOSXLOntology = 'loadSKOSXLOntology';
export const DATATEST_loadDCOntology = 'loadDCOntology';
export const DATATEST_viewInheritedPropertiesSwitch = 'viewInheritedPropertiesSwitch';

export const DATATEST_listViewButton = 'listViewButton';
export const DATATEST_treeViewButton = 'treeViewButton';
export const DATATEST_graphViewButton = 'graphViewButton';

export const MIXIN = 'data';
export const DATA = 'dataFilter';
export const CBD = 'none';
export const ETAG_VALUE_PAGE = 'page';
export const SORT_ASCENDING = 'ascending';
export const SORT_DESCENDING = 'descending';
export const PATHS = 'paths';
export const MIXIN_DEFAULT = 'default';

export const EQUAL_TO = 'eq';
export const NOT_EQUAL_TO = 'neq';
export const LESS_THAN = 'lt';
export const LESS_THAN_EQUAL_TO = 'lte';
export const GREATER_THAN = 'gt';
export const GREATER_THAN_EQUAL_TO = 'gte';
export const OBJECT = 'Matches';
export const OBJECT_INVERSE = 'Incoming Match';
export const BETWEEN = 'Between';
export const TEXT = 'q';
export const EXISTS = 'Exists';
export const ORDER_PRECEDENCE = 'orderPrecedence';
export const ORDER_NO_VALUE = 'orderNoValue';
export const ORDER_NO_VALUE_LAST = 'last';
export const ORDER_NO_VALUE_FIRST = 'first';
export const ORDER_LANG = 'lang';
export const LABEL_PROPERTY = 'labelProperty';
export const ORDER = 'order';
export const SORT_BY = 'sortBy';
export const ASYNC = 'async';
export const ACTIVITY_CONTEXT = 'activityContext';
export const VALUE_END_KEY = 'valueEnd';

export const COUNT_UPTO = 'countUpto';
export const PARAM_LANG = 'lang';
export const LABEL_PROPERTY_LANG = 'labelPropertyLang';
export const PAGE = 'page';
export const PAGE_SIZE = 'pageSize';
export const FACET = 'facet';
export const QUERY = 'query';


export const VALIDATION_ONTOLOGY_FILE_MAX_COUNT = 10;
export const VALIDATION_DATA_IMPORT_FILE_MAX_COUNT = 5;
export const VALIDATION_EXAMPLE_SET_FILE_MAX_COUNT = 1;
export const VALIDATION_MODEL_FILE_MAX_COUNT = 1;
export const VALIDATION_SITE_FILE_MAX_COUNT = 1;
export const VALIDATION_SCENARIO_FILE_MAX_COUNT = 20;
export const VALIDATION_BASE_IRI_MAX_LENGTH = 200;
export const VALIDATION_FEEDBACK_MAX_LENGTH = 1000;
export const VALIDATION_PREFIX_MAX_LENGTH = 12;
export const VALIDATION_LABEL_LENGTH = 100;
export const VALIDATION_USERNAME_LENGTH = 100;
export const VALIDATION_FIRSTNAME_LENGTH = 100;
export const VALIDATION_LASTNAME_LENGTH = 100;
export const VALIDATION_EMAIL_LENGTH = 100;
export const VALIDATION_PASSWORD_LENGTH = 100;
export const VALIDATION_PASSWORD_MIN_LENGTH = 8;
export const VALIDATION_REGEX_LENGTH = 200;
export const VALIDATION_LANGUAGE_NAME_LENGTH = 100;
export const VALIDATION_LANGUAGE_CODE_LENGTH = 20;
export const VALIDATION_DESCRIPTION_LENGTH = 500;
export const VALIDATION_PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%^*?&])[A-Za-z\d@$#!%^*?&]{8,}$/;
export const VALIDATION_BASE_IRI_REGEX = /http[s]?:\/\/[^ "#\/]+\.[a-z]{2,6}\b[-a-zA-Z0-9@:%_\+.~//=]*\/$/;
export const VALIDATION_URL = /http[s]?:\/\/[^ "#\/]+\.[a-z]{2,6}\b[-a-zA-Z0-9@:%_\+.~//=]*/;
export const VALIDATION_PREFIX_REGEX = /^[a-z][a-z0-9]*$/;
export const VALIDATION_ALIAS_NAME_REGEX = /^[_A-Za-z][_0-9A-Za-z]*$/;
export const VALIDATION_COMMON_LABEL_REGEX = /^[a-zA-Z0-9_]+$/;
export const VALIDATION_DATASET_LABEL_REGEX = /^[a-zA-Z0-9_-]+$/;
export const VALIDATION_BCP47_LANGUAGE_CODE = /^((?<grandfathered>(en-GB-oed|i-ami|i-bnn|i-default|i-enochian|i-hak|i-klingon|i-lux|i-mingo|i-navajo|i-pwn|i-tao|i-tay|i-tsu|sgn-BE-FR|sgn-BE-NL|sgn-CH-DE)|(art-lojban|cel-gaulish|no-bok|no-nyn|zh-guoyu|zh-hakka|zh-min|zh-min-nan|zh-xiang))|((?<language>([A-Za-z]{2,3}(-(?<extlang>[A-Za-z]{3}(-[A-Za-z]{3}){0,2}))?)|[A-Za-z]{4}|[A-Za-z]{5,8})(-(?<script>[A-Za-z]{4}))?(-(?<region>[A-Za-z]{2}|[0-9]{3}))?(-(?<variant>[A-Za-z0-9]{5,8}|[0-9][A-Za-z0-9]{3}))*(-(?<extension>[0-9A-WY-Za-wy-z](-[A-Za-z0-9]{2,8})+))*(-(?<privateUse>x(-[A-Za-z0-9]{1,8})+))?)|(?<privateUse1>x(-[A-Za-z0-9]{1,8})+))$/;
export const VALIDATION_INVALID_IRI_CHARACTERS_REGEX =  /^([A-Za-z][A-Za-z0-9+-.]*):[^ "<>{}|\\\[\]`]+$/;

export const VALIDATION_MESSAGE_INVALID_QUERY='Invalid query.';
export const VALIDATION_MESSAGE_REQUIRED_FIELD='is a required field.';
export const VALIDATION_PUBLIC_KEY_HEADER="Missing header. Key should start with '-----BEGIN PUBLIC KEY-----'";
export const VALIDATION_PUBLIC_KEY_FOOTER="Missing footer. Key should end with '-----END PUBLIC KEY-----'";
export const VALIDATION_MESSAGE_INVALID_BASE_IRI='Invalid base IRI. Format should be http(s)://{domain}{path}/';
export const VALIDATION_MESSAGE_INVALID_BASE_IRI_PROTOCOL='Should start with http or https.';
export const VALIDATION_MESSAGE_INVALID_BASE_IRI_SLASH='Should end with /.';
export const VALIDATION_MESSAGE_HASH_IN_BASE_IRI='Base IRI with # not supported. Please use / instead of #.';
export const VALIDATION_MESSAGE_YES_OR_EMPTY='Please type YES or leave empty.';
export const VALIDATION_MESSAGE_INVALID_ALIAS_NAME = 'Invalid value. Only alphanumeric characters and underscore are permitted. The first character cannot be a number.';
export const VALIDATION_MESSAGE_INVALID_COMMON_LABEL = 'Invalid value. Only alphanumeric characters and underscore are permitted.';
export const VALIDATION_MESSAGE_EMPTY_STRING = 'Invalid value.';
export const VALIDATION_MESSAGE_NONZERO_NUMBER = 'Please enter a number greater than zero.';
export const VALIDATION_MESSAGE_NUMBER = 'Please enter 0 or a number greater than zero.';
export const VALIDATION_MIN_LESS_THAN_MAX_COUNT = 'Min Count should be less than or equal to Max Count.';
export const VALIDATION_MAX_GREATER_THAN_MIN_COUNT = 'Max Count should be greater than or equal to Min Count.';
export const VALIDATION_MIN_LESS_THAN_MAX_LENGTH = 'Min Length should be less than or equal to Max Length.';
export const VALIDATION_MAX_GREATER_THAN_MIN_LENGTH = 'Max Length should be greater than or equal to Min Length.';

export const VALIDATION_CLASS_NAME_EXISTS = 'A class with the same name already exists.';
export const VALIDATION_PROPERTY_NAME_EXISTS = 'A property with the same name already exists.';
export const VALIDATION_RULE_NAME_EXISTS = 'Rule with same label already exists.';
export const VALIDATION_RULE_USERNAME_EXISTS = 'User with same username already exists.';
export const VALIDATION_PASSWORD = 'Minimum 8 characters including uppercase, lowercase, a number and a special character from @$#!%^*?&';

export const VALIDATION_INCORRECT_USERNAME_PASSWORD = 'Username or password is incorrect.';

export const MIN_MAX_LENGTH = 8;
export const MAX_VALUE = 2147483648;

export const STYLE_MAIN_SPACING = 8;
export const STYLE_GRID_ITEM_SPACING = 3;


export const BASE64_REGEX = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

export const DATATYPE_LITERAL_VALIDATION_REGEX = /"(.*)"\^\^<(.+)>/;
export const LANG_LITERAL_VALIDATION_REGEX = /"(.*)"@(.+)/;
export const BOOLEAN_REQUIRED_REGEX = /true|false/;
export const BOOLEAN_OPTIONAL_REGEX = /(true|false)?/;
export const RANDOM_LITERAL_REGEX = /"(Hello)"@en|"(Bonjour)"@fr|"(Hola)"@es|("\d\d"\^\^<http:\/\/www\.w3\.org\/2001\/XMLSchema#(integer|short|int|positiveInteger|long|byte)>)|("(((19[7-9][1-9]|20[0-3][0-7])-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|30))|(201[26]-02-29))T((0[1-9]|1[0-9]|2[0-3])(:[0-5]\d){2})"\^\^<http:\/\/www\.w3\.org\/2001\/XMLSchema#dateTime>)/;
export const G_DAY_REGEX = /---((0[1-9])|([1-2][0-9])|(3[0-1]))/;
export const G_MONTH_REGEX = /--((0[1-9])|(0[1-9])|(1[0-2]))/;
export const G_YEAR_REGEX = /(([0-9])|([0-9])|([0-9])|([1-9])){4}/;
export const G_MONTH_DAY_REGEX = /--((0[1-9])|(0[1-9])|(1[0-2]))-((0[1-9])|([1-2][0-9])|(3[0-1]))/;
export const G_YEAR_MONTH_REGEX = /(([0-9])|([0-9])|([0-9])|([1-9])){4}-((0[1-9])|(0[1-9])|(1[0-2]))/;
export const REGEX_FOR_DATATYPES = {
    [RDF_LANGSTRING] : /"(Hello (world|test|easygraph))"@en|"(Bonjour (tester|monde))"@fr|"(Hola (mundo| prueba))"@es/,
    [XSD_STRING] : /What is the colour of your (car|bike|shirt|hat|cap)\?/,
    [XSD_DECIMAL] : /[-+]?[0-9]{0,16}(\.[0-9]{1,6})?/,
    [XSD_FLOAT] : /[-+]?[0-9]{0,16}(\.[0-9]{1,6})?/,
    [XSD_DOUBLE] : /[-+]?[0-9]{0,16}(\.[0-9]{1,6})?/,
    [XSD_BYTE] : /((-128)|(0)|([-+]?[0-1][0-2][0-7]))/,
    [XSD_DURATION] : /P(([1-9]{1,3})Y)?([1-9]{1,2}M)?([1-9]{1,2}D)?T([1-9]{1,2}H)?([1-9]{1,2}M)?([1-9]{1,2}S)/,
    [XSD_INTEGER] : /[-+]?[0-9]{1,16}/,
    [XSD_INT] : /(-2147483648)|(2147483647)|0|([-+]?[0-9]{1,6})/,
    [XSD_SHORT] : /(-32768)|(32767)|0|([-+]?[0-9]{1,4})/,
    [XSD_LONG] : /[-+]?[0-9]{12,18}/,
    [XSD_NEGATIVE_INTEGER] : /-[1-9]{1,16}/,
    [XSD_NON_NEGATIVE_INTEGER] : /([0-9]{0,16}|0)/,
    [XSD_NON_POSITIVE_INTEGER] : /(-[1-9]{0,16}|0)/,
    [XSD_POSITIVE_INTEGER] : /([1-9]{1,16})/,
    [XSD_UNSIGNED_BYTE] : /((255)|(0)|([0-1][0-9][0-9])|([0-2][0-2][0-5]))/,
    [XSD_UNSIGNED_INT] : /((4294967295)|(0)|[0-9]{1,8})/,
    [XSD_UNSIGNED_SHORT] : /((65535)|(0)|[0-9]{1,4})/,
    [XSD_UNSIGNED_LONG] : /\+?[0-9]{12,18}/,
    [XSD_G_DAY] : G_DAY_REGEX,
    [XSD_G_MONTH]: G_MONTH_REGEX,
    [XSD_G_YEAR] : G_YEAR_REGEX,
    [XSD_G_YEAR_MONTH] : G_YEAR_MONTH_REGEX,
    [XSD_G_MONTH_DAY] : G_MONTH_DAY_REGEX,
    [XSD_DATETIME]: /((((19[7-9][1-9]|20[0-3][0-7])-(0[1]|0[3-9]|1[012])-(0[1-9]|[12][0-9]|30))|(201[26]-02-29))T((1[0-9]|0[1-9])(:[0-5]\d){2}\.([0-9]){3}Z))/,
    [XSD_DATE]: /((19[7-9][1-9]|20[0-3][0-7])-(0[1]|0[3-9]|1[012])-(0[1-9]|[12][0-9]|30))|(201[26]-02-29)/,
    [XSD_TIME]: /(1[0-2]|0[1-9])(:[0-5]\d){2}/,
    [XSD_ANY_URI]: /(http|https):\/\/[a-z]{4,7}\.(com|org|net|gov)\/anyuri/,
    [XSD_LANGUAGE]: /(en|pa|fr|es|de)/,
}


const ADD_RESET_REQUEST = 'Add Reset Data Request';
const ADD_CREATE_REQUEST = 'Add Create Request';
const ADD_SEARCH_REQUEST = 'Add Search Request';
const ADD_UPDATE_REQUEST = 'Add Update Request';
const ADD_DELETE_REQUEST = 'Add Delete Request';
const ADD_BATCH_REQUEST = 'Add Batch Request';

export const EXAMPLE_TYPE_TO_MENU_LABEL = {
    [ALIAS_SYS_EXAMPLE_TYPE_RESET_DATA] : ADD_RESET_REQUEST,
    [ALIAS_SYS_EXAMPLE_TYPE_CREATE] : ADD_CREATE_REQUEST,
    [ALIAS_SYS_EXAMPLE_TYPE_SEARCH] : ADD_SEARCH_REQUEST,
    [ALIAS_SYS_EXAMPLE_TYPE_UPDATE] : ADD_UPDATE_REQUEST,
    [ALIAS_SYS_EXAMPLE_TYPE_DELETE] : ADD_DELETE_REQUEST,
    [ALIAS_SYS_EXAMPLE_TYPE_BATCH] : ADD_BATCH_REQUEST,
}

export const EXAMPLE_TYPE_TO_COLOR = {
    [ALIAS_SYS_EXAMPLE_TYPE_RESET_DATA] : '#bf5337',
    [ALIAS_SYS_EXAMPLE_TYPE_CREATE] : '#1976d2',
    [ALIAS_SYS_EXAMPLE_TYPE_SEARCH] : '#3b3b3b',
    [ALIAS_SYS_EXAMPLE_TYPE_UPDATE] : '#2b942b',
    [ALIAS_SYS_EXAMPLE_TYPE_DELETE] : '#bc2d2d',
    [ALIAS_SYS_EXAMPLE_TYPE_BATCH] : '#6A0DAD',
}

export const EXAMPLE_TYPE_TO_TITLE = {
    [ALIAS_SYS_EXAMPLE_TYPE_RESET_DATA] : 'RESET',
    [ALIAS_SYS_EXAMPLE_TYPE_CREATE] : 'CREATE',
    [ALIAS_SYS_EXAMPLE_TYPE_SEARCH] : 'SEARCH',
    [ALIAS_SYS_EXAMPLE_TYPE_UPDATE] : 'UPDATE',
    [ALIAS_SYS_EXAMPLE_TYPE_DELETE] : 'DELETE',
    [ALIAS_SYS_EXAMPLE_TYPE_BATCH] : 'BATCH',
}


export const RENDER_MODE_SITE = "SITE";

export const VARIABLE_DATA_CONTEXT_URL = "{{DATA_CONTEXT_URL}}";

export const UPDATE_EVENT_LISTENER_REGISTER = 'UPDATE_EVENT_LISTENER_REGISTER';
export const UPDATE_EVENT_PUBLISHER = 'UPDATE_EVENT_PUBLISHER';
export const EVENT_TYPE_RESOURCE_REFRESH = 'EVENT_TYPE_RESOURCE_REFRESH';
export const EVENT_TYPE_RESOURCE_DELETE = 'EVENT_TYPE_RESOURCE_DELETE';
export const EVENT_TYPE_RESOURCE_PATCH = 'EVENT_TYPE_RESOURCE_PATCH';
export const EVENT_TYPE_NODE_IN_EDIT_STATE = 'EVENT_TYPE_NODE_IN_EDIT_STATE';


export const EVENT_SHACL_VALIDATE = 'EVENT_SHACL_VALIDATE';


export const IRI_CLASS_SKOSXL_ALT_LABEL = "http://www.w3.org/2008/05/skos-xl#altLabel";
export const IRI_CLASS_SKOSXL_PREF_LABEL = "http://www.w3.org/2008/05/skos-xl#prefLabel";
export const IRI_CLASS_SKOSXL_HIDDEN_LABEL = "http://www.w3.org/2008/05/skos-xl#hiddenLabel";
