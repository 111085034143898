import React from 'react';
import PropTypes from 'prop-types';
import {Paper, TextField as InternalTextField, Tooltip, Typography} from "@material-ui/core";
import Label from "./Label";
import {withStyles} from '@material-ui/core/styles';
import ErrorMessage from "./ErrorMessage";
import LockIcon from '@material-ui/icons/Lock';

export const CustomLockIcon = ({theme}) => <LockIcon datatest={'readOnly'} style={{color: theme.palette.grey.level2}} fontSize={"small"}/>;

const styles = () => ({
});

function isReadOnly(readOnly) {
    return readOnly && readOnly === true;
}

function TextField(props) {
    const { datatest, innerHelperText, innerTextLabel, placeHolder, autoFocus, rows, multiline, readOnly, maxLength, tooltip , theme } = props;
    const {innerHelperComponent, variant, fullWidth, margin, label, labelHelperText, error, helperText, disableErrorRendering, InputProps, inputProps, paperStyle, ...noLabel } = props;
    let InputPropsComputed = InputProps ? InputProps : {
        readOnly: isReadOnly(readOnly) ? true : false,
        endAdornment: isReadOnly(readOnly) ?  <CustomLockIcon theme={theme}/> : undefined,
    }
    let extraMarginOnLeft = 4
    let renderError = disableErrorRendering === true ? false : true
    let paperStyleMerged = {
        padding: theme.spacing(1) + extraMarginOnLeft,
        backgroundColor: theme.palette.border.main,
        ...paperStyle
    }
    let internalTextField = <InternalTextField
        autoFocus={autoFocus}
        rows={rows}
        multiline={multiline}
        error={!!error}
        variant={"outlined"}
        label={innerTextLabel}
        fullWidth={true}
        margin={"dense"}
        placeholder={placeHolder}
        InputProps={InputPropsComputed}
        {...noLabel}
        inputProps={{
            maxLength : maxLength,
            ...inputProps
        }}
    />

    let text =
        <div datatest={'textField-'+(label || '')}>
        <Paper elevation={0} style={paperStyleMerged}>
            <Label error={error} label={label}></Label>
            {
                labelHelperText && <div style={{marginLeft : extraMarginOnLeft}}>
                    <Typography component={'div'} variant={"caption"}>{labelHelperText}</Typography>
                </div>
            }
            {
                tooltip
                    ? <Tooltip title={tooltip} placement={"bottom-start"}>{internalTextField}</Tooltip>
                    : internalTextField
            }
            {
                innerHelperText && !error &&
                <div style={{marginLeft : extraMarginOnLeft}}>
                    <Typography component={'div'} variant={"caption"}>{innerHelperText}</Typography>
                </div>
            }
            {
                innerHelperComponent && innerHelperComponent()
            }
        </Paper>
            <div style={{marginLeft : extraMarginOnLeft}}>
                {helperText && !error && <ErrorMessage error={helperText}/>}
                {error && renderError && <ErrorMessage error={error}/>}
            </div>
        </div>
    return text;
}

TextField.propTypes = {
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    autoFocus: PropTypes.bool,
    maxLength: PropTypes.number,
    rows: PropTypes.number,
    multiline: PropTypes.bool,
    placeHolder: PropTypes.string,
    datatest: PropTypes.string,
    tooltip: PropTypes.string,
    error: PropTypes.string,
    disableErrorRendering: PropTypes.bool,
    paperStyle: PropTypes.object,
    labelHelperText: PropTypes.string,
    helperText: PropTypes.string,
    innerTextLabel: PropTypes.string,
    innerHelperText: PropTypes.string,
    innerHelperComponent: PropTypes.func,
    InputProps: PropTypes.object,
};

export default withStyles(styles, {withTheme: true})(TextField);

