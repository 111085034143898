import React, {Component} from "react";
import {
    ALIAS_MANAGEMENT_TYPE_TRAINING_SET, ALIAS_SYS_BASE_IRI,
    ALIAS_SYS_RESULTS,
    AT_CONTEXT,
    COUNT_UPTO,
    EASYGRAPH_DATA_NAMESPACE,
    ID,
    PAGE,
    PAGE_SIZE,
    QUERY,
    STYLE_GRID_ITEM_SPACING,
    TYPE
} from "../../Constants";
import {getUserIri} from "../common/Profile";
import {getBaseEndpointWithInstance, getData, getDataPrefixes, getManagementContextURL} from "../../service/graph-api";
import {BACKEND_PATH_MANAGEMENT_GRAPH_SEARCH} from "../../service/backend-paths";
import {
    centerVertically,
    getBaseIRI,
    getGraph,
    getResourceId,
    handleBackendError,
    isRequestSuccessful,
    toArray
} from "../../components/util";
import {
    Dialog,
    DialogContent,
    InputLabel,
    MenuItem,
    TextField as OtherTextField,
    TextField as MuiTextField
} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ListSubheader from "@material-ui/core/ListSubheader";
import {getBaseAIModels} from "../../Configs";
import {Autocomplete} from "@material-ui/lab";
import {getChipWithDelete} from "../apiplayground/SearchFilter";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import uuid4 from "uuid/v4";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import {withRouter} from "react-router-dom";
import {getAllClassesData} from "./DataViewSetup";
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {WarningOutlined} from "@material-ui/icons";
import WarningIcon from "@material-ui/icons/Warning";
import CircularProgress from "@material-ui/core/CircularProgress";

export const TYPE_AI_MODEL = "AIModel";

export function getNewIdForModel(uuid) {
    return EASYGRAPH_DATA_NAMESPACE + 'aimodel/' + uuid;
}

export const TARGET_CLASS_IRIS = 'targetClassIRIs';

export const TARGET_CLASS_BASE_IRIS = 'targetClassBaseIRIs';

class TrainModelDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saveVisualisationTitle : props.title,
            trainingSets : [],
            trainingSet : [],
            classesOptions: [],
            baseIRIs: [],
            useDataSchema : true,
            selectedClasses : [],
            selectedBaseIRIs : []
        }
    }

    componentDidMount() {
        let {configurations, settings, aliasesMap, ontology} = this.props;
        const typeToSearch = ALIAS_MANAGEMENT_TYPE_TRAINING_SET;
        let query = `{type(eq:["${typeToSearch}"]) createdBy(eq:["${getUserIri()}"]) }`;
        const searchParams = {
            [QUERY] : query,
            [PAGE] : 1,
            [PAGE_SIZE] : 1000,
            [COUNT_UPTO] : 1000
        }
        getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_GRAPH_SEARCH, searchParams).catch(handleBackendError(this)).then(async searchData => {
            if(isRequestSuccessful(searchData)) {
                const items = await searchData.json();
                this.setState({trainingSets : items[ALIAS_SYS_RESULTS]});
            }
        });
        getAllClassesData(configurations, settings, aliasesMap, ontology).then((classesOptions) => {
            const baseIRIs = new Set(toArray(classesOptions).map(co => getBaseIRI(getResourceId(co))));
            this.setState({classesOptions: classesOptions, baseIRIs : [...baseIRIs].map(b => ({id : b, title : b}))});
        });
        getDataPrefixes().then(async result => {
            if(isRequestSuccessful(result)) {
                const jsonData = await result.json();
                const graph = getGraph(jsonData);
                this.setState({missingPrefixes : graph});
            }
        })

    }


    showDialog = () => {
        const {loading, missingPrefixes, saveVisualisationTitle, saveVisualisationTitleError, model, trainingSets, trainingSet, classesOptions, selectedClasses, useDataSchema, baseIRIs, selectedBaseIRIs} = this.state;
        const {theme, onCancel, models} = this.props;
        return <Dialog
            aria-labelledby="Save"
            open={true}
            fullWidth={true}
            maxWidth={'sm'}
            datatest={'saveDialog'}
        >
            <DialogTitle id="form-dialog-title">
                Fine Tune Model
            </DialogTitle>
            <DialogContent>
                <div style={{display : "flex", gap : '16px', flexDirection :'column'}}>
                    <MuiTextField
                        datatest={'textFieldTitle'}
                        fullWidth={true}
                        label={'Title'}
                        value={saveVisualisationTitle||""}
                        error={saveVisualisationTitleError}
                        helperText={saveVisualisationTitleError}
                        onChange={(event) => {
                            let saveVisualisationTitleError = '';
                            const {target: {value}} = event;
                            if(!value) {
                                saveVisualisationTitleError = 'Value required.'
                            } else if (value.length > 200) {
                                saveVisualisationTitleError = 'Maximum 200 characters.'
                            } else {
                                saveVisualisationTitleError = ''
                            }
                            this.setState({saveVisualisationTitleError, saveVisualisationTitle : value});
                        }}
                    />
                    <FormControl style={{width : '100%'}} size={'small'} variant="outlined">
                        <InputLabel id="model-select">Base Model</InputLabel>
                        <Select
                            labelId="model-select"
                            datatest={'baseModelSelect'}
                            label="Base Model"
                            variant={'outlined'}
                            value={model}
                            onChange={(event) => {
                                this.setState({model : event.target.value}) ;
                            }}
                        >
                            <ListSubheader>Base Models</ListSubheader>
                            {getBaseAIModels().map(m => <MenuItem key={m} value={m}>{m}</MenuItem>)}
                            <ListSubheader>Fine Tuned Models</ListSubheader>
                            {models.map(l => <MenuItem key={getResourceId(l)} value={l.backendFineTunedModelId}>{l.backendFineTunedModelId}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <Autocomplete
                        datatest={'exampleSetSelect'}
                        id="textSearchPropertiesValueSelect"
                        value={trainingSet || []}
                        options={trainingSets}
                        getOptionLabel={option => option.title ?  option.title : ''}
                        getOptionSelected={(option, value) => {
                            return option.id === value.id;
                        }}
                        multiple={true}
                        onChange={(event, value, reason, details) => {
                            this.setState({trainingSet : value}) ;
                        }}
                        renderInput={params => (
                            <OtherTextField
                                label={"Example Sets"}
                                {...params}
                                margin={"dense"}
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{}}
                            />
                        )}
                        renderTags={(value, getTagProps) => {
                            return value.map((option, index) => {
                                return getChipWithDelete(theme, index, option.title, undefined, getTagProps({index}));
                            })
                        }}
                        size={"small"}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                datatest={'showQuery'}
                                size={"small"}
                                checked={useDataSchema}
                                value={true}
                                onChange={() => {
                                    this.setState({useDataSchema: !useDataSchema});
                                }}
                                name="showQuery"
                            ></Switch>
                        }
                        label={<Typography style={{color: theme.palette.primary.main}}>Use Data Schema in Training</Typography>}
                    />
                    <Typography variant={'caption'}>If this is turned on metadata about the data, the shape of the data and the ontology is added in the system messages.
                        This can help improve the accuracy but fine tuning will cost more.
                        You can specify which part of the model data is used by using the filters below.
                        </Typography>
                    {
                        useDataSchema  && <div style={{display :"flex", gap :'16px', flexDirection : 'column', margin : '0px 16px'}}>
                            {
                                toArray(missingPrefixes).length > 0 && <div style={{padding  :'8px', display :"flex", flexDirection : 'column', gap : '16px', border : '1px solid', borderColor : theme.palette.border.main, borderRadius : '4px'}}>
                                    <div style={{ display :"flex", gap : '16px'}}>
                                        {centerVertically(<WarningIcon style={{color : theme.palette.warning.main}}></WarningIcon>)}
                                        {centerVertically(<Typography variant={'caption'}>It is recommended that you define prefixes for below base IRIs. Otherwise randomly generated prefixes will be used for fine tuning metadata.</Typography>)}
                                    </div>
                                    <div style={{display : 'flex', flexDirection : 'column', gap : '4px'}}>
                                        {toArray(missingPrefixes).map(mp => {
                                            return <Typography variant={'caption'}>{mp[ALIAS_SYS_BASE_IRI]}</Typography>
                                        })}
                                    </div>
                                </div>
                            }
                            <Autocomplete
                                datatest={'autocompleteMultiValueSelect'}
                                id="textSearchPropertiesValueSelect"
                                value={selectedBaseIRIs || []}
                                options={baseIRIs}
                                getOptionLabel={option => option.title ? option.title : ''}
                                getOptionSelected={(option, value) => {
                                    return option.id === value.id;
                                }}
                                multiple={true}
                                onChange={(event, value, reason, details) => {
                                    this.setState({selectedBaseIRIs: value});
                                }}
                                renderInput={params => (
                                    <OtherTextField
                                        label={"Base IRIs"}
                                        {...params}
                                        margin={"dense"}
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{}}
                                        helperText={'If base IRIs are specified then a data model from the selected base IRIs is used for tuning. This can help reduce the training cost. If not sure leave it blank.'}

                                    />
                                )}
                                renderTags={(value, getTagProps) => {
                                    return value.map((option, index) => {
                                        return getChipWithDelete(theme, index, option.title, undefined, getTagProps({index}));
                                    })
                                }}
                                size={"small"}
                            />
                            <Autocomplete
                                datatest={'autocompleteMultiValueSelect'}
                                id="textSearchPropertiesValueSelect"
                                value={selectedClasses || []}
                                options={classesOptions}
                                getOptionLabel={option => option.title ? option.title : ''}
                                getOptionSelected={(option, value) => {
                                    return option.id === value.id;
                                }}
                                multiple={true}
                                onChange={(event, value, reason, details) => {
                                    this.setState({selectedClasses: value});
                                }}
                                renderInput={params => (
                                    <OtherTextField
                                        label={"Data Classes"}
                                        {...params}
                                        margin={"dense"}
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{}}
                                        helperText={'If class IRIs are specified then a data model for the selected classes is used. This can help reduce the training cost. If not sure leave it blank.'}
                                    />
                                )}
                                renderTags={(value, getTagProps) => {
                                    return value.map((option, index) => {
                                        return getChipWithDelete(theme, index, option.title, undefined, getTagProps({index}));
                                    })
                                }}
                                size={"small"}
                            />
                        </div>
                    }
                </div>

            </DialogContent>
            <DialogActions>
                <Button datatest={'cancelButton'} variant={"outlined"} onClick={onCancel} color="secondary">Cancel</Button>
                <Button
                    disabled={loading || !saveVisualisationTitle || saveVisualisationTitleError || ! model || toArray(trainingSet).length === 0}
                    datatest={'trainButton'}
                    style={{marginLeft: theme.spacing(STYLE_GRID_ITEM_SPACING)}}
                    variant={"contained"}
                    color="secondary"
                    onClick={() => {
                        let newModel = {
                            [AT_CONTEXT]: getManagementContextURL(),
                            [ID] : getNewIdForModel(uuid4()),
                            [TYPE] : TYPE_AI_MODEL,
                            createdBy : getUserIri(),
                            createdOn: new Date(),
                            title : saveVisualisationTitle,
                            trainingSet : toArray(trainingSet).map(ts => getResourceId(ts)),
                            backendBaseModelId : model,
                            [TARGET_CLASS_BASE_IRIS]: selectedBaseIRIs.map(br => getResourceId(br)),
                            [TARGET_CLASS_IRIS] : selectedClasses.map(br => getResourceId(br)),
                            useMetadata : useDataSchema
                        }
                        this.setState({loading : true})
                        this.props.onTrain(newModel)
                    }}>{loading && <CircularProgress size={24}></CircularProgress>}{ 'Train'}</Button>

            </DialogActions>
        </Dialog>;
    }


    render() {
        return this.showDialog();
    }
}

TrainModelDialog.propTypes = {
    location : PropTypes.any,
    configurations: PropTypes.any,
    settings: PropTypes.any,
    aliasesToIRIMap: PropTypes.any,
    aliasesMap: PropTypes.any,
    browseLanguage: PropTypes.any,
    ontology: PropTypes.any,
    theme: PropTypes.any,
    onCancel: PropTypes.any,
    onTrain: PropTypes.any,
}

export default withStyles(styles, {withTheme:true})(withRouter(TrainModelDialog));
