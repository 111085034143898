import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ApplicationCard from '../../components/ApplicationCard';
import help from "../../images/Dashboard-PNGs/Help@2x.png";
import {
    ALIAS_MANAGEMENT_ID_LABEL,
    ALIAS_SYS_ID_LABEL,
    LABEL_APPLICATIONS,
    LABEL_MANAGE_DATASETS,
    LABEL_MONITORING,
    LABEL_PROFILE,
    LABEL_USER_MANAGEMENT,
    ROUTE_API_PLAYGROUND, ROUTE_APPS_EXPLORER,
    ROUTE_BOOTSTRAP,
    ROUTE_CONFIGURATION_MANAGER_HOME,
    ROUTE_HELP,
    ROUTE_MANAGEMENT_DATASET,
    ROUTE_MANAGEMENT_HOME,
    ROUTE_MANAGEMENT_MONITORING,
    ROUTE_MANAGEMENT_USER,
    ROUTE_MANAGEMENT_USER_PROFILE,
    ROUTE_SCHEMA_BUILDER, ROUTE_SPARQL_EDITOR,
    STYLE_GRID_ITEM_SPACING
} from "../../Constants";
import {deepOrange, green, indigo, teal} from '@material-ui/core/colors';
import {Typography} from "@material-ui/core";
import graphifiLogoBG from "../../images/GraphifiLogoCrop.png";
import {getBaseEndpointWithInstance, getData, getDownloadURL} from "../../service/graph-api";
import {
    centerVertically,
    getDatasetLabel,
    getRouteInstancePath,
    getRouteWithInstance,
    getRouteWithInstanceAndDataset,
    getRouteWithInstanceAndUserDefaultDataset,
    getSearchResult,
    handleAPIResponse,
    handleBackendError, sort
} from "../../components/util";
import {canUpdateConfigs, isManagementUserLoggedIn, isSSOUser, isSuperadmin} from "../../layouts/common/Profile";
import {SCHEMA_BUILDER} from "../../layouts/modelbuilder/Models";
import model from "../../images/Dashboard-PNGs/GraphQLPlayground@2x.png";
import bootstrap from "../../images/Dashboard-PNGs/Bootstrap@2x.png";
import cogWheels from "../../images/Dashboard-PNGs/ConfigurationManager@2x.png";
import api from "../../images/Dashboard-PNGs/HTTPAPIPlayground@2x.png";
import datasets from "../../images/Dashboard-PNGs/datasets.svg";
import explore from "../../images/icons/explore_black_24dp.svg";
import {isMultipleDatasetsInstance, VERSION, VERSION_HASH} from "../../Configs";
import AddDatasetDialog from "../../layouts/management/AddDatasetDialog";
import {addDataset} from "../../layouts/management/Datasets";
import DatasetSelect from "../../layouts/management/DatasetSelect";
import history from "../../history";
import StorageOutlinedIcon from '@material-ui/icons/StorageOutlined';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import SpeedOutlinedIcon from '@material-ui/icons/SpeedOutlined';
import InstructionForAction from "../../components/InstructionForAction";
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import MenuColumnAndDetailsLayout, {getLeftMenuComponent} from "../../layouts/MenuColumnAndDetailsLayout";
import {BACKEND_PATH_MANAGEMENT_DATASET_SEARCH} from "../../service/backend-paths";
import ExploreIcon from '@material-ui/icons/Explore';

const styles = () => ({
});

export function getLeftComponent(callerThis, theme, minimized) {
    return getLeftMenuComponent(callerThis, theme, minimized, getLeftMenuItems(theme));
}

function navigateToRouteWithInstanceAndDataset(route) {
    return () => history.push(`${getRouteWithInstanceAndUserDefaultDataset(route)}`);
}

function navigateToRouteWithInstance(route) {
    return () => history.push(`${getRouteWithInstance(route)}`);
}

export function getLeftMenuItems(theme) {
    let iconStyle =  {color: theme.palette.white.main}
    let items = [
            {
                label: LABEL_APPLICATIONS,
                icon : <AppsOutlinedIcon style={iconStyle}/>,
                onClick : navigateToRouteWithInstanceAndDataset(ROUTE_MANAGEMENT_HOME)
            }
        ];
    if(isSuperadmin() && isMultipleDatasetsInstance()) {
        items.push({
            label: LABEL_MANAGE_DATASETS,
            icon : <StorageOutlinedIcon style={iconStyle}/>,
            onClick : navigateToRouteWithInstance(ROUTE_MANAGEMENT_DATASET)
        })
    }
    if(isSuperadmin()) {
        items.push({
            label: LABEL_USER_MANAGEMENT,
            icon : <SupervisedUserCircleOutlinedIcon style={iconStyle}/>,
            onClick : navigateToRouteWithInstance(ROUTE_MANAGEMENT_USER)
        })
    }
    if(isManagementUserLoggedIn() && !isSSOUser()) {
        items.push({
            label: LABEL_PROFILE,
            icon: <PersonOutlineIcon style={iconStyle}/>,
            onClick: navigateToRouteWithInstance(ROUTE_MANAGEMENT_USER_PROFILE)
        })
    }
    if(isSuperadmin()) {
        items.push({
            label: LABEL_MONITORING,
            icon : <SpeedOutlinedIcon style={iconStyle}/>,
            onClick : navigateToRouteWithInstance(ROUTE_MANAGEMENT_MONITORING)
        })
    }
    return items;
}

class AdminHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            minimized : true,
            datasets :[]
        }
        //setCookie(COOKIE_DATASET, "");
    }

    componentDidMount() {
        this.syncDataWithBackend().then(() => {});
    }

    syncDataWithBackend = async () => {
        if(isMultipleDatasetsInstance() && isManagementUserLoggedIn() ) {
            this.setState({loading: true});
            const apiResponse = await getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_DATASET_SEARCH).catch(handleBackendError(this));
            handleAPIResponse(
                this,
                apiResponse,
                () => {
                    apiResponse.json().then((response) => {
                        let datasets = sort(getSearchResult(response), ALIAS_MANAGEMENT_ID_LABEL);

                        this.setState({loading: false});
                        if(datasets.length < 1 && isSuperadmin()) {
                            this.setState({showCreateDatasetDialog: true});
                        }
                        this.setState({datasets : datasets});
                    }).catch((e) => {
                        this.setState({loading: false, apiErrorResponse: e, apiError: true});
                    })
                },
                handleBackendError(this)
            )
        } else {
            let datasetLabel = getDatasetLabel();
            if(datasetLabel) {
                this.setState({datasets: [{[ALIAS_MANAGEMENT_ID_LABEL]:datasetLabel}] })
            }
            this.setState({loading: false});
        }
    }


    download = () => {
        this.setState({showTC : false});
        getDownloadURL().then(d => {
            if (d.url) {
                let elementById = document.getElementById('downloadButton');
                elementById.href = d.url;
                elementById.click();
            }
        });
    }


    renderApplicationsCards = () => {
        if(isMultipleDatasetsInstance() && !getDatasetLabel()) {
            let {datasets} = this.state;
            if(datasets) {
                if (datasets.length > 0) {
                    return <InstructionForAction text={'Please select a dataset.'}/>;
                } else {
                    return <InstructionForAction text={'No dataset found. Please check your permissions.'}/>;
                }
            } else {
                return <></>;
            }
        }
        return <>
            {
                canUpdateConfigs() && <>
                    <Grid item>
                        <ApplicationCard
                            letter={"B"}
                            backgroundColor={deepOrange[500]}
                            url={getRouteWithInstanceAndDataset(ROUTE_SCHEMA_BUILDER)}
                            title={SCHEMA_BUILDER}
                            description="Build an API schema."
                            thumbnail={model}
                        />
                    </Grid>
                    <Grid item>
                        <ApplicationCard
                            letter={"B"}
                            backgroundColor={deepOrange[500]}
                            url={getRouteWithInstanceAndDataset(ROUTE_BOOTSTRAP)}
                            title="Bootstrap"
                            description="Load ontologies and generate SHACL Shapes, an HTTP API and GraphQL schemas."
                            thumbnail={bootstrap}
                        />
                    </Grid>
                </>
            }
            <Grid item>
                <ApplicationCard
                    backgroundColor={green[500]}
                    letter={"C"}
                    url={getRouteWithInstanceAndDataset(ROUTE_CONFIGURATION_MANAGER_HOME)}
                    title="System Manager"
                    description="View and edit the system configuration and manage your setup."
                    thumbnail={cogWheels}
                />
            </Grid>
            {
                canUpdateConfigs() &&
                <Grid item>
                    <ApplicationCard
                        letter={"B"}
                        backgroundColor={deepOrange[500]}
                        url={getRouteWithInstanceAndDataset(ROUTE_APPS_EXPLORER)}
                        title="Explorer"
                        description="Explore graph data."
                        thumbnail={explore}
                        cardMediaStyle={{backgroundSize : '35%'}}
                    />
                </Grid>
            }
            <Grid item>
                <ApplicationCard
                    backgroundColor={indigo[500]}
                    letter={"H"}
                    url={getRouteWithInstanceAndDataset(ROUTE_API_PLAYGROUND)}
                    title="Playground"
                    description="Try using your HTTP API and GraphQL. Create examples for your API."
                    thumbnail={api}
                />
            </Grid>
            <Grid item>
                <ApplicationCard
                    backgroundColor={indigo[500]}
                    letter={"H"}
                    url={getRouteWithInstanceAndDataset(ROUTE_SPARQL_EDITOR)}
                    title="SPARQL"
                    description="Run SPARQL queries."
                    thumbnail={datasets}
                />
            </Grid>
            <Grid item>
                <ApplicationCard
                    target={'new'}
                    backgroundColor={teal[500]}
                    letter={"S"}
                    url={ROUTE_HELP}
                    title="Help"
                    description="EasyGraph documentation."
                    thumbnail={help}
                />
            </Grid>
        </>
    }

    getMiddleComponent = () => {
        const {theme } = this.props;
        const {datasets} = this.state;

        return <div style={{height: 'calc(100% - 48px)',display :'flex', flexDirection : 'column'}}>

                <Grid xs container spacing={2} justify="space-evenly">
                    {
                        isMultipleDatasetsInstance() &&
                        <Grid justify={'flex-start'} container item xs={12}>
                            {
                                centerVertically(<DatasetSelect datasets={datasets} key={getDatasetLabel()+""} ></DatasetSelect>)
                            }
                        </Grid>
                    }
                    {this.renderApplicationsCards()}
                </Grid>
            <div style={{flexGrow: 1}}></div>
            <div>
                <div style={{ display: 'flex', marginTop : '24px',  paddingTop: theme.spacing(3)}}>
                    <Grid style={{borderTop : '1px solid #9E9E9E'}} xs direction={'row-reverse'} container spacing={STYLE_GRID_ITEM_SPACING}>
                        <Grid item>
                            <Typography color={"inherit"} variant="caption"  component={'span'}>
                                EasyGraph <span hash={VERSION_HASH}>{VERSION === 'VERSION_PLACEHOLDER' ? '' : VERSION}</span> from
                                <a href={'https://www.graphifi.com/'} target={'_blank'}>
                                    <img alt={'Graphifi'} src={graphifiLogoBG} style={{height: '20px', marginLeft : '16px',  verticalAlign: 'middle'}}/>
                                </a>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>;
    }

    render() {
        let {showCreateDatasetDialog} = this.state;
        const {theme, location } = this.props;
        return (<>
                <MenuColumnAndDetailsLayout
                    apiError={this.state.apiError}
                    apiErrorResponse={this.state.apiErrorResponse}
                    onApiErrorClose={() => this.setState({apiError:false, apiErrorResponse: undefined})}
                    headerTitle={'Applications'}
                    leftMenuItems={getLeftMenuItems(theme)}
                    leftColumnRenderer={undefined}
                    detailRenderer={this.getMiddleComponent}
                    location={location}
                />
                {
                showCreateDatasetDialog === true
                && <AddDatasetDialog
                    open={showCreateDatasetDialog}
                    title={'Add a Dataset To Get Started'}
                    handleOk={(obj) =>  {
                        return new Promise((resolve, reject) => {
                            addDataset(this, obj, this.syncDataWithBackend).then(d => {
                                if(d && Object.keys(d).length < 1) {
                                    let path = `${getRouteInstancePath()}/dataset/${obj[ALIAS_SYS_ID_LABEL]}${ROUTE_MANAGEMENT_HOME}`;
                                    history.push(path);
                                    resolve({});
                                    this.setState({})
                                } else {
                                    reject(d);
                                }
                            })
                        })
                    }}
                />
            }
        </>
        );
    }
}


export default withStyles(styles, {withTheme: true})(AdminHome);