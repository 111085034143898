import React, {Component, useEffect} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import GridContainer from "../../components/GridContainer";
import Grid from "@material-ui/core/Grid";
import FieldContainer from "../../components/FieldContainer";
import H2Title from "../../components/H2Title";
import uuid4 from 'uuid/v4'

import {
    ALIAS_MANAGEMENT_ID_LABEL,
    ALIAS_MANAGEMENT_SETTINGS,
    ALIAS_MANAGEMENT_TYPE_INSTANCE_CONFIGURATION,
    ALIAS_SYS_CLASS_IRI,
    ALIAS_SYS_IDENTIFIER,
    ALIAS_SYS_RESULTS,
    AT_CONTEXT,
    ID,
    INSTANCE_CONFIGURATION_SETTING_HOME_PAGE,
    IRI_TYPE_FEEDBACK,
    LINK_COLOR,
    PRIMARY_COLOR,
    SECONDARY_COLOR,
    SUCCESS_COLOR,
    TYPE
} from "../../Constants";
import {getUILabel, initSettingPath} from "./WorkspaceSettingsDialog";
import MainHeaderBar from "../../components/MainHeaderBar";
import TextField from "../../components/TextField";
import {
    ClickAwayListener,
    FormHelperText,
    IconButton as MUIIconButton,
    InputLabel,
    MenuItem,
    Paper,
    Popper,
    RadioGroup,
    TextField as OtherTextField,
    Typography
} from "@material-ui/core";
import {PaletteRounded} from "@material-ui/icons";
import {SketchPicker} from "react-color";
import {
    createAliasesMap,
    createContainerPayload,
    getApiConfigurationResource,
    getContainerData,
    getDatasetLabel,
    getResourceId,
    getSearchResult,
    getSystemContextURL,
    handleBackendError,
    isRequestSuccessful,
    restrictMaximumCharacters,
    toArray,
    validateMaxLength
} from "../../components/util";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
    getAllConfigurations,
    getBaseEndpointWithInstance,
    getData,
    getInstanceConfiguration,
    getManagementContextURL,
    ontologyBootstrap,
    patchManagementGraph,
    postManagementGraph,
    putConfiguration
} from "../../service/graph-api";
import {getSiteHomePath, renderBanner, renderLogo} from "./Workspace";
import {getInstanceConfigurationSettings} from "../../index";
import H3Title from "../../components/H3Title";
import {getBaseAIModels, isAIEnabled, isTrialInstance} from "../../Configs";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import UploadFileDialog from "./UploadFileDialog";
import UILabel from "./UILabel";
import {getHeader} from "../../components/header/APIPlaygroundHeader";
import {feedbackOntology} from "../../data/feedbackOntology";
import {MERGE_OPTIONS, MERGE_PARTIAL_MODEL_RELOAD} from "../../components/BootstrapDialog";
import {cloneDeep} from "lodash";
import {getModels} from "../../service/ai";
import Select from "@material-ui/core/Select";
import ListSubheader from "@material-ui/core/ListSubheader";
import {TARGET_CLASS_BASE_IRIS, TARGET_CLASS_IRIS} from "./TrainModelDialog";
import {BACKEND_PATH_MANAGEMENT_DATASET_SEARCH} from "../../service/backend-paths";

export function getAppBarButtonColor(settings) {
    return settings?.['banner']?.['appBarButtonColor'];
}

export function getBannerContentContainerStyle(settings) {
    return settings?.['bannerContentContainer']?.['style'];
}

export const KEY_AI_SETTING = 'aiSetting';
const KEY_AI_SEARCH_FOR_USERS = 'aiSearch';
const KEY_AI_SEARCH_RECORD_USAGE = 'aiSearchRecordUsage';

export function getDefaultAIModel(settings) {
    return settings?.[KEY_AI_SETTING]?.['defaultModel'];
}

export function getTargetClassIRIs(settings) {
    return settings?.[KEY_AI_SETTING]?.[TARGET_CLASS_IRIS];
}

const KEY_USER_MESSAGE_TEMPLATE = 'userMessageTemplate';

export function getUserMessageTemplate(settings) {
    return settings?.[KEY_AI_SETTING]?.[KEY_USER_MESSAGE_TEMPLATE];
}

export function getTargetClassBaseIRIs(settings) {
    return settings?.[KEY_AI_SETTING]?.[TARGET_CLASS_BASE_IRIS];
}

const LINKED_RESOURCE_VIEW_TYPE_WITHOUT_IRI = "withoutIRI";
const RESOURCE_VIEW_TYPE_SIMPLE = "simple";
const RESOURCE_VIEW_TYPE_TREE = "tree";


export function getLinkedResourceViewType(settings) {
    return settings?.['linkedResourcesViewType'];
}

export function isLinkedResourceViewTypeWithoutIRI(settings) {
    return getLinkedResourceViewType(settings) === LINKED_RESOURCE_VIEW_TYPE_WITHOUT_IRI;
}

export function isButtonsCardDisabled(settings) {
    const value = settings?.['disableButtonsCard'];
    return value === 'Yes';
}

export function getResourceDetailsViewType(settings) {
    return settings?.['resourceDetailsViewType'];
}

export function isResourceViewTypeSimple(settings) {
    const resourceDetailsViewType = getResourceDetailsViewType(settings);
    return resourceDetailsViewType === RESOURCE_VIEW_TYPE_SIMPLE;
}

export function getSimpleViewShowDatatype(settings) {
    return settings?.['simpleViewShowDatatype'];
}

export function isSimpleViewShowDatatype(settings) {
    const resourceDetailsViewType = getSimpleViewShowDatatype(settings);
    return resourceDetailsViewType === undefined || resourceDetailsViewType === 'Yes';
}

export function getSimpleViewShowLanguageCode(settings) {
    return settings?.['simpleViewShowLanguageCode'];
}

export function isSimpleViewShowLanguageCode(settings) {
    const resourceDetailsViewType = getSimpleViewShowLanguageCode(settings);
    return resourceDetailsViewType === undefined || resourceDetailsViewType === 'Yes';
}

export function getSimpleViewShowLanguageName(settings) {
    return settings?.['simpleViewShowLanguageName'];
}

export function isSimpleViewShowLanguageName(settings) {
    const resourceDetailsViewType = getSimpleViewShowLanguageName(settings);
    return resourceDetailsViewType === undefined || resourceDetailsViewType === 'Yes';
}

export function isFeedbackEnabled(settings) {
    return settings?.feedback?.enabled === true;
}
export function isFeedbackPlacementOnLeft(settings) {
    return settings?.feedback?.placement === 'left';
}

export function isAIEnabledInSettings(settings) {
    return settings?.[KEY_AI_SETTING]?.enabled === true;
}

export function isAISearchForUserEnabled(settings) {
    return settings?.[KEY_AI_SEARCH_FOR_USERS]?.enabled === true;
}

export function isAISearchRecordUsageEnabled(settings) {
    return settings?.[KEY_AI_SEARCH_RECORD_USAGE]?.enabled === true;
}

const DEFAULT_FEEDBACK_BG_COLOR = '#f0f4fb';

export function getFeedbackBackgroundColor(settings) {
    return settings?.['feedbackStyle']?.['backgroundColor'] || DEFAULT_FEEDBACK_BG_COLOR;
}

const DEFAULT_FEEDBACK_BORDER_COLOR = '#e9f1fb';

export function getFeedbackBorderColor(settings) {
    return settings?.['feedbackStyle']?.['borderColor'] || DEFAULT_FEEDBACK_BORDER_COLOR;
}

export function withWhiteBorder(content) {
    return <div style={{border : '2px solid white', padding : '12px', borderRadius : '4px', marginBottom : '16px'}}>{content}</div>;
}

export class ColorPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let {theme, readOnly, minimal, defaultValue, label, settings, onChange, placeholderValue, objectKey, valueKey, helperText, containerStyle} = this.props
        const openKey = objectKey+'-'+valueKey + 'PickerOpen'
        const anchorKey = objectKey+'-'+valueKey + 'PickerAnchor'
        const value = settings?.[objectKey]?.[valueKey] || defaultValue || "";
        let {error} = this.state;
        let extraMarginOnLeft = 4;
        let styleOverride =containerStyle || {};

        let paperStyleMerged = {
            padding: theme.spacing(1) + extraMarginOnLeft,
            backgroundColor: theme.palette.border.main,
            ...styleOverride
        }

        let textField = <OtherTextField
            disabled={readOnly}
            label={ minimal ? label : undefined}
            datatest={openKey}
            value={value}
            margin={"dense"}
            variant="outlined"
            helperText={error }
            error={error}
            fullWidth
            onChange={(event) => {
                const {target: {value}} = event;
                if (!settings[objectKey]) {
                    settings[objectKey] = {}
                }
                settings[objectKey][valueKey] = value || placeholderValue;
                let isValueInvalid = settings[objectKey][valueKey] && !/^#(?:[0-9a-fA-F]{3,4}){1,2}$/.test(settings[objectKey][valueKey]);
                let error ;
                if(isValueInvalid) {
                    if(!settings[BASIC_SETUP_ERRORS]) {
                        settings[BASIC_SETUP_ERRORS] = {}
                    }
                    error = "Invalid hex color value.";
                    this.setState({error : error});
                } else {
                    this.setState({error : undefined});
                }
                onChange && !error && onChange(settings[objectKey][valueKey]);
                this.setState({});

            }}
            InputProps={{
                readOnly : readOnly,
                endAdornment: <ClickAwayListener onClickAway={() => this.setState({[openKey]: false})}>
                    <div>

                        <MUIIconButton disabled={readOnly} datatest={openKey+"-paletteButton"} style={{border: '2px solid #000000'}} size={'small'} onClick={(ev) => {
                            this.setState({[anchorKey]: ev.currentTarget, [openKey]: true});
                        }}>
                            <PaletteRounded style={{color: value}}/>
                        </MUIIconButton>
                        <Popper style={{zIndex: '10000'}} anchorEl={this.state[anchorKey]}
                                open={this.state[openKey] || false}>
                            <SketchPicker disableAlpha={false} color={value} onChange={(color) => {
                                let value = color.hex;
                                let newValue = value || placeholderValue;
                                onChange && onChange(newValue);
                                if (!settings[objectKey]) {
                                    settings[objectKey] = {}
                                }
                                settings[objectKey][valueKey] = newValue;
                                this.setState({error : undefined});

                            }}></SketchPicker>
                        </Popper>
                    </div>
                </ClickAwayListener>
            }}
        />

        return minimal
            ?  <div>
                {textField}
                {error ? <></>: <FormHelperText >{helperText}</FormHelperText>}
            </div>
            : <Paper style={paperStyleMerged} elevation={0}>
                {label}
                {textField}
                {error ? <></>: <FormHelperText>{helperText}</FormHelperText>}
            </Paper>;

    }

}

ColorPicker.propTypes = {
    theme : PropTypes.any,
    label : PropTypes.any,
    settings : PropTypes.any,
    objectKey : PropTypes.any,
    valueKey : PropTypes.any,
    defaultValue : PropTypes.any,
    onChange : PropTypes.any,
    placeholderValue : PropTypes.any,
    helperText : PropTypes.any,
    minimal : PropTypes.any,
    containerStyle : PropTypes.any,
    readOnly : PropTypes.bool,

}


class ThemeColorPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let {label, name, theme, settings, onChange, placeholderValue} = this.props
        const openKey = name + 'PickerOpen'
        const anchorKey = name + 'PickerAnchor'
        const value = settings?.theme?.[name] || theme?.[name] || "";
        let {error} = this.state;
        return <OtherTextField
            datatest={label}
            style={{marginLeft: '16px'}}
            value={value}
            label={label}
            margin={"dense"}
            variant="outlined"
            helperText={error || `Default ${placeholderValue}`}
            error={error}
            fullWidth
            onChange={(event) => {
                const {target: {value}} = event;
                if (!settings.theme) {
                    settings.theme = {}
                }
                settings.theme[name] = value || placeholderValue;
                let isValueInvalid = settings.theme[name] && !/^#(?:[0-9a-fA-F]{3,4}){1,2}$/.test(settings.theme[name]);
                if(isValueInvalid) {
                    if(!settings[BASIC_SETUP_ERRORS]) {
                        settings[BASIC_SETUP_ERRORS] = {}
                    }
                    settings[BASIC_SETUP_ERRORS][label] = "Invalid hex color value.";
                    this.setState({error : "Invalid hex color value."});
                } else {
                    delete settings[BASIC_SETUP_ERRORS][label];
                    this.setState({error : undefined});
                }
                onChange && onChange();
                this.setState({});

            }}
            InputProps={{
                endAdornment: <ClickAwayListener onClickAway={() => this.setState({[openKey]: false})}>
                    <div>

                        <MUIIconButton style={{border: '2px solid #000000'}} size={'small'} onClick={(ev) => {
                            this.setState({[anchorKey]: ev.currentTarget, [openKey]: true});
                        }}>
                            <PaletteRounded style={{color: value}}/>
                        </MUIIconButton>
                        <Popper style={{zIndex: '10000'}} anchorEl={this.state[anchorKey]}
                                open={this.state[openKey] || false}>
                            <SketchPicker color={value} onChange={(color) => {
                                let value = color.hex;
                                onChange && onChange();
                                if (!settings.theme) {
                                    settings.theme = {}
                                }
                                settings.theme[name] = value || placeholderValue;
                                this.setState({});

                            }}></SketchPicker>
                        </Popper>
                    </div>
                </ClickAwayListener>
            }}

        />;

    }

}

class SetupTextField extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let {onChange, valuePaperStyle, multiline, settings, objectKey, valueKey, label, helpMessageText, defaultValue, innerTextLabel, readOnly} = this.props;
        let valueObject = settings[objectKey];
        return <TextField
            paperStyle={valuePaperStyle}
            readOnly={readOnly}
            multiline={multiline}
            fullWidth={true}
            label={label}
            innerTextLabel={innerTextLabel}
            name={valueKey}
            error={settings.basicSetupErrors?.[objectKey]?.[valueKey]}
            defaultValue={valueObject === undefined || valueObject[valueKey] === undefined ? defaultValue : (valueObject ? valueObject[valueKey] : '')}
            innerHelperComponent={() => <FormHelperText>{helpMessageText}</FormHelperText>}
            onChange={(event) => {
                const maxLength = 500;
                let newEvent = restrictMaximumCharacters(event, maxLength);
                const {target: {value}} = newEvent;
                if (!valueObject) {
                    settings[objectKey] = {};
                    valueObject = settings[objectKey];
                }
                valueObject[valueKey] = value;
                let error = validateMaxLength(value, maxLength);
                onChange(error);
                this.setState({})
            }}
        />;
    }
}

SetupTextField.propTypes = {
    settings: PropTypes.object,
    multiline : PropTypes.any,
    objectKey : PropTypes.any,
    valueKey : PropTypes.any,
    label : PropTypes.any,
    helpMessageText : PropTypes.any,
    defaultValue: PropTypes.any,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    valuePaperStyle: PropTypes.any
};

export function renderImage(srcValue, column) {
    return <img style={{maxWidth: column ? 'calc(100% - 32px)' : '240px'}} src={srcValue}></img>;
}

export class ImageSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let { workspace, datatest, readOnly, column, valueObject, objectKey, valueKey, label, innerTextLabel, containerStyle, helpMessage, valueProvider, previewRenderer, textFieldRenderer, onFileUpload} = this.props;
        let {uuid} = this.state;
        const divStyle = {display : 'flex'};
        if(column) {
            divStyle['flex-direction'] = 'column';
        }
        const previewStyle = column
            ? { textAlign : 'center', padding : '16px',  maxWidth : 'calc(100% - 32px)', minWidth : 'calc(100% - 32px)'}
            : {marginTop : previewRenderer ? '20px' : '40px',  maxWidth : '240px', minWidth : '240px'};
        return <FieldContainer datatest={datatest} style={containerStyle}>
            <div key={objectKey+"-"+valueKey + uuid} style={divStyle}>
                <div  style={{flexGrow : '1'}}>
                    <SetupTextField
                        readOnly={readOnly}
                        settings={valueObject}
                        objectKey={objectKey}
                        valueKey={valueKey}
                        label={label}
                        innerTextLabel={innerTextLabel}
                        valuePaperStyle={column ? {padding : '0px'} : {}}
                        onChange={(error) => {
                           onFileUpload && onFileUpload();
                            this.setState({});
                        }}
                    />
                    {
                        readOnly ? <></> :
                        <>
                            <FormHelperText style={{padding: '0px 16px'}}>{helpMessage}</FormHelperText>
                            <UploadFileDialog linkedTo={workspace[ID]} onSuccess={(url) => {
                                if (!valueObject[objectKey]) {
                                    valueObject[objectKey] = {};
                                }
                                valueObject[objectKey][valueKey] = valueProvider ? valueProvider(url) : url;
                                onFileUpload && onFileUpload();
                                this.setState({uuid: uuid4()});
                            }} style={{padding: '12px'}}/>
                        </>
                    }
                </div>
                <div style={previewStyle}>
                    {
                        valueObject?.[objectKey]?.[valueKey]
                        && (previewRenderer
                                ? previewRenderer(valueObject?.[objectKey]?.[valueKey])
                                : renderImage(valueObject?.[objectKey]?.[valueKey], column)
                        )}
                </div>
            </div>
        </FieldContainer>;

    }
}

ImageSetting.propTypes = {
    datatest: PropTypes.any,
    workspace: PropTypes.any,
    valueObject :  PropTypes.any,
    objectKey : PropTypes.any,
    valueKey : PropTypes.any,
    label : PropTypes.any,
    innerTextLabel : PropTypes.any,
    containerStyle : PropTypes.any,
    helpMessage : PropTypes.any,
    valueProvider : PropTypes.func,
    previewRenderer : PropTypes.func,
    textFieldRenderer : PropTypes.func,
    onFileUpload : PropTypes.func,
    column : PropTypes.bool,
    readOnly : PropTypes.bool
}

const BASIC_SETUP_ERRORS = 'basicSetupErrors';

export function getImageURL(value) {
    return value.replace('url(', '').replace(')', '');
}

export function LogoImageSettings({theme, workspace, settings, onFileUpload, onTextChange}) {
    const renderImageSetting = ( valueObject, objectKey, valueKey, label = 'Image URL', innerTextLabel, containerStyle = {}, helpMessage = 'Enter url above or upload a small PNG (.png) image without any background color.', valueProvider, previewRenderer) => {
        return <ImageSetting
            workspace={workspace}
            valueObject={valueObject}
            objectKey={objectKey}
            valueKey={valueKey}
            label={label}
            innerTextLabel={innerTextLabel}
            containerStyle={containerStyle}
            helpMessage={helpMessage}
            valueProvider={valueProvider}
            previewRenderer={previewRenderer}
            textFieldRenderer={renderTextFieldFor}
            onFileUpload={onFileUpload}
        />;
    }

    const renderTextFieldFor = (settings, objectKey, valueKey, label, helpMessageText, defaultValue, innerTextLabel, onChange, multiline) => {
        return <SetupTextField
            multiline={multiline}
            settings={settings}
            helpMessageText={helpMessageText}
            objectKey={objectKey}
            valueKey={valueKey}
            label={label}
            innerTextLabel={innerTextLabel}
            defaultValue={defaultValue}
            onChange={onTextChange(objectKey, valueKey, onChange)}
        />;
    }

    return <FieldContainer style={{padding: '0px'}}>
        <FieldContainer style={{padding: '24px'}}>
            <H3Title title={'Logo Image URL'}></H3Title>
            <FieldContainer
                datatest={'logo'}
                style={{marginTop: '8px', border: '2px solid', borderColor: theme.palette.white.main}}
                disableLeftRightPadding={true}>
                {renderImageSetting(settings, 'logo', 'imageURL', ' ', undefined, undefined, undefined, undefined, renderImage)}
            </FieldContainer>
        </FieldContainer>
    </FieldContainer>;
}

export function BannerContainerSettings({theme, workspace, settings, onChange, updateState}) {
    const renderImageSetting = ( valueObject, objectKey, valueKey, label = 'Image URL', innerTextLabel, containerStyle = {}, helpMessage = 'Enter url above or upload a small PNG (.png) image without any background color.', valueProvider, previewRenderer) => {
        return <ImageSetting
            workspace={workspace}
            valueObject={valueObject}
            objectKey={objectKey}
            valueKey={valueKey}
            label={label}
            innerTextLabel={innerTextLabel}
            containerStyle={containerStyle}
            helpMessage={helpMessage}
            valueProvider={valueProvider}
            previewRenderer={previewRenderer}
            textFieldRenderer={renderTextFieldFor}
        />;
    }

    const renderTextFieldFor = (settings, objectKey, valueKey, label, helpMessageText, defaultValue, innerTextLabel, onChange, multiline) => {
        return <SetupTextField
            multiline={multiline}
            settings={settings}
            helpMessageText={helpMessageText}
            objectKey={objectKey}
            valueKey={valueKey}
            label={label}
            innerTextLabel={innerTextLabel}
            defaultValue={defaultValue}
            onChange={onChange}
        />;
    }

    return <>
        <FieldContainer style={{padding: '0px'}}>
            <FieldContainer datatest={['banner', 'imageURL'].join('-')} style={{padding: '24px'}}>
                <H3Title title={'Banner Background'}></H3Title>

                <FieldContainer style={{
                    padding: '24px',
                    marginTop: '8px',
                    border: '2px solid',
                    borderColor: theme.palette.white.main
                }}>
                    <UILabel
                        settings={settings}
                        onChange={onChange}
                        helpText={''}
                        valueObject={initSettingPath(settings, ['banner', 'imageURL'])}
                        componentRenderer={(label, valueKey) => {
                            return renderImageSetting(settings['banner'], 'imageURL', valueKey, '', label, {
                                    border: '1px solid white',
                                    marginTop: '8px'
                                }
                                , 'Upload the image or Enter the value to set image \'url(https://graphologi.graphifi.com/images/logo-reversed.png)\' or try gradient e.g. \'linear-gradient(20deg,#13294B 50%, #00a9e0 ,#CF4520)\'. For possible values search CSS value background-image. For image use PNG(.png) image without background color.',
                                (url) => {
                                    return `url(${url})`;
                                },
                                (value) => {
                                    return value.startsWith('url')
                                        ? <img style={{maxWidth: '240px'}}
                                               src={getImageURL(value)}></img>
                                        : <div style={{
                                            width: '240px',
                                            height: '100%',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundImage: value
                                        }}></div>
                                }
                            );
                        }}
                    />
                </FieldContainer>
            </FieldContainer>
        </FieldContainer>
        <FieldContainer>
            <Grid container>
                <Grid item xs={4}>
                    {renderTextFieldFor(settings, 'banner', 'height', 'Banner Height', 'To set the banner height set this value e.g. 320px . ', '320px', undefined, updateState)}
                </Grid>
                <Grid item xs={4}>
                    <ColorPicker
                        theme={theme}
                        settings={settings}
                        onChange={updateState}
                        objectKey={'banner'}
                        valueKey={'backgroundColor'}
                        label={<H3Title
                            title={'Banner Background Color'}/>}
                        placeholderValue={'#FFFFFF'}
                        helperText={'To set the background color for banner. Default is primary color.'}
                    />
                </Grid>
                <Grid item xs={4}>
                    {renderTextFieldFor(settings, 'banner', 'backgroundSize', 'Background Size', 'To set the size of background image set this value e.g. 50%. For details search CSS value background-size.', '50%', undefined, updateState)}
                </Grid>
                <Grid item xs={4}>
                    {renderTextFieldFor(settings, 'banner', 'backgroundPosition', 'Background Position', "To set the position of background image set this value e.g. 'top 0px right -50px'. For details search CSS value background-position.", 'top 0px right -50px', undefined, updateState)}
                </Grid>
            </Grid>
        </FieldContainer>
    </>;
}

const containerStyleDefaultValue = `
{     
    "margin-top": "0px", 
    "text-align": "left" 
}`;

export function BannerContentContainerSettings({settings, onChange}) {
    const renderTextFieldFor = (settings, objectKey, valueKey, label, helpMessageText, defaultValue, innerTextLabel, onChange, multiline) => {
        return <SetupTextField
            multiline={multiline}
            settings={settings}
            helpMessageText={helpMessageText}
            objectKey={objectKey}
            valueKey={valueKey}
            label={label}
            innerTextLabel={innerTextLabel}
            defaultValue={defaultValue}
            onChange={onChange}
        />;
    }

    return <>
        {renderTextFieldFor(settings, 'bannerContentContainer', 'style', 'Banner Container Style', 'Style to apply on banner content container', containerStyleDefaultValue, undefined, () => {
            const style = getBannerContentContainerStyle(settings);
            try {
                JSON.parse(style);
                delete settings[BASIC_SETUP_ERRORS]?.['bannerContentContainer'];
                delete settings[BASIC_SETUP_ERRORS]?.['bannerContentContainer']?.['style'];
            } catch (e) {
                if (settings[BASIC_SETUP_ERRORS] === undefined) {
                    settings[BASIC_SETUP_ERRORS] = {};
                }
                if (settings[BASIC_SETUP_ERRORS]['bannerContentContainer'] === undefined) {
                    settings[BASIC_SETUP_ERRORS]['bannerContentContainer'] = {}
                }
                settings[BASIC_SETUP_ERRORS]['bannerContentContainer']['style'] = "Should be valid JSON."
            }

            onChange();
        }, true)}
    </>;
}

export function BannerTitleSettings({theme, settings, onTitleChange, updateState}) {
    const renderTextFieldFor = (settings, objectKey, valueKey, label, helpMessageText, defaultValue, innerTextLabel, onChange, multiline) => {
        return <SetupTextField
            multiline={multiline}
            settings={settings}
            helpMessageText={helpMessageText}
            objectKey={objectKey}
            valueKey={valueKey}
            label={label}
            innerTextLabel={innerTextLabel}
            defaultValue={defaultValue}
            onChange={onChange}
        />;
    }

    return <>
        {
            getUILabel(
                <H3Title title={'Banner Title'}/>,
                theme,
                settings,
                onTitleChange,
                settings,
                ['banner', 'title']
            )
        }
        <FieldContainer style={{paddingLeft: '8px', paddingRight: '8px'}}>
            <Grid container>
                <Grid item xs={4}>
                    {renderTextFieldFor(settings, 'banner', 'titleFontSize', 'Banner Title Size', 'To set the title size set this value e.g. 48px. For details search CSS value font-size.', '48px', undefined, updateState)}
                </Grid>
                <Grid item xs={4}>
                    {renderTextFieldFor(settings, 'banner', 'titleAlignment', 'Banner Title Alignment', 'To set the title alignment set this value e.g. left/right/center. For details search CSS value text-align.', 'center', undefined, updateState)}
                </Grid>
                <Grid item xs={4}>
                    <ColorPicker
                        theme={theme}
                        settings={settings}
                        onChange={updateState}
                        objectKey={'banner'}
                        valueKey={'titleColor'}
                        label={<H3Title
                            title={'Banner Title Color'}/>}
                        placeholderValue={'#FFFFFF'}
                        helperText={'To set the title color set this value. Default is #FFFFFF.'}
                    />
                </Grid>
            </Grid>
        </FieldContainer>
    </>;
}

export function SearchContainerSettings({settings, updateState}) {
    const renderTextFieldFor = (settings, objectKey, valueKey, label, helpMessageText, defaultValue, innerTextLabel, onChange, multiline) => {
        return <SetupTextField
            multiline={multiline}
            settings={settings}
            helpMessageText={helpMessageText}
            objectKey={objectKey}
            valueKey={valueKey}
            label={label}
            innerTextLabel={innerTextLabel}
            defaultValue={defaultValue}
            onChange={onChange}
        />;
    }

    return renderTextFieldFor(settings, 'searchBar', 'padding', 'Search Bar Padding', 'Set padding for search paper. For details search CSS padding', '0px 40px', undefined, updateState);
}

function RenderRadioGroup({valueKey, keyPath, groupLabel, value, settings, onChange, options, convertToBoolean}) {
    return <FieldContainer>
        <div>
            <FormControl datatest={valueKey} component="fieldset">
                <FormLabel component="legend">{groupLabel}</FormLabel>
                <RadioGroup
                    style={{margin: '8px 0px'}}
                    row
                    aria-label="position"
                    name="position"
                    value={convertToBoolean ? (value === true ? 'Yes': 'No') : value}
                    onChange={async (event, value) => {
                        let valueToSet = convertToBoolean ? (value === 'Yes' ? true: false) : value;
                        if(keyPath) {
                            settings[keyPath][valueKey] = valueToSet;
                        } else {
                            settings[valueKey] = valueToSet;
                        }
                        onChange();
                    }}
                >
                    {options.map(op => {
                        return <FormControlLabel
                            key={op.value}
                            value={op.value}
                            control={<Radio datatest={op.datatest || op.value} color="secondary"/>}
                            label={op.label}
                            labelPlacement="end"
                        />;
                    })}
                </RadioGroup>
            </FormControl>
        </div>
    </FieldContainer>;
}

export function ResourceViewSettings({settings, onChange}) {
    const value = getResourceDetailsViewType(settings);
    const valueKey = 'resourceDetailsViewType';
    const options = [{
        label: 'Tree',
        value: RESOURCE_VIEW_TYPE_TREE,
        datatest: 'resourceViewTypeTree'
    }, {
        label: 'Simple',
        value: RESOURCE_VIEW_TYPE_SIMPLE,
        datatest: 'resourceViewTypeSimple'

    }]
    const groupLabel = 'Resource details view type';

    return <RenderRadioGroup
        valueKey={valueKey}
        settings={settings}
        onChange={onChange}
        value={value}
        options={options}
        groupLabel={groupLabel}
    />;
}

export function LinkedResourceViewSettings({settings, onChange}) {
    return <FieldContainer>
        <div>
            <FormControl component="fieldset">
                <FormLabel component="legend">Linked resources view type</FormLabel>
                <RadioGroup style={{margin: '8px 0px'}} row aria-label="position" name="position"
                            value={getLinkedResourceViewType(settings)} onChange={async (event, value) => {
                    settings.linkedResourcesViewType = value;
                    onChange();
                }}>
                    <FormControlLabel
                        value="withIRI"
                        control={<Radio datatest={'linkedResourcesViewTypeWithIRI'} color="secondary"/>}
                        label="With IRI"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value={LINKED_RESOURCE_VIEW_TYPE_WITHOUT_IRI}
                        control={<Radio datatest={'linkedResourcesViewTypeWithoutIRI'} color="secondary"/>}
                        label="Without IRI"
                        labelPlacement="end"
                    />
                </RadioGroup>
            </FormControl>
        </div>
    </FieldContainer>;
}

export function DatatypeSettings({settings, onChange}) {
    const value = getSimpleViewShowDatatype(settings) || 'Yes';
    const valueKey = 'simpleViewShowDatatype';
    const options = [{
        label: 'Yes',
        value: 'Yes',
        datatest: 'simpleViewShowDatatypeYes'
    }, {
        label: 'No',
        value: 'No',
        datatest: 'simpleViewShowDatatypeNo'

    }]
    const groupLabel = 'Show data type chip';

    return <RenderRadioGroup
        valueKey={valueKey}
        settings={settings}
        onChange={onChange}
        value={value}
        options={options}
        groupLabel={groupLabel}
    />;
}

export function LanguageCodeSettings({settings, onChange}) {
    const value = getSimpleViewShowLanguageCode(settings) || 'Yes';
    const valueKey = 'simpleViewShowLanguageCode';
    const options = [{
        label: 'Yes',
        value: 'Yes',
        datatest: 'simpleViewShowLanguageCodeYes'
    }, {
        label: 'No',
        value: 'No',
        datatest: 'simpleViewShowLanguageCodeNo'

    }]
    const groupLabel = 'Show language code in the chip';
    return <RenderRadioGroup
        valueKey={valueKey}
        settings={settings}
        onChange={onChange}
        value={value}
        options={options}
        groupLabel={groupLabel}
    />;
}

export function LanguageNameSettings({settings, onChange}) {
    const value = getSimpleViewShowLanguageName(settings) || 'Yes';
    const valueKey = 'simpleViewShowLanguageName';
    const options = [{
        label: 'Yes',
        value: 'Yes',
        datatest: 'simpleViewShowLanguageNameYes'
    }, {
        label: 'No',
        value: 'No',
        datatest: 'simpleViewShowLanguageNameNo'

    }]
    const groupLabel = 'Show language name in the chip';
    return <RenderRadioGroup
        valueKey={valueKey}
        settings={settings}
        onChange={onChange}
        value={value}
        options={options}
        groupLabel={groupLabel}
    />;
}

export function ButtonsCardSetting({settings, onChange}) {
    const value = isButtonsCardDisabled(settings) ? 'Yes' : 'No';
    const valueKey = 'disableButtonsCard';
    const options = [{
        label: 'Yes',
        value: 'Yes',
        datatest: 'disableButtonsCardYes'
    }, {
        label: 'No',
        value: 'No',
        datatest: 'disableButtonsCardNo'

    }]
    const groupLabel = 'Disable buttons card';

    return <RenderRadioGroup
        valueKey={valueKey}
        settings={settings}
        onChange={onChange}
        value={value}
        options={options}
        groupLabel={groupLabel}
    />;
}

function RenderButtonGroupOld({settings, onChange, title, valueKey, datatest}) {
    return <FieldContainer>
        <div>
            <FormControl component="fieldset">
                <FormLabel component="legend">{title}</FormLabel>
                <RadioGroup style={{margin: '8px 0px'}} row aria-label="position" name="position"
                            value={settings?.[valueKey]?.disabled === true ? "yes" : "no"} onChange={async () => {
                    initSettingPath(settings, [valueKey]);
                    settings[valueKey].disabled = settings[valueKey].disabled ? false : true;
                    onChange();

                }}>
                    <FormControlLabel
                        value="yes"
                        control={<Radio datatest={datatest+'Yes'} color="secondary"/>}
                        label="Yes"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="no"
                        control={<Radio datatest={datatest+'No'} color="secondary"/>}
                        label="No"
                        labelPlacement="end"
                    />
                </RadioGroup>
            </FormControl>
        </div>
    </FieldContainer>;
}

function RenderButtonGroupForEnable({settings, onChange, title, valueKey, datatest}) {
    return <FieldContainer>
        <div>
            <FormControl component="fieldset">
                <FormLabel component="legend">{title}</FormLabel>
                <RadioGroup style={{margin: '8px 0px'}} row aria-label="position" name="position"
                            value={settings?.[valueKey]?.enabled === true ? "yes" : "no"} onChange={async () => {
                    initSettingPath(settings, [valueKey]);
                    settings[valueKey].enabled = settings[valueKey].enabled ? false : true;
                    onChange();

                }}>
                    <FormControlLabel
                        value="yes"
                        control={<Radio datatest={datatest+'Yes'} color="secondary"/>}
                        label="Yes"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="no"
                        control={<Radio datatest={datatest+'No'} color="secondary"/>}
                        label="No"
                        labelPlacement="end"
                    />
                </RadioGroup>
            </FormControl>
        </div>
    </FieldContainer>;
}

export function SheetsViewButtonSetting({settings, onChange}) {

    return <RenderButtonGroupOld
        title={'Disable sheets view button'}
        settings={settings}
        onChange={onChange}
        valueKey={'sheets'}
        datatest={'sheetViewSwitch'}
    />;
}

export function CreateResourceButtonSetting({settings, onChange}) {

    return <RenderButtonGroupOld
        title={'Disable create resource button'}
        settings={settings}
        onChange={onChange}
        valueKey={'createResourceButton'}
        datatest={'createResourceButtonSwitch'}
    />;
}


export function GraphVisualisationButtonSetting({settings, onChange}) {

    return <RenderButtonGroupOld
        title={'Disable graph visualisation button'}
        settings={settings}
        onChange={onChange}
        valueKey={'graphVisualisationButton'}
        datatest={'graphVisualisationButton'}
    />;
}

export function AIButtonSetting({settings, onChange}) {
    if(settings[KEY_AI_SETTING] === undefined) {
        settings[KEY_AI_SETTING] = {enabled : false};
    }

    return <RenderButtonGroupForEnable
        title={'Enable AI'}
        settings={settings}
        onChange={onChange}
        valueKey={KEY_AI_SETTING}
        datatest={KEY_AI_SETTING}
    />;
}

export function AISearchForUserSetting({settings, onChange}) {
    if(settings?.[KEY_AI_SEARCH_FOR_USERS] === undefined) {
        settings[KEY_AI_SEARCH_FOR_USERS] = {enabled : false};
    }

    return <RenderButtonGroupForEnable
        title={'Enable AI Search For User'}
        settings={settings}
        onChange={onChange}
        valueKey={KEY_AI_SEARCH_FOR_USERS}
        datatest={KEY_AI_SEARCH_FOR_USERS}
    />;
}

export function RecordAISearchUsage({settings, onChange}) {
    if(settings?.[KEY_AI_SEARCH_RECORD_USAGE] === undefined) {
        settings[KEY_AI_SEARCH_RECORD_USAGE] = {enabled : false};
    }

    return <RenderButtonGroupForEnable
        title={'Record AI Search Usage'}
        settings={settings}
        onChange={onChange}
        valueKey={KEY_AI_SEARCH_RECORD_USAGE}
        datatest={KEY_AI_SEARCH_RECORD_USAGE}
    />;
}

const userMessageTemplateDefaultValue = `{{USER_MESSAGE}}`;

export function AIUserMessageTemplate({settings, onChange}) {
    const renderTextFieldFor = (settings, objectKey, valueKey, label, helpMessageText, defaultValue, innerTextLabel, onChange, multiline) => {
        return <SetupTextField
            multiline={multiline}
            settings={settings}
            helpMessageText={helpMessageText}
            objectKey={objectKey}
            valueKey={valueKey}
            label={label}
            innerTextLabel={innerTextLabel}
            defaultValue={defaultValue}
            onChange={onChange}
        />;
    }

    return <>
        {renderTextFieldFor(settings, KEY_AI_SETTING, KEY_USER_MESSAGE_TEMPLATE, 'User Message Template',
            `A template to wrap search message. With this you can add extra instructions for query generation. This value must contain a placeholder ${userMessageTemplateDefaultValue}. When a message is sent to AI model, the placeholder value ${userMessageTemplateDefaultValue} is replaced with the search text entered by user.`, userMessageTemplateDefaultValue, undefined, () => {
            const template = getUserMessageTemplate(settings);
            if(!template.includes(userMessageTemplateDefaultValue)) {
                if(!settings[BASIC_SETUP_ERRORS]) {
                    settings[BASIC_SETUP_ERRORS]= {}
                }
                if(!settings[BASIC_SETUP_ERRORS][KEY_AI_SETTING]) {
                    settings[BASIC_SETUP_ERRORS][KEY_AI_SETTING] = {}
                }
                settings[BASIC_SETUP_ERRORS][KEY_AI_SETTING][KEY_USER_MESSAGE_TEMPLATE] = `Value string must include placeholder ${userMessageTemplateDefaultValue} .`
                onChange(settings[BASIC_SETUP_ERRORS][KEY_AI_SETTING][KEY_USER_MESSAGE_TEMPLATE]);
            } else {
                delete settings[BASIC_SETUP_ERRORS]?.[KEY_AI_SETTING];
                delete settings[BASIC_SETUP_ERRORS]?.[KEY_AI_SETTING]?.[KEY_USER_MESSAGE_TEMPLATE];
                onChange();
            }
        }, true)}
    </>;
}

export function DataVisualisationButtonSetting({settings, onChange}) {

    return <RenderButtonGroupOld
        title={'Disable data visualisation button'}
        settings={settings}
        onChange={onChange}
        valueKey={'dataVisualisationButton'}
        datatest={'dataVisualisationButton'}
    />;
}

export function AppBarSettings({theme, settings, onChange}) {
    return <Grid datatest={'appBarSettings'} container spacing={1}>
        <Grid item>
            <ColorPicker
                theme={theme}
                settings={settings}
                onChange={onChange}
                objectKey={'banner'}
                valueKey={'appBarBackgroundColor'}
                label={<H3Title title={'App Bar Background Color'}/>}
                placeholderValue={'#FFFFFF'}
                helperText={'Default is theme primary color.'}
            />
        </Grid>
        <Grid item>
            <ColorPicker
                theme={theme}
                settings={settings}
                onChange={onChange}
                objectKey={'banner'}
                valueKey={'appBarButtonColor'}
                label={<H3Title title={'App Bar Button Color'}/>}
                placeholderValue={'#FFFFFF'}
                helperText={'Default is theme primary color.'}
            />
        </Grid>
    </Grid>;
}

export function AISettings({settings, onRadioChange, onChange}) {
    const [models, setModels] = React.useState([]);
    const [apiErrorResponse, setApiErrorResponse] = React.useState([]);

    useEffect(() => {
        const loadData = async () => {
            getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_DATASET_SEARCH).then(async (apiResponse) => {
                let json = await apiResponse.json();
                let datasets = getSearchResult(json);
                let datasetLabel = getDatasetLabel();
                let found = toArray(datasets).find(dt => dt[ALIAS_MANAGEMENT_ID_LABEL] === datasetLabel);
                getModels(getResourceId(found)).then(async result => {
                    if(isRequestSuccessful(result)) {
                        const items = await result.json();
                        let modelsForList = toArray(items[ALIAS_SYS_RESULTS]).filter(m => m.viewStatus !== 'deleted' && m.backendJobStatus === "succeeded");
                        setModels(modelsForList);
                    } else {
                        setApiErrorResponse(apiErrorResponse);
                    }
                })
            }).catch(handleBackendError(this));
        };
        loadData().catch(e => {});
    }, []);


    return <>
        {isAIEnabled() ?
            <>
                <AIButtonSetting
                    settings={settings}
                    onChange={onRadioChange}
                />
                {
                    settings[KEY_AI_SETTING]?.enabled === true &&
                    <FieldContainer>
                        <FormControl style={{minWidth: '300px', marginBottom: '32px'}} size={'small'}
                                     variant="outlined">
                            <InputLabel id="model-select">Default Model</InputLabel>
                            <Select
                                datatest={'chatModelSelect'}
                                labelId="model-select"
                                label="Default Model"
                                variant={'outlined'}
                                value={getDefaultAIModel(settings)}
                                onChange={(event) => {
                                    if (settings[KEY_AI_SETTING] === undefined) {
                                        settings[KEY_AI_SETTING] = {}
                                    }
                                    const defaultModel = event.target.value;
                                    settings[KEY_AI_SETTING].defaultModel = defaultModel;
                                    const found = toArray(models).find(m => m.backendFineTunedModelId === defaultModel);
                                    delete settings[KEY_AI_SETTING]?.[TARGET_CLASS_BASE_IRIS];
                                    delete settings[KEY_AI_SETTING]?.[TARGET_CLASS_IRIS];
                                    if (found) {
                                        settings[KEY_AI_SETTING][TARGET_CLASS_BASE_IRIS] = found[TARGET_CLASS_BASE_IRIS];
                                        settings[KEY_AI_SETTING][TARGET_CLASS_IRIS] = found[TARGET_CLASS_IRIS];
                                    }
                                    onChange();
                                }}
                            >
                                <ListSubheader>Base Models</ListSubheader>
                                {getBaseAIModels().map(m => <MenuItem key={m} value={m}>{m}</MenuItem>)}
                                <ListSubheader>Fine Tuned Models</ListSubheader>
                                {toArray(models).map(l => <MenuItem key={getResourceId(l)}
                                                                               value={l.backendFineTunedModelId}>{l.title + " (" + l.backendFineTunedModelId + ")"}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <AISearchForUserSetting
                            settings={settings}
                            onChange={onRadioChange}
                        />
                        <RecordAISearchUsage
                            settings={settings}
                            onChange={onRadioChange}
                        />
                        <AIUserMessageTemplate
                            settings={settings}
                            onChange={onChange}
                        />
                    </FieldContainer>
                }
            </>
            : <Typography>To configure AI based search and model fine tuning set Open AI API key in the environment
                variable "OPENAI_API_KEY" and restart EasyGraph.</Typography>
        }
    </>;
}

class BasicSetup extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.setupChanged();
        this.syncInstanceConfigs();
        this.syncAIModels();
    }

    syncAIModels = (onComplete) => {
    }

    setupChanged = () => {
        let {onChange, settings} = this.props;
        this.validate();
        onChange(settings[BASIC_SETUP_ERRORS]);
    }

    validate = () => {
    }

    setError = (objectKey, valueKey, error) => {
        let {settings} = this.props;
        let errorObject = settings[BASIC_SETUP_ERRORS];
        if (error) {
            if (!errorObject) {
                settings[BASIC_SETUP_ERRORS] = {};
            }
            settings[BASIC_SETUP_ERRORS][objectKey] = {[valueKey]: error};
        } else {
            if (errorObject) {
                if(errorObject[objectKey]) {
                    delete errorObject[objectKey][valueKey];
                }
                if(errorObject[objectKey] && Object.keys(errorObject[objectKey]).length === 0) {
                    delete errorObject[objectKey];
                }
            }
        }
    }

    syncInstanceConfigs = async () => {
        let instanceConfiguration = await getInstanceConfiguration();
        let parsed = getInstanceConfigurationSettings(instanceConfiguration);
        this.setState({instanceConfiguration: instanceConfiguration, instanceConfigurationSettings : parsed});
    }


    setHomePage = async (isHomePage) => {
        const {instanceConfiguration} = this.state;
        const {location} = this.props;
        this.setState({loading : true});
        if(!instanceConfiguration) {
            let obj = {};
            obj[AT_CONTEXT] = getManagementContextURL();
            obj[TYPE] = ALIAS_MANAGEMENT_TYPE_INSTANCE_CONFIGURATION;
            let settings = {
                [INSTANCE_CONFIGURATION_SETTING_HOME_PAGE]: getSiteHomePath(location)
            }
            obj[ALIAS_MANAGEMENT_SETTINGS] = JSON.stringify(settings)

            await postManagementGraph(JSON.stringify(obj));
        } else {
            let settings = instanceConfiguration[ALIAS_MANAGEMENT_SETTINGS];
            let parsedSettings = JSON.parse(settings);
            if(isHomePage) {
                delete parsedSettings[INSTANCE_CONFIGURATION_SETTING_HOME_PAGE];
            } else {
                parsedSettings[INSTANCE_CONFIGURATION_SETTING_HOME_PAGE] = getSiteHomePath(location);
            }
            instanceConfiguration[ALIAS_MANAGEMENT_SETTINGS] = JSON.stringify(parsedSettings);
            instanceConfiguration[AT_CONTEXT] = getManagementContextURL();
            await patchManagementGraph(JSON.stringify(instanceConfiguration));
        }
        await this.syncInstanceConfigs();
        this.setState({loading:false});
    }


    renderColorPicker = (label, name, theme, settings, placeholderValue) => {
        return <Grid item> <ThemeColorPicker
            label={label}
            name={name}
            theme={theme}
            settings={settings}
            onChange={this.setupChanged}
            placeholderValue={placeholderValue}
        /></Grid>;
    }

    renderTextFieldFor = (settings, objectKey, valueKey, label, helpMessageText, defaultValue, innerTextLabel, onChange, multiline) => {
        return <SetupTextField
            multiline={multiline}
            settings={settings}
            helpMessageText={helpMessageText}
            objectKey={objectKey}
            valueKey={valueKey}
            label={label}
            innerTextLabel={innerTextLabel}
            defaultValue={defaultValue}
            onChange={this.handleTextChange(objectKey, valueKey, onChange)}
        />;
    }

    handleTextChange(objectKey, valueKey, onChange) {
        return (error) => {
            this.setError(objectKey, valueKey, error);
            this.setupChanged();
            onChange && onChange()
        };
    }

    renderImageSetting = ( valueObject, objectKey, valueKey, label = 'Image URL', innerTextLabel, containerStyle = {}, helpMessage = 'Enter url above or upload a small PNG (.png) image without any background color.', valueProvider, previewRenderer) => {
        return <ImageSetting
            workspace={this.props.workspace}
            valueObject={valueObject}
            objectKey={objectKey}
            valueKey={valueKey}
            label={label}
            innerTextLabel={innerTextLabel}
            containerStyle={containerStyle}
            helpMessage={helpMessage}
            valueProvider={valueProvider}
            previewRenderer={previewRenderer}
            textFieldRenderer={this.renderTextFieldFor}
            onFileUpload={this.handleFileUpload}
        />;
    }

    handleFileUpload = () => {
        this.setupChanged();
        this.setState({});
    }

    render() {
        let {settings, workspace, theme, stepName, onChange, location, classes} = this.props;
        let {instanceConfigurationSettings} = this.state;
        let isHomePage = instanceConfigurationSettings?.[INSTANCE_CONFIGURATION_SETTING_HOME_PAGE] === getSiteHomePath(location);
        return <GridContainer>
            <Grid item xs={12} style={{paddingTop: 0}}>
                <MainHeaderBar title={stepName}></MainHeaderBar>
            </Grid>
            <Grid item xs={12}>
                <FieldContainer style={{padding: '16px'}}>
                    <div>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Do you want to make this site the homepage and publicly
                                available?</FormLabel>
                            <RadioGroup style={{margin: '8px 0px'}} row aria-label="position" name="position"
                                        value={isHomePage === true ? "yes" : "no"} onChange={async () => {
                                await this.setHomePage(isHomePage);

                            }}>
                                <FormControlLabel
                                    value="yes"
                                    control={<Radio datatest={'readOnlySwitchYes'} color="secondary"/>}
                                    label="Yes"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="no"
                                    control={<Radio datatest={'readOnlySwitchYes'} color="secondary"/>}
                                    label="No"
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                            {
                                isTrialInstance()
                                    ? <FormHelperText>This option is not available for trial instance.</FormHelperText>
                                    :
                                    <FormHelperText>To make this site public you can switch on this setting. This means
                                        a user will be able to access the site without logging in. Note that you will
                                        also need to set the authentication for the API to public reads. You can do this
                                        from System Manager.</FormHelperText>
                            }
                        </FormControl>

                    </div>
                </FieldContainer>
            </Grid>
            <Grid datatest={'theme'} item xs={12}>
                <H2Title title={'Theme'}/>
                <FieldContainer style={{display: 'flex', marginBottom: '8px'}}>
                    <Grid container spacing={1}>
                        {this.renderColorPicker('Primary Color', 'primary', theme, settings, PRIMARY_COLOR)}
                        {this.renderColorPicker('Secondary Color', 'secondary', theme, settings, SECONDARY_COLOR)}
                        {this.renderColorPicker('Warning Color', 'warning', theme, settings, SECONDARY_COLOR)}
                        {this.renderColorPicker('Error Color', 'error', theme, settings, SECONDARY_COLOR)}
                        {this.renderColorPicker('Success Color', 'success', theme, settings, SUCCESS_COLOR)}
                        {this.renderColorPicker('Link Color', 'link', theme, settings, LINK_COLOR)}
                    </Grid>

                </FieldContainer>
            </Grid>

            <Grid datatest={'favicon'} item xs={12}>
                <H2Title title={'Favicon'}/>
                {this.renderImageSetting(settings, 'favicon', 'imageURL')}
            </Grid>
            <Grid datatest={'bannerContainer'} item xs={12}>
                <H2Title title={'Banner'}/>
                <FieldContainer>
                    <FieldContainer>
                        <H3Title title={'Banner Preview'}></H3Title>
                        <div style={{marginTop: '8px'}}>
                            {
                                getHeader(
                                    " ",
                                    undefined,
                                    () => {
                                    },
                                    undefined,
                                    () => {
                                        return renderLogo(classes, location, settings, theme);
                                    },
                                    () => {
                                    },
                                    true,
                                    {padding: '0px 32px 0px 64px'},
                                    true
                                )
                            }
                            {renderBanner(settings, theme, workspace, this.props.browseLanguage, () => {
                            })}
                        </div>
                    </FieldContainer>

                    {false && this.showBannerOnAllPagesSetting(settings)}

                </FieldContainer>
                <LogoImageSettings
                    theme={theme}
                    workspace={workspace}
                    settings={settings}
                    onFileUpload={this.handleFileUpload}
                    onTextChange={this.handleTextChange}
                />
                <FieldContainer style={{padding: '0px'}}>
                    <FieldContainer style={{padding: '24px'}}>
                        <H3Title title={'White Background Logo Image URL'}></H3Title>
                        <FieldContainer
                            datatest={'whiteLogo'}
                            style={{marginTop: '8px', border: '2px solid', borderColor: theme.palette.white.main}}
                            disableLeftRightPadding={true}>
                            {this.renderImageSetting(settings, 'logo', 'whiteBackgroundImageURL', ' ', undefined, undefined, undefined, undefined, renderImage)}
                        </FieldContainer>
                    </FieldContainer>
                </FieldContainer>
                <FieldContainer style={{paddingLeft: '8px', paddingRight: '8px'}}>
                    <AppBarSettings
                        theme={theme}
                        settings={settings}
                        onChange={() => this.setState({})}
                    />
                </FieldContainer>
                <FieldContainer style={{paddingLeft: '8px', paddingRight: '8px'}}>
                    <Grid container>
                        <Grid item>
                            <BannerContentContainerSettings
                                containerStyleDefaultValue={containerStyleDefaultValue}
                                settings={settings}
                                onChange={() => {
                                    this.setupChanged();
                                    this.setState({})
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <SearchContainerSettings
                                settings={settings}
                                updateState={() => this.setState({})}
                            />
                        </Grid>
                    </Grid>
                </FieldContainer>
                <BannerTitleSettings
                    settings={settings}
                    theme={theme}
                    onTitleChange={() => {
                        this.setupChanged();
                        this.setState({})
                    }}
                    updateState={() => this.setState({})}
                />
                <BannerContainerSettings
                    theme={theme}
                    workspace={workspace}
                    settings={settings}
                    onChange={onChange}
                    updateState={() => this.setState({})}
                />

            </Grid>
            <Grid datatest={'sheets'} item xs={12}>
                <H2Title title={'Feedback Settings'}/>
                <FieldContainer>
                    {this.enableFeedbackButton(settings, theme)}
                </FieldContainer>
            </Grid>
            <Grid datatest={'sheets'} item xs={12}>
                <H2Title title={'AI Settings'}/>
                <FieldContainer>
                    <AISettings
                        settings={settings}
                        onRadioChange={this.handleRadioChange}
                        onChange={(error) => {
                            this.setupChanged();
                            this.setState({})
                        }}/>
                </FieldContainer>
            </Grid>
            <Grid datatest={'sheets'} item xs={12}>
                <H2Title title={'Other Settings'}/>
                <FieldContainer>
                    <ButtonsCardSetting
                        settings={settings}
                        onChange={this.handleRadioChange}
                    />
                    {isButtonsCardDisabled(settings) || <CreateResourceButtonSetting
                        settings={settings}
                        onChange={this.handleRadioChange}
                    />}
                    {isButtonsCardDisabled(settings) || <SheetsViewButtonSetting
                        settings={settings}
                        onChange={this.handleRadioChange}
                    />}
                    {
                        isButtonsCardDisabled(settings) || <FieldContainer>
                            <div>
                                <GraphVisualisationButtonSetting
                                    settings={settings}
                                    onChange={this.handleRadioChange}
                                />
                            </div>
                        </FieldContainer>
                    }
                    {
                        isButtonsCardDisabled(settings) || <FieldContainer>
                            <div>
                                <DataVisualisationButtonSetting
                                    settings={settings}
                                    onChange={this.handleRadioChange}
                                />
                            </div>
                        </FieldContainer>
                    }
                    <LinkedResourceViewSettings
                        settings={settings}
                        onChange={this.handleRadioChange}
                    />
                    {this.resourceViewType(settings)}
                    {isResourceViewTypeSimple(settings) &&
                        <DatatypeSettings settings={settings} onChange={this.handleRadioChange}/>}
                    {isResourceViewTypeSimple(settings) &&
                        <LanguageCodeSettings settings={settings} onChange={this.handleRadioChange}/>}
                    {isResourceViewTypeSimple(settings) &&
                        <LanguageNameSettings settings={settings} onChange={this.handleRadioChange}/>}
                </FieldContainer>
            </Grid>

        </GridContainer>;

    }

    handleRadioChange = () => {
        this.setupChanged();
        this.setState({});
    }



    linkedResourcesViewType(settings) {
        return <LinkedResourceViewSettings
            settings={settings}
            onChange={() => {
                this.setupChanged();
                this.setState({});
            }}
        />;
    }

    resourceViewType(settings) {
        return <ResourceViewSettings
            settings={settings}
            onChange={() => {
                this.setupChanged();
                this.setState({});
            }}
        />;
    }

    enableFeedbackButton(settings, theme) {

        return <FieldContainer>
            <div>
                <Typography color={'secondary'} variant={'caption'}>After changing this setting please reload the page.</Typography>
                <div></div>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Do you want to enable feedback?</FormLabel>
                    <RadioGroup style={{margin: '8px 0px'}} row aria-label="position" name="position"
                                value={isFeedbackEnabled(settings) ? "yes" : "no"} onChange={async () => {
                        initSettingPath(settings, ['feedback']);
                        let filename = 'feedback.ttl';
                        let formData = new FormData();
                        formData.append('files', new File([new Blob([feedbackOntology])], filename));
                        const mergeOptionValue = MERGE_OPTIONS.find(m => m.label === MERGE_PARTIAL_MODEL_RELOAD).value;
                        formData.append('existingConfiguration', mergeOptionValue);

                        const feedbackEnabled = isFeedbackEnabled(settings);
                        settings.feedback.enabled = !feedbackEnabled;

                        //Load ontology if feedback is not enabled
                        if(feedbackEnabled === false) {
                            this.setState({loading: true});
                            //First bootstrap
                            let r = await ontologyBootstrap(formData);
                            if (!isRequestSuccessful(r)) {
                                this.setState({apiErrorResponse: r, loading: false});

                            } else {
                                //Now update configurations
                                await this.updateFeedbackConfiguration('true');
                            }
                        } else {
                            await this.updateFeedbackConfiguration('false');
                        }
                    }}>
                        <FormControlLabel
                            value="yes"
                            control={<Radio datatest={'feedbackSwitchYes'} color="secondary"/>}
                            label="Yes"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio datatest={'feedbackSwitchNo'} color="secondary"/>}
                            label="No"
                            labelPlacement="end"
                        />
                    </RadioGroup>
                </FormControl>
                <Grid container>
                    <Grid item xs={4}>
                        <ColorPicker
                            theme={theme}
                            settings={settings}
                            onChange={() => this.setState({})}
                            objectKey={'feedbackStyle'}
                            valueKey={'backgroundColor'}
                            label={<H3Title
                                title={'Widget Background Color'}/>}
                            placeholderValue={DEFAULT_FEEDBACK_BG_COLOR}
                            helperText={`Default is ${DEFAULT_FEEDBACK_BG_COLOR}.`}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <ColorPicker
                            theme={theme}
                            settings={settings}
                            onChange={() => this.setState({})}
                            objectKey={'feedbackStyle'}
                            valueKey={'borderColor'}
                            label={<H3Title
                                title={'Widget Border Color'}/>}
                            placeholderValue={DEFAULT_FEEDBACK_BORDER_COLOR}
                            helperText={`Default is ${DEFAULT_FEEDBACK_BORDER_COLOR}.`}
                        />

                    </Grid>
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Feedback widget placement</FormLabel>
                            <RadioGroup style={{margin: '8px 0px'}} row aria-label="position" name="position"
                                        value={isFeedbackPlacementOnLeft(settings) ? "left" : "right"} onChange={async () => {
                                settings.feedback.placement = isFeedbackPlacementOnLeft(settings) ? 'right' : 'left';
                                this.setState({});
                            }}>
                                <FormControlLabel
                                    value="left"
                                    control={<Radio datatest={'feedbackPlacementLeft'} color="secondary"/>}
                                    label="Bottom Left"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="right"
                                    control={<Radio datatest={'feedbackPlacementRight'} color="secondary"/>}
                                    label="Bottom Right"
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                        </FormControl>


                    </Grid>
                </Grid>

            </div>
        </FieldContainer>;
    }

    async updateFeedbackConfiguration(containerValue) {
        const response = await getAllConfigurations();
        let containers = getContainerData(response);
        let container = containers.find(cn => cn[ALIAS_SYS_CLASS_IRI] === IRI_TYPE_FEEDBACK);
        if(containerValue === 'true' && container === undefined) {
            let aliasesMap = createAliasesMap(getApiConfigurationResource(response));
            let label = aliasesMap[IRI_TYPE_FEEDBACK].toLowerCase();
            container = createContainerPayload(label, IRI_TYPE_FEEDBACK, 'true');
        }
        if(container) {
            const payload = cloneDeep(container);
            payload[AT_CONTEXT] = getSystemContextURL();
            payload[ALIAS_SYS_IDENTIFIER] = containerValue;
            await putConfiguration(payload);
        }
        await this.syncInstanceConfigs();
        this.setupChanged();
        this.setState({loading: false});
    }

    showBannerOnAllPagesSetting(settings) {
        return <FieldContainer>
            <div>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Do you want the banner to show on all the pages?</FormLabel>
                    <RadioGroup style={{margin: '8px 0px'}} row aria-label="position" name="position"
                                value={settings?.banner?.showOnAllPages === true ? "yes" : "no"} onChange={async () => {
                        initSettingPath(settings, ['banner']);
                        settings.banner.showOnAllPages = settings?.banner.showOnAllPages ? false : true;
                        this.setupChanged();
                        this.setState({});

                    }}>
                        <FormControlLabel
                            value="yes"
                            control={<Radio datatest={'readOnlySwitchYes'} color="secondary"/>}
                            label="Yes"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio datatest={'readOnlySwitchYes'} color="secondary"/>}
                            label="No"
                            labelPlacement="end"
                        />
                    </RadioGroup>
                </FormControl>

            </div>
        </FieldContainer>;
    }
}

BasicSetup.propTypes = {
    browseLanguage : PropTypes.any,
    workspace: PropTypes.object,
    settings: PropTypes.object,
    onChange: PropTypes.func,
    stepName: PropTypes.string,
    location: PropTypes.any,
};

export default withStyles(styles, {withTheme: true})(BasicSetup);
